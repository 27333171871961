import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import ProducerButton from "../../common/ProducerButton/ProducerButton";
import Timeline from "../../common/Timeline/Timeline";
import useForms from "../Forms/useForms";
import GreyCard from "./GreyCard";
import Invitation from "./Invitation";
import AboutPerceval from "./AboutPerceval";
import RenewableQuittances from "../ClientList/RenewablePolicies/RenewableQuittances";
import RenewablePolicies from "../ClientList/RenewablePolicies/RenewablePolicies";

const Dashboard = (props) => {
  const [state, dispatch] = useStore();
  const { PHONE_PERCEVAL, PHONE_PJ } = state.constants.items.PERCEVAL_PHONES;
  const { user } = state.auth;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoadingInvit, setIsLoadingInvit] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const { clients, hasAC, hasMRP, hasCYBER } = useForms();

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = () => {
    setIsLoading(true);
    dataService.get(
      "customers/me/dashboard",
      (datas) => {
        setDashboard(datas);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    if (dashboard) {
      tools.saveState("dashboard", "myRoles", dashboard.clients.roles);
    }
  }, [dashboard]);

  const acceptInvitation = (invitationId) => {
    setIsLoadingInvit(invitationId);
    dataService.get(
      `roles/${invitationId}/accept`,
      (datas) => {
        toast.success("Vous avez accepté l'invitation");
        getDashboard();
      },
      setErrors,
      () => setIsLoadingInvit(false)
    );
  };
  const declineInvitation = (invitationId) => {
    setIsLoadingInvit(invitationId);
    dataService.get(
      `roles/${invitationId}/reject`,
      (datas) => {
        toast.warning("Vous avez refusé l'invitation");
        getDashboard();
      },
      setErrors,
      () => setIsLoadingInvit(false)
    );
  };

  return (
    <Page margin="mt-5">
      {isLoading ? (
        <Loader size={70} />
      ) : (
        dashboard && (
          <div className="py-3 px-3 animated fadeIn">
            <strong
              className="text-success mb-3"
              style={{ fontSize: 30, lineHeight: "35px", fontWeight: "600" }}
            >
              Bienvenue{" "}
              <img
                style={{
                  position: "absolute",
                  transform: "translate(10px, -5px)",
                  height: 45,
                }}
                src="/images/hand.png"
                alt="Bonjour !"
              />
              <br />
              <span style={{ textTransform: "capitalize" }}>
                {user.firstname}
                {" !"}
              </span>
            </strong>
            <p
              className="mx-0 mt-4 mb-5"
              style={{ fontSize: 22, lineHeight: "26px", color: "black" }}
            >
              {dashboard.clients.nbPolicies == 0 ? (
                `Vous gérez ${dashboard.clients.nbClients} société${
                  dashboard.clients.nbClients > 1 ? "s" : ""
                } et aucun contrat pour le moment.`
              ) : (
                <>
                  Vous avez {dashboard.clients.nbClients} entreprise
                  {dashboard.clients.nbClients > 1 ? "s" : ""}, et{" "}
                  <Link
                    className="btn btn-link p-0"
                    to="/clients"
                    style={{
                      textDecoration: "underline",
                      fontSize: 22,
                      lineHeight: "26px",
                      textTransform: "lowercase",
                    }}
                  >
                    {dashboard.clients.nbPolicies} contrat
                    {dashboard.clients.nbPolicies > 1 ? "s" : ""}
                  </Link>{" "}
                  en cours.
                </>
              )}
            </p>
            <div className="row">
              <div className="col-12">
                <RenewablePolicies />
              </div>
              <div className="col-12">
                <RenewableQuittances />
              </div>
              <div className="col-12 col-lg-6">
                {dashboard.invitations.map((inv) => (
                  <Invitation
                    invitation={inv}
                    acceptInvitation={() => acceptInvitation(inv.id)}
                    declineInvitation={() => declineInvitation(inv.id)}
                    isLoadingInvit={isLoadingInvit}
                  />
                ))}

                {dashboard.clients.nbClients > 0 ? (
                  <GreyCard>
                    <>
                      <div
                        className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer"
                        onClick={() => props.history.push("/clients")}
                      >
                        <strong
                          className="text-primary"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: 19,
                            lineHeight: "23px",
                          }}
                        >
                          Entreprise·s
                        </strong>
                        <i
                          className={`fa fa-chess-rook text-primary`}
                          style={{ fontSize: 30 }}
                        />
                      </div>
                      <div className="">
                        {dashboard.clients.roles.map((r, rk) => {
                          return (
                            <React.Fragment key={`rolkey${rk}`}>
                              {r.clients
                                .sort((a, b) =>
                                  a.updatedAt > b.updatedAt ? -1 : 1
                                )
                                .map((c, ck) => {
                                  if (ck > 2) return null;
                                  return (
                                    <div
                                      key={`role${rk}${ck}`}
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: 14,
                                        fontWeight: "500",
                                        lineHeight: "22px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {c.name}
                                    </div>
                                  );
                                })}
                              {r.clients.length > 3 ? (
                                <i>
                                  ... +{r.clients.length - 3} autre
                                  {r.clients.length - 3 > 1 ? "s" : ""} société
                                  {r.clients.length - 3 > 1 ? "s" : ""}
                                </i>
                              ) : null}
                              <div
                                className="my-2"
                                style={{ fontSize: 14, lineHeight: "22px" }}
                              >
                                <i>Dont vous êtes</i> <strong>{r.role}</strong>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </>
                  </GreyCard>
                ) : null}

                {(hasAC || hasMRP) && (
                  <GreyCard>
                    <>
                      <div
                        className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer"
                        onClick={() => props.history.push("/forms")}
                      >
                        <strong
                          className="text-primary"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: 19,
                            lineHeight: "23px",
                          }}
                        >
                          Demande·s en cours
                        </strong>
                        <i
                          className={`fa fa-paper-plane text-primary`}
                          style={{ fontSize: 30 }}
                        />
                      </div>
                      <div className="">
                        <>
                          {hasAC && (
                            <>
                              <div>
                                <strong>Assurance Construction</strong>
                              </div>
                              {clients.AC.sort((a, b) =>
                                a.quotationUpdatedAt > b.quotationUpdatedAt
                                  ? -1
                                  : 1
                              ).map((c, ck) => {
                                if (ck > 2) return null;
                                return (
                                  <div
                                    key={`estabdeac${ck}`}
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: 14,
                                      fontWeight: "500",
                                      lineHeight: "22px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {c.establishments.map((et) => et.company)}
                                  </div>
                                );
                              })}
                            </>
                          )}
                          {hasCYBER && (
                            <>
                              <div>
                                <strong>Assurance Cyber</strong>
                              </div>
                              {clients.CYBER.sort((a, b) =>
                                a.quotationUpdatedAt > b.quotationUpdatedAt
                                  ? -1
                                  : 1
                              ).map((c, ck) => {
                                if (ck > 2) return null;
                                return (
                                  <div
                                    key={`estabdeac${ck}`}
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: 14,
                                      fontWeight: "500",
                                      lineHeight: "22px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {c.establishments.map((et) => et.company)}
                                  </div>
                                );
                              })}
                            </>
                          )}
                          {hasMRP && (
                            <>
                              <div className="mt-2">
                                <strong>Questionnaire Multirisque Pro</strong>
                              </div>
                              {clients.MRP.sort((a, b) =>
                                a.quotationUpdatedAt > b.quotationUpdatedAt
                                  ? -1
                                  : 1
                              ).map((c, ck) => {
                                if (ck > 2) return null;
                                return (
                                  <div
                                    key={`estabdemrp${ck}`}
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: 14,
                                      fontWeight: "500",
                                      lineHeight: "22px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {c.establishments.map((et) => (
                                      <div>{et.company}</div>
                                    ))}
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {/* <div
                              className="my-2"
                              style={{ fontSize: 14, lineHeight: "22px" }}
                            >
                              <i>Dont vous êtes</i> <strong>{r.role}</strong>
                            </div> */}
                        </>
                      </div>
                    </>
                  </GreyCard>
                )}

                {/* AJOUTER ICI LE BLOCK PJ */}
                {dashboard.producers.length > 0 && (
                  <GreyCard>
                    <>
                      <div className="d-flex align-items-start flex-row justify-content-between w-100 ">
                        <strong
                          className="text-success"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: 19,
                            lineHeight: "23px",
                          }}
                        >
                          Gestionnaire·s
                        </strong>
                        <i
                          className={`fa fa-user-tie text-success`}
                          style={{ fontSize: 30 }}
                        />
                      </div>
                      <div className="" style={{ lineHeight: "23px" }}>
                        <ul className="px-0 mb-0" style={{ listStyle: "none" }}>
                          {dashboard.producers.map((producer, pk) => (
                            <li
                              className="mr-2 text-grey"
                              key={`producers${pk}`}
                            >
                              <div className="mb-2 d-flex flex-column">
                                <ProducerButton producer={producer} />
                                {producer.policies.map((policy) => (
                                  <i
                                    className="text-body"
                                    style={{ fontSize: 12 }}
                                    key={`prodpol${policy.id}`}
                                  >
                                    {policy.title}
                                  </i>
                                ))}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  </GreyCard>
                )}
                <GreyCard>
                  <div className="d-flex align-items-start flex-row justify-content-between w-100">
                    <strong
                      className="text-success"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: 19,
                        lineHeight: "23px",
                      }}
                    >
                      Contacter notre équipe
                    </strong>
                  </div>
                  <div className="" style={{ marginTop: 12 }}>
                    <p className="m-0">
                      Pour obtenir une réponse rapide de <br />
                      <strong>8h30 à 18h du lundi au vendredi.</strong>
                    </p>
                    <a
                      href="#"
                      className="btn btn-link text-primary px-0 py-3 d-flex align-items-center justify-content-start"
                      style={{
                        textTransform: "none",
                        fontSize: 16,
                        fontWeight: "bold",
                        lineHeight: "19px",
                        textDecoration: "none",
                      }}
                      onClick={tools.openChat}
                    >
                      <i
                        className="fa fa-comment-dots mr-3"
                        style={{ fontSize: 26 }}
                      />
                      Discutons sur le tchat
                    </a>

                    {dashboard.clients.isGozen ? (
                      <>
                        <p className="m-0">
                          Une{" "}
                          <strong>
                            question ou un litige d'ordre juridique ?
                          </strong>{" "}
                          Plus de 90 juristes spécialisés de Groupama PJ vous
                          répondent du lundi au samedi (non surtaxé).
                        </p>
                        <a
                          href={`tel:${PHONE_PJ.split(" ").join("")}`}
                          className="btn btn-link text-primary p-0 d-flex align-items-center justify-content-start text-left font-weight-bold my-3"
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            lineHeight: "26px",
                            textDecoration: "none",
                          }}
                        >
                          <i
                            className="fa fa-phone-volume mr-3"
                            style={{ fontSize: 26 }}
                          />
                          <span>
                            Faire appel à un juriste GoZen au{" "}
                            <span style={{ whiteSpace: "nowrap" }}>
                              {PHONE_PJ}
                            </span>
                          </span>
                        </a>
                      </>
                    ) : (
                      <>
                        <p className="m-0">
                          Une <strong>question ou besoin d'aide ?</strong> pour
                          gérer <strong>votre contrat ?</strong>
                        </p>
                        <a
                          href={`tel:${PHONE_PERCEVAL.split(" ").join("")}`}
                          className="btn btn-link text-primary p-0 d-flex align-items-start justify-content-start text-left font-weight-bold my-3"
                          style={{
                            textTransform: "none",
                            fontSize: 16,
                            lineHeight: "26px",
                            textDecoration: "none",
                          }}
                        >
                          <i
                            className="fa fa-phone-volume mr-3"
                            style={{ fontSize: 26 }}
                          />
                          Appeler au {PHONE_PERCEVAL}
                        </a>
                      </>
                    )}
                    <p className="my-3">
                      Vous préférez prendre le temps de{" "}
                      <strong>
                        nous écrire, l'équipe vous répondra dans la journée.
                      </strong>
                    </p>
                    <a
                      href="mailto:bonjour@goperceval.fr"
                      className="btn btn-link text-primary p-0 d-flex align-items-start justify-content-start text-left font-weight-bold my-3"
                      style={{
                        textTransform: "none",
                        fontSize: 16,
                        lineHeight: "26px",
                        textDecoration: "none",
                      }}
                    >
                      <i
                        className="fa fa-paper-plane mr-3"
                        style={{ fontSize: 26 }}
                      />
                      bonjour@goperceval.fr
                    </a>
                  </div>
                </GreyCard>
                <GreyCard dataPriv="app_viewusers">
                  <>
                    <div
                      className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer"
                      onClick={() => props.history.push("/accounts")}
                    >
                      <strong
                        className="text-primary"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontSize: 19,
                          lineHeight: "23px",
                        }}
                      >
                        Utilisateur·s
                      </strong>
                      <i
                        className={`fa fa-users text-primary`}
                        style={{ fontSize: 30 }}
                      />
                    </div>
                    <div className="">
                      {!dashboard.users.length ? (
                        <p>
                          Pour le moment{" "}
                          <strong>
                            vous êtes le seul à pouvoir gérer vos sociétés.
                          </strong>
                          <strong> Ajoutez</strong> le·s membre·s de votre
                          équipe et <strong>attribuez un rôle</strong> à chacun.
                        </p>
                      ) : (
                        dashboard.users.map((u, uk) => {
                          if (uk > 2) return null;
                          return (
                            <div key={`cus${uk}`} className="mb-3">
                              <p
                                className="m-0 text-ellipsis"
                                style={{
                                  fontWeight: "500",
                                  lineHeight: "23px",
                                }}
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {u.firstname}
                                </span>{" "}
                                <span style={{ textTransform: "uppercase" }}>
                                  {u.lastname}
                                </span>
                                {u.isPending && <i> (en attente)</i>}
                              </p>
                              {u.roles.map((r, rk) => {
                                return (
                                  <div
                                    className="text-ellipsis"
                                    style={{ fontSize: 12, lineHeight: "19px" }}
                                  >
                                    <i style={{ textTransform: "capitalize" }}>
                                      {r.role}
                                    </i>
                                    <br />
                                    <ul
                                      className="px-0 mb-0"
                                      style={{ listStyle: "none" }}
                                    >
                                      {r.clients.map((c, ck) => {
                                        return ck <= 1 ? (
                                          <li className="mr-2 text-grey">
                                            - {c}
                                          </li>
                                        ) : null;
                                      })}
                                      {r.clients.length > 2 ? (
                                        <li className="mr-2 text-grey">
                                          <i style={{ fontSize: 11 }}>
                                            + {r.clients.length - 2} autre
                                            {r.clients.length - 2 > 1
                                              ? "s"
                                              : ""}{" "}
                                            société
                                            {r.clients.length - 2 > 1
                                              ? "s"
                                              : ""}
                                          </i>
                                        </li>
                                      ) : null}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })
                      )}
                    </div>
                    {dashboard.users.length > 3 ? (
                      <i
                        onClick={() => props.history.push("/accounts")}
                        className="fa fa-ellipsis-h"
                        style={{
                          fontSize: 21,
                          color: "grey",
                          textIndent: "5px",
                        }}
                      />
                    ) : null}
                  </>
                </GreyCard>
                {dashboard.clients.isGozen && (
                  <GreyCard>
                    <>
                      <div
                        className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer"
                        onClick={() =>
                          props.history.push("/protection-juridique")
                        }
                      >
                        <strong
                          className="text-primary"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: 19,
                            lineHeight: "23px",
                          }}
                        >
                          Protection Juridique
                        </strong>
                        <i
                          className={`fa fa-user-shield text-primary`}
                          style={{ fontSize: 30 }}
                        />
                      </div>
                      <div className="">
                        <p>
                          L’assistance d’experts du droit pour vos litiges, en
                          attaque comme en défense.{" "}
                          <strong>
                            Toutes les infos sur vos garanties sont ici, juste
                            au cas où…
                          </strong>
                        </p>
                      </div>
                    </>
                  </GreyCard>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <GreyCard>
                  <>
                    <div
                      className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer"
                      onClick={() => props.history.push("/timeline")}
                    >
                      <strong
                        className="text-primary"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontSize: 19,
                          lineHeight: "23px",
                        }}
                      >
                        Votre Timeline
                      </strong>
                      <i
                        className={`fa fa-heartbeat text-primary`}
                        style={{ fontSize: 30 }}
                      />
                    </div>
                    <div className="">
                      <p className="m-0 mb-3">
                        Un historique simple et clair des{" "}
                        <strong>actions</strong> et{" "}
                        <strong>événements sur vos contrats</strong>.
                      </p>
                      <Timeline events={dashboard.timeline} noSwitch />
                    </div>
                  </>
                </GreyCard>

                <GreyCard dataPriv="app_modifycompanycontract">
                  <>
                    <div
                      className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer"
                      onClick={() => props.history.push("/paymentmethods")}
                    >
                      <strong
                        className="text-primary"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontSize: 19,
                          lineHeight: "23px",
                        }}
                      >
                        Moyens de paiement
                      </strong>
                      <i
                        className={`fa fa-credit-card text-primary`}
                        style={{ fontSize: 30 }}
                      />
                    </div>
                    <div className="" style={{ lineHeight: "23px" }}>
                      <p className="m-0">
                        Créez et gérez vos CB et prélèvements SEPA par société{" "}
                        <strong>sans aucun frais.</strong>
                      </p>
                      <p className="m-0 text-grey">
                        Service de paiement sécurisé fourni par{" "}
                        <span style={{ color: "black" }}>Stripe France</span>,
                        10 boulevard Hausmann, Paris 9ème.
                      </p>
                    </div>
                  </>
                </GreyCard>
              </div>
            </div>
            <AboutPerceval />
          </div>
        )
      )}{" "}
    </Page>
  );
};

export default withRouter(Dashboard);
