import React from "react";
import NotificationItem from "./NotificationItem";

const NotifContainer = ({ notifications, updateReadStatus }) => {
  if (notifications?.length === 0) {
    return <p className="text-center">Aucune notification</p>;
  }
  return notifications.map((notif, k) => (
    <NotificationItem
      notif={notif}
      updateReadStatus={updateReadStatus}
      k={k}
      key={`notif-${notif.id}`}
    />
  ));
};

export default NotifContainer;
