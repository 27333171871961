import React from "react";
import Control from "./Control";

const SimpleDynamicList = ({
  listLabel,
  uniqueKey,
  element,
  fields,
  items,
  btnLabel,
  change,
}) => {
  function addItem() {
    items.push("");
    change({ target: { name: element, value: items } });
  }

  function changeItem(e, k) {
    items[k] = e.target.value;
    change({ target: { name: element, value: items } });
  }
  function removeItem(kk) {
    items = items.filter((i, k) => k !== kk);
    change({ target: { name: element, value: items } });
  }

  return (
    <div className="form-group">
      <label>{listLabel}</label>
      <ul className="list-group list-unstyled">
        {items.map((it, k) => (
          <li key={"dyn" + element + uniqueKey + "_" + k}>
            <div className="d-flex align-items-center flex-wrap">
              {fields.map((fi, kk) => (
                <Control
                  className={"mr-0 "}
                  k={k}
                  labelClassname="mb-0 mt-0 mr-2 text-black-50"
                  inputStyle={
                    kk == fields.length - 1
                      ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                      : {}
                  }
                  key={"dynlistinp" + kk}
                  label={fi.label}
                  name={fi.name}
                  type={fi.type}
                  value={it}
                  datas={fi.datas}
                  change={changeItem}
                  suffix={fi.suffix ? fi.suffix : null}
                  margin={fi.margin ? fi.margin : ""}
                />
              ))}
              <div
                style={{
                  background: "white",
                  height: 40,
                  borderTopRightRadius: window.borderRadius,
                  borderBottomRightRadius: window.borderRadius,
                }}
                className="d-flex align-items-center px-2"
              >
                <button
                  className="btn btn-default btn-sm shadow-none"
                  onClick={() => removeItem(k)}
                >
                  <i className="fa fa-times text-danger" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {btnLabel && (
        <button className="btn btn-default btn-sm mt-2" onClick={addItem}>
          <i className="fa fa-plus mr-2" />
          {btnLabel}
        </button>
      )}
    </div>
  );
};

export default SimpleDynamicList;
