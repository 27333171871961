import React, { useState } from "react";
import Control from "../../common/Control";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";

const AmendmentItem = ({ amendment }) => {
  const [state, dispatch] = useStore();
  const constantData = state.constants.items;

  return constantData ? (
    <>
      <div className="mx-auto mb-4" style={{ maxWidth: 900 }}>
        <h4 className="mt-3">Informations générales</h4>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Date de création"
              className="readonly"
              value={DateTime.fromISO(amendment.createdAt).toFormat(
                "dd/MM/yyyy"
              )}
            />
            <Control
              label="Date d'effet"
              className="readonly"
              value={DateTime.fromISO(amendment.effectDate).toFormat(
                "dd/MM/yyyy"
              )}
            />
            <Control
              label="Raison"
              className="readonly"
              value={amendment.reason && amendment.reason.value}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Date de modification"
              className="readonly"
              value={
                amendment.updatedAt
                  ? DateTime.fromISO(amendment.updatedAt).toFormat("dd/MM/yyyy")
                  : "inconnue"
              }
            />
            <Control
              label="Echéance principale"
              className="readonly"
              value={DateTime.fromISO(amendment.mainEcheance).toFormat("dd/MM")}
            />
          </div>
          {/* <div className="col-12 col-md-6">
            <Control
              label="Détails"
              type="textarea"
              className="readonly"
              inputStyle={{ minHeight: 80 }}
              value={amendment.details}
            />
          </div> */}
        </div>

        <h4 className="mt-3">Prime fractionnée</h4>

        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Périodicité"
              className="readonly"
              value={
                constantData.PERIODICITIES.find(
                  (c) => c.id == amendment.periodicity
                ).name
              }
            />
            <Control
              label="Prime annuelle TTC"
              className="readonly"
              value={amendment.annualPremium}
            />
            {/* <Control
              label="Commission"
              className="readonly"
              value={amendment.commission}
            /> */}
            <Control
              label="Indice"
              className="readonly"
              value={amendment.index}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Prime TTC"
              className="readonly"
              value={amendment.premiumWithVat}
            />
            <Control
              label="Prime HT"
              className="readonly"
              value={amendment.premiumWithoutVat}
            />
            <Control
              label="Indemnité de gestion"
              className="readonly"
              value={amendment.managmentFee}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default AmendmentItem;
