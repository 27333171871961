import { Provider } from "jotai";
import React from "react";
import useClientItem from "../useClientItem";
import CompanyItem from "./CompanyItem/CompanyItem";

const CompanyList = () => {
  const { client } = useClientItem();

  return (
    <div>
      {client.clients.map((company) => (
        <Provider key={`companyitem${company.id}`}>
          <CompanyItem companyData={company} />
        </Provider>
      ))}
    </div>
  );
};

export default CompanyList;
