import axios from "axios";
import { API_URL, REF_APP } from "../../config";
import dataService from "../../helpers/dataService";
import tools from "../../helpers/tools";
import { CONSTANT_TYPES } from "../reducers/constantReducers";

export const inputsConfigActions = (state, dispatch, props) => {
  function getInputsConfig() {
    dataService.get(`perceval/formconfig`, (datas) => {
      dispatch({
        type: "UPDATE_INPUTS_CONFIG",
        payload: datas,
      });
    });
  }

  return {
    getInputsConfig,
  };
};
