import React from "react";

const MinusIcon = () => {
  return (
    <div
      className="d-center animated pulse infinite fast"
      style={{
        position: "absolute",
        left: -18,
        background: "#009403",
        width: 30,
        height: 30,
        color: "white",
      }}
    >
      <i className="fa fa-minus" style={{ lineHeight: "30px" }} />
    </div>
  );
};

export default MinusIcon;
