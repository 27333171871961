import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Timeline.scss";
import TimelineEvent from "./TimelineEvent";

const Timeline = ({ events, noSwitch }) => {
  const [layout, setLayout] = useState("1-column");

  function toggleLayout() {
    if (layout == "2-columns") setLayout("1-column");
    if (layout == "1-column") setLayout("2-columns");
  }

  return (
    <div style={{ maxWidth: 700 }}>
      {!noSwitch ? (
        <div className="justify-content-end d-flex flex-row p-2">
          <i
            style={{ cursor: "pointer" }}
            onClick={toggleLayout}
            className={
              "fa fa-columns " + (layout == "2-columns" ? "active" : "")
            }
          />
        </div>
      ) : null}
      <VerticalTimeline layout={layout}>
        {events.map((ev, evk) => (
          <TimelineEvent key={"ev" + evk} event={ev} layout={layout} />
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default Timeline;
