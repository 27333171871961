import React, { Component, useEffect, useState } from "react";
import Axios from "axios";
import { API_URL, REF_APP } from "./config";

const RoleStyle = (props) => {
  const [hiddenPriv, setHiddenPriv] = useState([]);
  const [myPriv, setMyPriv] = useState(false);
  const [privCss, setPrivCss] = useState("");

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    Axios.get(API_URL + "roles/mine")
      .then((res) => {
        setMyPriv({ myPriv: res.data });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (myPriv) {
      setConfig();
    }
  }, [myPriv]);

  const setConfig = () => {
    var companiesPriv = {};
    var ownerOfCompanies = [];

    myPriv.myPriv.forEach((priv) => {
      if (priv.roleKey == "OWNER") {
        ownerOfCompanies.push(...priv.clients);
      }
      priv.clients.forEach((c) => {
        if (!companiesPriv[c]) {
          companiesPriv[c] = priv.privilegesOff;
        } else {
          companiesPriv[c].push(...priv.privilegesOff);
        }
      });
    });
    generateCss(companiesPriv, ownerOfCompanies);
    // var companySelected = tools.getCompanySelected();
    // if (companySelected) {
    //   var hiddenPriv = this.state.myPriv.find((c) =>
    //     c.companies.includes(companySelected.id)
    //   ).privilegesOff;

    //   this.setState({ hiddenPriv }, () => {
    //     this.generateCss();
    //   });
    // }
  };

  const generateCss = (companiesPriv, ownerOfCompanies) => {
    var css = "";
    Object.keys(companiesPriv).forEach((h, hk) => {
      var val = companiesPriv[h];
      val.forEach((v) => {
        if (!ownerOfCompanies.includes(h)) {
          css +=
            "[data-priv='app_viewowner-" +
            h +
            "'] { display: none !important;}";
          css +=
            "[data-priv='app_viewusers-" +
            h +
            "'] { display: none !important;}";
        }
        css += "[data-priv='" + v + "-" + h + "'] { display: none !important;}";
      });
    });
    if (!ownerOfCompanies.length) {
      css += "[data-priv='app_viewusers'] {display:none !important;}";
    }
    setPrivCss(css);
  };

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
          ${privCss}
      `,
      }}
    />
  );
};
export default RoleStyle;
