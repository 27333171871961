import Axios from "axios";
import fileDownload from "js-file-download";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../../config";
import tools from "../../../../helpers/tools";

const DownloadDocument = ({
  document,
  name,
  companyName,
  productName,
  className = "",
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = () => {
    if (isDownloading) return false;
    setIsDownloading(true);
    Axios({
      url: API_URL + "files/" + document.id,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        var filename = tools.getDocumentName({
          fileExt: res.headers["content-disposition"].split(".")[1],
          documentName: document.fileType.name,
          productName,
          companyName,
          uploadedAt: document.uploadedAt,
        });
        fileDownload(res.data, filename);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  return document ? (
    <button
      className={`btn btn-primary btn btn-primary font-weight-bold  d-flex flex-column align-items-center justify-content-center px-3  mb-2 mx-1 ${className}`}
      // style={{ height: 29, fontSize: 14 }}
      onClick={downloadFile}
    >
      <i className="fa fa-download mb-1" />
      {name}
    </button>
  ) : null;
};

export default DownloadDocument;
