import { mrpFormActions } from "../../../../context/actions/mrpFormActions";
import useStore from "../../../../context/useStore";

const useFormState = () => {
  const [state, dispatch] = useStore();
  const actions = mrpFormActions(state, dispatch);
  const { mrpForm } = state;
  const { formData, apiData, errors } = mrpForm;
  const { inputsConfig } = state;

  return {
    state,
    dispatch,
    actions,
    mrpForm,
    formData,
    apiData,
    inputsConfig,
    constants: state.constants.items,
    errors,
  };
};

export default useFormState;
