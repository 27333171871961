import React, { useEffect } from "react";
import Loader from "../../common/Loader";
import Colors from "../../../variables.scss";
import { DateTime } from "luxon";
import PlusIcon from "../../common/PaymentComponents/PlusIcon";
import MinusIcon from "../../common/PaymentComponents/MinusIcon";
import tools from "../../../helpers/tools";

const PriceResults = ({
  params,
  results,
  isCalculating,
  contractName,
  displayPayment,
  setDisplayPayment,
  setPaymentOk,
  mainEcheance,
  whatHasChanged,
  oldPrime,
  paymentAction,
}) => {
  const basePrice = results.proration?.BASE;
  const globalPrice = results.proration?.GLOBAL;
  const optFs = results.proration?.OPTION_FS;
  const optRc = results.proration?.OPTION_RC;
  const annualPrice = results.annualBase?.GLOBAL;
  const isAmountNegative = parseFloat(globalPrice.ttc) <= 0;

  const validateModifications = () => {
    if (isAmountNegative) {
      //return;
      setPaymentOk({
        policyId: params.policyId,
        paymentDatas: {
          paymentAction,
        },
        product: {
          beginAt: params.beginAt,
          isPremium: params.isPremium,
          optRc: params.optRc,
          optFs: params.optFs,
          turnover: params.turnover,
        },
      });
    } else {
      setDisplayPayment(true);
    }
  };

  return (
    <>
      {isCalculating ? (
        <div className="mt-3">
          <Loader />
        </div>
      ) : (
        results && (
          <>
            <h4
              className="d-center flex-column text-center"
              style={{ fontSize: 22 }}
            >
              Montant de votre nouvelle prime
              <span className="text-black-50 mt-1" style={{ fontSize: 12 }}>
                Au prorata des sommes déjà réglées
              </span>
              <i className="fa fa-chevron-down my-2 animated fadeInDown fast" />
            </h4>
            <div
              className="card results-card shadow-sm"
              style={{ borderBottomRightRadius: 0, overflow: "visible" }}
            >
              <ul className="list-group">
                <li
                  className="list-group-item d-center w-100 justify-content-between animated fadeInRight faster"
                  style={{ animationDelay: "0ms" }}
                >
                  <div className="d-flex justify-content-between flex-wrap">
                    <span
                      className="flex-column  d-center justify-content-between"
                      style={{ fontSize: 18, color: Colors.secondary }}
                    >
                      {/* {contractName} */}
                      {"PJ Professionnelle GoZen"}
                      <strong className="text-success">
                        {params.isPremium && !whatHasChanged.isPremium && (
                          <PlusIcon />
                        )}
                        {params.isPremium && "avec les garanties renforcées"}
                      </strong>
                    </span>
                  </div>
                  <strong
                    className="d-center text-success"
                    style={{ fontSize: 16 }}
                  >
                    {tools.formatNumber2Decimals(basePrice.ttc)}€
                  </strong>
                </li>
                {params.optFs && (
                  <li
                    className="list-group-item d-center w-100 justify-content-between animated fadeInRight faster"
                    style={{ animationDelay: "100ms" }}
                  >
                    {!whatHasChanged.optFs && <PlusIcon />}
                    <div className="d-flex justify-content-between flex-wrap">
                      <span
                        className="flex-row d-center  text-success font-weight-bold"
                        style={{ fontSize: 18, color: Colors.secondary }}
                      >
                        <i className="fa fa-check  mr-2 d-center" />
                        <span>Option Fiscale et Sociale</span>
                      </span>
                    </div>
                    <strong
                      className="d-center text-success"
                      style={{ fontSize: 16 }}
                    >
                      {tools.formatNumber2Decimals(optFs.ttc)}€
                    </strong>
                  </li>
                )}
                {params.optRc && (
                  <li
                    className="list-group-item d-center w-100 justify-content-between animated fadeInRight faster"
                    style={{ animationDelay: "200ms" }}
                  >
                    {!whatHasChanged.optRc && <PlusIcon />}
                    <div className="d-flex justify-content-between flex-wrap">
                      <span
                        className="flex-row d-center text-success font-weight-bold"
                        style={{ fontSize: 18, color: Colors.secondary }}
                      >
                        <i className="fa fa-check  mr-2 d-center" />
                        <span>Option Recouvrement de Créances</span>
                      </span>
                    </div>
                    <strong
                      className="d-center text-success"
                      style={{ fontSize: 16 }}
                    >
                      {tools.formatNumber2Decimals(optRc.ttc)}€
                    </strong>
                  </li>
                )}
                <li
                  className="list-group-item d-center w-100 justify-content-between animated fadeInRight faster"
                  style={{ animationDelay: "200ms" }}
                >
                  <MinusIcon />
                  <div className="d-flex justify-content-between flex-wrap">
                    <span
                      className="flex-row d-center text-info font-weight-bold"
                      style={{ fontSize: 18 }}
                    >
                      <i className="fa fa-check  mr-2 d-center" />
                      <span>Sommes déjà réglées pour la période</span>
                    </span>
                  </div>
                  <strong
                    className="d-center text-info"
                    style={{ fontSize: 16 }}
                  >
                    -{tools.formatNumber2Decimals(globalPrice.alreadyPaid)}€
                  </strong>
                </li>
                <li
                  className="list-group-item d-center w-100 justify-content-between animated fadeInRight faster"
                  style={{
                    animationDelay: "200ms",
                    background: "#f0f3f5",
                  }}
                >
                  <p className="mb-0">
                    Votre prime <strong>annuelle</strong> TTC passe de{" "}
                    {tools.formatNumber2Decimals(oldPrime)}
                    €/an à {tools.formatNumber2Decimals(annualPrice.ttc)}€/an.
                  </p>
                </li>
                <li
                  className="list-group-item d-center w-100 justify-content-between bg-success text-white font-weight-bold animated fadeInRight faster"
                  style={{
                    animationDelay: "300ms",
                    borderBottomRightRadius: 0,
                  }}
                >
                  <div className="d-flex justify-content-between flex-wrap">
                    <span
                      className="d-flex flex-column "
                      style={{ fontSize: 20 }}
                    >
                      TOTAL {isAmountNegative ? "EN AVOIR" : "À PAYER"}
                      <i style={{ fontSize: 14 }}>
                        prime du{" "}
                        {DateTime.fromISO(params.beginAt).toFormat(
                          "dd/MM/yyyy"
                        )}{" "}
                        au{" "}
                        {DateTime.fromISO(mainEcheance)
                          .minus({ day: 1 })
                          .toFormat("dd/MM/yyyy")}
                      </i>
                    </span>
                  </div>
                  <span className="d-center" style={{ fontSize: 16 }}>
                    {tools.formatNumber2Decimals(globalPrice.ttc)}€
                  </span>
                </li>
              </ul>
            </div>
            {!displayPayment && (
              <div className="d-flex justify-content-end w-100">
                <button
                  className="btn btn-info font-weight-bold animated fadeInDown delay-1s"
                  style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                  onClick={validateModifications}
                >
                  Valider ces modifications
                </button>
              </div>
            )}
          </>
        )
      )}
    </>
  );
};

export default PriceResults;
