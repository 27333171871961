import React from "react";

const FileNotExist = ({ isNewVersion, documentUploaded, inline = false }) => {
  return (
    <>
      <div
        className={
          "d-flex align-items-center p-2 " +
          (inline
            ? " flex-row  w-100 "
            : " flex-column  justify-content-center ")
        }
      >
        {documentUploaded ? (
          inline ? (
            <div className="btn btn-success btn-sm m-auto">
              Envoyer un fichier
            </div>
          ) : (
            <>
              <p className={inline ? "mb-0" : ""}>
                Glissez-déposez{" "}
                {isNewVersion ? (
                  <b>une nouvelle version du fichier</b>
                ) : (
                  "un fichier"
                )}{" "}
                ici, ou cliquez pour parcourir vos dossiers
              </p>
              {!inline && <i className="fa fa-cloud-upload-alt fa-5x" />}
            </>
          )
        ) : (
          <p className="m-0">
            Ce document sera bientôt mis à votre disposition par nos équipes.
          </p>
        )}
      </div>
    </>
  );
};

export default FileNotExist;
