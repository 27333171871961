import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import Control from "../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";
import useFormState from "../useFormState";
import buildingTips from "./buildingTips";

const BuildingInputs = () => {
  const { constants, actions, errors, formData, inputsConfig } = useFormState();
  const inputState = formData.buildingInputs;

  const updateMrpData = (e) => {
    const { name, value } = e.target;
    //actions.updateBuildingInputs({ [name]: value });

    var buildingSize = 0;
    if (name == "buildingNumberOfFloors") {
      if (value <= 5) {
        buildingSize = 1;
      } else if (value > 5 && value <= 8) {
        buildingSize = 2;
      } else {
        buildingSize = 3;
      }
      actions.updateBuildingInputs({
        buildingSize,
        [name]: value,
      });
    } else {
      actions.updateBuildingInputs({ [name]: value });
    }
  };

  const onBlur = () => {};

  var showBuildingIcon =
    inputState.buildingSize &&
    inputState.buildingNumberOfFloors > 0 &&
    !isNaN(inputState.buildingNumberOfFloors);

  return (
    <BlockWrapper>
      <Control
        name="isInBuilding"
        label={inputsConfig.isInBuilding.label}
        type="btnList"
        datas={inputsConfig.isInBuilding.datas}
        dataIndex="id"
        value={inputState.isInBuilding}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        tips={buildingTips.isInBuilding}
        info="Immeuble = batiment de plusieurs étages, ou occupants multiples."
        onBlur={onBlur}
      />
      {inputState.isInBuilding == 1 && (
        <>
          <Control
            label={inputsConfig.buildingNumberOfFloors.label}
            name="buildingNumberOfFloors"
            type="number"
            value={inputState.buildingNumberOfFloors}
            change={updateMrpData}
            error={errors}
            onBlur={onBlur}
            tips={buildingTips.buildingNumberOfFloors}
            info={
              <>
                <p>
                  Les sous-sols, le rez-de-chaussée, les étages sont des
                  niveaux.
                </p>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    layout
                    className="d-center w-100"
                    animate={{ height: "auto" }}
                    initial={{ height: 0 }}
                    exit={{
                      height: 0,
                    }}
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    {showBuildingIcon && (
                      <div>
                        {" "}
                        {inputsConfig.buildingSize.datas.map((b) => {
                          if (b.id == inputState.buildingSize) {
                            return (
                              <button
                                class="d-flex
                              btn noeffect  
                              position-relative 
                              flex-row align-items-center py-2 border-0  flex-fill   
                              btn-sm text-center my-1 mr-3 justify-content-end  flex-wrap flex-column 
                                "
                                type="button"
                                style={{
                                  overflow: "visible",
                                  color: "rgb(62, 117, 255)",
                                  cursor: "auto",
                                }}
                              >
                                <div class="d-flex align-items-center justify-content-center p-2 ">
                                  <img src={`/images/icons/${b.icon}`} />
                                </div>
                                <div class="d-flex mb-0 font-weight-bold flex-column justify-content-center flex-row ">
                                  {b.name}
                                  <span
                                    class="my-1 "
                                    style={{
                                      fontSize: 12,
                                      lineHeight: "13px;",
                                    }}
                                  >
                                    {b.description1}
                                  </span>
                                </div>
                                <i
                                  class="check-icon fa fa-check ml-2 animated d-none faster
          position-absolute bg-success text-white shadow-sm 
                  "
                                  style={{
                                    top: "-7px",
                                    right: -7,
                                    fontSize: 12,
                                    padding: 8,
                                    borderRadius: "100%",
                                    zIndex: "90",
                                  }}
                                ></i>
                              </button>
                            );
                          }
                        })}
                      </div>
                    )}
                  </motion.div>
                </AnimatePresence>
              </>
            }
            suffix={"niveaux"}
          />

          <Control
            name="officeLocationInBuilding"
            label={inputsConfig.officeLocationInBuilding.label}
            type="btnList"
            datas={inputsConfig.officeLocationInBuilding.datas}
            dataIndex="id"
            value={inputState.officeLocationInBuilding}
            change={updateMrpData}
            dataIcon={"icon"}
            btnInline
            fullWidth
            labelClassname="w-100"
            error={errors}
            onBlur={onBlur}
          />

          <Control
            name="isBuildingGroupMore4000"
            label={inputsConfig.isBuildingGroupMore4000.label}
            type="btnList"
            datas={inputsConfig.isBuildingGroupMore4000.datas}
            dataIndex="id"
            value={inputState.isBuildingGroupMore4000}
            change={updateMrpData}
            btnInline
            fullWidth
            labelClassname="w-100"
            error={errors}
            onBlur={onBlur}
          />
          {inputState.isBuildingGroupMore4000 == 1 && (
            <>
              <Control
                name="buildingGroupArea"
                label={inputsConfig.buildingGroupArea.label}
                type="number"
                value={inputState.buildingGroupArea}
                change={updateMrpData}
                error={errors}
                onBlur={onBlur}
                suffix="m²"
              />
              <Control
                label={inputsConfig.buildingGroupType.label}
                name="buildingGroupType"
                type="checkBtnList"
                datas={inputsConfig.buildingGroupType.datas}
                value={inputState.buildingGroupType}
                change={updateMrpData}
                btnInline
                fullWidth
                labelClassname="w-100"
                error={errors}
                info="Choix multiples."
                onBlur={onBlur}
              />
              <Control
                label={inputsConfig.isGroupHeightMore28.label}
                name="isGroupHeightMore28"
                type="btnList"
                datas={inputsConfig.isGroupHeightMore28.datas}
                dataIndex="id"
                value={inputState.isGroupHeightMore28}
                change={updateMrpData}
                btnInline
                fullWidth
                labelClassname="w-100"
                error={errors}
                onBlur={onBlur}
              />
            </>
          )}
        </>
      )}
      <Control
        label={inputsConfig.risksAroundInBuilding.label}
        name="risksAroundInBuilding"
        type="btnList"
        dataIndex="id"
        dataLabel="name"
        btnInline
        fullWidth
        datas={inputsConfig.risksAroundInBuilding.datas}
        value={inputState.risksAroundInBuilding}
        change={updateMrpData}
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
        tips={buildingTips.risksAroundInBuilding}
        placeholder="Nature des activités exercées par des entreprises tierces dans le même espace/local. "
      />
      {inputState.risksAroundInBuilding == 1 && (
        <Control
          label={inputsConfig.risksAroundNextToBuilding.label}
          name="risksAroundNextToBuilding"
          type="textarea"
          value={inputState.risksAroundNextToBuilding}
          change={updateMrpData}
          labelClassname="w-100"
          error={errors}
          onBlur={onBlur}
          tips={buildingTips.risksAroundNextToBuilding}
          placeholder="Stockage de produits inflammables, laboratoires, garage, bar de nuit, atelier de menuiserie, etc."
        />
      )}
      <Control
        name="isRankedBuilding"
        label={inputsConfig.isRankedBuilding.label}
        type="btnList"
        datas={inputsConfig.isRankedBuilding.datas}
        dataIndex="id"
        value={inputState.isRankedBuilding}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        tips={buildingTips.isRankedBuilding}
        onBlur={onBlur}
      />

      <Control
        label={inputsConfig.isBuildingInMall.label}
        name="isBuildingInMall"
        type="btnList"
        datas={inputsConfig.isBuildingInMall.datas}
        dataIndex="id"
        value={inputState.isBuildingInMall}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      {inputState.isBuildingInMall == 1 && (
        <Control
          label={inputsConfig.isMallMore2000.label}
          name="isMallMore2000"
          type="btnList"
          datas={inputsConfig.isMallMore2000.datas}
          dataIndex="id"
          value={inputState.isMallMore2000}
          change={updateMrpData}
          btnInline
          fullWidth
          labelClassname="w-100"
          error={errors}
          onBlur={onBlur}
        />
      )}
      <Control
        name="isBuildingNearWater"
        label={inputsConfig.isBuildingNearWater.label}
        type="btnList"
        datas={inputsConfig.isBuildingNearWater.datas}
        dataIndex="id"
        value={inputState.isBuildingNearWater}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        info="Zone inondable, proximité de risque technologique (nucléaire, barrage)."
        onBlur={onBlur}
      />
    </BlockWrapper>
  );
};

export default BuildingInputs;
