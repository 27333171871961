import React from "react";

const UserCompanies = ({ companies, role, roles, setRoles }) => {
  var companiesAlreadyUsed = [];
  roles
    .filter((ro) => ro.id != role.id)
    .forEach((ro) => ro.clientsName.forEach((com) => companiesAlreadyUsed.push(com)));
  const toggleSelected = (companyId) => {
    if (role.clients.includes(companyId)) {
      setRoles(
        roles.map((r) => {
          if (r.id == role.id) {
            return {
              ...r,
              clients: r.clients.filter((c) => c != companyId),
              clientsName: r.clientsName.filter(
                (c) => c != companies.find((co) => co.id == companyId).company
              ),
            };
          }
          return r;
        })
      );
    } else {
      setRoles(
        roles.map((r) => {
          if (r.id == role.id) {
            return {
              ...r,
              clients: [...r.clients, companyId],
              clientsName: [...r.clientsName, companies.find((co) => co.id == companyId).company],
            };
          }
          return r;
        })
      );
    }
  };

  return (
    <div>
      <div className="font-weight-bold text-success mb-4" style={{ fontSize: 17 }}>
        Pour quelle·s société·s ?
      </div>
      <div>
        {companies
          .filter((c) => !companiesAlreadyUsed.includes(c.company))
          .map((comp, ck) => (
            <div
              className={`d-flex flex-row justify-content-start align-items-center cursor-pointer p-3 rounded mb-3 ${
                role.clients.includes(comp.id)
                  ? "bg-success text-white"
                  : "bg-greylight text-primary"
              }`}
              onClick={() => toggleSelected(comp.id)}
            >
              <div style={{ fontSize: 35 }} className="pr-3">
                <i className="fa fa-chess-rook" />
              </div>
              <div style={{ fontSize: 14 }}>{comp.company}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UserCompanies;
