import React from "react";

const RecapCard = (props) => {
  return (
    <div>
      <div
        className={`${props.className}`}
        style={{
          backgroundImage: `url(/images/${props.bgImage || "card-down.svg"})`,
          backgroundSize: "100% 100%",
          padding: 20,
          marginLeft: -7,
          marginRight: -7,
          backgroundPosition: "0px -6px",
          backgroundRepeat: "no-repeat",
          paddingBottom: 40,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default RecapCard;
