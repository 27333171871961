import React, { useEffect } from "react";
import { REF_APP } from "../../../config";
import Control from "../../common/Control";

const UserPrivileges = ({ PRIVILEGES, roleConfig, roles, role, setRoles }) => {
  var r = role;
  const filteredPrivileges = PRIVILEGES[REF_APP].filter((pr) => roleConfig[pr.key][r.roleKey]);

  useEffect(() => {
    //if (!role.privileges.length) {
    setRoles(
      roles.map((ro) => {
        if (ro.id == role.id) {
          return {
            ...ro,
            privileges: filteredPrivileges.map((fp) => fp.key),
          };
        }
        return ro;
      })
    );
    //}
    // else {
    //   setRoles(
    //     roles.map((ro) => {
    //       if (ro.id == role.id) {
    //         return {
    //           ...ro,
    //           privileges: ro.privileges.filter((priv) => {
    //             if (!filteredPrivileges.find((fp) => fp.key == priv)) {
    //               return false;
    //             }
    //             return true;
    //           }),
    //         };
    //       }
    //       return ro;
    //     })
    //   );
    // }
  }, [role.roleKey]);

  const changePrivilege = (e) => {
    var { name } = e.target;
    if (role.privileges.includes(e.target.name)) {
      setRoles(
        roles.map((ro) => {
          if (ro.id == role.id) {
            return {
              ...ro,
              privileges: ro.privileges.filter((p) => p != name),
            };
          }
          return ro;
        })
      );
    } else {
      setRoles(
        roles.map((ro) => {
          if (ro.id == role.id) {
            return {
              ...ro,
              privileges: [...ro.privileges, name],
            };
          }
          return ro;
        })
      );
    }
  };

  useEffect(() => {
    var hoverable = document.querySelectorAll(".highlightable");
    hoverable.forEach((elem) => {
      elem.addEventListener("mouseover", () => {
        activeBgColor(elem, "#ffffff", "red");
      });
      elem.addEventListener("click", () => {
        activeBgColor(elem, "#ffffff", "red");
      });
      elem.addEventListener("focus", () => {
        activeBgColor(elem, "#ffffff", "red");
      });
      elem.addEventListener("mouseout", () => {
        activeBgColor(elem, "transparent", "#ffffff");
      });
      elem.addEventListener("focusout", () => {
        activeBgColor(elem, "transparent", "#ffffff");
      });
    });
  });
  function activeBgColor(elem, bgColor, colorHl) {
    elem.style.background = bgColor;
    let dependencies = elem.getAttribute("data-ref");
    if (dependencies != "") {
      dependencies = dependencies.split("+");
      if (dependencies.length > 0) {
        dependencies.forEach((dep) => {
          var links = document.querySelectorAll(
            "[data-key=" + dep + "] .badge .fa, [data-key=" + dep + "] span"
          );

          links.forEach((lk) => {
            lk.style.color = colorHl;
          });
        });
      }
    }
  }

  const hiddenPrivileges = ["app_updatecompanyinfo"];

  return (
    <div className="mt-4">
      <div className="font-weight-bold text-success mb-3" style={{ fontSize: 17 }}>
        Ajustez les privilèges
      </div>
      {filteredPrivileges.map((p, pk) => {
        const isHidden = hiddenPrivileges.includes(p.key);
        return (
          <div
            className={`highlightable position-relative ${p.dependsOn ? "" : "mt-3"} ${
              isHidden ? "d-none" : ""
            }`}
            data-ref={p.dependsOn ? p.dependsOn.join("+") : ""}
            data-key={p.key}
          >
            {!r.privileges.includes(p.key) && (
              <span
                className="badge badge-light position-absolute"
                style={{
                  left: -18,
                  top: 6,
                  background: "#ffffff",
                }}
              >
                <i
                  style={{ color: "transparent" }}
                  className="fa fa-lock animated heartBeat infinite"
                />
                <span
                  style={{
                    color: "transparent",
                    position: "relative",
                    top: -19,
                    left: -8,
                    fontWeight: "400",
                  }}
                >
                  Ce privilège est requis pour activer ceux ci-dessous
                </span>
              </span>
            )}
            {p.dependsOn ? (
              <div
                style={{
                  width: 20,
                  height: 21,
                  position: "absolute",
                  left: 11,
                  top: 8,
                  transform: "translateY(-50%)",
                  zIndex: "0",
                  borderLeft: "1px solid #b7b7b7",
                  borderBottom: "1px solid #b7b7b7",
                }}
              ></div>
            ) : null}
            <Control
              label={p.label}
              type="checkbox"
              name={p.key}
              inputStyle={{ fontSize: 14 }}
              labelClassname={r.privileges.includes(p.key) ? "text-success" : "text-black-50"}
              checked={r.privileges.includes(p.key)}
              change={changePrivilege}
              margin="my-0 py-1"
              containerStyle={
                p.dependsOn
                  ? {
                      marginLeft: 30,
                    }
                  : {}
              }
              disabled={
                p.dependsOn
                  ? r.privileges.some((arr) => p.dependsOn.includes(arr))
                    ? false
                    : "disabled"
                  : false
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default UserPrivileges;
