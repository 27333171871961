import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import SaveBtn from "../../common/SaveBtn";
import Axios from "axios";
import { API_URL } from "../../../config";
import { DateTime } from "luxon";
import DocumentsDisplay from "./DocumentsDisplay";
import Loader from "../../common/Loader";
import ModalBtn from "../../common/ModalBtn";
import Modal from "../../common/Modal";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

const TabClient = ({
  client,
  openClient,
  documents,
  documentUploaded,
  documentRemoved,
  uploadNewFile,
  cancelNewVersion,
  isDocsLoading,
  isBOA,
  closeOverlay,
}) => {
  const [state, dispatch] = useStore();
  const [isImportingModulr, setIsImportingModulr] = useState(false);
  const [isImportedModulr, setIsImportedModulr] = useState(false);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [selectedUserMigration, setSelectedUserMigration] = useState("");
  const [isMigrating, setIsMigrating] = useState(false);

  const constantData = state.constants.items;

  function importModulr() {
    setIsImportingModulr(true);
    Axios.get(API_URL + "modulr/clients/" + client.id)
      .then((res) => {
        setIsImportedModulr(true);
        openClient();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsImportingModulr(false);
      });
  }

  function getUsers() {
    setIsLoadingUsers(true);
    Axios.get(API_URL + "customers")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoadingUsers(false);
      });
  }

  function migrateClient() {
    setIsMigrating(true);
    Axios.patch(API_URL + "clients/" + client.id + "/migration", {
      user: selectedUserMigration,
    })
      .then((res) => {
        window.$("#migrateClientModal").modal("hide");
        closeOverlay(true);
        toast.success("La société a bien été migrée");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsMigrating(false);
      });
  }

  function updateData(e) {
    setSelectedUserMigration(e.target.value);
  }

  return (
    <div className="px-3">
      <div className="mx-auto" style={{ maxWidth: 900 }}>
        <div>
          <div className="row">
            <div className="col-12 col-md-6">
              <h4>Identité</h4>
            </div>
            {isBOA && (
              <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                <div className="dropdown">
                  <button
                    className="btn btn-default btn-sm"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-ellipsis-v mx-2" />
                    Autres actions
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <SaveBtn
                      className="dropdown-item shadow-none"
                      type="default"
                      isSaving={isImportingModulr}
                      isSaved={isImportedModulr}
                      save={importModulr}
                      text={
                        <>
                          <i className="fa fa-cloud-download-alt mr-2" />
                          Importer les données du client depuis Modulr
                        </>
                      }
                    />
                    <ModalBtn
                      id={"migrateClientModal"}
                      label={
                        <>
                          <i className="fas fa-sign-out-alt mr-2" /> Migrer vers un autre compte
                        </>
                      }
                      btnClass="btn btn-default dropdown-item shadow-none"
                      backdrop={false}
                      onClick={() => {
                        if (users.length == 0) {
                          getUsers();
                        }
                      }}
                    />
                  </div>
                </div>

                <Modal
                  id={"migrateClientModal"}
                  title={
                    "Migrer la société " + client.company + " vers un autre compte utilisateur"
                  }
                  bodyStyle={{
                    background: "#f0f3f5",
                  }}
                  actions={
                    selectedUserMigration.length > 0 ? (
                      <SaveBtn
                        className="btn btn-primary"
                        isSaving={isMigrating}
                        isSaved={false}
                        save={migrateClient}
                        text={
                          <>
                            <i className="fa fa-cloud-download-alt mr-2" />
                            Migrer la société
                          </>
                        }
                      />
                    ) : (
                      ""
                    )
                  }
                >
                  {isLoadingUsers || users.length == 0 ? (
                    <Loader />
                  ) : (
                    <Control
                      containerStyle={{ animationDelay: "100ms" }}
                      label="Sélectionnez le compte utilisateur vers lequel vous souhaitez migrer cette société"
                      name="users"
                      value={selectedUserMigration}
                      type="autocomplete"
                      datas={users}
                      dataIndex={"id"}
                      dataLabel={"firstname"}
                      dataLabel2={"lastname"}
                      dataLabel3={"email"}
                      autoCompleteDisplayIndex={"email"}
                      error={errors}
                      change={updateData}
                      placeholder={"Nom ou prénom ou email"}
                      isValid={selectedUserMigration}
                    />
                  )}
                </Modal>

                {errors.other && <span className="text-danger animated flash">{errors.other}</span>}
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Date de création"
                className="readonly"
                value={DateTime.fromISO(client.createdAt).toFormat("dd/MM/yyyy")}
              />
              <Control
                label="Type de client"
                className="readonly"
                value={
                  constantData.CLIENT_TYPES
                    ? constantData.CLIENT_TYPES.find((c) => c.id == client.type)
                      ? constantData.CLIENT_TYPES.find((c) => c.id == client.type).name
                      : ""
                    : ""
                }
              />

              <Control label="Référence" className="readonly" value={client.reference} />
              <Control label="Société" className="readonly" value={client.company} />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Date de modification"
                className="readonly"
                value={
                  client.updatedAt
                    ? DateTime.fromISO(client.updatedAt).toFormat("dd/MM/yyyy")
                    : "inconnue"
                }
              />
              <Control
                label="Civilité"
                type="select"
                className="readonly"
                datas={[
                  { id: 1, name: "Monsieur" },
                  { id: 2, name: "Madame" },
                ]}
                value={client.gender}
              />
              <Control label="Nom du dirigeant" className="readonly" value={client.lastname} />
              <Control label="Prénom du dirigeant" className="readonly" value={client.firstname} />
            </div>
          </div>
        </div>
        <div>
          <h4 className="mt-3">Informations de la société</h4>
          <div className="row">
            <div className="col-12 col-md-6">
              <Control label="Raison sociale" className="readonly" value={client.businessName} />

              <Control label="SIRET" className="readonly" value={client.siret} />
              <Control label="Code APE" className="readonly" value={client.ape} />
              <Control label="Activité" className="readonly" value={client.companyActivity} />
              <Control
                label="Date d'immatriculation"
                className="readonly"
                value={
                  client.registrationAt
                    ? DateTime.fromISO(client.registrationAt).toFormat("dd/MM/yyyy")
                    : ""
                }
              />
              <Control
                label="Activité de construction"
                className="readonly"
                value={
                  constantData.CONSTRUCTION_ACTIVITIES.find(
                    (c) => c.id == client.constructionActivity
                  )
                    ? constantData.CONSTRUCTION_ACTIVITIES.find(
                        (c) => c.id == client.constructionActivity
                      ).name
                    : ""
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Forme juridique"
                className="readonly"
                value={client.legalForm ? client.legalForm.value : ""}
              />
              <Control label="Chiffre d'affaires" className="readonly" value={client.turnover} />
              <Control label="Capital social" className="readonly" value={client.capital} />
              <Control label="Effectif" className="readonly" value={client.workforce} />
              <Control label="Résultat" className="readonly" value={client.income} />
              <Control
                label="CA sous-traité (%)"
                className="readonly"
                value={client.turnoverOutsourced}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="mt-3">Informations de contact</h4>
          <div className="row">
            <div className="col-12 border-bottom">
              <strong className="my-3 d-block">Adresse principale</strong>
              <Control
                label="Adresse"
                className="readonly"
                value={client.location1 && client.location1.address}
              />
              <Control
                label="Adresse (suite)"
                className="readonly"
                value={client.location1 && client.location1.address2}
              />

              <Control
                label="Adresse (fin)"
                className="readonly"
                value={client.location1 && client.location1.address3}
              />
              <Control
                label="Code postal"
                className="readonly"
                value={client.location1 && client.location1.postalCode}
              />
              <Control
                label="Ville"
                className="readonly"
                value={client.location1 && client.location1.city}
              />

              <Control
                label="Pays"
                className="readonly"
                value={client.location1 && client.location1.country}
              />
            </div>

            <div className="col-12 border-bottom">
              <strong className="my-3 d-block">Adresse secondaire</strong>
              <Control
                label="Adresse"
                className="readonly"
                value={client.location2 && client.location2.address}
              />
              <Control
                label="Adresse (suite)"
                className="readonly"
                value={client.location2 && client.location2.address2}
              />

              <Control
                label="Adresse (fin)"
                className="readonly"
                value={client.location2 && client.location2.address3}
              />
              <Control
                label="Code postal"
                className="readonly"
                value={client.location2 && client.location2.postalCode}
              />
              <Control
                label="Ville"
                className="readonly"
                value={client.location2 && client.location2.city}
              />

              <Control
                label="Pays"
                className="readonly"
                value={client.location2 && client.location2.country}
              />
            </div>

            <div className="col-12 col-md-6">
              <Control label="Email" className="readonly" value={client.email} />
              <Control label="Téléphone domicile" className="readonly" value={client.phone1} />
              <Control label="Téléphone bureau" className="readonly" value={client.phone2} />
              <Control label="Téléphone mobile" className="readonly" value={client.mobilePhone} />
            </div>
          </div>
        </div>
        <div>
          <h4 className="mt-3">Notes</h4>
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                type="textarea"
                className="readonly"
                label="Commentaires"
                value={client.notes}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                type="textarea"
                className="readonly"
                label="Note importante"
                value={client.importantNote}
              />
              {/* <div className="form-group">
                <label>Centres d'intérêt : </label>
                {client.interests.map((ci, cik) => (
                  <span className="badge badge-primary mx-1">
                    {
                      constantData.CLIENT_INTERESTS.find((cc) => cc.id == ci)
                        .name
                    }
                  </span>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row px-2 mt-5" data-priv={"app_viewcompanydocs-" + client.id}>
        {isDocsLoading ? (
          <Loader withContainer text={"Chargement des documents..."} />
        ) : (
          <DocumentsDisplay
            dataPriv={"app_modifycompanydocs-" + client.id}
            documents={documents}
            documentUploaded={documentUploaded}
            documentRemoved={documentRemoved}
            uploadNewFile={uploadNewFile}
            cancelNewVersion={cancelNewVersion}
            authorizedModels={isBOA ? false : ["Client", "UserCustomer"]}
            inline={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default TabClient;
