import React from "react";
import Loader from "./Loader";

const styles = {
  transition: "1s",
};

const SaveBtn = ({
  save,
  isSaving,
  isSaved,
  text = "Enregistrer",
  type = "success",
  className = "",
  typeBtn = "button",
}) => {
  return (
    <button
      type={typeBtn}
      className={"d-flex align-items-center btn btn-" + type + " " + className}
      onClick={save}
      disabled={isSaving}
      style={isSaving ? { ...styles, opacity: ".7" } : { ...styles }}
    >
      <span>{isSaving ? "" : text}</span>
      {isSaving && <Loader color={"#ffffff"} size={24} />}
      {isSaved && !isSaving && <i className="fa fa-check" />}
    </button>
  );
};

export default SaveBtn;
