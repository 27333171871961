import React from "react";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";

const CustomModal = () => {
  const [state, dispatch] = useStore();
  const actions = modalActions(state, dispatch);
  const modalProps = state.modal;

  return (
    <div
      onClick={() => {
        actions.updateModal({ isOpen: false });
      }}
      className="position-fixed w-100 h-100 align-items-center justify-content-center"
      style={{
        display: modalProps.isOpen ? "flex" : "none",
        top: 0,
        left: 0,
        background: "rgb(0 0 0 / 54%)",
        zIndex: "9999",
        overflowY: "auto",
        backdropFilter: "blur(3px)",
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={
          "position-fixed p-5 rounded " +
          (modalProps.isOpen ? "animated zoomIn faster" : "")
        }
        style={{
          zIndex: 9999,
          top: "50%",
          width: "min(700px,95%)",
          overflowY: "auto",
          transform: "translateY(-50%)",
          maxHeight: "100%",
          fontSize: 16,
          background: "white",
          ...modalProps.containerStyle,
        }}
      >
        {modalProps.content}

        <div className="d-center w-100 mt-2">
          {modalProps.actions}
          <button
            onClick={() =>
              actions.updateModal({ isOpen: false, content: null })
            }
            className="btn btn-light "
            style={{ fontSize: 16 }}
          >
            Fermer
          </button>
        </div>
        <div
          className="position-absolute"
          style={{
            top: 7,
            right: 10,
          }}
        >
          {modalProps.actions}
          <i
            onClick={() =>
              actions.updateModal({ isOpen: false, content: null })
            }
            style={{ fontSize: 20, color: "black" }}
            className="fa fa-times  cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
