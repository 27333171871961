import React, { useEffect, useState } from "react";
import Control from "../../common/Control";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";

const ReceiptItem = ({ receipt }) => {
  const [state, dispatch] = useStore();
  const [paymentData, setPaymentData] = useState({});
  const constantData = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    setIsLoading(true);

    Axios.get(API_URL + "receipts/" + receipt.id + "/payments")
      .then((res) => {
        if (Object.keys(res.data).length > 0) {
          setPaymentData(res.data);
        }
      })
      .catch((err) => {
        toast.error("Une erreur est survenue lors de la récupération des paiements, veuillez recharger la page");
      })
      .then(() => setIsLoading(false));
  };

  return constantData.PAYMENT_STATUS && Object.keys(paymentData).length > 0 ? (
    <>
      <div className="mx-auto mb-4" style={{ maxWidth: 900 }}>
        <h4 className="mt-3 d-flex align-items-center">
          Statut de la quittance{" "}
          <div
            className={"ml-3 badge badge-" + constantData.PAYMENT_STATUS.find((p) => p.id == paymentData.status).color}
          >
            {constantData.PAYMENT_STATUS.find((p) => p.id == paymentData.status).name}
          </div>
        </h4>
        <div className="row">
          <div className="col-12">
            <Control label="Montant" className="readonly" value={Math.round(paymentData.amount * 100) / 10000 + "€"} />
            <Control
              label="Créé le"
              className="readonly"
              value={DateTime.fromISO(paymentData.createdAt).toFormat("dd/MM/yyyy")}
            />
            <Control
              label="Modifié le"
              className="readonly"
              value={DateTime.fromISO(paymentData.updatedAt).toFormat("dd/MM/yyyy")}
            />
            <h5 className="mt-3">Historique des modifications du statut</h5>
            <ul
              className="list-group"
              style={{
                height: 300,
                overflowY: "scroll",
              }}
            >
              {paymentData.historics.map((h, hk) => {
                var pstatus = constantData.PAYMENT_STATUS.find((p) => p.id == h.status);
                return (
                  <li key={"payhisto" + hk} className="list-group-item">
                    <div className={"float-right badge badge-" + pstatus.color}>{pstatus.name}</div>
                    <div>
                      Modifié le : <strong>{DateTime.fromISO(h.updatedAt).toFormat("dd/MM/yyyy")}</strong>
                    </div>
                    <div>Montant : {Math.round(h.amount * 100) / 10000}€</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  ) : isLoading ? (
    <Loader />
  ) : (
    <p className="my-2 text-center">Aucun paiement trouvé</p>
  );
};

export default ReceiptItem;
