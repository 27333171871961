import React, { useEffect, useState } from "react";

const PjPoliciesRcList = ({ users }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (users.length) {
      var allClients = [];
      users.forEach((user) => {
        user.clients.map((client) => {
          if (client.policies.filter((c) => c.isGozen && c.options.includes("OPTION_RC")).length) {
            allClients.push(client.company);
          }
        });
      });
      setClients(allClients);
    }
  }, [users]);

  return clients.length ? (
    <ul className="custom-ul">
      {clients.map((c, ck) => {
        var str = `${c}`;

        return (
          <li key={`polrclist${ck}`}>
            <strong key={`optrc${ck}`}>{str}</strong>
          </li>
        );
      })}
    </ul>
  ) : (
    <ul className="custom-ul">
      <li>Vous n'avez souscrit cette option pour aucune de vos sociétés.</li>
    </ul>
  );
};

export default PjPoliciesRcList;
