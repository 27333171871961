import React from "react";

const InfoOptRc = () => {
  return (
    <div className="row">
      <div className="col-12">
        <p>
          Votre juriste dédié s’occupe de tout pour le recouvrement de vos
          créances professionnelles, certaines, liquides et exigibles ayant une
          ancienneté maximale de 180 jours et dont le montant est au moins égal
          à 500€.
        </p>
        <p>
          Il sera retenu, à titre de franchise, 15% du montant des sommes
          recouvrées par dossier.
        </p>
      </div>
    </div>
  );
};

export default InfoOptRc;
