import { DateTime } from "luxon";
import { sidenavActions } from "../../../../../../context/actions/sidenavActions";
import useStore from "../../../../../../context/useStore";
import TimelineFiltered from "../../../../../common/Timeline/TimelineFiltered";

const useContextualTimeline = () => {
  const [state, dispatch] = useStore();
  const sActions = sidenavActions(state, dispatch);

  const openTimeline = (tlProps) => {
    sActions.updateSidenav({
      isOpen: true,
      content: (
        <TimelineFiltered
          className="mt-4"
          showFilters={true}
          initialSearch={{
            ...tlProps,
            searchString: "",
            beginAt: DateTime.local().minus({ months: 6 }).startOf("day"),
            endAt: DateTime.local().endOf("day"),
          }}
        />
      ),
    });
  };

  return { openTimeline };
};

export default useContextualTimeline;
