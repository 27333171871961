import { useEffect } from "react";
import Loader from "../../common/Loader";
import Page from "../../common/layout/Page";
import ClientItem from "./ClientItem";
import useContractRenewAll from "./useContractRenewAll";

const ContractRenewAll = () => {
  const {
    isLoading,
    quittances,
    clients,
    selectedClientToPay,
    getAllRenewableContracts,
    onSelectClientToPay,
    onDeselectClientToPay,
    onPaidQuittances,
  } = useContractRenewAll();

  useEffect(() => {
    getAllRenewableContracts();
  }, []);

  return (
    <Page
      container="container-fluid"
      title={
        <div className="text-left">
          Payer mes assurances
          <p
            className="custom-p pt-2 font-weight-normal"
            style={{ color: "black", lineHeight: "20px" }}
          >
            🔒 Réglez chaque contrat individuellement ou plusieurs contrats
            simultanément, par carte bancaire ou prélèvement SEPA.{" "}
            <strong>Un justificatif sera généré pour chaque paiement.</strong>
          </p>
        </div>
      }
      back="/"
      style={{ maxWidth: 800, margin: "0 auto" }}
    >
      {isLoading && <Loader />}
      {quittances.length === 0 && !isLoading && (
        <div className="alert alert-info">Aucun contrat à renouveler</div>
      )}
      {quittances?.length > 0 && (
        <div>
          {Object.keys(clients).map((clientKey) => {
            const client = clients[clientKey].client;
            return (
              <ClientItem
                key={client.id}
                client={clients[clientKey]}
                onSelectClientToPay={onSelectClientToPay}
                onDeselectClientToPay={onDeselectClientToPay}
                selected={selectedClientToPay?.client.id === client.id}
                disabled={
                  selectedClientToPay &&
                  selectedClientToPay.client.id !== client.id
                }
                onPaidQuittances={onPaidQuittances}
              />
            );
          })}
        </div>
      )}
    </Page>
  );
};

export default ContractRenewAll;
