import { Provider } from "jotai";
import React, { useEffect } from "react";
import styled from "styled-components";
import useStore from "../../../../context/useStore";
import useInsuranceCompanies from "../../../../hooks/useInsuranceCompanies/useInsuranceCompanies";
import useProducts from "../../../../hooks/useProducts/useProducts";
import useProductTypes from "../../../../hooks/useProductTypes/useProductTypes";
import { ClientContext } from "./ClientContext/ClientContext";
import ClientCount from "./ClientCount";
// import ClientFilters from "./ClientFilters/ClientFilters";
import ClientItem from "./ClientItem/ClientItem";
import useClientList from "./useClientList";
import useUserCustomers from "./useUserCustomers";

const ClientList = () => {
  const [state] = useStore();
  const { myClients, otherClients, actions, resultCount } = useClientList();
  const { userCustomers, actions: userCustomersActions } = useUserCustomers();
  const { products, onGetProducts } = useProducts();
  const { productTypes, onGetProductTypes } = useProductTypes();
  const { insuranceCompanies, onGetInsuranceCompanies } = useInsuranceCompanies();

  useEffect(() => {
    actions.onGetClients();
    onGetProducts();
    onGetProductTypes();
    onGetInsuranceCompanies();
  }, []);

  return (
    <StyledClientList>
      {/* <ClientFilters /> */}
      {/* <ClientCount /> */}
      {myClients.map((client) => (
        <ClientContext.Provider
          value={{
            clientActions: actions,
            userCustomers: userCustomers,
            products,
            productTypes,
            insuranceCompanies,
            client,
          }}
          key={`client${client.id}`}
        >
          <Provider>
            <ClientItem clientData={client} isMine />
          </Provider>
        </ClientContext.Provider>
      ))}
      {otherClients.map((client) => (
        <ClientContext.Provider
          value={{
            clientActions: actions,
            userCustomers: userCustomers,
            products,
            productTypes,
            insuranceCompanies,
            client,
          }}
          key={`client${client.id}`}
        >
          <Provider>
            <ClientItem clientData={client} />
          </Provider>
        </ClientContext.Provider>
      ))}
    </StyledClientList>
  );
};

const StyledClientList = styled.div``;

export default ClientList;
