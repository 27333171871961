import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";

const legalFormsAtom = atom([]);

const useLegalForms = () => {
  const [legalForms, setLegalForms] = useAtom(legalFormsAtom);

  const getLegalForms = () => {
    dataService.get(`constants/legalforms`, setLegalForms);
  };

  return {
    legalForms,
    legalFormsActions: {
      getLegalForms,
    },
  };
};

export default useLegalForms;
