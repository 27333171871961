import { DateTime } from "luxon";
import React from "react";
import Colors from "../../../Colors";

const Stepper = (props) => {
  return (
    <ul className="list-group animated fadeIn">
      {props.steps.map((step, stepk) => (
        <li key={"step" + stepk} className="list-group-item d-flex flex-row align-items-center border-0">
          <span
            className="d-flex align-items-center justify-content-center position-relative"
            style={{
              background: Colors["" + step.color],
              borderRadius: "100%",
              color: step.color == "light" ? "black" : "white",
              padding: 10,
            }}
          >
            <i className={"position-relative fa fa-" + step.icon} style={{ zIndex: "9999" }} />
            <div
              className=""
              style={{
                position: "absolute",
                bottom: -15,
                height: "200%",
                background: Colors["" + step.color],
                width: 1,
                zIndex: "0",
              }}
            ></div>
          </span>
          <span className="ml-2" style={{ fontSize: 12 }}>
            {step.title}
            {step.date ? (
              <i className="text-muted d-block">{DateTime.fromISO(step.date).toFormat("dd/MM/yyyy HH:mm")}</i>
            ) : (
              false
            )}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default Stepper;
