import React from "react";
import { useParams } from "react-router-dom";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import ACContainer from "./ACContainer";
import MRPContainer from "./MRPContainer";
import useForms from "./useForms";
import CYBERContainer from "./CYBERContainer";

const Forms = () => {
  const { hasAC, hasMRP, hasCYBER, clients, isLoading } = useForms();

  const hasNoForms = hasAC === 0 && hasMRP === 0 && hasCYBER === 0;

  return (
    <Page margin="mt-5" back={"/"} backText="Retour">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-4">
            <p className="custom-p ">
              👀 <strong>Visualisez ici</strong> votre demande d'Assurance
              Construction ou d'Assurance Cyber.
              <br />
              <strong>Téléchargez</strong> et/ou <strong>modifiez</strong> votre
              questionnaire d'Assurance Multirisque Professionnelle.
            </p>
            {hasNoForms && (
              <p className="custom-p text-center">
                <strong>
                  Vous n'avez aucune demande en cours pour le moment.
                </strong>
              </p>
            )}
          </div>
          {hasMRP > 0 && <MRPContainer MRPClients={clients.MRP} />}
          {hasAC > 0 && <ACContainer ACClients={clients.AC} />}
          {hasCYBER > 0 && <CYBERContainer CYBERClients={clients.CYBER} />}
          <div className="d-center">
            <a
              className="btn btn-primary text-white cursor-pointer"
              href={process.env.REACT_APP_WEB_FORM_URL}
              target="_blank"
            >
              Découvrir les assurances disponibles pour mon entreprise
            </a>
          </div>
        </>
      )}
    </Page>
  );
};

export default Forms;
