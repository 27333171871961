import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import { API_URL } from "../../config";
import { DateTime } from "luxon";
import FileExist from "./FileExist";
import FileUpload from "./FileUpload";
import FileNotExist from "./FileNotExist";
import { colors } from "../../helpers/colors";

const DropFile = ({
  document,
  documentUploaded,
  documentRemoved,
  uploadNewFile,
  cancelNewVersion,
  small = false,
  inline = false,
  trueList,
  dataPriv,
  client,
  productName,
}) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    setErrors({});
    updateFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function updateFile(files) {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (!documentUploaded) {
      alert("Vous ne pouvez pas effectuer cette action.");
      return false;
    }
    if (files.length > 0 && allowedTypes.indexOf(files[0].type) != -1) {
      let data = new FormData();
      data.append("file", files[0]);
      data.append("fileType", document.fileType.id);
      data.append("entityId", document.entity.id);
      data.append("entityRef", document.entity.ref);

      uploadFile(data);
    } else {
      alert("Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf.");
    }
  }
  function uploadFile(data) {
    setIsUploading(true);
    Axios.post(API_URL + "files", data, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

        setProgress(percentCompleted);
        // do whatever you like with the percentage complete
        // maybe dispatch an action that will update a progress bar or something
      },
    })
      .then((res) => {
        documentUploaded(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsUploading(false);
      });
  }

  return (
    <>
      <div
        className={
          "d-flex  justify-content-center " +
          (inline ? "  mb-0 align-items-center " : " mb-2 align-items-end   ") +
          (trueList ? " " : " w-100 ")
        }
        style={{
          color: document.fileName ? "" : inline ? "" : "#828282",
          minHeight: inline ? 0 : small ? 60 : 80,
        }}
      >
        <h5
          style={{
            fontWeight: "600",
            fontSize: small ? 14 : inline ? 12 : 16,
          }}
          className={
            " " +
            (inline ? " my-2 mr-2 text-left " : "  text-center ") +
            (trueList ? " " : " w-100 ")
          }
        >
          {document.fileType.name}
        </h5>
      </div>
      <div
        className={
          "d-flex align-items-center justify-content-center  " +
          (document.fileName ? " bg-white   " : "  ") +
          (inline ? " flex-row  " : " flex-column shadow-sm w-100 ") +
          (document.fileName ? (inline ? "p-2" : " p-3 border h-100 ") : "")
        }
        style={{
          borderRadius: window.borderRadius,
          minHeight: inline ? 0 : small ? 0 : 200,
        }}
      >
        {document.fileName ? (
          <FileExist
            dataPriv={dataPriv}
            document={document}
            documentRemoved={documentRemoved}
            uploadNewFile={uploadNewFile}
            small={small}
            inline={inline}
            client={client}
            productName={productName}
          />
        ) : (
          <div
            data-priv={dataPriv}
            className={
              "h-100 w-100 d-flex align-items-center justify-content-center " +
              (isDragActive && documentUploaded ? "animated pulse infinite" : "")
            }
            style={{
              borderRadius: window.borderRadius,
              outline: "none",
              minHeight: inline ? 0 : 200,
              color: isDragActive ? colors.primary : documentUploaded ? "#737373" : colors.success,
              border: documentUploaded
                ? inline
                  ? "none"
                  : "2px dashed #cacaca"
                : "2px solid " + colors.success,
              background: isDragActive && documentUploaded ? "#38556c40" : "",
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            {documentUploaded && <input {...getInputProps()} />}
            {isDragActive && documentUploaded ? (
              <>
                <div className="">
                  <p className="mb-3">
                    <b>Déposer le fichier ici</b>
                  </p>
                  {!inline && <i className="fa fa-expand fa-5x mb-2" />}
                </div>
              </>
            ) : isUploading ? (
              <FileUpload progress={progress} />
            ) : (
              <FileNotExist
                isNewVersion={document.isNewVersion}
                documentUploaded={documentUploaded}
                inline={inline}
              />
            )}
            {errors.document && !isDragActive && (
              <span className="text-danger p-3">{errors.document}</span>
            )}
          </div>
        )}
      </div>
      {document.isNewVersion && progress == 0 && cancelNewVersion && (
        <button className="btn btn-danger btn-sm mt-2 w-100" onClick={cancelNewVersion}>
          Annuler l'envoi
        </button>
      )}
    </>
  );
};

export default DropFile;
