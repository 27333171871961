import dataService from "../helpers/dataService";

export const getPolicyReceipts = (policyId) => {
  return new Promise((resolve, reject) => {
    dataService.get(`receipts/policy/${policyId}`, resolve, reject);
  });
};

export const getReceiptPayments = (receiptId) => {
  return new Promise((resolve, reject) => {
    dataService.get(`receipts/${receiptId}/payments`, resolve, reject);
  });
};

export const patchReceiptPayment = (paymentId, receiptPayment) => {
  return new Promise((resolve, reject) => {
    dataService.patch(`payments/${paymentId}/status`, receiptPayment, resolve, reject);
  });
};

export const patchReceiptModulrId = (receiptId, modulrId) => {
  return new Promise((resolve, reject) => {
    dataService.patch(`receipts/${receiptId}/modulrId`, { modulrId }, resolve, reject);
  });
};

export const postRegenerateCP = (policyId, generationData) => {
  return new Promise((resolve, reject) => {
    dataService.post(`policies/${policyId}/dp`, generationData, resolve, reject);
  });
};

export const patchPolicyGroupamaStatus = (policyId, groupamaStatus) => {
  return new Promise((resolve, reject) => {
    dataService.post(`policies/${policyId}/groupama`, groupamaStatus, resolve, reject);
  });
};
