import React, { useState, useEffect } from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";

import CardSectionSepa from "./CardSectionSepa";
import Loader from "../Loader";
import tools from "../../../helpers/tools";

export default function CheckoutFormSepa({
  paymentIntent,
  setErrors,
  setPaymentIntent,
  isPaying,
  setIsPaying,
  validatePayment,
  userDatas,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setErrors({});
    setIsPaying(true);

    const iban = elements.getElement(IbanElement);

    const {
      address,
      address2,
      postalCode,
      city,
    } = userDatas.policy.client.location1;
    const {
      gender,
      firstname,
      lastname,
      siret,
      ape,
      email,
      mobilePhone,
      companyActivity,
    } = userDatas.policy.client;

    const result = await stripe.confirmSepaDebitPayment(
      paymentIntent.clientSecret,
      {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name: lastname,
            email: email,
            phone: mobilePhone,
            address: {
              city: city,
              country: "FR",
              line1: address,
              line2: address2,
              postal_code: postalCode,
            },
          },
          metadata: {
            Civilité: gender == 1 ? "Mr" : "Mme",
            Prénom: firstname,
            Nom: lastname,
            Mobile: mobilePhone,
            SIRET: siret,
            APE: ape,
            "Activité 1": companyActivity,
            "Activité 2": companyActivity,
          },
        },
        setup_future_usage: "off_session",
      }
    );

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setErrors({ payment: result.error.message });
      setIsPaying(false);
    } else {
      // The payment has been processed!
      if (
        result.paymentIntent.status === "succeeded" ||
        result.paymentIntent.status === "processing"
      ) {
        setIsPaying(false);
        validatePayment();
        setPaymentIntent(result.paymentIntent);

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardSectionSepa />
        <div className="d-flex justify-content-between mt-2">
          {isPaying ? (
            <div className="d-center w-100">
              <Loader
                className="flex-column mt-4"
                text="Paiement en cours"
                textClassName="mt-2"
              />
            </div>
          ) : (
            <button
              className="btn-primary py-3 font-weight-bold w-100 border-0"
              disabled={!stripe}
            >
              <i className="fa fa-lock mr-2" />
              PAYER{" "}
              {paymentIntent.amount
                ? tools
                    .truenumberWithSpaces(
                      Math.round((paymentIntent.amount / 100) * 100) / 100
                    )
                    .toString()
                    .replace(".", ",") + "€"
                : ""}
            </button>
          )}
        </div>
      </form>
    </>
  );
}
