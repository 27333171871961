import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";

const productTypesAtom = atom([]);

const useProductTypes = () => {
  const [productTypes, setProductTypes] = useAtom(productTypesAtom);

  const onGetProductTypes = () => {
    dataService.get(`productTypes`, setProductTypes);
  };

  return {
    productTypes,
    onGetProductTypes,
  };
};

export default useProductTypes;
