import { useEffect } from "react";
import Loader from "../../../../../../../../../../common/Loader";
import useClientItem from "../../../../../../useClientItem";
import useCompanyItem from "../../../../useCompanyItem";
import BPDocumentItem from "../../../BPDocuments/BPDocumentItem/BPDocumentItem";
import usePolicyItem from "../usePolicyItem";
import useBPPolicyDocuments from "./useBPPolicyDocuments";

const BPPolicyDocuments = () => {
  const { policy, companyPolicy } = usePolicyItem();
  const { isLoadingPolicyDocuments, policyDocuments, policyDocumentsActions } =
    useBPPolicyDocuments();

  useEffect(() => {
    if (policyDocuments.length == 0) {
      policyDocumentsActions.onGetPolicyDocuments(policy.id);
    }
  }, []);

  function checkDocActionAvailable(doc) {
    return ["Client", "UserCustomer"].includes(doc.entity.ref);
  }

  return (
    <div className="row mt-3 bg-body">
      {isLoadingPolicyDocuments && <Loader withContainer />}

      {policyDocuments.map((document) => {
        return (
          <div
            className="col-12 col-md-6 col-lg-4 p-2"
            key={`poldocgroup${document.map((d) => d.fileType.name).join("-")}`}
          >
            <BPDocumentItem
              doc={{ ...document, productName: policy.title, companyName: companyPolicy.company }}
              checkCanModify={checkDocActionAvailable}
              dataPriv={`app_modifycompanydocs-${policy.client}`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BPPolicyDocuments;
