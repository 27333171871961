import { useEffect } from "react";
import tools from "../../../../../../../../../helpers/tools";
import LabelValuePair from "../../../../../../../../common/LabelValuePair/LabelValuePair";
import Loader from "../../../../../../../../common/Loader";
import useBPUsers from "./useBPUsers";

const BPUsers = () => {
  const { users, isLoadingUsers, onGetCompanyUsers } = useBPUsers();

  useEffect(() => {
    onGetCompanyUsers();
  }, []);

  return (
    <div>
      {isLoadingUsers && <Loader withContainer />}
      {users?.length == 0 && !isLoadingUsers && (
        <p className="m-0 text-center">Aucun utilisateur</p>
      )}
      <ul className="list-group">
        {users?.map((user) => {
          return (
            <li className="list-group-item" key={`user${user.id}`}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div>
                    <strong>{tools.getFullName(user)}</strong>
                  </div>
                  <div>{user.email}</div>
                </div>
                <div className="col-12 col-md-6">
                  {user.role?.map((role) => (
                    <div key={`userrole${user.id}${role.id}`}>
                      <LabelValuePair
                        label={
                          <>
                            <strong>{role.role}</strong> de
                          </>
                        }
                        value={role.clients.join(", ")}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BPUsers;
