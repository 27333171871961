import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

const CreateCard = ({ customerId, getPaymentMethods, client }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      if (
        window.confirm(
          "Le service de paiement n'a pas donné de réponse, cliquez sur ok pour recharger la page puis réessayez."
        )
      ) {
        window.location.reload();
      }
      setIsLoading(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setIsLoading(false);
    } else {
      dataService.post(
        `clients/${client.id}/stripe/paymentmethods`,
        {
          paymentMethodId: paymentMethod.id,
        },
        (datas) => {
          getPaymentMethods();
          toast.success("Votre moyen de paiement a bien été ajouté.");
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Monserrat, sans-serif",
        fontSmoothing: "antialiased",
        lineHeight: "26px",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      <p className="custom-p">
        Entrez les informations de la carte à ajouter :
      </p>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      {Object.values(errors).map((err) => (
        <p className="alert alert-danger">{err}</p>
      ))}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-100 d-center mt-4">
          <button
            type="submit"
            className="btn btn-info btn-sm"
            disabled={!stripe}
          >
            Ajouter cette carte
          </button>
        </div>
      )}
    </form>
  );
};

export default CreateCard;
