import React from "react";
import { REF_APP } from "../../../config";

const UserRoleSelect = ({ ROLES, setRoles, roles, role, user }) => {
  const updateRole = (key) => {
    setRoles(
      roles.map((r) => {
        if (r.id == role.id) {
          return { ...r, roleKey: key };
        }
        return r;
      })
    );
  };

  return (
    <div className="my-4">
      <p style={{ fontSize: 17 }} className="font-weight-bold text-success">
        Quel sera le rôle de{" "}
        <span style={{ color: "black" }}>{user.firstname}</span> ?
      </p>
      <div className="d-center flex-row">
        {Object.values(ROLES[REF_APP])
          .filter((ro) => ro.key != "OWNER")
          .map((rol) => (
            <button
              className={`btn d-center flex-fill flex-column mx-1 ${
                role.roleKey == rol.key
                  ? "btn-success"
                  : "bg-greylight text-primary"
              }`}
              onClick={() => updateRole(rol.key)}
              style={{ fontSize: 14 }}
            >
              <i className={`fa fa-${rol.icon}`} style={{ fontSize: 24 }} />
              {rol.label}
            </button>
          ))}
      </div>
      {role.roleKey.length ? (
        <div
          className="mt-4"
          style={{ fontSize: 14, lineHeight: "22px" }}
          dangerouslySetInnerHTML={{
            __html: Object.values(ROLES[REF_APP])
              .filter((ro) => ro.key != "OWNER")
              .find((r) => r.key == role.roleKey).description,
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default UserRoleSelect;
