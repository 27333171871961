import React from "react";

const LabelValuePair = ({ label, value }) => {
  return (
    <>
      <span style={{ color: "black", fontWeight: "500" }}>{label}</span> : {value}
    </>
  );
};

export default LabelValuePair;
