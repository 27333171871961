import ImageFileCrop2 from "../../common/ImageFileCrop/ImageFileCrop2";
import GreyCard from "../Dashboard/GreyCard";

const MyAvatar = ({ setModifyAvatar }) => {
  return (
    <>
      <button
        className="btn btn-link pl-0 text-success mb-2"
        onClick={() => setModifyAvatar(false)}
      >
        <i className="fa fa-arrow-left mr-2" />
        Retour
      </button>
      <p className="custom-p ">
        <strong>Choisissez une photo</strong> parmi vos fichiers ou prenez
        directement un selfie si vous êtes sur mobile.
      </p>
      <GreyCard>
        <ImageFileCrop2 setModifyAvatar={setModifyAvatar} />
      </GreyCard>
    </>
  );
};

export default MyAvatar;
