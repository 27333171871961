import { Provider } from "jotai";
import { useEffect } from "react";
import useCompanyItem from "../../useCompanyItem";
import PolicyItem from "./PolicyItem/PolicyItem";
import useCompanyPolicies from "./useCompanyPolicies";

const BPPolicies = () => {
  const { company, bottomPanelParams } = useCompanyItem();
  const { companyPolicies, initCompanyPolicies } = useCompanyPolicies();

  useEffect(() => {
    initCompanyPolicies(company.policies);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      checkPolicySelected();
    }, 500);
  }, [companyPolicies, bottomPanelParams]);

  const checkPolicySelected = () => {
    if (bottomPanelParams) {
      let firstItemFound = false;
      for (var key in companyPolicies) {
        const policy = companyPolicies[key];
        let elem = document.querySelector(`.policy-${policy.id}`);
        if (elem) {
          elem.classList.remove("animated");
          elem.classList.remove("pulse");
        }
        if (elem && bottomPanelParams.productTypeModulrId == policy.product.productTypeModulrId) {
          if (!firstItemFound && companyPolicies.length > 1) {
            firstItemFound = true;
            let parent = getScrollParent(elem);
            if (parent && parent.classList.contains("companyBottomPanel")) {
              parent.scroll(0, elem.offsetTop);
            }
          }
          elem.classList.add("animated");
          elem.classList.add("pulse");
          elem.classList.add("faster");
          elem.style.animationDelay = "500ms";
        }
      }
    }
  };

  function getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }

  return (
    <div className="row mx-0">
      {companyPolicies.map((policy) => {
        return (
          <Provider>
            <PolicyItem companyData={company} policyData={policy} key={`pol${policy.id}`} />
          </Provider>
        );
      })}
    </div>
  );
};

export default BPPolicies;
