import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  IbanElement,
} from "@stripe/react-stripe-js";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

const CreateIban = ({ customerId, getPaymentMethods, client }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      if (
        window.confirm(
          "Le service de paiement n'a pas donné de réponse, cliquez sur ok pour recharger la page puis réessayez."
        )
      ) {
        window.location.reload();
      }
      setIsLoading(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const ibanElement = elements.getElement(IbanElement);

    const { lastname, email, mobilePhone, location1 } = client;

    const { city, address, address2, country, postalCode } = location1;

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "sepa_debit",
      sepa_debit: ibanElement,
      billing_details: {
        name: lastname,
        email: email,
        phone: mobilePhone,
        address: {
          city: city,
          country: "FR",
          line1: address,
          line2: address2,
          postal_code: postalCode,
        },
      },
    });

    if (error) {
      setIsLoading(false);
    } else {
      dataService.post(
        `clients/${client.id}/stripe/paymentmethods`,
        {
          paymentMethodId: paymentMethod.id,
        },
        (datas) => {
          toast.success("Votre moyen de paiement a bien été ajouté.");
          getPaymentMethods();
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  const IBAN_STYLE = {
    base: {
      color: "#32325d",
      fontFamily: "Monserrat, sans-serif",
      fontSmoothing: "antialiased",
      lineHeight: "26px",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };
  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: "FR",
    style: IBAN_STYLE,
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      <p className="custom-p">Entrez l'IBAN du compte à ajouter</p>
      <IbanElement options={IBAN_ELEMENT_OPTIONS} />
      {Object.values(errors).map((err) => (
        <p className="alert alert-danger">{err}</p>
      ))}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-100 d-center mt-4">
          <button
            type="submit"
            className="btn btn-info btn-sm"
            disabled={!stripe}
          >
            Ajouter cet IBAN
          </button>
        </div>
      )}
    </form>
  );
};

export default CreateIban;
