import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import useContractRenewAll from "../../ContractRenewAll/useContractRenewAll";
import "./comp.scss";

const RenewableQuittances = () => {
  const { clients, getAllRenewableContracts } = useContractRenewAll();
  const history = useHistory();

  useEffect(() => {
    getAllRenewableContracts();
  }, []);

  const onClickContratToPay = () => {
    history.push("/payer-mes-contrats");
  };

  let policies = [];

  if (!clients) return null;

  const getProduitBadgeArray = (client) => {
    //return an array of objects {typeProduit: "ERGOBAT", count: 2}, so that there is no duplicate
    let produitBadges = [];
    client.contrats.forEach((contrat) => {
      const typeProduit = contrat.typeProduit;
      const count = client.contrats.filter(
        (c) => c.typeProduit === typeProduit
      ).length;

      if (!produitBadges.find((pb) => pb.typeProduit === typeProduit)) {
        produitBadges.push({ typeProduit, count });
      }
    });
    return produitBadges;
  };

  //base on the sample client object, build the policies array
  //so that it can be used in the return statement

  Object.keys(clients).forEach((clientKey) => {
    const clientContracts = clients[clientKey].contrats;
    clientContracts.forEach((contract) => {
      policies.push({ ...contract, client: clients[clientKey].client });
    });
  });

  return policies.length ? (
    <div style={{ marginBottom: 25 }} className="row">
      <div className="col-12 mb-2">
        <div
          className="d-flex flex-row align-items-center flex-wrap justify-content-between"
          style={{ gap: "15px" }}
        >
          <p
            className=" bounceInRight text-success font-weight-bold m-0"
            style={{ fontSize: 14 }}
          >
            <i className="fa fa-arrow-down text-success mr-2" />
            Vous avez {policies.length} contrat(s) en attente de paiement :
          </p>
          <Link to={`/payer-mes-contrats`} className="btn btn-primary btn-sm">
            <i className="fa fa-sync mr-2" />
            Payer mes contrats
          </Link>
        </div>
      </div>
      <div className="col-12">
        <ul className="list-group shadow-small">
          {Object.keys(clients).map((clientId, clk) => {
            const client = clients[clientId];
            const produitBadges = getProduitBadgeArray(client);
            return (
              <li
                className={`list-group-item border bg-blue cursor-pointer animated fadeInUp faster d-flex align-items-center justify-content-between flex-wrap polist`}
                style={{
                  animationDelay: `${clk * 50}ms`,
                }}
                key={`polrenew${clk}`}
                onClick={() => {
                  onClickContratToPay();
                }}
              >
                <div className="d-flex flex-row align-items-center flex-wrap">
                  <div
                    className="text-success font-weight-bold mr-2 w-100"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    <i className="fa fa-building mr-2" />
                    {client.client?.nomSociete}
                  </div>
                  <div
                    className="mr-2 mt-1 font-weight-bold d-flex flex-wrap"
                    style={{ gap: "0.5rem" }}
                  >
                    {produitBadges.map((pol, polk) => {
                      if (polk > 2) return null;
                      const hasMoreThan1 = pol.count > 1;
                      return (
                        <div key={`pol${polk}`}>
                          <span
                            className={`badge bg-white shadow-small px-2 text-success rounded`}
                            style={{ fontSize: 12 }}
                          >
                            {pol.typeProduit ? pol.typeProduit : ""}
                            {hasMoreThan1 && (
                              <span className="bg-success badge ml-1 text-white rounded-circle">
                                {pol.count}
                              </span>
                            )}
                          </span>
                        </div>
                      );
                    })}
                    {produitBadges.length > 3 ? (
                      <span className="badge text-success d-center font-weight-normal">
                        +{produitBadges.length - 3} autre(s)
                      </span>
                    ) : null}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

export default RenewableQuittances;

/*

Client sample object : 

{
    "5e54e607ffdf7d0017258194": {
        "client": {
            "id": "5e54e607ffdf7d0017258194",
            "nomSociete": "ECBO"
        },
        "contrats": [
            {
                "id": "619d8c639b8c8a001b93ed36",
                "nomProduit": "ERGOBAT",
                "numero": "SV75094581",
                "titre": "ERGO BATISSEURS",
                "objet": null,
                "compagnie": "ERGO FRANCE",
                "quittances": [
                    {
                        "id": "62197e19afdd72001c08b8f0",
                        "debutPeriode": "2020-09-30T20:00:00.000Z",
                        "dateLimite": "2020-10-15T20:00:00.000Z",
                        "finPeriode": "2020-12-30T20:00:00.000Z",
                        "restantDu": 4796.62
                    },
                    {
                        "id": "62197e19afdd72001c08b85f",
                        "debutPeriode": "2020-06-30T20:00:00.000Z",
                        "dateLimite": "2020-07-15T20:00:00.000Z",
                        "finPeriode": "2020-09-29T20:00:00.000Z",
                        "restantDu": 4796.62
                    }
                ]
            }
        ]
    }
}

*/
