import React from "react";

const GreyCard = (props) => {
  return (
    <div
      className={`position-relative d-flex align-items-start justify-content-start flex-column animated fadeIn border ${
        props.paddingClassname || "p-3"
      }`}
      data-priv={props.dataPriv}
      style={{
        background: "#F1F2FE",
        borderRadius: 5,
        fontSize: 14,
        lineHeight: "22px",
        marginBottom: props.marginBottom || 25,
        boxShadow: "0 1px 4px -3px black",
      }}
    >
      <div
        onClick={() => {}}
        className="w-100"
        style={{
          color: "black",
        }}
      >
        {props.disabled && (
          <div
            className="position-absolute w-100 h-100 bg-white"
            style={{
              left: 0,
              top: 0,
              zIndex: 2,
              opacity: 0.35,
              borderRadius: 5,
            }}
          ></div>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default GreyCard;
