import React from "react";
import tools from "../../../helpers/tools";

const UserCard = ({ user }) => {
  return (
    <div className=" my-4">
      <div className="d-center flex-column">
        {/* <div
          style={{
            overflow: "hidden",
            width: "100%",
            height: 200,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            textAlign: "center",
            backgroundImage: `url(/images/partner-avatar.svg)`,
          }}
        ></div> */}
        <i
          className="fa fa-user-tie text-success"
          style={{
            fontSize: 140,
          }}
        />
        <strong className="mt-3" style={{ fontSize: 20 }}>
          {tools.getFullName(user)}
        </strong>
        <strong className="text-success" style={{ fontSize: 18 }}>
          {user.company}
        </strong>
        <ul className="list-group mt-3 w-100 text-center">
          {user.email && (
            <li className="list-group-item bg-transparent border-0 py-0 px-0">
              <a href={`mailto:${user.email}`} className="btn btn-link text-left py-1">
                <i className="fa fa-envelope mr-1" />
                {user.email}
              </a>
            </li>
          )}
          {user.phone && (
            <li className="list-group-item bg-transparent border-0 py-0 px-0">
              <a href={`tel:${user.phone}`} className="btn btn-link text-left py-1">
                <i className="fa fa-phone mr-1" />
                {tools.formatPhone(user.phone)}
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default UserCard;
