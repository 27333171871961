import { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useRenewablePolicies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [policies, setPolicies] = useState([]);

  const getAllRenewablePolicies = async () => {
    setIsLoading(true);
    dataService.get(
      "policies/mines/to-renew",
      setPolicies,
      () => {},
      (err) => {},
      () => setIsLoading(false)
    );
  };
  return {
    getAllRenewablePolicies,
    isLoading,
    policies,
  };
};

export default useRenewablePolicies;
