import React from "react";

const OpenDetailButton = ({
  onClick,
  title,
  className = "",
  dataPriv,
  icon = "fa-edit",
  ...props
}) => {
  return (
    <div
      data-priv={dataPriv}
      onClick={onClick}
      className={` d-flex btn-custom align-items-center justify-content-between cursor-pointer border-bottom ${className}`}
      {...props}
    >
      <strong className="text-left">
        <span className="">{title}</span>
      </strong>
      <i className={`fa ${icon}`} />
    </div>
  );
};

export default OpenDetailButton;
