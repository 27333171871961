import { atom, useAtom } from "jotai";
import dataService from "../../../../../../../../../../../helpers/dataService";

const policyDocumentsAtom = atom([]);
const isLoadingPolicyDocumentsAtom = atom(false);

const useBPPolicyDocuments = () => {
  const [policyDocuments, setPolicyDocuments] = useAtom(policyDocumentsAtom);
  const [isLoadingPolicyDocuments, setIsLoadingPolicyDocuments] = useAtom(
    isLoadingPolicyDocumentsAtom
  );

  const onGetPolicyDocuments = (policyId) => {
    setIsLoadingPolicyDocuments(true);
    dataService.get(
      `policies/${policyId}/files`,
      setPolicyDocuments,
      (err) => {},
      () => setIsLoadingPolicyDocuments(false)
    );
  };

  return {
    policyDocuments,
    isLoadingPolicyDocuments,
    policyDocumentsActions: {
      onGetPolicyDocuments,
    },
  };
};

export default useBPPolicyDocuments;
