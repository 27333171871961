import React from "react";
import { ClipLoader } from "react-spinners";
import { SentryRoute } from "../../App";
import useStore from "../../context/useStore";
import Login from "../pages/Login/Login";
import RenewPasswordLoggedIn from "../pages/RenewPassword/RenewPasswordLoggedIn";
import MustActivateAccount from "./MustActivateAccount/MustActivateAccount";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [state, dispatch] = useStore();

  return state.auth.isLoading ? (
    <ClipLoader />
  ) : (
    <SentryRoute
      {...rest}
      render={(props) => {
        return state.auth.isAuthenticated == true ? (
          state.auth.user.mustRenewPassword ? (
            <RenewPasswordLoggedIn />
          ) : !state.auth.user.isActivated ? (
            <MustActivateAccount />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Login />
        );
      }}
    />
  );
};

export default PrivateRoute;
