import React, { useRef, useState, useEffect } from "react";

import tools from "../../../helpers/tools";

const WhiteCard = (props) => {
  const [showDetail, setShowDetail] = useState(false);
  var cardRef = useRef(null);

  return (
    <div
      className={`d-flex align-items-start justify-content-start flex-column animated fadeIn ${
        props.paddingClassname || "p-3"
      }`}
      data-priv={props.dataPriv}
      style={{
        background: props.bgColor || "#ffffff",
        borderRadius: 5,
        fontSize: 14,
        lineHeight: "22px",
        marginBottom: props.marginBottom || 25,
        boxShadow: "0 1px 4px -3px black",
      }}
      ref={cardRef}
    >
      <div
        className="w-100"
        style={{
          color: "black",
        }}
      >
        <div
          className="d-flex align-items-center flex-row justify-content-start w-100 position-relative cursor-pointer"
          onClick={() => setShowDetail(!showDetail)}
        >
          <img src={props.image} />
          <strong
            className="text-success ml-3"
            style={{
              fontSize: 17,
              lineHeight: "22px",
              maxWidth: "68%",
              display: "block",
            }}
          >
            {props.title}
          </strong>
          <i
            className={`position-absolute fa fa-chevron-${
              showDetail ? "up" : "down"
            } text-primary animated faster ${showDetail ? "rollIn" : "rotateIn"}`}
            style={{
              fontSize: 18,
              right: 0,
            }}
          />
        </div>

        <div className="position-relative">
          {props.text ? (
            <div
              onClick={() => setShowDetail(!showDetail)}
              className="cursor-pointer"
              style={{ marginTop: 12 }}
            >
              {props.text}
            </div>
          ) : null}
          <div
            style={{
              marginTop: props.text ? 0 : showDetail ? 12 : 0,
              overflow: "hidden",
              transition: "400ms",
              maxHeight: showDetail ? "800px" : "0px",
            }}
          >
            {props.content}
          </div>
          {!showDetail ? null : (
            <i
              onClick={() => setShowDetail(!showDetail)}
              className={`position-absolute fa fa-minus text-primary`}
              style={{
                fontSize: 18,
                right: 0,
                bottom: 0,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WhiteCard;
