/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Monserrat, sans-serif",
      fontSmoothing: "antialiased",
      lineHeight: "26px",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
var cardBrandToPfClass = {
  visa: "pf-visa",
  mastercard: "pf-mastercard",
  amex: "pf-american-express",
  discover: "pf-discover",
  diners: "pf-diners",
  jcb: "pf-jcb",
  unknown: "pf-credit-card",
};

const setBrandIcon = (brand) => {
  var brandIconElement = document.getElementById("brand-icon");
  var pfClass = "pf-credit-card";
  if (brand in cardBrandToPfClass) {
    pfClass = cardBrandToPfClass[brand];
  }
  for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
    brandIconElement.classList.remove(brandIconElement.classList[i]);
  }
  brandIconElement.classList.add("pf");
  brandIconElement.classList.add(pfClass);
};
const cardElementChange = (e) => {
  if (e.brand) {
    setBrandIcon(e.brand);
  }
};

function CardSection() {
  return (
    <div className="row">
      {/* <div className="col-12">
        <label className="w-100">
          Numéro de carte
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </div> */}
      <div className="col-12">
        <label className="w-100">
          Numéro de carte
          <CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={cardElementChange} />
          <span
            className="brand"
            style={{
              position: "absolute",
              bottom: 26,
              right: 24,
              fontSize: 22,
              color: "#a0aed3",
            }}
          >
            <i className="pf pf-credit-card" id="brand-icon"></i>
          </span>
        </label>
      </div>
      <div className="col-12 col-md-6">
        <label className="w-100">
          Date d'expiration
          <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </div>
      <div className="col-12 col-md-6">
        <label className="w-100">
          CVC
          <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </div>
    </div>
  );
}

export default CardSection;
