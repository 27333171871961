import React from "react";
import styled from "styled-components";
import useCompanyItem from "../../useCompanyItem";

export const CloseCompanyBottomPanelButton = () => {
  const { actions, bottomPanelContent } = useCompanyItem();
  return bottomPanelContent ? (
    <StyledArrowButton
      className="fabArrow d-center shadow-sm bg-white  border cursor-pointer"
      onClick={actions.closeBottomPanel}
    >
      <i className="fa fa-chevron-down" />
    </StyledArrowButton>
  ) : null;
};

const CompanyBottomPanel = ({ children }) => {
  const { actions } = useCompanyItem();

  return (
    <div className="row ">
      <div className="col-12 px-0">
        <StyledCompanyBottomPanel className="companyBottomPanel">
          {children}
        </StyledCompanyBottomPanel>
      </div>
    </div>
  );
};

const StyledCompanyBottomPanel = styled.div`
  padding: 30px 15px;
  position: relative;
  box-shadow: inset 0px 6px 6px -10px #000000;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
  background: #ebecf7;
`;

const StyledArrowButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translateX(-50%) translateY(-20px);
  z-index: 9;
  & i {
    transition: 300ms;
  }

  &:hover {
    i {
      transform: rotate(-180deg);
    }
  }
`;

export default CompanyBottomPanel;
