import React from "react";
import Control from "../../common/Control";
import Loader from "../../common/Loader";
import useStore from "../../../context/useStore";
import DynamicList from "../../common/DynamicList";
import SimpleDynamicList from "../../common/SimpleDynamicList";

const DocumentFilters = ({
  search,
  searchDocuments,
  searchText,
  updateDocumentSearch,
  isLoading,
  customFilters,
  fileTypes,
  displayInline,
  setDisplayInline,
}) => {
  const [state, dispatch] = useStore();

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Control
          label="Modèle"
          margin="my-0"
          labelClassname="mt-0"
          type="select"
          datas={customFilters}
          value={search.file.entityModel}
          name="entityModel"
          change={updateDocumentSearch}
        />
        <SimpleDynamicList
          listLabel="Type de document"
          uniqueKey="1"
          element={"fileTypes"}
          btnLabel="Ajouter un type de fichier"
          fields={[
            {
              label: "Type",
              name: "fileType",
              type: "select",
              datas: fileTypes.filter((f) => f.fileCategories.find((fc) => fc.entityModel == search.file.entityModel)),
            },
          ]}
          items={search.file.fileTypes}
          change={updateDocumentSearch}
        />
      </div>

      <div className="col-12  col-md-6">
        {customFilters.find((e) => e.id == search.file.entityModel) && (
          <DynamicList
            labelClassname={"my-0"}
            listLabel="Entités"
            uniqueKey="1"
            element="filters"
            change={updateDocumentSearch}
            fields={[
              {
                label: "Entité",
                type: "select",
                name: "id",
                datas: customFilters.find((e) => e.id == search.file.entityModel).filters,
                dataIndex: "id",
                dataLabel: "name",
              },
              {
                label: "Valeur",
                type: "text",
                name: "value",
                isDynamic: true,
                datas: [],
              },
            ]}
            dynamicTypes={customFilters.find((e) => e.id == search.file.entityModel).filters}
            items={search.file.filters}
            btnLabel="Ajouter une entité"
          />
        )}
        <Control
          label="Inclusion/Exclusion"
          name="isExist"
          type="btnList"
          margin={"mb-2"}
          labelClassname={"mt-0 mb-2"}
          btnInline
          datas={[
            {
              id: "-",
              name: "Tous",
            },
            {
              id: true,
              name: "Possède les documents",
              value: "",
            },
            {
              id: false,
              name: "Ne possède pas les documents",
            },
          ]}
          change={updateDocumentSearch}
          value={search.file.isExist}
        />

        <Control
          btnInline={true}
          label="Disposition des éléments"
          type="btnList"
          name="displayInline"
          value={displayInline}
          datas={[
            { id: true, name: "Ligne" },
            { id: false, name: "Colonne" },
          ]}
          change={(e) => setDisplayInline(e.target.value)}
        />
      </div>

      <div className="col-12  justify-content-center d-flex font-weight-bold">
        <button
          style={{ fontWeight: "600" }}
          className={
            "btn  d-flex align-items-center w-100 justify-content-center mb-2  " +
            (searchText == "Rechercher" ? "btn-primary shadow" : "btn-success")
          }
          onClick={searchDocuments}
        >
          {isLoading ? <Loader color="white" /> : searchText}
        </button>
      </div>
    </div>
  );
};

export default DocumentFilters;
