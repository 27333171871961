import { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";

const useForms = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState({});

  useEffect(() => {
    getForms();
  }, []);

  const getForms = () => {
    setIsLoading(true);
    dataService.get(
      `quotations/mines`,
      setClients,
      (err) => {},
      () => setIsLoading(false)
    );
  };

  const hasMRP = clients.MRP?.length > 0;
  const hasAC = clients.AC?.length > 0;
  const hasCYBER = clients.CYBER?.length > 0;

  return {
    hasMRP,
    hasAC,
    hasCYBER,
    clients,
    isLoading,
  };
};

export default useForms;
