import React, { useState } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";

import useFormState from "../useFormState";
import stockageTips from "./stockageTips";

const StockageInputs = () => {
  const { constants, actions, errors, formData, inputsConfig } = useFormState();
  const inputState = formData.stockageInputs;

  const updateMrpData = (e) => {
    const { name, value } = e.target;
    actions.updateStockageInputs({ [name]: value });
  };

  const onBlur = () => {};

  return (
    <BlockWrapper>
      <Control
        label={inputsConfig.classicContentValue.label}
        name="classicContentValue"
        type="number"
        value={inputState.classicContentValue}
        change={updateMrpData}
        labelClassname="w-100"
        error={errors}
        suffix="€"
        tips={stockageTips.classicContentValue}
        info="Meubles, matériels et outils professionnels, marchandise destinée à la vente."
        onBlur={onBlur}
      />
      <Control
        label={inputsConfig.itContentValue.label}
        name="itContentValue"
        type="number"
        value={inputState.itContentValue}
        change={updateMrpData}
        labelClassname="w-100"
        error={errors}
        suffix="€"
        tips={stockageTips.itContentValue}
        info="Ordinateurs portables et de bureau, tablettes, photocopieurs."
        onBlur={onBlur}
      />
      <Control
        label={inputsConfig.electricalContentValue.label}
        name="electricalContentValue"
        type="number"
        value={inputState.electricalContentValue}
        change={updateMrpData}
        labelClassname="w-100"
        error={errors}
        suffix="€"
        tips={stockageTips.electricalContentValue}
        info="Équipements électriques, alarme, machines, machine à café."
        onBlur={onBlur}
      />
      {tools.isOwner(formData.constructionInputs.qualityOccupant) && (
        <Control
          name="amenitiesValue"
          label={inputsConfig.amenitiesValue.label}
          type="number"
          value={inputState.amenitiesValue}
          change={updateMrpData}
          labelClassname="w-100"
          error={errors}
          suffix="€"
          tips={stockageTips.amenitiesValue}
          info="Chauffe-eau solaire, climatisation, panneaux photovoltaïques."
          onBlur={onBlur}
        />
      )}
      {!tools.isOwner(formData.constructionInputs.qualityOccupant) && (
        <Control
          name="amenitiesAndEmbelishmentsValue"
          label={inputsConfig.amenitiesAndEmbelishmentsValue?.label}
          type="number"
          value={inputState.amenitiesAndEmbelishmentsValue}
          change={updateMrpData}
          labelClassname="w-100"
          error={errors}
          suffix="€"
          tips={stockageTips.amenitiesAndEmbelishmentsValue}
          info="Climatisation, parquet, stores, panneaux photovoltaïques."
          onBlur={onBlur}
        />
      )}
      <Control
        label={inputsConfig.isMerchandiseStorage.label}
        name="isMerchandiseStorage"
        type="btnList"
        datas={inputsConfig.isMerchandiseStorage.datas}
        dataIndex="id"
        value={inputState.isMerchandiseStorage}
        change={updateMrpData}
        btnInline
        fullWidth
        error={errors}
        labelClassname="w-100"
        info="Tout bien destiné à être vendu avec ou sans transformation"
        onBlur={onBlur}
      />
      {inputState.isMerchandiseStorage == 1 && (
        <>
          <Control
            name="isMerchandiseStored10cmFromFloor"
            label={inputsConfig.isMerchandiseStored10cmFromFloor.label}
            type="btnList"
            datas={inputsConfig.isMerchandiseStored10cmFromFloor.datas}
            dataIndex="id"
            value={inputState.isMerchandiseStored10cmFromFloor}
            change={updateMrpData}
            btnInline
            fullWidth
            error={errors}
            labelClassname="w-100"
            onBlur={onBlur}
          />
          <Control
            label={inputsConfig.isDangerousProductsStored.label}
            name="isDangerousProductsStored"
            type="btnList"
            datas={inputsConfig.isDangerousProductsStored.datas}
            dataIndex="id"
            value={inputState.isDangerousProductsStored}
            change={updateMrpData}
            btnInline
            fullWidth
            error={errors}
            labelClassname="w-100"
            tips={stockageTips.isDangerousProductsStored}
            info="Aérosols, carburant, produits inflammables ou corrosifs."
            onBlur={onBlur}
          />
          {inputState.isDangerousProductsStored == 1 && (
            <Control
              label={inputsConfig.dangerousProductsNature.label}
              name="dangerousProductsNature"
              type="textarea"
              value={inputState.dangerousProductsNature}
              error={errors}
              change={updateMrpData}
              labelClassname="w-100"
              onBlur={onBlur}
              placeholder="Nature et quantité des produits dangereux"
              tips={stockageTips.dangerousProductsNature}
            />
          )}
          <Control
            label={inputsConfig.isConditionningValueMoreThan7500.label}
            name="isConditionningValueMoreThan7500"
            type="btnList"
            datas={inputsConfig.isConditionningValueMoreThan7500.datas}
            dataIndex="id"
            value={inputState.isConditionningValueMoreThan7500}
            change={updateMrpData}
            btnInline
            fullWidth
            error={errors}
            labelClassname="w-100"
            info="Carton, polystyrène, plastique."
            onBlur={onBlur}
          />
        </>
      )}

      <Control
        label={inputsConfig.isFreezeRoom.label}
        name="isFreezeRoom"
        type="btnList"
        datas={inputsConfig.isFreezeRoom.datas}
        dataIndex="id"
        value={inputState.isFreezeRoom}
        change={updateMrpData}
        btnInline
        fullWidth
        error={errors}
        labelClassname="w-100"
        onBlur={onBlur}
      />
      {inputState.isFreezeRoom == 1 && (
        <Control
          name="isFreezeRoomMoreThan120"
          label={inputsConfig.isFreezeRoomMoreThan120.label}
          type="btnList"
          datas={inputsConfig.isFreezeRoom.datas}
          dataIndex="id"
          value={inputState.isFreezeRoomMoreThan120}
          change={updateMrpData}
          btnInline
          fullWidth
          error={errors}
          labelClassname="w-100"
          onBlur={onBlur}
        />
      )}
    </BlockWrapper>
  );
};

export default StockageInputs;
