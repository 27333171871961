import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import Page from "../../common/layout/Page";
import dataService from "../../../helpers/dataService";
import AccountItem from "./AccountItem";

const AccountList = () => {
  const [accounts, setAccounts] = useState([]);
  const [pendingAccounts, setPendingAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isCanceling, setIsCanceling] = useState(false);

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    setIsLoading(true);
    dataService.post(
      `customers/mines`,
      {},
      (datas) => {
        setAccounts(datas.filter((d) => !d.isPending));
        setPendingAccounts(datas.filter((d) => d.isPending));
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const cancelInvitation = (roleId) => {
    setIsCanceling(roleId);
    dataService.get(
      `roles/${roleId}/cancel`,
      (datas) => {
        getAccounts();
      },
      setErrors,
      () => setIsCanceling(false)
    );
  };

  return (
    <Page container="container-fluid pb-5" errors={errors} style={{ maxWidth: 960 }}>
      <div style={{ lineHeight: "22px" }} className="p-3">
        <p className="mb-0">
          <strong>Invitez des utilisateurs</strong> à consulter ou gérer les sociétés dont vous êtes propriétaire.
        </p>
        <p style={{ color: "#939393" }}>
          Les droits d'accès peuvent être personnalisés finement dans les privilèges de chaque utilisateur.
        </p>
        <div className="d-center mt-4">
          <Link className="btn btn-primary" to="/accounts/create">
            Ajouter un nouvel utilisateur
          </Link>
        </div>
      </div>

      {pendingAccounts.length ? (
        <div>
          <div className="font-weight-bold mt-5 mb-4 text-success" style={{ fontSize: 17, lineHeight: "20px" }}>
            Invitation•s en attente
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="row mx-0">
              {pendingAccounts.map((user, uk) => (
                <div className={`col-12 col-lg-6 px-0 ${uk % 2 == 0 ? "pl-lg-0  pr-lg-2" : "pr-lg-0 pl-lg-2"}`}>
                  <AccountItem
                    user={user}
                    key={`user${user.id}`}
                    cancelInvitation={cancelInvitation}
                    isCanceling={isCanceling}
                    k={uk}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}
      {accounts.length ? (
        <div>
          <div className="font-weight-bold mt-5 mb-4 text-success" style={{ fontSize: 17, lineHeight: "20px" }}>
            Utilisateur•s actif•s
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="row mx-0">
              {accounts.map((user, uk) => (
                <div className={`col-12 col-lg-6 px-0 ${uk % 2 == 0 ? "pl-lg-0  pr-lg-2" : "pr-lg-0 pl-lg-2"}`}>
                  <AccountItem
                    user={user}
                    key={`user${user.id}`}
                    cancelInvitation={cancelInvitation}
                    isCanceling={isCanceling}
                    k={uk}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : null}
    </Page>
  );
};

export default AccountList;
