import React, { useState, useEffect, useRef } from "react";
import tools from "../../helpers/tools";
import DigitControl from "./DigitControl";

const Control = ({
  inputStyle = {},
  label = false,
  type,
  value,
  change = () => {},
  name,
  id = null,
  suffix = false,
  k = -1,
  error = false,
  disabled = false,
  checked = false,
  datas = false,
  dataIndex = "id",
  dataLabel = "name",
  dataLabel2 = false,
  dataLabel3 = false,
  dataIcon = false,
  className = "",
  itemClassName = "",
  required = false,
  inputClassName = "",
  dataLabelIndex = false,
  inputParams = { min: 1, max: 100, step: 1 },
  selectFirstLabel = "...",
  containerStyle = {},
  isValid = false,
  autocomplete = "on",
  margin = " my-2 ",
  placeholder = "",
  digitParams = [],
  btnInline = false,
  labelClassname = "",
  autoCompleteDisplayIndex = false,
  noInput = false,
  autoFocus,
  appendStyle = {},
  itemStyle = {},
  iconStyle = {},
  dataIconSet = "fa",
  separator = " ",
  pattern = "",
}) => {
  const [acInput, setAcInput] = useState(value);
  const [acResults, setAcResults] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const cnames = className.split(" ");
  if (cnames.includes("readonly") && (value == "" || !value)) {
    containerStyle = { ...containerStyle, display: "none" };
  }

  useEffect(() => {
    if (type == "autocomplete") {
      var acs = [];
      datas.forEach((d) => {
        if (
          (d[dataLabel] && d[dataLabel].toLowerCase().includes(value.toLowerCase())) ||
          (d[dataLabel2] && d[dataLabel2].toLowerCase().includes(value.toLowerCase())) ||
          (d[dataLabel3] && d[dataLabel3].toLowerCase().includes(value.toLowerCase()))
        ) {
          acs.push(d);
        }
      });

      setAcResults(acs);
    }
  }, [value]);

  useEffect(() => {
    if (type == "autocomplete") {
      var selector = k != -1 ? name + "-ac" + k : id ? id : name;
      document.querySelector("#" + selector).addEventListener("focus", function () {
        document.querySelector("#acres" + selector).style.display = "block";
      });
      document.querySelector("#" + selector).addEventListener("focusout", function () {
        setTimeout(() => {
          if (document.querySelector("#acres" + selector)) {
            document.querySelector("#acres" + selector).style.display = "none";
          }
        }, 200);
      });
    }
  }, []);

  const formatTelOutput = (e) => {
    return e.target.value.split(separator).join("").substring(0, 10);
  };

  if (type == "checkbox") {
    return (
      <div
        style={containerStyle}
        className={"custom-control custom-switch " + className + " " + margin}
      >
        <input
          type="checkbox"
          className={"custom-control-input " + inputClassName}
          disabled={disabled ? "disabled" : false}
          name={name}
          id={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
          onChange={k != -1 ? (e) => change(e, k) : change}
          checked={checked ? "checked" : false}
        />
        <label
          className={"custom-control-label " + labelClassname}
          htmlFor={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
        >
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
      </div>
    );
  } else if (type == "select") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label className={labelClassname} htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <select
            style={inputStyle ? inputStyle : {}}
            className={"form-control " + inputClassName}
            disabled={disabled ? "disabled" : cnames.includes("readonly") ? "disabled" : false}
            value={value ? value : ""}
            name={name}
            id={k != -1 ? name + "-" + k : id ? id : name}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          >
            <option value="">{selectFirstLabel}</option>
            {datas.map((data, k) => (
              <option key={name + "-" + (data.id ? data.id : k)} value={data[dataIndex]}>
                {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}{" "}
                {dataLabel2 ? " - " + data[dataLabel2] : ""}
              </option>
            ))}
          </select>
          {suffix && (
            <div className="input-group-append" style={appendStyle}>
              {suffix}
            </div>
          )}
        </div>
        <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "autocomplete") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label
            className={labelClassname ? labelClassname : ""}
            htmlFor={k != -1 ? name + "-" + k : name}
          >
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            className={"form-control " + inputClassName}
            disabled={disabled ? "disabled" : false}
            value={
              autoCompleteDisplayIndex && datas.find((d) => d[dataIndex] == value)
                ? datas.find((d) => d[dataIndex] == value)[autoCompleteDisplayIndex]
                : value
                ? value
                : ""
            }
            name={name}
            id={k != -1 ? name + "-ac" + k : id ? id : name}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
            autoComplete={autocomplete}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 10, bottom: 14 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          <div
            className="ac-results shadow position-absolute"
            id={"acres" + (k != -1 ? name + "-ac" + k : id ? id : name)}
            style={{
              top: 41,
              padding: 0,
              width: "100%",
              zIndex: "99999",
              display: "none",
              background: "white",
              borderRadius: window.borderRadius,
            }}
          >
            <p className="my-1 text-primary p-2">Sélectionnez une option ci-dessous</p>
            {acResults.map((a, ak) => {
              //if (ak >= 5) return false;
              return (
                <div
                  className="animated fadeInUp faster"
                  style={{
                    padding: "8px 7px",
                    width: "100%",
                    cursor: "pointer",
                    borderTop: "1px solid #dddddd",
                    animationDelay: ak * 50 + "ms",
                  }}
                  onClick={
                    k != -1
                      ? () => change({ target: { name: name, value: a[dataIndex] } }, k)
                      : () =>
                          change({
                            target: { name: name, value: a[dataIndex] },
                          })
                  }
                  key={"acres" + ak + "" + k}
                >
                  {a[dataLabel]} {dataLabel2 ? " - " + a[dataLabel2] : ""}{" "}
                  {dataLabel3 ? " - " + a[dataLabel3] : ""}
                </div>
              );
            })}
          </div>
          {suffix && (
            <div className="input-group-append" style={appendStyle}>
              {suffix}
            </div>
          )}
        </div>
        <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "textarea") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <textarea
            style={{ ...inputStyle, minHeight: 200 }}
            className={"form-control " + inputClassName}
            type={type}
            disabled={disabled ? "disabled" : cnames.includes("readonly") ? "disabled" : false}
            id={k != -1 ? name + "-" + k : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {suffix && (
            <div className="input-group-append" style={appendStyle}>
              <span className="input-group-text" style={disabled ? { color: "#9a9a9a" } : {}}>
                {suffix}
              </span>
            </div>
          )}
        </div>
        {error && (
          <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
            {error[name]}
          </small>
        )}
      </div>
    );
  } else if (type == "btnList") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        <label htmlFor={k != -1 ? name + "-" + k : name} className={"d-block " + labelClassname}>
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
        <div className={" " + (btnInline ? "d-flex flex-row flex-wrap " : "btn-group")}>
          {datas.map((data, dk) => (
            <button
              className={
                itemClassName +
                " " +
                "btn btn-default shadow-sm border " +
                (btnInline ? "btn-sm shadow-sm mx-1 " : " ") +
                (value == data[dataIndex] ? "active " : " btn-default ") +
                (btnInline ? "text-center btn-sm" : "")
              }
              type="button"
              key={name + "-" + (data.id ? data.id : data._id ? data._id + dk : "abcd" + dk)}
              onClick={
                k != -1
                  ? (e) => change({ target: { name: name, value: data[dataIndex] } }, k)
                  : (e) => change({ target: { name: name, value: data[dataIndex] } })
              }
              style={itemStyle}
            >
              {dataIcon && (
                <i style={iconStyle} className={dataIconSet + " fa-" + data[dataIcon]} />
              )}
              <p className="font-weight-bold" style={{ width: btnInline ? "" : "", margin: 0 }}>
                {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}
              </p>
              {dataLabel2 && <p className="my-0 ml-2">{data[dataLabel2]}</p>}
              {value == data[dataIndex] && !btnInline && (
                <i
                  className="fa fa-check ml-2 animated fadeInRight faster position-absolute"
                  style={{ right: 16 }}
                />
              )}
            </button>
          ))}
        </div>

        <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "checkboxList") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        <label htmlFor={k != -1 ? name + "-" + k : name} className="d-block">
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
        <div className="btn-group">
          {datas.map((data, k) => (
            <div className={"my-3 custom-control custom-switch"}>
              <input
                type="checkbox"
                className={"custom-control-input " + inputClassName}
                disabled={disabled ? "disabled" : false}
                name={name}
                id={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
                onChange={
                  k != -1
                    ? (e) =>
                        change(
                          {
                            target: {
                              name: name,
                              value: data[dataIndex],
                              formArray: e,
                              isExclusive: data.isExclusive,
                            },
                          },
                          k
                        )
                    : (e) =>
                        change({
                          target: {
                            name: name,
                            value: data[dataIndex],
                            formArray: e,
                            isExclusive: data.isExclusive,
                          },
                        })
                }
                checked={value.includes(data[dataIndex]) ? "checked" : false}
              />
              <label
                className="custom-control-label"
                htmlFor={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
              >
                {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}
                {required && <span className="text-danger ml-1">*</span>}
              </label>
            </div>
          ))}
        </div>

        <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "checkBtnList") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        <label htmlFor={k != -1 ? name + "-" + k : name} className="d-block">
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
        <div className={"btn-group " + (btnInline ? "d-flex flex-row flex-wrap " : "")}>
          {datas.map((data, dk) => (
            <button
              className={
                itemClassName +
                " " +
                "btn btn-default " +
                (value.includes(data[dataIndex]) ? "active " : " ") +
                (btnInline ? "text-center btn-sm" : "")
              }
              key={name + "-" + (data.id ? data.id : data._id ? data._id + dk : "abcd" + dk)}
              onClick={
                k != -1
                  ? (e) =>
                      change(
                        {
                          target: {
                            name: name,
                            value: data[dataIndex],
                            formArray: e,
                          },
                        },
                        k
                      )
                  : (e) =>
                      change({
                        target: {
                          name: name,
                          value: data[dataIndex],
                          formArray: e,
                        },
                      })
              }
            >
              <p style={{ width: "93%", margin: 0 }}>
                {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}
              </p>
              {value.includes(data[dataIndex]) && !btnInline && (
                <i className="fa fa-check ml-2 animated fadeInRight faster " />
              )}
            </button>
          ))}
        </div>

        <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "range") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          {/* <span>{inputParams.min}</span> */}
          <input
            min={inputParams.min}
            max={inputParams.max}
            step={inputParams.step}
            className={"form-control  custom-range " + inputClassName}
            type={type}
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {/* <span>{inputParams.max}</span> */}
          {suffix && (
            <div className="input-group-append" style={appendStyle}>
              <span className="input-group-text" style={disabled ? { color: "#9a9a9a" } : {}}>
                {suffix}
              </span>
            </div>
          )}
        </div>
        {error && (
          <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
            {error[name]}
          </small>
        )}
        {!noInput && (
          <input
            type="text"
            className="form-control range-inp-value"
            onChange={k != -1 ? (e) => change(e, k) : change}
            name={name}
            value={value}
            disabled={disabled ? "disabled" : false}
            placeholder={placeholder}
          />
        )}
      </div>
    );
  } else if (type == "digits") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <DigitControl
            params={digitParams}
            name={name}
            complete={k != -1 ? (e) => change(e, k) : change}
            value={value}
            pattern={pattern}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: -20, bottom: 50 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
        </div>

        {error && (
          <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
            {error[name]}
          </small>
        )}
      </div>
    );
  } else if (type == "numberInt") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              className="btn btn-light shadow-none"
              onClick={
                k != -1
                  ? () => change({ target: { name: name, value: parseInt(value) - 1 } }, k)
                  : () =>
                      change({
                        target: { name: name, value: parseInt(value) - 1 },
                      })
              }
            >
              -
            </button>
          </div>
          <input
            autoComplete={autocomplete}
            style={inputStyle}
            className={"form-control text-center " + inputClassName}
            type={type}
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 0, bottom: 17 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          <div className="input-group-append" style={appendStyle}>
            <button
              className="btn btn-light shadow-none"
              onClick={
                k != -1
                  ? () => change({ target: { name: name, value: parseInt(value) + 1 } }, k)
                  : () =>
                      change({
                        target: { name: name, value: parseInt(value) + 1 },
                      })
              }
            >
              +
            </button>
          </div>
        </div>
        {error && (
          <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
            {error[name]}
          </small>
        )}
      </div>
    );
  } else if (type == "tel") {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label className={labelClassname} htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            autoFocus={autoFocus}
            autoComplete={autocomplete}
            style={inputStyle}
            className={"form-control " + inputClassName}
            pattern="[0-9]*"
            type={"tel"}
            disabled={disabled ? "disabled" : cnames.includes("readonly") ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={tools.formatTelValue(value)}
            onChange={
              k != -1
                ? (e) =>
                    change(
                      {
                        target: {
                          type: e.target.type,
                          name: e.target.name,
                          value: formatTelOutput(e),
                        },
                      },
                      k
                    )
                : (e) =>
                    change({
                      target: {
                        type: e.target.type,
                        name: e.target.name,
                        value: formatTelOutput(e),
                      },
                    })
            }
            placeholder={placeholder}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 4, bottom: 14 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          {suffix && (
            <div className="input-group-append" style={appendStyle}>
              {suffix}
            </div>
          )}
        </div>

        {error[name] && (
          <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
            {error[name]}
          </small>
        )}
      </div>
    );
  } else {
    return (
      <div style={containerStyle} className={"form-group " + className + " " + margin}>
        {label && (
          <label className={labelClassname} htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            autoFocus={autoFocus}
            autoComplete={autocomplete}
            style={inputStyle}
            className={`form-control ${
              type == "password" ? "password-input" : ""
            } ${inputClassName}`}
            type={passwordVisible ? "text" : type}
            disabled={disabled ? "disabled" : cnames.includes("readonly") ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {isValid && type != "password" && (
            <i
              style={{ position: "absolute", right: 4, bottom: 14 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          {suffix && (
            <div className="input-group-append" style={appendStyle}>
              {suffix}
            </div>
          )}
          {type == "password" && (
            <div
              className="input-group-append px-2"
              onClick={() => setPasswordVisible(!passwordVisible)}
              style={{ cursor: "pointer" }}
            >
              <i className={" fa " + (passwordVisible ? "fa-eye-slash" : "fa-eye")} />
            </div>
          )}
        </div>

        {error[name] && (
          <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>
            {error[name]}
          </small>
        )}
      </div>
    );
  }
};

export default Control;

/*
EXEMPLE APPEL AUTOCOMPLETE
  <Control
    className="animated fadeInUp faster"
    containerStyle={{ animationDelay: "100ms" }}
    label="Forme juridique"
    name="legalForm"
    value={company.legalForm}
    type="autocomplete"
    datas={legalForms}
    dataIndex={"code"}
    dataLabel={"code"}
    dataLabel2={"label"}
    error={errors}
    change={updateData}
    placeholder={"SARL, EURL, SAS..."}
    isValid={legalForms.find(l => l.code == company.legalForm)}
  />

EXEMPLE APPEL DIGITS
  <Control
    type="digits"
    className="animated fadeInUp faster"
    containerStyle={{ animationDelay: "50ms" }}
    label="SIRET du siège social"
    digitParams={[
      { size: 9, value: "", placeholder: "SIREN" },
      { size: 5, value: "", placeholder: "clé" }
    ]}
    name={"siret"}
    change={updateData}
    value={company.siret}
    isValid={company.siret.length == 14}
    error={errors}
  />
*/
