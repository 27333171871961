import React from "react";

const UserRecap = ({ user, setUserFormEdit }) => {
  return (
    <div
      className="my-4 p-3 rounded"
      style={{ background: "#F1F2FE", fontSize: 14, lineHeight: "19px" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        Vous {user.id ? "modifiez" : "invitez"} :
        {!user.id ? (
          <button
            className="btn btn-link pr-0 pt-0"
            onClick={() => setUserFormEdit(true)}
          >
            <i className="fa fa-pen mr-2" />
            Modifier
          </button>
        ) : null}
      </div>
      <strong className="d-block" style={{ textTransform: "capitalize" }}>
        {user.firstname} {user.lastname}
      </strong>
      <div>{user.email}</div>
      <div>{user.phone}</div>
    </div>
  );
};

export default UserRecap;
