import React from "react";

const AboutPerceval = () => {
  return (
    <div className="row mt-3">
      <div className="col-12 mb-4 ">
        <h4
          className="text-center"
          style={{
            textTransform: "none",
          }}
        >
          Aller plus loin avec Perceval
        </h4>
      </div>
      <div className="col-12 col-lg-4 col-md-12">
        <CardLink
          title="Sur LinkedIn"
          description="Rejoignez-nous sur notre réseau social préféré pour suivre notre actualité, découvrir les nouveautés de Perceval et être informés des futurs évènements."
          icon={<img style={{ height: 95 }} src="/images/icons/linkedin.png" />}
          link="https://www.linkedin.com/company/perceval-sas/"
          buttonText="Suivez-nous"
        />
      </div>
      <div className="col-12 col-lg-4 col-md-12">
        <CardLink
          title="Blog"
          description="Enrichissez continuellement vos connaissances avec des articles clairs qui abordent des sujets liés au monde de l'assurance et de l'entrepreneuriat."
          icon={<img style={{ height: 95 }} src="/images/icons/blog.png" />}
          link="https://blog.goperceval.fr/"
          buttonText="Lire les articles"
        />
      </div>
      <div className="col-12 col-lg-4 col-md-12">
        <CardLink
          title="Le podcast de Perceval"
          description="Découvrez « Entreprendre avec assurance », le podcast qui vous emmène dans les coulisses d'une profession, d'un métier ou d'un sujet d'actualité."
          icon={<img style={{ height: 95 }} src="/images/icons/podcast.png" />}
          link="https://smartlink.ausha.co/entreprendre-avec-assurance"
          buttonText="Écouter le podcast"
        />
      </div>
    </div>
  );
};

const CardLink = ({ title, description, icon, link, buttonText }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="mb-4 custom-card-link d-block"
    >
      <div className="custom-card-link-content p-3 border shadow-sm rounded ">
        <h5 className="text-center text-primary font-weight-bold">{title}</h5>
        <p className="text-center  mb-3" style={{ color: "black" }}>
          {description}
        </p>
        <div className="d-center flex-column mt-2">
          {icon}
          <span
            className="p-2 px-3 mt-1 rounded text-primary  font-weight-bold d-center"
            style={{
              flexWrap: "nowrap",
            }}
          >
            {buttonText}
            <i className="fa fa-arrow-right ml-2" />
          </span>
        </div>
      </div>
    </a>
  );
};

export default AboutPerceval;
