import { useState } from "react";
import dataService from "../../../helpers/dataService";
import { DateTime } from "luxon";

const useMesFactures = () => {
  const [isLoadingFactures, setIsLoadingFactures] = useState(false);
  const [factures, setFactures] = useState(null);

  const getFactures = () => {
    setIsLoadingFactures(true);
    dataService.get(
      `billings/mines`,
      (data) =>
        setFactures(
          data.filter((d) => {
            //remove factures that has media.length===0 and payeLe ISO date is past 1 week
            //calculate date using DateTime from luxon
            return (
              d.medias.length > 0 &&
              DateTime.fromISO(d.payeLe).diffNow("weeks").weeks < 1
            );
          })
        ),
      (err) => {},
      () => setIsLoadingFactures(false)
    );
  };

  return {
    isLoadingFactures,
    factures,
    getFactures,
  };
};

export default useMesFactures;
