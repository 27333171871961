import Axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Avatar from "../../common/Avatar";
import Control from "../../common/Control";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import GreyCard from "../Dashboard/GreyCard";
import MyAvatar from "./MyAvatar";
import MyEmail from "./MyEmail";
import MyPassword from "./MyPassword";
import MyRoles from "./MyRoles";
import tools from "../../../helpers/tools";
import MyInformations from "./MyInformations";

const UserDetail = (props) => {
  const [state, dispatch] = useStore();

  var params = props.location.search;

  useEffect(() => {
    if (params == "?avatar") {
      setShowPassword(false);
      setModifyAvatar(true);
    }
    if (params == "?password") {
      setModifyAvatar(false);

      setShowPassword(true);
    }
  }, [props.location.search]);

  const [user, setUser] = useState({
    comment: "",
    company: "",
    firstname: "",
    lastname: "",
    createdAt: "",
    function: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingPassword, setIsSavingPassword] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [modifyAvatar, setModifyAvatar] = useState(
    params == "?avatar" ? true : false
  );
  const [showPassword, setShowPassword] = useState(
    params == "?password" ? true : false
  );
  const [modifyInfo, setModifyInfo] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [modifyEmail, setModifyEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [emailError, setEmailError] = useState({});

  useEffect(() => {
    setIsLoading(true);
    Axios.get(API_URL + "customers/me")
      .then((res) => {
        setUser(res.data);
        setEmail(res.data.email);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error(
            "Une erreur est suvenue lors de la récupération des informations de votre compte"
          );
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  function updateData(e) {
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setIsSaved(false);
    setUser({ ...user, [name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});

    Axios.patch(API_URL + "customers/me", user)
      .then((res) => {
        setUser(res.data);
        setIsSaved(true);
        toast.success("Vos informations ont bien été modifiées");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(
          "Une erreur est survenue lors de la modification de vos informations"
        );
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSaving(false);
      });
  }
  var pwdIdentical = password === passwordConfirm && (password + "").length;

  function savePassword() {
    if (!pwdIdentical) {
      toast.error("Les mots de passe ne sont pas identiques");
    } else {
      setIsSavingPassword(true);
      Axios.patch(API_URL + "customers/me/password", { password: password })
        .then((res) => {
          toast.success("Votre mot de passe a bien été modifié.");
        })
        .catch((err) => {
          toast.error("Une erreur est survenue");
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSavingPassword(false);
        });
    }
  }

  function saveEmail() {
    if (email == user.email) {
      setEmailError({ email: "Cet email est identique au précédent" });
      return false;
    }
    if (
      !window.confirm("Êtes vous certain(e) de vouloir modifier votre email ?")
    )
      return false;
    setIsLoadingEmail(true);
    dataService.patch(
      `customers/me/email`,
      { email },
      (datas) => {
        setModifyEmail(false);
        setUser({ ...user, email });
        toast.success(
          "Votre email a bien été modifié, utilisez le lors de vos prochaines connexions."
        );
      },
      setEmailError,
      () => setIsLoadingEmail(false)
    );
  }

  if (modifyAvatar) {
    return (
      <Page margin="mt-5" errors={errors}>
        <MyAvatar setModifyAvatar={setModifyAvatar} />
      </Page>
    );
  }
  if (showPassword) {
    return (
      <Page margin="mt-5" errors={errors}>
        <MyPassword
          setShowPassword={setShowPassword}
          password={password}
          setPassword={setPassword}
          setPasswordValid={setPasswordValid}
          setPasswordConfirm={setPasswordConfirm}
          passwordConfirm={passwordConfirm}
          errors={errors}
          pwdIdentical={pwdIdentical}
          passwordValid={passwordValid}
          savePassword={savePassword}
          isSavingPassword={isSavingPassword}
        />
      </Page>
    );
  }
  if (modifyEmail) {
    return (
      <Page margin="mt-5" errors={errors}>
        <MyEmail
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          saveEmail={saveEmail}
          setModifyEmail={setModifyEmail}
          isLoadingEmail={isLoadingEmail}
        />
      </Page>
    );
  }

  if (modifyInfo) {
    return (
      <Page margin="mt-5" errors={errors}>
        <MyInformations
          user={user}
          updateData={updateData}
          errors={errors}
          save={save}
          isSaving={isSaving}
          isSaved={isSaved}
          state={state}
          setModifyInfo={setModifyInfo}
        />
      </Page>
    );
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader withContainer={true} />
  ) : (
    <Page margin="mt-5" errors={errors}>
      <p className="custom-p mb-5">
        <strong>Vos informations de profil</strong> peuvent être mises à jour
        ici.
      </p>

      <GreyCard>
        <div className="row">
          <div className="col" style={{ maxWidth: 200 }}>
            <div
              className="d-flex flex-column mb-3 position-relative cursor-pointer"
              onClick={() => setModifyAvatar(true)}
            >
              <Avatar
                width={150}
                height={150}
                id={user.id}
                acronymSize={150}
                acronymFontsize={30}
              />
              <div>
                <button
                  className=" d-center  text-success  rounded-circle"
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 15,
                    background: "white",
                    height: 40,
                    width: 40,
                    border: "1px solid",
                  }}
                >
                  <i className="fa fa-camera" />
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex flex-column justify-content-start align-items-start">
              <div className="font-weight-bold mb-2">
                <div style={{ fontSize: 18, lineHeight: "28px" }}>
                  {tools.getFullName(user)}
                </div>
                <div>{user.phone}</div>
                <div>{user.email}</div>
              </div>
              <button
                className="btn btn-link py-1 px-0"
                onClick={() => setModifyEmail(true)}
              >
                Changer mon email
              </button>
              <button
                className="btn btn-link py-1 px-0"
                onClick={() => setShowPassword((p) => !p)}
              >
                Changer mon mot de passe
              </button>
              <button
                className="btn btn-link py-1 px-0"
                onClick={() => setModifyInfo(true)}
              >
                Changer mes informations
              </button>
              <MyRoles />
            </div>
          </div>
        </div>
      </GreyCard>
    </Page>
  );
};

export default UserDetail;
