import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import axios from "axios";
import { DateTime } from "luxon";

const tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  getDateFrom: (date) => {
    var dt = date;
    if (date.length >= 10 && !date.isValid) {
      if (DateTime.fromFormat(date, "dd/MM/yyyy").isValid) {
        dt = DateTime.fromFormat(date, "dd/MM/yyyy").toFormat("yyyy-MM-dd");
      } else {
        if (DateTime.fromISO(date).isValid) {
          dt = DateTime.fromISO(date).toFormat("yyyy-MM-dd");
        } else {
          dt = date;
        }
      }
    } else if (date.isValid) {
      dt = date.toFormat("yyyy-MM-dd");
    }
    return dt;
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },

  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },
  round: (value) => {
    if (!value) return 0;
    return Math.round(parseFloat(value) * 100) / 100;
  },
  debounce: (fn, ms) => {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this);
      }, ms);
    };
  },
  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      var formatErr = "";
      if (errors.name == "SecurityError") {
        formatErr = "Pour utiliser ce site, vous devez autoriser les cookies.";
      } else {
        formatErr = errors;
      }
      toast.error("Erreur interne : " + formatErr);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
  },

  isParentRoleActive(roleKey, roleConfig, dependsOn) {
    if (!dependsOn) {
      return true;
    }
    var isActive = true;
    dependsOn.forEach((d) => {
      if (!roleConfig[d][roleKey]) {
        isActive = false;
      }
    });
    return isActive;
  },
  truenumberWithSpaces: (x) => {
    if (!x) return "";
    x = x == "0" ? "" : x + "";
    x = x.replace(/\s/g, "");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  formatNumber2Decimals: (num) => {
    num = num + "";
    num = num.split(".");
    if (num[1] && num[1].length > 0) {
      num[1] = num[1].length == 1 ? num[1] + "0" : num[1];
    }
    return num.join(".");
  },
  openChat: () => {
    window.Tawk_API.showWidget();
    window.Tawk_API.maximize();
  },
  scrollToTargetAdjusted: (target, offset = 45) => {
    if (!target) return false;
    var elementPosition = target.getBoundingClientRect().top;
    var offsetPosition = elementPosition - offset;
    var duration = 1000;
    var startingY = window.pageYOffset;
    var diff = offsetPosition - startingY;
    var start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start;
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
    // document.querySelector("html").scrollTo({
    //   top: offsetPosition,
    //   behavior: "smooth",
    // });
  },
  formatTelValue: (value, separator = " ", separation = [2, 2, 2, 2, 2]) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    var valueConcat = value.split(separator).join("");

    var newValue = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < valueConcat.length) {
        newValue += valueConcat.substring(curr, ns);
        var newValuesp = newValue.split(separator);
        if (
          newValuesp[newValuesp.length - 1].length == separation[key] &&
          newValuesp.length < separation.length
        ) {
          newValue += separator;
        }
      }

      curr = ns;
    }
    if (newValue.substr(newValue.length - separator.length) == separator) {
      newValue = newValue.substring(0, newValue.length - separator.length);
    }

    return newValue;
  },
  isOwner: (qualityOccupant) => {
    return qualityOccupant == 1 || qualityOccupant == 2;
  },
  calculateContentValue: (sumArray) => {
    return sumArray.reduce((a, b) => a + b);
  },
  formatDate: (date) => {
    if (date) {
      return DateTime.fromISO(date).toFormat("dd/MM/yyyy");
    }
    return "";
  },
  formatDateHours: (date) => {
    return DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm");
  },
  formatFollowingPattern: (value = "", separation = [], separator = "") => {
    if (!value) return "";
    var nirconcat = value.split(separator).join("");

    var newnir = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < nirconcat.length) {
        newnir += nirconcat.substring(curr, ns);
        var newnirsp = newnir.split(separator);
        if (
          newnirsp[newnirsp.length - 1].length == separation[key] &&
          newnirsp.length < separation.length
        ) {
          newnir += separator;
        }
      }

      curr = ns;
    }
    if (newnir.substr(newnir.length - separator.length) == separator) {
      newnir = newnir.substring(0, newnir.length - separator.length);
    }
    return newnir;
  },
  getDocumentName: ({
    fileExt,
    documentName,
    productName,
    companyName,
    uploadedAt,
  }) => {
    var filename = `${documentName}${productName ? "-" + productName : ""}${
      companyName ? "-" + companyName : ""
    }-${DateTime.fromISO(uploadedAt).toFormat("yyyy")}.${fileExt}`;

    return filename;
  },
  formatPhone: (phone) => {
    if (!phone) return "";
    let normalVal = phone.split(".").join("").split(" ").join("");
    return tools.formatFollowingPattern(normalVal, [4, 2, 2, 2], " ");
  },
  getFullName: (user) => {
    return user && Object.values(user).length > 0
      ? (user.gender == 1 ? "Mr " : user.gender == 2 ? "Mme " : "") +
          (tools.capitalize(((user.firstname || "") + "").toLowerCase()) ||
            "") +
          " " +
          (tools.capitalize(((user.lastname || "") + "").toLowerCase()) || "")
      : "";
  },
  getGender: (gender) => {
    return gender ? (gender == 1 ? "Mr" : "Mme") : "";
  },
  capitalize: (s) => {
    if (typeof s !== "string") return "";
    let words = s.split(" ");
    let result = "";
    words.forEach((word) => {
      result += word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });
    return result;
  },
  getFullAddress: (location) => {
    return location
      ? `
    ${location.address || ""} ${location.address2 || ""} ${
          location.postalCode || ""
        } ${location.city || ""}
    `
      : "";
  },
  findIn: (inTheArray, theValue, theField) => {
    var returnValue = {};
    if (inTheArray?.length && theField) {
      returnValue = inTheArray.find((en) => en[theField] == theValue) || {};
    }
    return returnValue;
  },
  canUploadSpecialDocument: (doc) => {
    const canUploadDocCodes = ["DER"];
    return !canUploadDocCodes.includes(doc.fileType.code);
  },
  canDeleteSpecialDocument: (doc) => {
    const cantDeleteDocCodes = ["DER", "RBE"];
    return !cantDeleteDocCodes.includes(doc.fileType.code);
  },
  transformIntentParams: (params) => {
    // params :
    // {
    //   policyId: params.policyId,
    //   paymentData: {
    //     paymentType: modeP,
    //     paymentAction,
    //     paymentMethodId: selectedPaymentMethod,
    //   },
    //   productData: {
    //     beginAt: params.beginAt,
    //     isPremium: params.isPremium,
    //     optRc: params.optRc,
    //     optFs: params.optFs,
    //     turnover: params.turnover,
    //   },
    // }
    return {
      produitData: {
        contratId: params.policyId, // Manquant dans ton objet
        debutLe: params.productData.beginAt,
        chiffreAffaires: params.productData.turnover,
        typeProduit: "PJ",
        avenantType: params.paymentData.paymentAction,
        simulation: false,
        data: {
          isPremium: params.productData.isPremium,
          optFs: params.productData.optFs,
          optRc: params.productData.optRc,
        },
      },
      paiementData: {
        avenantType: params.paymentData.paymentAction,
        typeMethodePaiement: params.paymentData?.paymentType || null,
        // intentionPaiementId: params.paymentData?.paymentIntentId || null, // C'est ce qu'on veut récupérer...
        methodePaiementId: params.paymentData?.paymentMethodId || null, // Manquant dans ton objet
      },
    };
  },
};

export default tools;
