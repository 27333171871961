import React from "react";
import { sidenavActions } from "../../../context/actions/sidenavActions";
import useStore from "../../../context/useStore";
import colors from "../../../helpers/colors";

const CustomSidenav = () => {
  const [state, dispatch] = useStore();
  const actions = sidenavActions(state, dispatch);
  const modalProps = state.sidenav;

  const close = () => {
    actions.updateSidenav({ isOpen: false, content: null });
    if (modalProps.onClose) {
      modalProps.onClose();
    }
  };

  const closeBackdrop = (e) => {
    if (e.target !== e.currentTarget) return;
    actions.updateSidenav({ isOpen: false, content: null });
    if (modalProps.onClose) {
      modalProps.onClose();
    }
  };

  return (
    <div
      className="position-fixed w-100 h-100 align-items-center justify-content-end"
      id="sidenavmodal"
      style={{
        display: modalProps.isOpen ? "flex" : "none",
        top: 0,
        left: 0,
        background: "rgb(0 0 0 / 54%)",
        zIndex: "9999",
        overflowY: "auto",
        ...modalProps.style,
      }}
      onClick={closeBackdrop}
    >
      <div
        className={
          " position-relative p-5 " + (modalProps.isOpen ? "animated fadeInRight faster" : "")
        }
        style={{
          width: "400px",
          height: "100%",
          maxWidth: "400",
          borderRadius: 5,
          overflowY: "scroll",
          background: "white",
        }}
      >
        {modalProps.content}

        <div
          className="d-flex align-items-center justify-content-end "
          style={{ position: "fixed", top: "2%", right: "3%", zIndex: "99999" }}
        >
          {modalProps.actions}
          {!modalProps.noClose && (
            <button onClick={close} className="btn btn-default bg-white">
              Fermer
              <i className="fa fa-times ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomSidenav;
