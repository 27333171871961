import React, { useEffect } from "react";

const OverlayBottom = (props) => {
  useEffect(() => {
    if (props.overlayOpen) {
      // document.querySelector("#react-burger-cross-btn").click();
    }
  }, [props.overlayOpen]);

  return (
    <div
      className={
        "overlay-bottom animated  faster pt-0 pt-md-5 " +
        (props.overlayOpen ? "fadeInUp" : "hidden")
      }
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        background: "#f0f3f5",
        zIndex: "9999",
      }}
    >
      <button
        className="btn w-100 mt-2"
        onClick={props.closeOverlay}
        style={{
          top: 0,
          left: 0,
          borderRadius: 0,
          borderBottom: "0",
          boxShadow: "none",
          height: 74,
          zIndex: "999",
        }}
      >
        <i
          className="fa fa-chevron-down fa-2x text-white shadow"
          style={{
            borderRadius: "100%",
            background: "#040ca5",
            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
            fontSize: 16,
          }}
        />
      </button>
      <div
        className="p-4 mt-3"
        style={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default OverlayBottom;
