import React from "react";
import { useParams } from "react-router-dom";
import ProducerButton from "../../common/ProducerButton/ProducerButton";
import ACBlocks from "./ACBlocks";
import ClientAccordion from "./ClientAccordion";
import DownloadButton from "./DownloadButton";
import MRPBlocks from "./MRPBlocks";
import MRPDocs from "./MRPDocs";
import ProductTitle from "./ProductTitle";

const MRPContainer = ({ MRPClients }) => {
  const { id } = useParams();
  return (
    <div className="mb-5">
      <ProductTitle icon="store" title="Demande d'Assurance Multirisque Professionnelle" />
      <div className="mt-3">
        <p>
          <strong>Téléchargez les questionnaires PDF</strong> en fonction des Compagnies de votre
          choix. Mettez à jour vos réponses si besoin.
        </p>
      </div>

      {MRPClients.map((client) => {
        return (
          <ClientAccordion client={client} isOpenByDefault={id == client.clientId}>
            {client.establishments.map((company) => (
              <MRPBlocks client={client} company={company} key={`compac${company.name}`} />
            ))}
            {client.producer && (
              <div className="w-100  pb-0 pt-3 px-3 text-center" style={{ marginTop: 10 }}>
                Gestionnaire : <ProducerButton producer={client.producer} />
              </div>
            )}
            <MRPDocs client={client} />
          </ClientAccordion>
        );
      })}
    </div>
  );
};

export default MRPContainer;
