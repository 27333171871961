import React from "react";
import { REF_APP } from "../../../config";
import Loader from "../../common/Loader";

const RoleRecap = ({ role, user, ROLES, setEditRoleId }) => {
  var roleData = Object.values(ROLES[REF_APP]).find((r) => r.key == role.roleKey);

  return !roleData ? (
    <Loader />
  ) : (
    <div className="rounded p-3 animated fadeInUp fast" style={{ background: "#F1F2FE" }}>
      <div className="d-flex justify-content-end w-100">
        <button
          className="btn btn-link text-primary bg-transparent pt-0 pr-0"
          onClick={() => setEditRoleId(role.id)}
        >
          <i className="fa fa-pen mr-2" />
          Modifier
        </button>
      </div>
      <div
        className="d-flex flex-row justify-content-start align-items-start cursor-pointer"
        onClick={() => setEditRoleId(role.id)}
      >
        <div
          className="bg-success text-white p-3 rounded-circle d-center"
          style={{ height: 42, width: 42 }}
        >
          <i className={`fa fa-${roleData.icon}`} style={{ fontSize: 24 }} />
        </div>
        <div className="pl-3">
          <p style={{ fontSize: 17, lineHeight: "22px" }} className="font-weight-bold text-success">
            <span style={{ color: "black", textTransform: "capitalize" }}>
              {user.firstname} {user.lastname}
            </span>{" "}
            est <span style={{ color: "black" }}>{roleData.label}</span> de{" "}
            {role.clientsName.length > 1 ? "ces " : "cette "}
            société{role.clientsName.length > 1 ? "s" : ""} :
          </p>
          <ul
            className="text-success"
            style={{ paddingLeft: 0, color: "black", listStyle: "none" }}
          >
            {role.clientsName.map((c, ck) => (
              <li key={`comp${role.id}${c}`}>
                <strong>
                  <i className="fa fa-chess-rook mr-2" />
                  {c}
                </strong>
              </li>
            ))}
          </ul>

          <i className="mb-3 d-block">{role.privileges.length} privileges sont actifs.</i>
        </div>
      </div>
      <div
        className="bg-light px-3 pt-2 pb-1 rounded"
        style={{ fontSize: 14, lineHeight: "22px" }}
        dangerouslySetInnerHTML={{
          __html: roleData.description,
        }}
      ></div>
    </div>
  );
};

export default RoleRecap;
