import { DateTime } from "luxon";
import React from "react";
import { authActions } from "../../../context/actions/authActions";
import useStore from "../../../context/useStore";

const MustActivateAccount = () => {
  const [state, dispatch] = useStore();
  const aActions = authActions(state, dispatch);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        marginTop: -64,
      }}
      className="d-center "
    >
      <div
        className="d-center flex-column mx-auto bg-blue p-4 shadow-small  text-left "
        style={{
          maxWidth: 350,
          fontSize: 16,
          borderRadius: 20,
        }}
      >
        <img src="/images/logo-small.svg" style={{ height: 60 }} />
        <p className="d-block w-100 mt-4">
          <strong>
            Vous devez activer votre compte pour pouvoir accéder à votre Espace
            client.{" "}
          </strong>
        </p>
        <p className="d-block w-100">
          Un email contenant votre lien d'activation vous a été envoyé le{" "}
          {DateTime.fromISO(state.auth.user.activationTokenSendedAt).toFormat(
            "dd/MM/yyyy"
          )}
          .
        </p>
        <p className="d-block w-100">Pensez à vérifier dans vos spams.</p>

        <button
          className="btn btn-primary text-white mb-2"
          onClick={() => window.location.reload()}
        >
          J'ai activé mon compte
        </button>
        <button className="btn btn-link" onClick={() => aActions.logoutUser()}>
          Me déconnecter
        </button>
      </div>
    </div>
  );
};

export default MustActivateAccount;
