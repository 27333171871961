import React from "react";
import AddressModifier from "./AddressModifier";
import RecapCard from "./RecapCard";
import RecapCompanyInfos from "./RecapCompanyInfos";

const CompanyTopRecap = ({ company }) => {
  return (
    <RecapCard>
      <RecapCompanyInfos company={company} companyType={company.type} />
      <AddressModifier company={company} />
    </RecapCard>
  );
};

export default CompanyTopRecap;
