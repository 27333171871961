import React, { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Control";
import AddPaymentMethod from "./AddPaymentMethod";
import CreateCard from "./CreateCard";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import tools from "../../../helpers/tools";

const PaymentItem = ({ client, setNbPm, onSetPaymentMethods }) => {
  const [displayAddCard, setDisplayAddCard] = useState(false);
  const [displayAddIban, setDisplayAddIban] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = () => {
    setIsLoading(true);
    setDisplayAddCard(false);
    setDisplayAddIban(false);
    setPaymentMethods([]);
    const clientId = client.id;
    dataService.get(
      `clients/${clientId}/stripe/paymentmethods`,
      (datas) => {
        setPaymentMethods(datas);
        onSetPaymentMethods(datas);
        setNbPm((n) => ({ ...n, [client.id]: datas.length }));
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const removePaymentMethod = (id) => {
    setIsLoading(true);
    dataService.remove(
      `clients/${client.id}/stripe/paymentmethods/${id}`,
      {},
      (res) => {
        getPaymentMethods();
        toast.success("Ce moyen de paiement a bien été supprimé.");
      },
      (err) => {
        setErrors(err);
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  };

  const updateDefaultPaymentMethod = (id) => {
    setIsLoading(true);
    dataService.patch(
      `clients/${client.id}/stripe/paymentmethods/${id}/default`,
      {},
      (res) => {
        getPaymentMethods();
        toast.success(
          "Ce moyen de paiement sera utilisé pour vos futurs paiements."
        );
      },
      (err) => {
        setErrors(err);
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  };

  var cardBrandToPfClass = {
    visa: "pf-visa",
    mastercard: "pf-mastercard",
    amex: "pf-american-express",
    discover: "pf-discover",
    diners: "pf-diners",
    jcb: "pf-jcb",
    unknown: "pf-credit-card",
  };

  const ibans = paymentMethods.filter((p) => p.type == "SEPA");
  const cards = paymentMethods.filter((p) => p.type == "CB");

  return isLoading ? (
    <Loader />
  ) : (
    <div className="row">
      <div className="col-12 col-md-6">
        <label
          className="w-100 mt-4 mb-3 font-weight-bold"
          style={{
            fontSize: 17,
          }}
        >
          Cartes enregistrées ({cards?.length})
        </label>
        {cards && (
          <>
            <ul className="list-group">
              {cards.map((p) => (
                <li
                  className="list-group-item d-flex align-items-center justify-content-between p-2 mb-1 border shadow-sm"
                  style={{ fontSize: 17 }}
                >
                  <div>
                    <div className="d-flex align-items-center justify-content-start w-100">
                      <i className={`mr-2 pf ${cardBrandToPfClass[p.brand]}`} />
                      <div className="text-success" style={{ fontSize: 14 }}>
                        {p.info}
                      </div>
                    </div>
                    {DateTime.local() > DateTime.fromISO(p.expirationDate) && (
                      <div
                        className="mt-2 mr-1 text-danger"
                        style={{ fontSize: 13, whiteSpace: "pre-wrap" }}
                      >
                        Cette carte a expiré le{" "}
                        {tools.formatDate(p.expirationDate)}, veuillez ajouter
                        une nouvelle carte en cours de validité.
                      </div>
                    )}
                  </div>

                  <div className="d-flex">
                    {p.isDefault ? (
                      <button className="btn p-0 shadow-none text-info d-center">
                        <i className="fa fa-check mr-1" />
                        Par défaut
                      </button>
                    ) : DateTime.local() >
                      DateTime.fromISO(p.expirationDate) ? null : (
                      <button
                        className="btn py-1 px-2 btn-light shadow-small shadow-small"
                        style={{ position: "relative", left: 10 }}
                        onClick={() =>
                          updateDefaultPaymentMethod(p.paymentMethodId)
                        }
                      >
                        Utiliser
                      </button>
                    )}
                    <button
                      onClick={() => {
                        removePaymentMethod(p.paymentMethodId);
                      }}
                      className="btn btn-sm shadow-none "
                      style={{ position: "relative", left: 10 }}
                    >
                      <i className="fa fa-times-circle fa-2x text-danger" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-100 mt-4 ">
              {displayAddCard ? (
                <AddPaymentMethod
                  client={client}
                  type="card"
                  getPaymentMethods={getPaymentMethods}
                />
              ) : (
                <div className="w-100">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => setDisplayAddCard(!displayAddCard)}
                  >
                    <i className="fa fa-plus mr-1" />
                    Ajouter une carte
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="col-12 col-md-6">
        <label
          className="w-100 mt-4 mb-3 font-weight-bold"
          style={{
            fontSize: 17,
          }}
        >
          IBAN enregistrés ({ibans?.length})
        </label>
        {ibans && (
          <>
            <ul className="list-group">
              {ibans.map((iban, ibk) => (
                <li
                  className="list-group-item d-flex align-items-center justify-content-between p-2 border shadow-sm"
                  style={{ fontSize: 17 }}
                  key={`iban${ibk}`}
                >
                  <div className="d-center">
                    <i className="pf pf-sepa mr-2" />
                    <div className="text-success" style={{ fontSize: 14 }}>
                      {iban.info}
                    </div>
                  </div>
                  <div className="d-flex">
                    {iban.isDefault ? (
                      <button className="btn p-0 shadow-none text-info d-center">
                        <i className="fa fa-check mr-1" />
                        Par défaut
                      </button>
                    ) : (
                      <button
                        className="btn py-1 px-2 btn-light shadow-small shadow-small"
                        style={{ position: "relative", left: 10 }}
                        onClick={() =>
                          updateDefaultPaymentMethod(iban.paymentMethodId)
                        }
                      >
                        Utiliser
                      </button>
                    )}
                    <button
                      onClick={() => {
                        removePaymentMethod(iban.paymentMethodId);
                      }}
                      className="btn btn-sm shadow-none "
                      style={{ position: "relative", left: 10 }}
                    >
                      <i className="fa fa-times-circle fa-2x text-danger" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-100 mt-4 ">
              {displayAddIban ? (
                <AddPaymentMethod
                  client={client}
                  type="iban"
                  getPaymentMethods={getPaymentMethods}
                />
              ) : (
                <div className="w-100">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => setDisplayAddIban(!displayAddIban)}
                  >
                    <i className="fa fa-plus mr-1" />
                    Ajouter un IBAN
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentItem;
