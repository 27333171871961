import Loader from "../Loader";
import PayWithSavedCard from "./PayWithSavedCard";
import PayWithSavedSepa from "./PayWithSavedSepa";
import SavedCBs from "./SavedCBs";
import SavedSEPAs from "./SavedSEPAs";
import { Elements } from "@stripe/react-stripe-js";

const PaymentFormWithSavedMethod = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  isLoadingPaymentIntent,
  paymentIntent,
  setPaymentIntent,
  errors,
  setErrors,
  isPaying,
  modePayment,
  stripePromise,
  setIsPaying,
  validatePayment,
  setDisplayPaymentForm,
  hiddenPaymentModes = [],
}) => {
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <label className="font-weight-bold">Cartes enregistrées</label>
        <SavedCBs
          paymentMethods={paymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
      </div>
      <div className="col-12 col-md-6">
        <label className="font-weight-bold">IBAN enregistrés</label>
        {hiddenPaymentModes.includes("SEPA") ? (
          <p className="py-2">
            Le virement SEPA est indisponible car le montant est supérieur à 10
            000€.
          </p>
        ) : (
          <SavedSEPAs
            paymentMethods={paymentMethods}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
        )}
      </div>
      {selectedPaymentMethod &&
        (isLoadingPaymentIntent ? (
          <div className="col-12 my-2 d-center">
            <Loader />
          </div>
        ) : (
          <div className="col-12 d-center flex-column">
            <Elements stripe={stripePromise}>
              {modePayment === "CB" && (
                <PayWithSavedCard
                  paymentIntent={paymentIntent}
                  setPaymentIntent={setPaymentIntent}
                  errors={errors}
                  setErrors={setErrors}
                  isPaying={isPaying}
                  setIsPaying={setIsPaying}
                  validatePayment={validatePayment}
                  selectedPaymentMethod={selectedPaymentMethod}
                />
              )}
              {modePayment === "SEPA" && (
                <PayWithSavedSepa
                  paymentIntent={paymentIntent}
                  setPaymentIntent={setPaymentIntent}
                  errors={errors}
                  setErrors={setErrors}
                  isPaying={isPaying}
                  setIsPaying={setIsPaying}
                  validatePayment={validatePayment}
                  selectedPaymentMethod={selectedPaymentMethod}
                />
              )}
            </Elements>
            <div className="text-center py-2 font-weight-bold">- OU -</div>
          </div>
        ))}
      <div className="col-12 d-center flex-column">
        <button
          className="btn btn-default"
          onClick={() => setDisplayPaymentForm(true)}
        >
          Choisir une nouvelle méthode de paiement
          <i className="fa fa-arrow-right ml-2" />
        </button>
      </div>
    </div>
  );
};

export default PaymentFormWithSavedMethod;
