import React, { useRef } from "react";
import { colors } from "../../../../helpers/colors";

const ButtonItem = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  data,
  dk,
  dataIndex = "id",
  dataLabel = "name",
  dataLabelIndex = false, //si le label est dans un objet ex : { label: { name: "Label"} }
  dataLabel2 = false,
  dataIcon = false,
  btnInline = false,
  itemClassName = "",
  itemStyle = {},
  isActive,
  valueChange,
  name,
  textContentClassname = "flex-row",
  textContentStyle = {},
  dataLabel2Classname = "",
  dataLabel2Style = {},
  dataIconSet = null,
}) => {
  const btnRef = useRef(null);

  return (
    <button
      ref={btnRef}
      className={`
        d-flex
            btn noeffect  
            position-relative 
            flex-row align-items-center py-2  border-0  flex-fill   
            
            ${
              btnInline
                ? "btn-sm text-center my-1 mr-2 justify-content-end  flex-wrap flex-column "
                : "mb-1 justify-content-between w-100"
            }
              ${
                isActive
                  ? "btn-primary shadow checkedBtn"
                  : "btn-default bg-white shadow-small"
              }
               ${itemClassName} 
            `}
      style={{
        ...itemStyle,
        overflow: "visible",
        borderRadius: 5,
        color: isActive ? "white" : colors.secondary,
      }}
      disabled={disabled ? "disabled" : false}
      type="button"
      key={`${name} - ${
        data.id ? data.id : data._id ? data._id + dk : "abcd" + dk
      } `}
      onClick={(e) => {
        valueChange(e, data[dataIndex], data.isExclusive);
      }}
    >
      {dataIcon && (
        <div
          className={`d-flex align-items-center justify-content-center py-2 ${
            !btnInline ? "mr-2" : ""
          }`}
        >
          {data.extIcon ? (
            <img src={`/images/icons/${data[dataIcon]}`} />
          ) : (
            <i
              className={
                dataIconSet
                  ? `${dataIconSet} ${data[dataIcon]}`
                  : "illustration fa fa-" + data[dataIcon] + ""
              }
              style={{
                background: "transparent",
                fontSize: 26,
                padding: 0,
              }}
            />
          )}
        </div>
      )}
      <div
        className={`d-flex mb-0 font-weight-bold flex-column justify-content-center ${textContentClassname} ${
          dataIcon ? "" : "flex-fill"
        }`}
        style={{ ...textContentStyle }}
      >
        {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}

        {data[dataLabel2] && (
          <span
            className={`my-1 ${dataLabel2Classname}`}
            style={{ fontSize: 12, lineHeight: "13px", ...dataLabel2Style }}
          >
            {data[dataLabel2]}
          </span>
        )}
      </div>

      {btnInline && (
        <i
          className={`check-icon fa fa-check ml-2 animated ${
            isActive ? "fadeInLeft d-block" : "d-none"
          } faster 
          ${
            btnInline
              ? "position-absolute bg-success text-white shadow-sm "
              : ""
          }
                  `}
          style={{
            top: -7,
            right: -7,
            fontSize: 12,
            padding: 8,
            borderRadius: "100%",
            zIndex: "90",
          }}
        />
      )}
    </button>
  );
};

export default ButtonItem;
