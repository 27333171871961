import React from "react";
import ClientAccordion from "./ClientAccordion";
import ProductTitle from "./ProductTitle";
import CYBERBlocks from "./CYBERBlocks";

const CYBERContainer = ({ CYBERClients }) => {
  return (
    <div className="mb-5">
      <ProductTitle icon="mask" title="Demande d'Assurance Cyber" />
      <div className="mt-3">
        <p>
          <strong>Nous étudions votre demande d’Assurance Cyber</strong> à
          partir des informations que vous avez renseigné. Nous revenons vers
          vous au plus vite.
        </p>
      </div>
      {CYBERClients.map((client) => {
        return (
          <ClientAccordion client={client}>
            {client.establishments.map((company) => (
              <CYBERBlocks company={company} key={`compac${company.name}`} />
            ))}
          </ClientAccordion>
        );
      })}
    </div>
  );
};

export default CYBERContainer;
