import { atom, useAtom } from "jotai";

export const clientAtom = atom(null);

const useClientItem = () => {
  const [client, setClient] = useAtom(clientAtom);

  const initClient = (data) => {
    setClient({ ...data });
  };

  return {
    client,
    actions: {
      initClient,
    },
  };
};

export default useClientItem;
