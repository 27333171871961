import React, { useState } from "react";
import { DateTime } from "luxon";
import Axios from "axios";
import { API_URL } from "../../config";
import FileDownload from "js-file-download";
import Loader from "../common/Loader";
import { toast } from "react-toastify";
import tools from "../../helpers/tools";

const FileExist = ({
  document,
  documentRemoved = false,
  uploadNewFile = false,
  small = false,
  inline = false,
  dataPriv,
  client,
  productName = "",
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const downloadFile = () => {
    setIsDownloading(true);
    Axios({
      url: API_URL + "files/" + document.id,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        var filename = tools.getDocumentName({
          fileExt: res.headers["content-disposition"].split(".")[1],
          documentName: document.fileType.name,
          productName,
          companyName: client.company,
          uploadedAt: document.uploadedAt,
        });
        FileDownload(res.data, filename);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  function removeFile() {
    if (!window.confirm("Confirmez vous la suppression de ce document ?")) return false;
    setIsDeleting(true);
    Axios.delete(API_URL + "files/" + document.id)
      .then((res) => {
        documentRemoved(document);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDeleting(false);
      });
  }

  return (
    <div
      className={
        "h-100 " +
        (inline
          ? " d-flex flex-row flex-wrap align-items-center w-100 justify-content-center  "
          : " px-3 ")
      }
    >
      <p className="mb-0">
        Envoyé le {DateTime.fromISO(document.uploadedAt).toFormat("dd/MM/yyyy HH:mm")}
      </p>

      <p className={inline ? "mb-0 mx-2" : "mb-2"}>
        Par{" "}
        {document.uploadedByManager || document.uploadedByCustomer
          ? typeof document.uploadedByManager === "object" ||
            typeof document.uploadedByCustomer === "object"
            ? document.uploadedByManager
              ? document.uploadedByManager.firstname + " " + document.uploadedByManager.lastname
              : document.uploadedByCustomer.firstname + " " + document.uploadedByCustomer.lastname
            : "Perceval"
          : ""}
      </p>

      {isDownloading || isDeleting ? (
        <Loader size={70} />
      ) : (
        <div
          className={
            inline ? " d-flex flex-row flex-wrap align-items-center justify-content-center " : ""
          }
        >
          <button
            className={
              "btn btn-success  " + (small ? " btn-sm " : " ") + (inline ? " btn-sm " : " mb-2 ")
            }
            onClick={downloadFile}
          >
            {!small && <i className="fa fa-cloud-download-alt mr-2" />}
            Télécharger
          </button>
          {uploadNewFile && (
            <button
              data-priv={dataPriv}
              className={
                "btn btn-primary btn-sm d-block " +
                (small ? " btn-sm " : " ") +
                (inline ? " mx-1 btn-sm " : " mb-2 mx-auto ")
              }
              onClick={uploadNewFile}
            >
              {!small && <i className="fa fa-cloud-upload-alt mr-2" />}
              {small ? "Renvoyer" : "Envoyer à nouveau"}
            </button>
          )}

          {documentRemoved && (
            <button
              data-priv={dataPriv}
              className="btn btn-danger btn-sm m-auto d-block "
              onClick={removeFile}
            >
              {!small && <i className="fa fa-times mr-2" />}
              Supprimer
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default FileExist;
