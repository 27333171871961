import Axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { API_URL } from "../config";

const dataService = {
  getMany: (
    urls,
    successCallback = () => {},
    beforeCallBack = () => {},
    anyCallBack = () => {}
  ) => {
    beforeCallBack();
    var allPromises = [];
    urls.forEach((url) => {
      switch (url.type) {
        case "GET":
          allPromises.push(
            new Promise((resolve, reject) => {
              dataService.get(
                url.path,
                (datas) => resolve({ name: url.name, datas }),
                reject,
                () => {}
              );
            })
          );
          break;
        case "POST":
          allPromises.push(
            new Promise((resolve, reject) => {
              dataService.post(
                url.path,
                url.postData,
                (datas) => resolve({ name: url.name, datas }),
                reject,
                () => {}
              );
            })
          );
          break;
      }
    });
    var results = {};
    Promise.all(allPromises)
      .then((values) => {
        values.forEach((val) => {
          results[val.name] = val.datas;
        });
        successCallback(results);
      })
      .catch((err) => {})
      .then(anyCallBack);
  },
  get: (url, successCallback = () => {}, errorCallback = () => {}, anyCallBack = () => {}) => {
    Axios.get(API_URL + url)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  post: (
    url,
    postData,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.post(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  patch: (
    url,
    postData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.patch(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  remove: (
    url,
    deleteData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {},
    noConfirm = false
  ) => {
    if (!noConfirm) {
      if (!window.confirm("Supprimer cette entrée ?")) {
        anyCallBack();
        return false;
      }
    }
    Axios.delete(API_URL + url, deleteData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  postFile: (
    url,
    postData,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.post(API_URL + url, postData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  errorDisplay: (err, errorCallback) => {
    if (
      err.response &&
      err.response.data &&
      Object.keys(err.response.data).length > 0 &&
      typeof err.response.data === "object"
    ) {
      Object.values(err.response.data).forEach((err) => toast.error(err));
      errorCallback(err.response.data);
    } else {
      errorCallback({ other: "Une erreur est survenue dans l'application" });
    }
  },

  downloadFile: (
    url,
    options,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios({
      url: API_URL + url,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        const filename = options.filename || res.headers["content-disposition"].split("=")[1];
        fileDownload(res.data, filename);
        successCallback(res.data);
      })
      .catch(errorCallback)
      .then(anyCallBack);
  },
};

export default dataService;
