import { useEffect } from "react";
import tools from "../../../../../../../../../../helpers/tools";
import LabelValuePair from "../../../../../../../../../common/LabelValuePair/LabelValuePair";
import Loader from "../../../../../../../../../common/Loader";
import PolicyBottomPanel from "./PolicyBottomPanel";
import usePolicyItem from "./usePolicyItem";

const PolicyItem = ({ companyData, policyData }) => {
  const {
    POLICIES_STATUS,
    PERIODICITIES,
    products,
    productTypes,
    insuranceCompanies,
    isLoadingCompanyPolicies,
    policy,
    bottomPanels,
    bottomPanelContent,
    policyActions,
  } = usePolicyItem();

  useEffect(() => {
    policyActions.initPolicy(policyData);
    policyActions.initCompanyPolicy(companyData);
  }, [policyData]);

  return !policy ? null : (
    <div className={`col-12 shadow-sm bg-white py-2 mb-3 rounded policy-${policy.id}`}>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="d-flex align-items-center">
            <span className="badge badge-primary mr-2">
              {tools.findIn(productTypes, policy.product.productType, "id").name}
            </span>
            <strong>{policy.title}</strong>
            <small className="ml-2">{policy.product.name}</small>
          </div>

          <div>
            {policy.reference} - {policy.identification}
          </div>
          <div>
            <LabelValuePair
              label="Status"
              value={tools.findIn(POLICIES_STATUS, policy.status, "id").name}
            />
          </div>
          <div>
            <LabelValuePair label="Compagnie d'assurance" value={policy.company.name} />
          </div>

          <div>
            <LabelValuePair
              label="Gestionnaires"
              value={
                <>
                  {policy.producer?.company} {policy.producer2?.company}
                </>
              }
            />
          </div>
          <div>
            <LabelValuePair
              label="Options"
              value={policy.options.map((opt) => opt.name).join(", ")}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div>
            <LabelValuePair label="Date de création" value={tools.formatDate(policy.createdAt)} />
          </div>
          <div>
            <LabelValuePair label="Date de modif" value={tools.formatDate(policy.updatedAt)} />
          </div>
          {isLoadingCompanyPolicies && <Loader />}
          {policy.amendments?.length > 0 && (
            <>
              <div>
                <LabelValuePair
                  label="Date d'effet"
                  value={tools.formatDate(policy.amendments[0].effectDate)}
                />
              </div>
              <div>
                <LabelValuePair
                  label="Échéance principale"
                  value={tools.formatDate(policy.amendments[0].mainEcheance)}
                />
              </div>
              <div>
                <LabelValuePair
                  label="Prime annuelle TTC"
                  value={`${policy.amendments[0].annualPremium} €`}
                />
              </div>

              <div>
                <LabelValuePair label="Honoraires" value={`${policy.amendments[0].fees || 0} €`} />
              </div>
              <div>
                <LabelValuePair
                  label="Périodicité"
                  value={`${
                    tools.findIn(PERIODICITIES, policy.amendments[0].periodicity, "id").name
                  }`}
                />
              </div>
            </>
          )}
        </div>

        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between mt-3 flex-wrap">
            {bottomPanels.map((panel) => (
              <button
                className={`btn btn-default border mr-1 mb-1 btn-sm d-center flex-fill ${
                  panel.content == bottomPanelContent ? "active" : ""
                }`}
                onClick={
                  panel.onClick ? panel.onClick : () => policyActions.openBottomPanel(panel.content)
                }
                dataPriv={panel.dataPriv}
              >
                {panel.name}
              </button>
            ))}
          </div>
        </div>
        <div className="col-12">
          <PolicyBottomPanel />
        </div>
      </div>
    </div>
  );
};

export default PolicyItem;
