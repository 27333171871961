import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../../../common/Loader";
import CheckoutForm from "../../../common/Payment/CheckoutForm";
import CheckoutFormSepa from "../../../common/Payment/CheckoutFormSepa";
import PaymentChoice from "../../../common/Payment/PaymentChoice";
import PaymentFormWithSavedMethod from "../../../common/Payment/PaymentFormWithSavedMethod";
import ErrorBlock from "../../../common/PaymentComponents/ErrorBlock";
import usePaymentFormQuittances from "./usePaymentFormQuittances";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentFormQuittances = ({
  client,
  quittances,
  setPaymentOk = () => {},
  hiddenPaymentModes = [],
}) => {
  const {
    isLoading,
    isLoadingPaymentIntent,
    paymentMethods,
    pmErrors,
    paymentErrors,
    piErrors,
    selectedPaymentMethod,
    paymentIntent,
    isPaying,
    modePayment,
    hasSavedPaymentMethods,
    displayPaymentForm,
    modeP,
    setPaymentIntent,
    setPmErrors,
    setIsPaying,
    setPaymentErrors,
    onChangeNewModePayment,
    onSelectSavedPaymentMethod,
    toggleNexPaymentForm,
    onValidatePayement,
  } = usePaymentFormQuittances({ client, quittances, setPaymentOk });

  var paymentErrorsArr = Object.values(paymentErrors);
  var piErrorsArr = Object.values(piErrors);

  return (
    <div className="payment-method-module">
      {isLoading ? (
        <Loader />
      ) : (
        paymentMethods &&
        !displayPaymentForm && (
          <div className="animated fadeIn fast">
            <PaymentFormWithSavedMethod
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={onSelectSavedPaymentMethod}
              isLoadingPaymentIntent={isLoadingPaymentIntent}
              paymentIntent={paymentIntent}
              setPaymentIntent={setPaymentIntent}
              errors={pmErrors}
              setErrors={setPmErrors}
              isPaying={isPaying}
              modePayment={modePayment}
              stripePromise={stripePromise}
              setIsPaying={setIsPaying}
              validatePayment={onValidatePayement}
              setDisplayPaymentForm={toggleNexPaymentForm}
              hiddenPaymentModes={hiddenPaymentModes}
            />
          </div>
        )
      )}
      {displayPaymentForm && (
        <div className="payment-module animated fadeIn fast">
          {hasSavedPaymentMethods && (
            <div className="w-100 text-center">
              <button
                className="btn btn-default"
                onClick={() => toggleNexPaymentForm(false)}
              >
                <i className="fa fa-arrow-left mr-2" />
                Voir mes moyens de paiement enregistrés
              </button>
            </div>
          )}
          <h4
            className="d-center flex-column text-center"
            style={{ fontSize: 22 }}
          >
            Mode de paiement
            <i className="fa fa-chevron-down my-2 animated fadeIn fast" />
          </h4>
          <div className=" position-relative">
            <PaymentChoice
              modeP={modeP}
              setModeP={onChangeNewModePayment}
              hiddenPaymentModes={hiddenPaymentModes}
            />
            {isLoadingPaymentIntent ? (
              <Loader />
            ) : piErrorsArr.length > 0 ? (
              <ErrorBlock errorsArray={piErrorsArr} />
            ) : (
              <div className="mt-3">
                {modeP === "CB" && (
                  <>
                    <PaymentModeHeader title="Carte bleue" />
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        paymentIntent={paymentIntent}
                        setPaymentIntent={setPaymentIntent}
                        setErrors={setPaymentErrors}
                        isPaying={isPaying}
                        setIsPaying={setIsPaying}
                        validatePayment={onValidatePayement}
                      />
                    </Elements>
                  </>
                )}

                {modeP === "SEPA" && (
                  <>
                    <PaymentModeHeader
                      title="Virement SEPA"
                      description="Vous optez pour le paiement par prélevement SEPA, les garanties vous sont acquises à partir de la date d'effet choisie."
                    />
                    <Elements stripe={stripePromise}>
                      <CheckoutFormSepa
                        paymentIntent={paymentIntent}
                        setPaymentIntent={setPaymentIntent}
                        setErrors={setPaymentErrors}
                        isPaying={isPaying}
                        setIsPaying={setIsPaying}
                        validatePayment={onValidatePayement}
                        userDatas={{
                          policy: {
                            client: {
                              gender: client.civilite,
                              firstname: client.prenom,
                              lastname: client.nom,
                              siret: client.siret,
                              ape: client.ape,
                              email: client.email,
                              mobilePhone: client.telephone,
                              companyActivity: client.activiteSociete,
                              location1: {
                                address: client.adresse,
                                address2: client.complementAdresse || "",
                                postalCode: client.codePostal,
                                city: client.ville,
                              },
                            },
                          },
                        }}
                      />
                    </Elements>
                  </>
                )}
                {paymentErrorsArr.length > 0 && (
                  <ErrorBlock errorsArray={paymentErrorsArr} />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentFormQuittances;

const PaymentModeHeader = ({ title, description }) => {
  return (
    <>
      <div
        className="font-weight-bold mb-2 mt-4"
        style={{
          fontSize: 24,
        }}
      >
        {title}
      </div>
      {description && <p>{description}</p>}
    </>
  );
};
