import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import DocumentFilters from "./DocumentFilters";
import { toast } from "react-toastify";
import DocumentsDisplay from "../ClientList/DocumentsDisplay";

var keepDocumentK = [];

const DocumentList = () => {
  const [documents, setDocuments] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setError] = useState(false);
  const [search, setSearch] = useState({
    file: {
      entityModel: "",
      fileTypes: [],
      filters: [],
      isExist: "-",
    },
    page: 1,
  });
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [customFilters, setCustomFilters] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [searchText, setSearchText] = useState("Rechercher");
  const fileEntitiesData = state.constants.items ? state.constants.items.FILE_ENTITIES_MODEL : false;
  const [isInit, setIsInit] = useState(true);
  const [displayInline, setDisplayInline] = useState(true);

  useEffect(() => {
    setSearchText("Rechercher");
  }, [search]);

  useEffect(() => {
    if (!isInit) {
      searchDocuments();
    }
    setIsInit(false);
  }, [search.page]);

  useEffect(() => {
    if (nbResults > 0) setSearchText(nbResults + " résultats trouvés");
  }, [nbResults]);
  useEffect(() => {
    getDatas();
  }, []);

  function getDatas() {
    Axios.get(API_URL + "constants/files/filters").then((res) => {
      setCustomFilters(res.data);
    });
    Axios.get(API_URL + "filetypes").then((res) => {
      setFileTypes(res.data);
    });
  }
  function searchDocuments() {
    setError(false);
    setIsLoading(true);
    var formatedSearch = JSON.parse(JSON.stringify(search));
    if (formatedSearch.file.isExist == "-") formatedSearch.file.isExist = undefined;
    Axios.post(API_URL + "files/search", formatedSearch)
      .then((res) => {
        setDocuments(res.data.entities);
        setNbResults(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data) {
          setError(err.response.data);
        }
        setDocuments([]);
      });
  }
  function updateDocumentSearch(e) {
    var { name, value, type } = e.target;
    var su = { ...search };
    su.file[name] = value;

    if (name == "entityModel") {
      su.file.fileTypes = [];
    }

    setSearch(su);
  }
  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function documentUploaded(k, ck, doc, entk) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs = JSON.parse(JSON.stringify(keepDocumentK));
    dcs[entk].files[k][0] = JSON.parse(JSON.stringify(doc));
    toast.success("Document envoyé avec succès");
    setDocuments(dcs);
  }
  function documentRemoved(k, ck, oldDoc, entk) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[entk].files[k] = dcs[k].filter((dc, dck) => {
      return dck != ck;
    });

    if (dcs[entk].files[k].length == 0) {
      dcs[entk].files[k].push({
        fileType: oldDoc.fileType,
        entity: oldDoc.entity,
      });
    }
    keepDocumentK = JSON.parse(JSON.stringify(dcs));
    toast.success("Document supprimé avec succès");
    setDocuments(dcs);
  }
  function uploadNewFile(k, ck, entk) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[entk].files[k] = [
      {
        fileType: dcs[entk].files[k][ck].fileType,
        entity: dcs[entk].files[k][ck].entity,
        isNewVersion: true,
      },
    ].concat(dcs[entk].files[k]);
    keepDocumentK = JSON.parse(JSON.stringify(dcs));

    setDocuments(dcs);
  }

  useEffect(() => {
    keepDocumentK = JSON.parse(JSON.stringify(documents));
  }, [documents]);

  function cancelNewVersion() {}

  return (
    <Page container="container-fluid px-5" title={"Liste des documents"} notAnimated>
      <DocumentFilters
        search={search}
        searchDocuments={searchDocuments}
        searchText={searchText}
        updateDocumentSearch={updateDocumentSearch}
        isLoading={isLoading}
        customFilters={customFilters}
        fileTypes={fileTypes}
        displayInline={displayInline}
        setDisplayInline={setDisplayInline}
      />
      <div className="row">
        <div className="col-12" style={{ position: "inherit" }}>
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader withContainer={true} />
          ) : (
            documents.map((model, entk) => (
              <div className="row mb-3" key={"list" + entk}>
                <div className="col-12" style={{ position: "inherit" }}>
                  <div
                    className="card"
                    style={{
                      borderRadius: window.borderRadius,
                      position: "inherit",
                    }}
                  >
                    <div className="card-header d-flex flex-row p-0 align-items-center">
                      <div
                        className="d-flex flex-column p-2 mr-2"
                        style={{
                          background: fileEntitiesData.find((c) => c.id == model.entity.type).color,
                        }}
                      >
                        <i className={fileEntitiesData.find((c) => c.id == model.entity.type).icon} />
                        {fileEntitiesData.find((c) => c.id == model.entity.type).name}
                        {fileEntitiesData.find((c) => c.id == model.entity.type).title}
                      </div>
                      <div className="mr-2">
                        <b>{model.entity.title}</b>
                      </div>
                      <div className="mr-2">{model.entity.subtitle}</div>
                      <div className="mr-2">
                        <i>{model.entity.description}</i>
                      </div>
                    </div>
                    <div className="card-body pb-0 pt-2">
                      <div className="row">
                        <DocumentsDisplay
                          small
                          inline={displayInline}
                          documents={model.files}
                          documentUploaded={(dk, k, d) => documentUploaded(dk, k, d, entk)}
                          documentRemoved={(dk, k, d) => documentRemoved(dk, k, d, entk)}
                          uploadNewFile={(dk, k) => uploadNewFile(dk, k, entk)}
                          cancelNewVersion={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Pagination nbPages={nbPages} page={search.page} changePage={changePage} />
    </Page>
  );
};

export default DocumentList;
