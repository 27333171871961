import React from "react";

const EmailLink = ({ email }) => {
  return (
    <a href={`mailto:${email}`} style={{ wordBreak: "break-all" }}>
      {email}
    </a>
  );
};

export default EmailLink;
