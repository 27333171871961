import { useContext } from "react";
import { ClientContext } from "../../../../ClientContext/ClientContext";
import useCompanyItem from "../useCompanyItem";

const CompanyPoliciesRecap = () => {
  const { company, actions } = useCompanyItem();
  const { productTypes } = useContext(ClientContext);

  const nbPolicies = company.policies?.length || 0;
  const hasMany = nbPolicies > 0;
  const companyProducts = {};

  if (company.policies) {
    company.policies.forEach((policy) => {
      const productTypeRef = productTypes.find((p) => p.id == policy?.product?.productType);
      if (productTypeRef) {
        let entryRef = companyProducts[policy.product?.productType] || {
          count: 0,
          name: productTypeRef.name,
          productTypeModulrId: productTypeRef.modulrId,
        };
        companyProducts[policy.product?.productType] = {
          ...entryRef,
          count: entryRef.count + 1,
        };
      }
    });
  }
  const openPolicy = (product) => {
    actions.openBottomPanel("POLICIES", product);
  };

  return (
    <>
      <div>
        <strong>{nbPolicies} contrat(s)</strong>
        {Object.values(companyProducts).map((product) => {
          return (
            <div
              className="d-flex align-items-center justify-content-start btn-custom cursor-pointer"
              onClick={() => openPolicy(product)}
              key={`cpproduct${company.id}${product.name}`}
            >
              <span className="badge badge-primary mr-1">{product.count}</span>
              {product.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CompanyPoliciesRecap;
