import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CreateCard from "./CreateCard";
import Loader from "../../common/Loader";
import dataService from "../../../helpers/dataService";
import CreateIban from "./CreateIban";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddPaymentMethod = ({ client, type, getPaymentMethods }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(client.stripeCustomerId);

  useEffect(() => {
    if (!client.stripeCustomerId) {
      setIsLoading(true);
      dataService.get(
        `clients/${client.id}/stripe/customerid`,
        (datas) => setCustomerId(datas.stripeCustomerId),
        (err) => {},
        () => setIsLoading(false)
      );
    }
  }, [client]);

  return isLoading ? (
    <Loader />
  ) : (
    <Elements stripe={stripePromise}>
      {type == "card" ? (
        <CreateCard
          client={client}
          customerId={customerId}
          getPaymentMethods={getPaymentMethods}
        />
      ) : (
        <CreateIban
          client={client}
          customerId={customerId}
          getPaymentMethods={getPaymentMethods}
        />
      )}
    </Elements>
  );
};

export default AddPaymentMethod;
