import React, { useState } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";
import useFormState from "../useFormState";
import fundsTips from "./fundsTips";

const FundsInputs = () => {
  const { constants, actions, errors, formData, inputsConfig } = useFormState();
  const inputState = formData.fundsInputs;
  const updateMrpData = (e) => {
    const { name, value } = e.target;

    actions.updateFundsInputs({ [name]: value });
  };

  const onBlur = () => {};

  return (
    <BlockWrapper>
      <Control
        label={inputsConfig.isFundsMarket.label}
        name="isFundsMarket"
        type="btnList"
        datas={inputsConfig.isFundsMarket.datas}
        dataIndex="id"
        value={inputState.isFundsMarket}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        tips={fundsTips.isFundsMarket}
        info="Droit au bail, pas-de porte, marque."
        onBlur={onBlur}
      />
      {inputState.isFundsMarket == 1 && (
        <Control
          label={inputsConfig.qualityOccupantFundsMarket.label}
          name="qualityOccupantFundsMarket"
          type="btnList"
          datas={inputsConfig.qualityOccupantFundsMarket.datas}
          dataIndex="id"
          value={inputState.qualityOccupantFundsMarket}
          change={updateMrpData}
          btnInline
          fullWidth
          labelClassname="w-100"
          error={errors}
          tips={fundsTips.qualityOccupantFundsMarket}
          onBlur={onBlur}
        />
      )}
      {!tools.isOwner(formData.constructionInputs.qualityOccupant) && (
        <Control
          label={inputsConfig.isBuildingExclusion.label}
          name="isBuildingExclusion"
          type="btnList"
          datas={inputsConfig.isBuildingExclusion.datas}
          dataIndex="id"
          value={inputState.isBuildingExclusion}
          change={updateMrpData}
          btnInline
          fullWidth
          labelClassname="w-100"
          error={errors}
          tips={fundsTips.isBuildingExclusion}
          onBlur={onBlur}
        />
      )}
      {formData.constructionInputs.qualityOccupant == 3 && (
        <Control
          name="isInsuranceCommonAccount"
          label={inputsConfig.isInsuranceCommonAccount.label}
          type="btnList"
          datas={inputsConfig.isInsuranceCommonAccount.datas}
          dataIndex="id"
          value={inputState.isInsuranceCommonAccount}
          change={updateMrpData}
          btnInline
          fullWidth
          labelClassname="w-100"
          error={errors}
          tips={fundsTips.isInsuranceCommonAccount}
          info="Pour le compte du propriétaire en cas d'intérêt commun. Selon bail."
          onBlur={onBlur}
        />
      )}
      {tools.isOwner(formData.constructionInputs.qualityOccupant) && (
        <Control
          label={inputsConfig.isAlignmentHit.label}
          name="isAlignmentHit"
          type="btnList"
          datas={inputsConfig.isAlignmentHit.datas}
          dataIndex="id"
          value={inputState.isAlignmentHit}
          change={updateMrpData}
          btnInline
          fullWidth
          labelClassname="w-100"
          error={errors}
          onBlur={onBlur}
          info="Par décision administrative ou judiciaire."
          tips={fundsTips.isAlignmentHit}
        />
      )}
    </BlockWrapper>
  );
};

export default FundsInputs;
