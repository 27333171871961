import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import PaymentCB from "./PaymentCb";
import PaymentCheck from "./PaymentCheck";
import useStore from "../../../context/useStore";
import Control from "../Control";
import PaymentSepa from "./PaymentSepa";
import Loader from "../Loader";
import PaymentChoice from "./PaymentChoice";

const Payment = ({
  params,
  paymentOk,
  setPaymentOk,
  paymentAction,
  policyDatas,
  priceToPay,
  hasSavedPaymentMethods,
  setDisplayPaymentForm,
}) => {
  const [modeP, setModeP] = useState();

  useEffect(() => {
    document.querySelector(".payment-module").scrollIntoView();
  }, []);

  const validatePayment = (params) => {
    setPaymentOk(params);
  };

  return (
    <div className="payment-module animated fadeInUp fast">
      {hasSavedPaymentMethods && (
        <div className="w-100 text-center">
          <button
            className="btn btn-default"
            onClick={() => setDisplayPaymentForm(false)}
          >
            <i className="fa fa-arrow-left mr-2" />
            Voir mes moyens de paiement enregistrés
          </button>
        </div>
      )}
      <h4 className="d-center flex-column text-center" style={{ fontSize: 22 }}>
        Mode de paiement
        <i className="fa fa-chevron-down my-2 animated fadeInDown fast" />
      </h4>
      <div className=" position-relative">
        <PaymentChoice modeP={modeP} setModeP={setModeP} />

        <div className="mt-3">
          {modeP == "CB" && (
            <PaymentCB
              params={params}
              validatePayment={validatePayment}
              paymentAction={paymentAction}
            />
          )}
          {modeP == "CHECK" && (
            <PaymentCheck
              params={params}
              validatePayment={validatePayment}
              paymentAction={paymentAction}
              priceToPay={priceToPay}
            />
          )}
          {modeP == "SEPA" && (
            <PaymentSepa
              policyDatas={policyDatas}
              params={params}
              validatePayment={validatePayment}
              paymentAction={paymentAction}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
