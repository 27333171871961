import React, { useState } from "react";
import TimelineFiltered from "../../common/Timeline/TimelineFiltered";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";

const MyTimeline = () => {
  const [state, dispatch] = useStore();
  const user = state.auth.user;
  const [filtersOpen, setFiltersOpen] = useState(false);

  return (
    <div className="  my-5 mx-4 mx-md-5 pb-5">
      <div>
        <p className="custom-p ">
          <strong>Visualisez ici</strong> l'historique des actions effectuées
          dans votre Espace client : emails, documents, contrats, utilisateurs,
          etc.
        </p>
        <p
          className="custom-p d-flex align-items-center"
          style={{ fontSize: 16, lineHeight: "26px" }}
        >
          <strong className="mr-2">
            <span style={{ textTransform: "capitalize" }}>
              {user.firstname}
            </span>
            ,{" "}
            {filtersOpen
              ? "personnalisez votre recherche."
              : "voici les derniers événements de votre Timeline."}
          </strong>
          <img
            src="/images/sablier.png"
            style={{ height: 30, position: "relative", bottom: 5 }}
          />
        </p>
      </div>
      {state.auth.user.id && (
        <TimelineFiltered
          showFilters={true}
          title=""
          autoSearch={true}
          setFiltersOpen={setFiltersOpen}
          initialSearch={{
            model: "users",
            id: "me",
            searchString: "",
            action: "",
            type: "",
            beginAt: DateTime.local().minus({ months: 1 }).startOf("day"),
            endAt: DateTime.local().endOf("day"),
          }}
        />
      )}
    </div>
  );
};

export default MyTimeline;
