import tools from "../../../../../../../../../../helpers/tools";
import Loader from "../../../../../../../../../common/Loader";

const OlderDocs = ({
  document,
  onDownload,
  onRemove,
  isDownloadingDocId,
  isRemovingDocId,
  checkCanModify = () => {},
  dataPriv = "",
}) => {
  return (
    <ul className="h-100 list-group">
      {document.map((doc, docIndex) => {
        if (docIndex == 0) return null;
        return (
          <li
            className="list-group-item p-1 d-flex align-items-center justify-content-between border-top-0 border-left-0 border-right-0 rounded-0 border-bottom"
            key={`olderdoc${doc.id}`}
          >
            <div>{tools.formatDate(doc.uploadedAt)}</div>
            <div className="d-center ">
              <button
                className="btn btn-sm btn-link"
                onClick={() => onDownload(doc)}
              >
                {isDownloadingDocId == doc.id ? (
                  <Loader />
                ) : (
                  <i className="fa fa-download text-primary" />
                )}
              </button>
              {checkCanModify(doc) && (
                <button
                  data-priv={dataPriv}
                  className="btn btn-sm btn-link"
                  onClick={() => onRemove(doc.id)}
                >
                  {isRemovingDocId == doc.id ? (
                    <Loader />
                  ) : (
                    <i className="fa fa-times text-danger" />
                  )}
                </button>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default OlderDocs;
