import { DateTime } from "luxon";
import React from "react";
import tools from "../../../../../../helpers/tools";
import EmailLink from "../../../../../common/EmailLink/EmailLink";
import LabelValuePair from "../../../../../common/LabelValuePair/LabelValuePair";
import PhoneLink from "../../../../../common/PhoneLink/PhoneLink";
import SaveBtn from "../../../../../common/SaveBtn";
import useClientItem from "../useClientItem";
import useContextualTimeline from "../useContextualTimeline/useContextualTimeline";
import useUserInfos from "./useUserInfos";

const UserStatus = () => {
  const { client } = useClientItem();

  const textColorCN = client.isActivated ? "text-info" : "text-danger";
  const iconCN = client.isActivated ? "fa-check" : "fa-times";
  const text = client.isActivated ? "Compte activé" : "Compte non activé";

  return (
    <div className={textColorCN}>
      <i className={`fa mr-2 ${iconCN}`} />
      {text}
    </div>
  );
};

const ActivationStatus = () => {
  const { client } = useClientItem();

  const tokenAt = client.activationTokenSendedAt;
  const textColorCN = tokenAt ? "text-info" : "text-danger";
  const text = tokenAt
    ? `Activation envoyée ${tools.formatDateHours(tokenAt)}`
    : "Activation non envoyée";

  return (
    <div className={textColorCN}>
      <i className={`fa mr-2 fa-envelope`} />
      {text}
    </div>
  );
};

const UserInfos = () => {
  const { client } = useUserInfos();
  const { openTimeline } = useContextualTimeline();

  return (
    <div className="row">
      <div className="col-md-4 col-12">
        <div>
          <strong>{tools.getFullName(client)}</strong>
        </div>
        <div>
          <PhoneLink phone={client.phone} />
        </div>
        <div className="mb-1">
          <EmailLink email={client.email} />
        </div>
        <div>
          <LabelValuePair label="Créé le" value={tools.formatDate(client.createdAt)} />
        </div>
      </div>
      <div className="col-md-4 col-12">
        <UserStatus />
        <ActivationStatus />
      </div>
      <div className="col-md-4 col-12">
        <button
          className="btn btn-default border mt-1 w-100 font-weight-normal btn-sm"
          onClick={() => openTimeline({ model: "users", id: client.id, searchString: "" })}
        >
          Événements du compte
        </button>
      </div>
    </div>
  );
};

export default UserInfos;
