import React from "react";

const UserExistsMessage = ({ userIsMine, user, setCheckResult, approve }) => {
  return !userIsMine ? (
    <div className="mt-4">
      <p>
        <strong>Cet email est déjà associé à un compte Perceval.</strong> Êtes-vous sûr de vouloir envoyer l'invitation
        ?
      </p>
      <div className="d-flex justify-content-around align-items-center">
        <button className="btn btn-primary btn-sm px-2" onClick={approve}>
          Oui
        </button>
        <button className="btn btn-light btn-sm px-2" onClick={() => setCheckResult(false)}>
          Non
        </button>
      </div>
    </div>
  ) : (
    <div className="mt-4">
      <p>
        <strong>Cet email est déjà associé à un de vos utilisateurs Perceval.</strong> Est-ce bien cet utilisateur que
        vous souhaitez modifier ?
      </p>
      <div className="d-flex justify-content-around align-items-center">
        <button
          className="btn btn-primary btn-sm px-2"
          onClick={() => {
            window.location.href = `/accounts/${user.id}`;
          }}
        >
          Oui
        </button>
        <button className="btn btn-light btn-sm px-2" onClick={() => setCheckResult(false)}>
          Non
        </button>
      </div>
    </div>
  );
};

export default UserExistsMessage;
