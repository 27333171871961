import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import Axios from "axios";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";
import TimelineFiltered from "../../common/Timeline/TimelineFiltered";
import { DateTime } from "luxon";

const TabAccount = ({ user, setUsers, updateUsersData, resendActivationMail, isSendingMail, isBOA }) => {
  const [userData, setUserData] = useState(JSON.parse(JSON.stringify(user)));
  const [errors, setErrors] = useState({});
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [phoneDisabled, setPhoneDisabled] = useState(true);
  const [isSavingEmail, setIsSavingEmail] = useState(false);
  const [isSavedEmail, setIsSavedEmail] = useState(false);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isSavedUser, setIsSavedUser] = useState(false);
  const [isSavingPhone, setIsSavingPhone] = useState(false);
  const [isSavedPhone, setIsSavedPhone] = useState(false);
  const [pwdIdentical, setPwdIdentical] = useState(false);
  const [isAllClients, setIsAllClients] = useState(false);

  useEffect(() => {
    setIsSavedEmail(false);
    setIsSavedPhone(false);
  }, [userData]);

  function updateUser(e) {
    setIsSavedEmail(false);
    setIsSavedUser(false);
    setIsSavedPhone(false);
    var { name, value } = e.target;
    var us = JSON.parse(JSON.stringify(userData));

    us[name] = value;
    checkPwdIdentical(us);
    setUserData(us);
  }

  function saveUser() {
    setErrors({});
    setIsSavingUser(true);
    Axios.patch(API_URL + "customers/" + userData.id, userData)
      .then((res) => {
        setIsSavedUser(true);
        toast.success("Les informations de l'utilisateur ont bien été modifiées");
        updateUsersData(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSavingUser(false);
      });
  }

  function saveEmail() {
    setIsSavingEmail(true);
    Axios.patch(API_URL + "customers/" + userData.id + "/email", userData)
      .then((res) => {
        setIsSavedEmail(true);
        toast.success("L'email de l'utilisateur a bien été modifié");
        updateUsersData(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSavingEmail(false);
      });
  }
  function savePhone() {
    setIsSavingPhone(true);
    Axios.patch(API_URL + "customers/" + userData.id + "/phone", {
      ...userData,
      isAllClients: isAllClients,
    })
      .then((res) => {
        setIsSavedPhone(true);
        toast.success("Le numéro de téléphone de l'utilisateur a bien été modifié");
        updateUsersData(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSavingPhone(false);
      });
  }
  function checkPwdIdentical(ac) {
    if (ac.password == ac.password_confirm && ac.password && ac.password != "" && !pwdIdentical) {
      setPwdIdentical(true);
    } else {
      setPwdIdentical(false);
    }
  }
  function activateMail() {
    setErrors({});
    setIsSavingUser(true);
    Axios.patch(API_URL + "customers/" + userData.id, {
      ...userData,
      isActivated: true,
    })
      .then((res) => {
        setIsSavedUser(true);
        toast.success("Le compte de l'utilisateur a bien été activé");
        updateUsersData(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSavingUser(false);
      });
  }

  return (
    <div className="d-flex flex-wrap m-auto" style={{ maxWidth: 900 }}>
      <div className="col-12 col-md-6">
        <Control
          label="Civilité"
          name="gender"
          type="btnList"
          btnInline
          datas={[
            { id: 1, name: "Monsieur" },
            { id: 2, name: "Madame" },
          ]}
          value={userData.gender}
          change={updateUser}
          error={errors}
        />
        <Control label="Prénom" name="firstname" value={userData.firstname} change={updateUser} error={errors} />
        <Control label="Nom" name="lastname" value={userData.lastname} change={updateUser} error={errors} />

        {/* <SaveBtn
          isSaved={isSavedUser}
          isSaving={isSavingUser}
          save={saveUser}
          text="Enregistrer"
        /> */}
      </div>
      <div className="col-12  col-md-6 " style={{ marginTop: 64 }}>
        <div className="position-relative emailContainer">
          <Control
            label="Email"
            name="email"
            value={userData.email}
            change={updateUser}
            error={errors}
            disabled={emailDisabled}
          />
          {isBOA && (
            <button
              className="btn btn-primary position-absolute modifyInputBtn"
              onClick={() => setEmailDisabled(!emailDisabled)}
            >
              <i className={"fa mr-2 " + (emailDisabled ? "fa-lock" : "fa-lock-open")} />
              Modifier
            </button>
          )}
          {!emailDisabled && (
            <div className="alert alert-secondary my-2">
              Attention, ceci aura pour effet de modifier l'email lié à chaque entreprise de l'utilisateur dans modul'r.
            </div>
          )}
          {!emailDisabled && (
            <SaveBtn isSaved={isSavedEmail} isSaving={isSavingEmail} save={saveEmail} text="Enregistrer l'email" />
          )}
          <div className="mt-2">
            {user.isActivated ? (
              <span className="text-success">
                <i className="fa fa-check-double mr-2" title="Email vérifié" />
                Activé
              </span>
            ) : isBOA ? (
              <>
                {!user.activationTokenSendedAt || user.activationTokenSendedAt == "" ? (
                  <i className="fa fa-lock text-danger" />
                ) : (
                  <i className="fa fa-lock-open text-warning" />
                )}
                <button
                  className="btn btn-primary btn-sm btn-warning ml-2  shadow-sm  btn-xsm resendMail"
                  onClick={() => resendActivationMail(userData.id)}
                  title="Renvoyer l'email d'activation"
                >
                  <i className="fa fa-envelope mr-1" />
                  {isSendingMail ? "Envoi en cours..." : "Renvoyer l'email d'activation"}
                </button>
                <button
                  className="btn btn-primary btn-sm btn-primary ml-1  shadow-sm  btn-xsm"
                  onClick={activateMail}
                  title="Forcer l'activation du mail"
                >
                  <i className="fa fa-user-check mr-1" />
                  Forcer l'activation
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="position-relative emailContainer">
          <Control
            label="Téléphone"
            name="phone"
            value={userData.phone}
            change={updateUser}
            error={errors}
            disabled={phoneDisabled}
          />
          {isBOA && (
            <button
              className="btn btn-primary position-absolute modifyInputBtn"
              onClick={() => setPhoneDisabled(!phoneDisabled)}
            >
              <i className={"fa mr-2 " + (phoneDisabled ? "fa-lock" : "fa-lock-open")} />
              Modifier
            </button>
          )}
          {!phoneDisabled && (
            <>
              <div className="alert alert-secondary my-2">
                Attention, ceci aura pour effet de modifier le téléphone sur lequel les réinitialisations de mot de
                passe seront envoyées.
              </div>
              <Control
                margin={"mt-2 mb-3"}
                type="checkbox"
                label="Mettre à jour toutes les entreprises de cet utilisateur sur Modul'r"
                checked={isAllClients}
                change={(e) => setIsAllClients(e.target.checked)}
              />
            </>
          )}
          {!phoneDisabled && (
            <SaveBtn
              isSaved={isSavedPhone}
              isSaving={isSavingPhone}
              save={savePhone}
              text="Enregistrer le numéro de téléphone"
            />
          )}
        </div>
      </div>
      <div className="col-12">
        <TimelineFiltered
          className="mt-4"
          showFilters={true}
          initialSearch={{
            model: "users",
            id: userData.id,
            searchString: "",
            beginAt: DateTime.local().minus({ months: 1 }).startOf("day"),
            endAt: DateTime.local().endOf("day"),
            type: "",
          }}
        />
      </div>
    </div>
  );
};

export default TabAccount;
