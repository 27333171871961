import { atom, useAtom } from "jotai";
import dataService from "../../../../../../../../../helpers/dataService";

const companyDocumentsAtom = atom([]);
const isLoadingDocumentsAtom = atom(false);

const useCompanyDocuments = () => {
  const [companyDocuments, setCompanyDocuments] = useAtom(companyDocumentsAtom);
  const [isLoadingDocuments, setIsLoadingDocuments] = useAtom(isLoadingDocumentsAtom);

  const getCompanyDocuments = (companyId) => {
    setIsLoadingDocuments(true);
    dataService.get(
      `clients/${companyId}/files`,
      setCompanyDocuments,
      (err) => {},
      () => setIsLoadingDocuments(false)
    );
  };

  return {
    documentState: {
      isLoadingDocuments,
      companyDocuments,
    },
    actions: {
      getCompanyDocuments,
    },
  };
};

export default useCompanyDocuments;
