import { useState } from "react";
import GreyCard from "../Dashboard/GreyCard";
import ContractItem from "./ContractItem";
import PaymentFormQuittances from "./PaymentFormQuittances/PaymentFormQuittances";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";

const ClientItem = ({
  client,
  onSelectClientToPay,
  onDeselectClientToPay,
  selected,
  disabled,
  onPaidQuittances,
}) => {
  const [selectedQuittances, setSelectedQuittances] = useState([
    ...client.contrats.map((c) => c.quittances).flat(),
  ]);
  const clientContracts = client.contrats;

  const onToggleQuittance = (quittance) => {
    if (selectedQuittances.find((q) => q.id === quittance.id)) {
      setSelectedQuittances(
        selectedQuittances.filter((q) => q.id !== quittance.id)
      );
    } else {
      setSelectedQuittances([...selectedQuittances, quittance]);
    }
  };

  //check in client.contrats array if all quittances are paid
  const areAllQuittancesPaid = clientContracts.every((contract) =>
    contract.quittances.every((q) => q.isPaid)
  );

  const setPaymentOk = (data) => {
    setSelectedQuittances([]);
    onPaidQuittances(data.quittancesIds);
  };

  //montantTotalQuittance adds up the total amount of all quittances selected
  //we must find the quittance in the contract.quittances array to get the restantDu

  const montantTotalQuittance = selectedQuittances.reduce(
    (acc, quittance) => acc + quittance.restantDu,
    0
  );
  const hiddenPaymentModes = montantTotalQuittance >= 10000 ? ["SEPA"] : [];

  return (
    <GreyCard disabled={disabled || areAllQuittancesPaid}>
      <h3
        className={`font-weight-bold ${
          selectedQuittances.length > 0 ? "text-success" : "text-grey"
        }`}
      >
        {client.client.nomSociete}
      </h3>
      {clientContracts.map((contract, indx) => {
        return (
          <div
            className={`w-100 mb-2 py-2 d-flex align-items-end justify-content-start align-items-md-start justify-content-md-between flex-row flex-wrap ${
              indx === clientContracts.length - 1 ? "" : `border-bottom`
            }`}
            key={contract.id}
          >
            <ContractItem
              key={contract.id}
              contrat={contract}
              selectedQuittances={selectedQuittances}
              onToggleQuittance={onToggleQuittance}
              disabled={disabled || selected}
            />
          </div>
        );
      })}
      {selected ? (
        <div className="text-left">
          <div className="text-right">
            <button
              className="btn btn-sm text-primary cursor-pointer"
              onClick={onDeselectClientToPay}
              style={{ fontSize: 12 }}
            >
              {selectedQuittances.length} quittance
              {selectedQuittances.length > 1 && "s"} sélectionnée
              {selectedQuittances.length > 1 && "s"}
              <i className="fa fa-times ml-2" />
            </button>
          </div>
          <div className="my-3">
            <PaymentFormQuittances
              client={client.client}
              quittances={selectedQuittances}
              setPaymentOk={setPaymentOk}
              hiddenPaymentModes={hiddenPaymentModes}
            />
          </div>
        </div>
      ) : areAllQuittancesPaid ? (
        ""
      ) : (
        <div className="text-right">
          {selectedQuittances.length > 0 &&
          parseFloat(montantTotalQuittance) > 0 ? (
            <button
              className="btn btn-primary"
              disabled={disabled}
              onClick={() => onSelectClientToPay(client, selectedQuittances)}
            >
              Payer{" "}
              {tools.truenumberWithSpaces(
                parseFloat(montantTotalQuittance).toFixed(2)
              )}{" "}
              €
            </button>
          ) : (
            <div
              className="font-italic d-flex align-items-center justify-content-end text-grey"
              style={{ height: 44 }}
            >
              Sélectionnez au moins une quittance à payer
            </div>
          )}
        </div>
      )}
    </GreyCard>
  );
};

export default ClientItem;
