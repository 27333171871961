import React, { useEffect, useState } from "react";
import CheckboxNumber from "../../../../common/CheckboxNumber/CheckboxNumber";
import Control from "../../../../common/Controls/Control";
import Loader from "../../../../common/Loader";
import useCalculatedValues from "../Accident/useCalculatedValues";
import BlockWrapper from "../BlockWrapper";
import useFormState from "../useFormState";

const AccidentInputs2 = () => {
  const { constants, actions, errors, formData, inputsConfig, apiData } = useFormState();
  const onBlur = () => {};
  const inputState = formData.accident2Inputs;

  const updateMrpData = (e) => {
    const { name, value, type, checked } = e.target;

    var realValue = type == "checkbox" ? (checked ? true : false) : value;
    actions.updateAccident2Inputs({ [name]: realValue });
  };
  const { calculatedValues, isCalcLoading } = useCalculatedValues({
    company: { ape: apiData.ape, state: formData },
  });

  const updateMrpDataStealDamage = (e) => {
    const { name, value, type, checked } = e.target;
    var realValue = type == "checkbox" ? (checked ? true : false) : value;
    let updateObj = {
      [name]: realValue,
      isStealInCheckoutDamage: realValue,
      isStealInChestDamage: realValue,
      isAggressionDamage: realValue,
    };
    actions.updateFormData({ accident2Inputs: { ...inputState, ...updateObj } });
  };

  useEffect(() => {
    let updateObj = {};

    Object.keys(calculatedValues).forEach((valkey) => {
      if (!inputState[valkey]) {
        updateObj[valkey] = calculatedValues[valkey].value;
      }
    });
    actions.updateFormData({ accident2Inputs: { ...inputState, ...updateObj } });
  }, [calculatedValues]);

  return (
    <BlockWrapper>
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isElectricalDamage.label}
          numberLabel={inputsConfig.electricalDamageValue.label}
          checkboxName="isElectricalDamage"
          numberName="electricalDamageValue"
          isChecked={inputState.isElectricalDamage}
          trueValue={inputState.electricalDamageValue}
          nbValue={inputState.electricalDamageValue || calculatedValues.electricalDamageValue.value}
          defaultValue={calculatedValues.electricalDamageValue.value}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Accidents d’ordre électrique et chute de la foudre sur le matériel, les machines électriques ou électroniques."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.electricalDamageValue.min),
            max: parseInt(calculatedValues.electricalDamageValue.max),
            step: (1 * parseInt(calculatedValues.electricalDamageValue.max)) / 100,
          }}
        />
      )}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isWaterDamage.label}
          numberLabel={inputsConfig.waterDamageValue.label}
          isChecked={inputState.isWaterDamage}
          nbValue={inputState.waterDamageValue || calculatedValues.waterDamageValue.value}
          trueValue={inputState.waterDamageValue}
          defaultValue={calculatedValues.waterDamageValue.value}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isWaterDamage"
          numberName="waterDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Dommages consécutifs à l’action de l’eau comme une fuite, un débordement, une infiltration, etc."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.waterDamageValue.min),
            max: parseInt(calculatedValues.waterDamageValue.max),
            step: (1 * parseInt(calculatedValues.waterDamageValue.max)) / 100,
          }}
        />
      )}

      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isStealDamage.label}
          numberLabel={inputsConfig.stealDamageValue.label}
          isChecked={inputState.isStealDamage}
          nbValue={inputState.stealDamageValue || calculatedValues.stealDamageValue.value}
          trueValue={inputState.stealDamageValue}
          defaultValue={calculatedValues.stealDamageValue.value}
          setIsChecked={updateMrpDataStealDamage}
          setNbValue={updateMrpData}
          checkboxName="isStealDamage"
          numberName="stealDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Valeur du contenu à assurer en cas de vol de vos meubles, matériels et outils professionnels, marchandise destinée à la vente."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.stealDamageValue.min),
            max: parseInt(calculatedValues.stealDamageValue.max),
            step: (1 * parseInt(calculatedValues.stealDamageValue.max)) / 100,
          }}
        />
      )}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <>
          <Control
            label={
              <>
                <div style={{ color: "black" }}>
                  <strong style={{ fontWeight: "625" }}>
                    {inputState.isStealDamage ? (
                      <i className="fa fa-check" style={{ fontSize: "20px" }} />
                    ) : (
                      <i className="fa fa-times" style={{ fontSize: "20px" }} />
                    )}
                    {"  "} {inputsConfig.stealInCheckoutDamageValue.label}
                  </strong>
                </div>
              </>
            }
            type="range"
            name="stealInCheckoutDamageValue"
            value={
              inputState.stealInCheckoutDamageValue ||
              calculatedValues.stealInCheckoutDamageValue.value
            }
            displayInput={inputState.isStealDamage}
            change={updateMrpData}
            error={errors}
            labelClassname="w-100"
            className="w-100"
            after={"€"}
            onBlur={onBlur}
            inputParams={{
              min: parseInt(calculatedValues.stealInCheckoutDamageValue.min),
              max: parseInt(calculatedValues.stealInCheckoutDamageValue.max),
              step: (1 * parseInt(calculatedValues.stealInCheckoutDamageValue.max)) / 100,
            }}
            info="Valeur assurée en cas d’effraction ou de vol sur votre caisse, tiroirs-caisses, caisses enregistreuses."
          />
          {isCalcLoading ? (
            <Loader />
          ) : (
            <Control
              label={
                <>
                  <div style={{ color: "black" }}>
                    <strong style={{ fontWeight: "625" }}>
                      {inputState.isStealDamage ? (
                        <i className="fa fa-check" style={{ fontSize: "20px" }} />
                      ) : (
                        <i className="fa fa-times" style={{ fontSize: "20px" }} />
                      )}
                      {"  "}
                      {inputsConfig.stealInChestDamageValue.label}
                    </strong>
                  </div>
                </>
              }
              className="w-100"
              type="range"
              name="stealInChestDamageValue"
              displayInput={inputState.isStealDamage}
              value={
                inputState.stealInChestDamageValue || calculatedValues.stealInChestDamageValue.value
              }
              change={updateMrpData}
              error={errors}
              labelClassname="w-100"
              after={"€"}
              onBlur={onBlur}
              inputParams={{
                min: parseInt(calculatedValues.stealInChestDamageValue.min),
                max: parseInt(calculatedValues.stealInChestDamageValue.max),
                step: (1 * parseInt(calculatedValues.stealInChestDamageValue.max)) / 100,
              }}
              info="Valeur assurée en cas d’effraction et de vol de votre coffre fort."
            />
          )}
          {isCalcLoading ? (
            <Loader />
          ) : (
            <Control
              label={
                <>
                  <div style={{ color: "black" }}>
                    <strong style={{ fontWeight: "625" }}>
                      {inputState.isStealDamage ? (
                        <i className="fa fa-check" style={{ fontSize: "20px" }} />
                      ) : (
                        <i className="fa fa-times" style={{ fontSize: "20px" }} />
                      )}
                      {"  "}
                      {inputsConfig.aggressionDamageValue.label}
                    </strong>
                  </div>
                </>
              }
              className="w-100"
              type="range"
              displayInput={inputState.isStealDamage}
              name="aggressionDamageValue"
              value={
                inputState.aggressionDamageValue || calculatedValues.aggressionDamageValue.value
              }
              change={updateMrpData}
              error={errors}
              labelClassname="w-100"
              after={"€"}
              onBlur={onBlur}
              inputParams={{
                min: parseInt(calculatedValues.aggressionDamageValue.min),
                max: parseInt(calculatedValues.aggressionDamageValue.max),
                step: (1 * parseInt(calculatedValues.aggressionDamageValue.max)) / 100,
              }}
              info="Valeur assurée en cas d’agression lors d’un transfert de fond."
            />
          )}
        </>
      )}
      <Control
        label={inputsConfig.protectionAgainstSteal.label}
        name="protectionAgainstSteal"
        type="btnList"
        datas={inputsConfig.protectionAgainstSteal.datas}
        dataIndex="id"
        dataLabel="name"
        dataLabel2="description"
        value={inputState.protectionAgainstSteal}
        change={updateMrpData}
        btnInline
        itemClassName="justify-content-start align-items-start "
        dataIcon="icon"
        error={errors}
        onBlur={onBlur}
      />
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isIceBreakDamage.label}
          numberLabel={inputsConfig.iceBreakDamageValue.label}
          isChecked={inputState.isIceBreakDamage}
          nbValue={inputState.iceBreakDamageValue || calculatedValues.iceBreakDamageValue.value}
          trueValue={inputState.iceBreakDamageValue}
          defaultValue={calculatedValues.iceBreakDamageValue.value}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isIceBreakDamage"
          numberName="iceBreakDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Valeur assurée en cas de dommages sur vos vitrines, fenêtres, portes vitrées, etc."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.iceBreakDamageValue.min),
            max: parseInt(calculatedValues.iceBreakDamageValue.max),
            step: (1 * parseInt(calculatedValues.iceBreakDamageValue.max)) / 100,
          }}
        />
      )}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isSignBreakDamage.label}
          numberLabel={inputsConfig.signBreakDamageValue.label}
          isChecked={inputState.isSignBreakDamage}
          nbValue={inputState.signBreakDamageValue || calculatedValues.signBreakDamageValue.value}
          trueValue={inputState.signBreakDamageValue}
          defaultValue={calculatedValues.signBreakDamageValue.value}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isSignBreakDamage"
          numberName="signBreakDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo=" Dommages sur vos enseignes lumineuses ou non lumineuses."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.signBreakDamageValue.min),
            max: parseInt(calculatedValues.signBreakDamageValue.max),
            step: (1 * parseInt(calculatedValues.signBreakDamageValue.max)) / 100,
          }}
        />
      )}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isTransportedGoodsDamage.label}
          numberLabel={inputsConfig.transportedGoodsDamageValue.label}
          isChecked={inputState.isTransportedGoodsDamage}
          nbValue={
            inputState.transportedGoodsDamageValue ||
            calculatedValues.transportedGoodsDamageValue.value
          }
          trueValue={inputState.transportedGoodsDamageValue}
          defaultValue={calculatedValues.transportedGoodsDamageValue.value}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isTransportedGoodsDamage"
          numberName="transportedGoodsDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Dommages et pertes qui résultent du transport, du chargement ou du déchargement de marchandises."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.transportedGoodsDamageValue.min),
            max: parseInt(calculatedValues.transportedGoodsDamageValue.max),
            step: (1 * parseInt(calculatedValues.transportedGoodsDamageValue.max)) / 100,
          }}
        />
      )}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isHardwareDamage.label}
          numberLabel={inputsConfig.hardwareDamageValue.label}
          isChecked={inputState.isHardwareDamage}
          nbValue={inputState.hardwareDamageValue || calculatedValues.hardwareDamageValue.value}
          defaultValue={calculatedValues.hardwareDamageValue.value}
          trueValue={inputState.hardwareDamageValue}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isHardwareDamage"
          numberName="hardwareDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Dommages sur vos ordinateurs portables et de bureau, tablettes, photocopieurs."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.hardwareDamageValue.min),
            max: parseInt(calculatedValues.hardwareDamageValue.max),
            step: (1 * parseInt(calculatedValues.hardwareDamageValue.max)) / 100,
          }}
        />
      )}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isMachineDamage.label}
          numberLabel={inputsConfig.machineDamageValue.label}
          isChecked={inputState.isMachineDamage}
          nbValue={inputState.machineDamageValue || calculatedValues.machineDamageValue.value}
          defaultValue={calculatedValues.machineDamageValue.value}
          trueValue={inputState.machineDamageValue}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isMachineDamage"
          numberName="machineDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Dommages sur vos machines et équipements techniques ou industriels."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.machineDamageValue.min),
            max: parseInt(calculatedValues.machineDamageValue.max),
            step: (1 * parseInt(calculatedValues.machineDamageValue.max)) / 100,
          }}
        />
      )}
      {inputState.isFreezeRoom == 1 &&
        (isCalcLoading ? (
          <Loader />
        ) : (
          <CheckboxNumber
            checkboxLabel={inputsConfig.isFreezeRoomGoodsDamage.label}
            numberLabel={inputsConfig.freezeRoomGoodsDamageValue.label}
            isChecked={inputState.isFreezeRoomGoodsDamage}
            nbValue={
              inputState.freezeRoomGoodsDamageValue ||
              calculatedValues.freezeRoomGoodsDamageValue.value
            }
            trueValue={inputState.freezeRoomGoodsDamageValue}
            defaultValue={calculatedValues.freezeRoomGoodsDamageValue.value}
            setIsChecked={updateMrpData}
            setNbValue={updateMrpData}
            checkboxName="isFreezeRoomGoodsDamage"
            numberName="freezeRoomGoodsDamageValue"
            error={errors}
            onBlur={onBlur}
            checkboxInfo="Valeur assurée en cas de perte des marchandises périssables, entreposées dans les appareils de la chaîne du froid."
            numberInfo=""
            inputParams={{
              min: parseInt(calculatedValues.freezeRoomGoodsDamageValue.min),
              max: parseInt(calculatedValues.freezeRoomGoodsDamageValue.max),
              step: (1 * parseInt(calculatedValues.freezeRoomGoodsDamageValue.max)) / 100,
            }}
          />
        ))}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isOperatingLossDamage.label}
          numberLabel={inputsConfig.operatingLossDamageValue.label}
          isChecked={inputState.isOperatingLossDamage}
          nbValue={
            inputState.operatingLossDamageValue || calculatedValues.operatingLossDamageValue.value
          }
          trueValue={inputState.operatingLossDamageValue}
          defaultValue={calculatedValues.operatingLossDamageValue.value}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isOperatingLossDamage"
          numberName="operatingLossDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Assurer la perte de marge brute, commissions, honoraires en cas de d’arrêt d’activité suite à un sinistre garanti. Pour faire face aux charges, salaires, impôts, loyers, etc."
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.operatingLossDamageValue.min),
            max: parseInt(calculatedValues.operatingLossDamageValue.max),
            step: (1 * parseInt(calculatedValues.operatingLossDamageValue.max)) / 100,
          }}
        />
      )}
      {isCalcLoading ? (
        <Loader />
      ) : (
        <CheckboxNumber
          checkboxLabel={inputsConfig.isGoodwillMarketvalueDamage.label}
          numberLabel={inputsConfig.goodwillMarketValueDamageValue.label}
          isChecked={inputState.isGoodwillMarketvalueDamage}
          nbValue={
            inputState.goodwillMarketValueDamageValue ||
            calculatedValues.goodwillMarketValueDamageValue.value
          }
          trueValue={inputState.goodwillMarketValueDamageValue}
          defaultValue={calculatedValues.goodwillMarketValueDamageValue.value}
          setIsChecked={updateMrpData}
          setNbValue={updateMrpData}
          checkboxName="isGoodwillMarketvalueDamage"
          numberName="goodwillMarketValueDamageValue"
          error={errors}
          onBlur={onBlur}
          checkboxInfo="Assurer la perte de valeur de l’entreprise (droit au bail, pas-de-porte, clientèle, implantation, etc.) après un sinistre. "
          numberInfo=""
          inputParams={{
            min: parseInt(calculatedValues.goodwillMarketValueDamageValue.min),
            max: parseInt(calculatedValues.goodwillMarketValueDamageValue.max),
            step: (1 * parseInt(calculatedValues.goodwillMarketValueDamageValue.max)) / 100,
          }}
        />
      )}
      <Control
        label={inputsConfig.generalFranchise?.label}
        name="generalFranchise"
        type="btnList"
        datas={inputsConfig.generalFranchise?.datas || []}
        dataIndex="id"
        dataLabel="name"
        value={inputState.generalFranchise}
        change={updateMrpData}
        btnInline
        fullWidth
        className="w-100"
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
    </BlockWrapper>
  );
};

export default AccidentInputs2;
