import React from "react";

const RecapCompanyInfos = ({ company, companyType }) => {
  return (
    <div>
      <div className="d-flex flex-row align-items-start w-100">
        <div>
          <i
            className="mr-2 pt-1 fa fa-star"
            style={{
              fontSize: 22,
              color: "#FFBD51",
            }}
          />
        </div>
        <div
          style={{
            overflow: "hidden",
            fontSize: 16,
          }}
        >
          <div>
            <strong
              style={{
                width: "100%",
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
              }}
            >
              {company.company}
            </strong>
          </div>
          <div>
            <p className="m-0">{company.siret}</p>
          </div>
          <div>
            <strong
              className="text-success"
              style={{
                fontSize: 16,
              }}
            >
              {companyType}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecapCompanyInfos;
