import React from "react";

const CheckList = ({ items }) => {
  return (
    <ul style={{ listStyle: "none", paddingLeft: 20, paddingRight: 15 }}>
      {items.map((it, itk) => {
        let isDisabled = it.isDisabled;
        return (
          <li
            key={`checklistitem${itk}${items.length}`}
            className={`${isDisabled ? " text-black-50" : ""}  `}
            style={{ fontSize: 16 }}
          >
            <div className="d-flex justify-content-start align-items-start flex-row">
              <i
                style={{
                  animationDelay: itk * 60 + "ms",
                }}
                className={`${
                  isDisabled
                    ? "far fa-times-circle text-danger"
                    : "fa fa-check text-success animated fadeInLeft faster"
                }  mr-2 pt-1`}
              />
              <div className="">{it.text}</div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CheckList;
