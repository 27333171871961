import { SIDENAV_TYPES } from "../reducers/sidenavReducers";

export const sidenavActions = (state, dispatch, props) => {
  function updateSidenav(modalData) {
    dispatch({ type: SIDENAV_TYPES.UPDATE_SIDENAV_DATA, payload: modalData });
  }

  return {
    updateSidenav,
  };
};
