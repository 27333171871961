import tools from "../../../helpers/tools";
import Control from "../../common/Controls/Control";

const ContractItem = ({
  contrat,
  onToggleQuittance,
  selectedQuittances,
  disabled,
}) => {
  //should filter the selectedQuittances to only show the quittances of the current contract
  //the current contract is the one passed as props and has an array of "quittances"
  const quittancesOfThisContrat = contrat.quittances;
  const hasAtLeast1QuittanceSelected = selectedQuittances.some((q) =>
    quittancesOfThisContrat.find((quittance) => quittance.id === q.id)
  );

  return (
    <>
      <ContratInfos
        contrat={contrat}
        hasAtLeast1QuittanceSelected={hasAtLeast1QuittanceSelected}
      />
      <div className="text-right mt-2 flex-fill align-self-end">
        {contrat.quittances.map((quittance) => {
          const isSelected = selectedQuittances.find(
            (q) => q.id === quittance.id
          );
          const textColor = quittance.isPaid
            ? "text-info"
            : isSelected
            ? ""
            : "text-grey";
          return (
            <div
              key={quittance.id}
              className="d-flex"
              style={{
                gap: 16,
                cursor: "pointer",
                position: "relative",
                left: 12,
              }}
            >
              <div
                onClick={() => {
                  if (!disabled && !quittance.isPaid)
                    onToggleQuittance(quittance);
                }}
                className={`flex-fill ${textColor}`}
                style={{ marginBottom: 5 }}
              >
                <QuittanceInfos quittance={quittance} isSelected={isSelected} />
              </div>
              {quittance.isPaid ? (
                <i
                  className="fa fa-check-double text-info mr-3"
                  style={{
                    marginTop: 6,
                    marginRight: "26px",
                    marginLeft: "10px",
                  }}
                />
              ) : (
                <Control
                  type="checkbox"
                  margin="m-0"
                  name={quittance.id}
                  checked={isSelected}
                  change={() => onToggleQuittance(quittance)}
                  noPaddingOnFormGroup
                  noBg
                  disabled={disabled}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const ContratInfos = ({ contrat, hasAtLeast1QuittanceSelected }) => {
  return (
    <div
      className={`flex-fill d-flex align-items-start 

${hasAtLeast1QuittanceSelected ? "" : "text-grey"}`}
    >
      <div className="flex-fill">
        <div
          className={`${
            hasAtLeast1QuittanceSelected ? "text-success" : ""
          } font-weight-bold`}
        >
          {contrat.typeProduit}
        </div>
        <div>{contrat.titre}</div>
        <div>{contrat.objet}</div>
        <div className="text-sm">N° contrat : {contrat.numero}</div>
        <div className="text-sm">Compagnie : {contrat.compagnie}</div>
      </div>
    </div>
  );
};

const QuittanceInfos = ({ quittance, isSelected }) => {
  const textColor = quittance.isPaid
    ? "text-info"
    : isSelected
    ? "text-primary"
    : "text-grey";

  return (
    <>
      <div
        className={`font-weight-bold ${textColor} flex-fill`}
        style={{ marginTop: 3 }}
      >
        {quittance.isPaid ? (
          "Payée"
        ) : (
          <>
            {tools.truenumberWithSpaces(
              parseFloat(quittance.restantDu).toFixed(2)
            )}{" "}
            € TTC
          </>
        )}
      </div>
      <div className="text-sm">
        pour la période du {tools.formatDate(quittance.debutPeriode)}
      </div>
      <div className="text-sm">au {tools.formatDate(quittance.finPeriode)}</div>
    </>
  );
};

export default ContractItem;
