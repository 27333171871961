import React from "react";

const CheckboxInput = ({
  inputClassName = "",
  disabled = false,
  name,
  k = -1,
  checked = false,
  labelClassname = "",
  change = () => {},
  required = false,
  label = false,
  error = {},
  inputStyle = {},
  checkboxClassname = "",
  ...props
}) => {
  const valueChange = (e) => {
    var filteredValue = e.target.value;
    let changeEvent = {
      target: {
        name: name,
        value: filteredValue,
        checked: e.target.checked,
        type: "checkbox",
        k: k,
      },
    };

    change(changeEvent);
  };

  return (
    <>
      <div
        className={`
          custom-control custom-switch d-flex  flex-column ${checkboxClassname}`}
        style={{ height: 54, ...inputStyle }}
      >
        <input
          type="checkbox"
          className={"custom-control-input " + inputClassName}
          disabled={disabled ? "disabled" : false}
          name={name}
          id={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
          onChange={valueChange}
          checked={checked ? "checked" : false}
        />
        <Label
          label={label}
          k={k}
          name={name}
          required={required}
          labelClassname={labelClassname}
        />
        {props.info && (
          <div
            className="text-muted mt-2 mb-0 d-flex flex-column flex-md-row align-items-center justify-content-start"
            style={{
              fontWeight: "400",
              marginLeft: "-3.375rem",
            }}
          >
            {props.info}
          </div>
        )}
      </div>
      {error[name] && (
        <small
          className={
            "form-text font-weight-bold input-error-msg d-block text-danger animated flash"
          }
        >
          {error[name]}
        </small>
      )}
    </>
  );
};

const Label = ({ label, k, name, required, labelClassname }) => {
  return (
    <label
      className={
        "custom-control-label d-flex align-items-center border-0 " +
        labelClassname
      }
      style={{ cursor: "pointer", minHeight: "0", padding: 0 }}
      htmlFor={
        k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name
      }
    >
      {label && (
        <span className="mb-1 text-success" style={{}}>
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </span>
      )}
    </label>
  );
};

export default CheckboxInput;
