import React from "react";

const PaymentChoice = ({ modeP, setModeP, hiddenPaymentModes = [] }) => {
  const cname =
    "d-center flex-column w-100 h-100 shadow-sm border rounded p-3 font-weight-bold cursor-pointer";

  const activeClass = "bg-primary text-white";
  const defaultClass = "bg-white";

  return (
    <div className="row d-center">
      {!hiddenPaymentModes.includes("CB") && (
        <div className="col-12 col-md-6">
          <div
            className={`${modeP == "CB" ? activeClass : defaultClass} ${cname}`}
            onClick={() => setModeP("CB")}
          >
            <i className="fa fa-credit-card fa-2x mb-2" />
            CARTE BLEUE
          </div>
        </div>
      )}
      {/* <div className="col-12 col-md-4">
        <div
          className={`${
            modeP == "CHECK" ? activeClass : defaultClass
          } ${cname}`}
          onClick={() => setModeP("CHECK")}
        >
          <i className="fa fa-money-check fa-2x mb-2" />
          CHÈQUE
        </div>
      </div> */}
      {!hiddenPaymentModes.includes("SEPA") && (
        <div className="col-12 col-md-6">
          <div
            className={`${
              modeP == "SEPA" ? activeClass : defaultClass
            } ${cname}`}
            onClick={() => setModeP("SEPA")}
          >
            <i className="fa fa-university fa-2x mb-2" />
            VIREMENT BANCAIRE
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentChoice;
