import Control from "../Control";

var cardBrandToPfClass = {
  visa: "pf-visa",
  mastercard: "pf-mastercard",
  amex: "pf-american-express",
  discover: "pf-discover",
  diners: "pf-diners",
  jcb: "pf-jcb",
  unknown: "pf-credit-card",
};

const SavedCBs = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  return paymentMethods.find((p) => p.type == "CB") ? (
    <Control
      type="btnList"
      itemClassName={"justify-content-start"}
      datas={paymentMethods
        .filter((p) => p.type == "CB")
        .map((p) => ({
          ...p,
          icon: " " + cardBrandToPfClass[p.brand],
          expireAt:
            p.expireAt.split("/")[0].length == 1
              ? "0" + p.expireAt.split("/")[0] + "/" + p.expireAt.split("/")[1]
              : p.expireAt,
        }))}
      iconStyle={{ marginRight: 10 }}
      dataIconSet="pf p-2 bg-success text-white rounded  mr-2"
      dataIndex="paymentMethodId"
      dataLabel="info"
      dataLabel2="expireAt"
      dataIcon="icon"
      value={selectedPaymentMethod}
      change={(e) => setSelectedPaymentMethod(e.target.value)}
    />
  ) : (
    <div className="text-muted font-italic">aucune carte enregistrée</div>
  );
};

export default SavedCBs;
