import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Avatar from "../Avatar";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import Sidenav from "./Sidenav";
import "./topheader.scss";
import tools from "../../../helpers/tools";
import Notifications from "./Notifications/Notifications";

const TopHeader = (props) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", documentClick);

    return () => document.removeEventListener("click", documentClick);
  }, []);

  const documentClick = (e) => {
    if (e.target.id != "spec-avatar-dropdown") {
      setUserMenuOpen(false);
    }
  };

  useEffect(() => {
    if (state.auth.isAuthenticated) {
      // window.$(document).ready(function () {
      //   window.$("#sidebarCollapse").on("click", function () {
      //     window.$("#sidebar").toggleClass("active");
      //     window.$(this).toggleClass("active");
      //   });
      // });
    }
  }, [state.auth.isAuthenticated]);
  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);

      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);
  var authData = state.auth;

  const logoutUser = () => {
    localStorage.removeItem("jwtToken");
    window.location.reload();
  };

  return authData.isAuthenticated &&
    !authData.user.mustRenewPassword &&
    state.auth.user.isActivated ? (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-success position-fixed w-100 animated fadeInDown fast"
        style={{ height: 64, top: 0, zIndex: "999" }}
      >
        <div className="container-fluid p-0">
          <ul className="justify-content-end h-100 m-0 d-flex align-items-center w-100">
            {/* <li className="nav-item mr-2 active">
              <NavLink
                className="nav-link text-primary d-flex flex-row align-items-center"
                to="/me"
              >
                {avatarVisible && (
                  <Avatar
                    className="mr-2"
                    width={25}
                    height={25}
                    id={authData.user.id}
                  />
                )}
                {authData.user.firstname + " " + authData.user.lastname}
              </NavLink>
            </li> */}
            <li
              className="nav-item text-white mr-3 list-unstyled cursor-pointer"
              style={{ fontSize: 16, fontWeight: "700", listStyle: "none" }}
            >
              <Notifications />
            </li>
            <li
              className="nav-item text-white mr-2 list-unstyled cursor-pointer"
              style={{ fontSize: 16, fontWeight: "700", listStyle: "none" }}
            >
              <Link
                to="/me"
                style={{
                  textTransform: "capitalize",
                  color: "white",
                }}
              >
                {authData.user.firstname}
              </Link>
            </li>
            <li className="nav-item position-relative list-unstyled">
              <button className="bg-transparent border-0 spec-avatar position-relative">
                <div
                  id="spec-avatar-dropdown"
                  onClick={() => setUserMenuOpen(!userMenuOpen)}
                  className="position-absolute w-100 h-100"
                  style={{
                    top: 0,
                    left: 0,
                  }}
                ></div>
                {avatarVisible && <Avatar width={40} height={40} id={authData.user.id} />}
              </button>
              <ul
                className={`list-group py-3 px-2 bg-white rounded shadow user-menu ${
                  userMenuOpen ? "animated fadeInRight faster" : "hidden"
                }`}
              >
                <li className="list-group-item  border-bottom">
                  <NavLink
                    className="px-0 d-flex align-items-center justify-content-start"
                    onClick={() => setUserMenuOpen(false)}
                    to="/me"
                  >
                    <i className="fa fa-user mr-3" style={{ fontSize: 21 }} />
                    Voir mon profil
                  </NavLink>
                </li>
                <li className="list-group-item  border-bottom">
                  <NavLink
                    className="px-0 d-flex align-items-center justify-content-start"
                    onClick={() => setUserMenuOpen(false)}
                    to="/me?password"
                  >
                    <i className="fa fa-lock mr-3" style={{ fontSize: 21 }} />
                    Modifier le mot de passe
                  </NavLink>
                </li>
                <li className="list-group-item  border-bottom">
                  <NavLink
                    className="px-0 d-flex align-items-center justify-content-start"
                    onClick={() => setUserMenuOpen(false)}
                    to="/me?avatar"
                  >
                    <i className="fa fa-camera mr-3" style={{ fontSize: 21 }} />
                    Changer ma photo
                  </NavLink>
                </li>
                <li className="list-group-item  border-bottom">
                  <NavLink
                    className="px-0 d-flex align-items-center justify-content-start"
                    onClick={() => {
                      tools.openChat();
                      setUserMenuOpen(false);
                    }}
                    to="#"
                  >
                    <i className="fa fa-comment-dots mr-3" style={{ fontSize: 21 }} />
                    Assistance par tchat
                  </NavLink>
                </li>
                <li
                  className="list-group-item pt-3 text-center"
                  onClick={() => actions.logoutUser()}
                >
                  <div className="d-center d-flex">
                    <span>Déconnexion</span>
                    <i className="fa fa-sign-out-alt ml-3" style={{ fontSize: 21 }} />
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  ) : (
    <></>
  );
};

export default TopHeader;
