import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";

const productsAtom = atom([]);

const useProducts = () => {
  const [products, setProducts] = useAtom(productsAtom);

  const onGetProducts = () => {
    dataService.get(`products`, setProducts);
  };

  return {
    products,
    onGetProducts,
  };
};

export default useProducts;
