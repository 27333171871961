import { DateTime } from "luxon";
import React, { useState } from "react";
import dataService from "../../../../helpers/dataService";
import NotifAction from "./NotifAction";

const NotificationItem = ({ notif, k, updateReadStatus }) => {
  const [isRead, setIsRead] = useState(notif.readAt ? true : false);

  const toggleNotifRead = (read) => {
    setIsRead(read);
    updateReadStatus(read, notif.id);
    dataService.patch(`notifications/${notif.id}`, { isRead: read });
  };

  return (
    <div
      className={` animated fadeInDown faster pt-3 pb-2 px-3 mb-3 rounded ${
        !isRead ? "notif-unread cursor-pointer" : ""
      }`}
      style={{
        border: "1px solid #040ca5",
        animationDelay: k * 50 + "ms",
      }}
      onClick={() => {
        if (!isRead) toggleNotifRead(true);
      }}
    >
      <div className="d-flex  d-flex flex-fill">
        <div>
          <div
            className="notif-content "
            dangerouslySetInnerHTML={{ __html: notif.description }}
          />
        </div>
        <div className="position-relative d-flex pl-3 read-toogle">
          <div
            style={{
              width: 17,
              height: 17,
              transition: "400ms",
              boxShadow: isRead ? "none" : "0px 0px 6px -2px black",
              border: isRead ? "none" : "1px solid white",
            }}
            className={`${isRead ? "" : "bg-success rounded-circle shadow-sm"}`}
          >
            <div className="read-tooltip">
              {isRead ? "marquer comme non lu" : "marquer comme lu"}
            </div>
            {isRead ? (
              <i
                className="fa fa-check cursor-pointer"
                style={{ color: "#939393" }}
                onClick={() => toggleNotifRead(false)}
              />
            ) : null}
          </div>
        </div>
      </div>
      {notif.actionToDo.type ? <NotifAction action={notif.actionToDo} /> : null}
      <div
        className="d-flex justify-content-end pt-2"
        style={{
          color: "#939393",
          fontSize: 12,
          lineHeight: "20px",
          fontWeight: "400",
        }}
      >
        {DateTime.fromISO(notif.createdAt)
          .setLocale("FR")
          .toFormat("dd MMMM yyyy")}
      </div>
    </div>
  );
};

export default NotificationItem;
