import React, { useRef, useEffect, useState } from "react";
import Axios from "axios";
import { API_URL } from "../../config";
import useStore from "../../context/useStore";

const Avatar = ({
  id,
  containerWidth = false,
  containerHeight = false,
  width = 200,
  height = 200,
  className = "",
  imageUrl = false,
  inverted,
  acronymSize = 30,
  acronymFontsize = 12,
  user = false,
}) => {
  const [state, dispatch] = useStore();
  const [imageSrc, setImageSrc] = useState("/images/default-avatar.jpg");
  const [showInitial, setShowInitial] = useState(false);

  const getAcronym = () => {
    var userName = user || state.auth.user.firstname + " " + state.auth.user.lastname;
    var matches = userName.match(/\b(\w)/g); // ['J','S','O','N']
    var acronym = matches.join("");
    if (acronym.length > 2) {
      acronym = acronym.slice(0, 2);
    }
    setShowInitial(acronym);
  };

  useEffect(() => {
    if (id) {
      getImage();
    } else if (imageUrl) {
      setImageSrc(imageUrl);
    }
  }, [id]);

  useEffect(() => {
    var sp = imageSrc.split("/");
    sp = sp[sp.length - 1].split("?");
    sp = sp[0];

    if (["default.jpg"].includes(sp)) {
      getAcronym();
    }
  }, [imageSrc]);

  const getImage = async () => {
    let source = await Axios.get(API_URL + "files/avatars/" + id).catch((err) => err);

    if (source.status !== 200) {
      //gestion erreur
    } else {
      setImageSrc(source.data + "?" + new Date().toISOString());
    }
  };

  return showInitial ? (
    <div
      className={`d-center rounded-circle font-weight-bold p-2 ${
        inverted ? "text-white bg-success" : "text-success bg-white"
      } `}
      style={{
        border: "1px solid",
        width: acronymSize,
        height: acronymSize,
        textTransform: "uppercase",
        fontSize: acronymFontsize,
      }}
    >
      {showInitial}
    </div>
  ) : (
    <div
      className={className}
      style={{
        borderRadius: "100%",
        overflow: "hidden",
        width: containerWidth || width,
        height: containerHeight || height,
        textAlign: "center",
      }}
    >
      <img width={width} height={height} src={imageSrc} alt="Avatar" />
    </div>
  );
};

export default Avatar;
