import React from "react";
import { Link } from "react-router-dom";

const Custom404 = () => {
  return (
    <div
      className="w-100 h-100 position-fixed d-flex align-items-center justify-content-center flex-column text-center"
      style={{
        backgroundImage: "url(/images/page404.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        top: 0,
      }}
    >
      <div
        className="d-center flex-column"
        style={{
          position: "absolute",
          top: "4%",
        }}
      >
        <img src="/images/img404.png" className="mt-5" />

        <div
          style={{
            background: "rgba(255,255,255,0.50)",
            padding: 8,
            width: "min(100%, 375px)",
            fontSize: 21,
            fontWeight: "bold",
          }}
        >
          Diantre ! Vous semblez égaré, cette page n'existe pas sur goperceval.fr
        </div>
      </div>
      <div
        className="position-absolute"
        style={{
          bottom: "12%",
        }}
      >
        <Link to="/accueil" className="btn btn-secondary d-center text-white">
          Revenir à l'accueil
        </Link>
      </div>
    </div>
  );
};

export default Custom404;
