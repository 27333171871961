import React, { useState } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Controls/Control";
import BlockWrapper from "../BlockWrapper";
import useFormState from "../useFormState";

import risksTips from "./risksTips";

const RisksInputs = () => {
  const { constants, actions, errors, formData, inputsConfig } = useFormState();
  const inputState = formData.risksInputs;

  const updateMrpData = (e) => {
    const { name, value } = e.target;

    actions.updateRisksInputs({ [name]: value });
  };
  const onBlur = () => {};

  return (
    <BlockWrapper>
      <Control
        label={inputsConfig.isInsuredLast24Months.label}
        name="isInsuredLast24Months"
        type="btnList"
        datas={inputsConfig.isInsuredLast24Months.datas}
        dataIndex="id"
        value={inputState.isInsuredLast24Months}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
        onBlur={onBlur}
      />
      {inputState.isInsuredLast24Months == 1 && (
        <Control
          name="isContractCanceled"
          label={inputsConfig.isContractCanceled.label}
          type="btnList"
          datas={inputsConfig.isContractCanceled.datas}
          dataIndex="id"
          value={inputState.isContractCanceled}
          change={updateMrpData}
          btnInline
          fullWidth
          labelClassname="w-100"
          error={errors}
          onBlur={onBlur}
        />
      )}
      {inputState.isContractCanceled == 1 && (
        <>
          <Control
            name="contractCanceledBy"
            label={inputsConfig.contractCanceledBy.label}
            type="btnList"
            datas={inputsConfig.contractCanceledBy.datas}
            dataIndex="id"
            value={inputState.contractCanceledBy}
            change={updateMrpData}
            btnInline
            fullWidth
            labelClassname="w-100"
            error={errors}
            onBlur={onBlur}
          />
          <Control
            label={inputsConfig.contractCancelReason.label}
            name="contractCancelReason"
            type="textarea"
            value={inputState.contractCancelReason}
            change={updateMrpData}
            labelClassname="w-100"
            error={errors}
            onBlur={onBlur}
            placeholder="Résilié pour mise en concurrence, sinistre, insatisfaction etc."
          />
        </>
      )}

      <Control
        label={<>Avez-vous déclaré un sinitre au cours des 36 derniers mois ?</>}
        name="isSinisterLast36Months"
        type="btnList"
        datas={inputsConfig.isSinisterLast36Months.datas}
        dataIndex="id"
        value={inputState.isSinisterLast36Months}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
      />
      {inputState.isSinisterLast36Months == 1 && (
        <>
          <Control
            label={inputsConfig.nbSinistersDamage.label}
            name="nbSinistersDamage"
            type="number"
            value={inputState.nbSinistersDamage}
            change={updateMrpData}
            error={errors}
          />

          {inputState.nbSinistersDamage >= 1 && (
            <Control
              label={inputsConfig.sinistersNature.label}
              name="sinistersNature"
              type="textarea"
              value={inputState.sinistersNature}
              change={updateMrpData}
              error={errors}
              labelClassname="w-100"
              placeholder="Nature du/des sinistre(s)"
            />
          )}

          <Control
            label={<>Combien de fois cet étalissement a vu sa Responsabilité Civile mise en cause ?</>}
            name="nbSinistersRC"
            type="number"
            value={inputState.nbSinistersRC}
            change={updateMrpData}
            error={errors}
          />
          {inputState.nbSinistersRC >= 1 && (
            <Control
              label={inputsConfig.sinistersRCNature.label}
              name="sinistersRCNature"
              type="textarea"
              value={inputState.sinistersRCNature}
              change={updateMrpData}
              error={errors}
              labelClassname="w-100"
              info="Préciser si blessures corporelles ou dommages matériels"
              placeholder="Nature du/des sinistre(s)"
            />
          )}
        </>
      )}
      <Control
        label={inputsConfig.isCompanyInLiquidation.label}
        name="isCompanyInLiquidation"
        type="btnList"
        datas={inputsConfig.isCompanyInLiquidation.datas}
        dataIndex="id"
        value={inputState.isCompanyInLiquidation}
        change={updateMrpData}
        btnInline
        fullWidth
        labelClassname="w-100"
        error={errors}
      />
      {tools.isOwner(formData.constructionInputs.qualityOccupant) && (
        <Control
          label={<>Le bien immobilier dont cet établissement est propriétaire est-il hypothéqué ?</>}
          name="isMortgage"
          type="btnList"
          datas={inputsConfig.isMortgage.datas}
          dataIndex="id"
          value={inputState.isMortgage}
          change={updateMrpData}
          btnInline
          fullWidth
          labelClassname="w-100"
          error={errors}
          tips={risksTips.isMortgage}
          info="Immeuble mis en garantie au profit d'un créancier."
        />
      )}
    </BlockWrapper>
  );
};

export default RisksInputs;
