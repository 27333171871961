import React from "react";
import { sidenavActions } from "../../../context/actions/sidenavActions";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import UserCard from "../UserCard/UserCard";

const ProducerButton = ({ producer, ...props }) => {
  const [state, dispatch] = useStore();

  const sActions = sidenavActions(state, dispatch);

  const openProducer = (e) => {
    e.stopPropagation();
    sActions.updateSidenav({
      isOpen: true,
      content: <UserCard user={producer} />,
    });
  };
  if (!producer) return null;

  return (
    <button onClick={openProducer} className="btn btn-link text-left p-0" {...props}>
      {producer.company}{" "}
      {producer.lastname && (
        <>
          - <strong>{tools.getFullName(producer)}</strong>
        </>
      )}
    </button>
  );
};

export default ProducerButton;
