import React from "react";
import SaveBtn from "../../../common/SaveBtn";
import { toast } from "react-toastify";

const FormContainer = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit();
  };

  return (
    <>
      <div
        id="mrpformcontainer"
        style={{
          maxHeight: "calc(100vh - 315px)",
          overflowX: "scroll",
        }}
      >
        {props.children}
      </div>
      <div
        className="d-center w-100 bg-default pb-4 pt-3 border-top w-100"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: "999",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <p className="custom-p mt-3 d-flex align-self-center">
          <strong>Pensez à enregistrer vos modifications</strong>
        </p>
        <SaveBtn text="Enregistrer mes réponses" save={handleSubmit} isSaving={props.isSaving} />
      </div>
    </>
  );
};

export default FormContainer;
