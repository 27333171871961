import React, { useEffect, useState } from "react";
import { modalActions } from "../../context/actions/modalActions";
import { colors } from "../../helpers/colors";
import useFormState from "../pages/ModifyAnswers/MRPForm/useFormState";

const Tips = ({ tips, isPulsing, style = {} }) => {
  const { title, content } = tips;

  const { state, dispatch } = useFormState();
  const modal_actions = modalActions(state, dispatch);
  const [pulsing, setPulsing] = useState(isPulsing);

  const openModal = () => {
    setPulsing(false);

    modal_actions.updateModal({
      isOpen: true,
      title: (
        <>
          <strong style={{ color: colors.primary }}>{title}</strong>
        </>
      ),
      content: <>{content}</>,
    });
  };

  return (
    <div
      style={{
        position: "absolute",
        right: -6,
        top: -6,
        zIndex: 9,
        ...style,
      }}
      className={`cursor-pointer ${pulsing ? "pulsing " : ""}`}
      onClick={openModal}
    >
      <button
        className={`rounded-circle bg-secondary text-white d-center ${pulsing ? "pulsing" : ""}`}
        style={{
          fontSize: "12px",

          height: "25px",
          width: "25px",
          padding: "7px 9px 7px 9.5px",
          overflow: "hidden",
          border: 0,
          outline: "none",
        }}
      >
        <i className={`fa fa-info`} />
      </button>
    </div>
  );
};

export default Tips;
