import React from "react";

const InfoOptFs = () => {
  return (
    <div className="row">
      <div className="col-12">
        <p>En cas de contrôle par : </p>
        <ul>
          <li>l’administration fiscale Française, </li>
          <li>
            ou portant sur les cotisations sociales versées à l’URSSAF et
            organismes assimilés,{" "}
          </li>
          <li>
            et dans le cadre d’éventuels recours, conséquences de ce(s)
            contrôle(s).
          </li>
        </ul>
        <p>Vous êtes couvert pour : </p>
        <ul>
          <li>
            la prise en charge des honoraires d’expert-comptable, les frais et
            honoraires d'avocat et d'huissier de justice, ainsi que les frais de
            procédure,{" "}
          </li>
          <li>avec un engagement maximum fixé à 10 000€ par contrôle,</li>
          <li>
            et pour tout contrôle notifié 2 mois après la souscription de
            l’option (délai de carence).{" "}
          </li>
        </ul>
        <p>Vous n’êtes pas couverts en cas : </p>
        <ul>
          <li>
            de défaut de réponse aux demandes de renseignements, non
            transmission de pièces, retard de déclaration, ou de non-paiement
            des créances à l’administration fiscale, l’URSSAF ou organismes
            assimilés,{" "}
          </li>
          <li>de non tenue de comptabilité ou de comptabilité irrégulière, </li>
          <li>
            d’opposition à un contrôle fiscal ou URSSAF ou à celui d’un
            organisme assimilé,{" "}
          </li>
          <li>
            d’inexactitude, d’insuffisance ou d’omissions de façon délibérée,{" "}
          </li>
          <li>de poursuites pénales. </li>
        </ul>
      </div>
    </div>
  );
};

export default InfoOptFs;
