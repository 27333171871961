import React from "react";
import Loader from "../../common/Loader";

const Invitation = ({
  invitation,
  acceptInvitation,
  declineInvitation,
  isLoadingInvit,
}) => {
  return (
    <div
      className={`d-flex align-items-start justify-content-start flex-column animated fadeIn p-3`}
      style={{
        background: "white",
        borderRadius: 5,
        fontSize: 14,
        lineHeight: "22px",
        marginBottom: 25,
        boxShadow: "0 1px 4px -3px black",
        border: "1px solid #dc5f45",
      }}
    >
      <div
        onClick={() => {}}
        className="w-100"
        style={{
          color: "black",
        }}
      >
        <div className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer">
          <strong
            className="text-danger"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: 19,
              lineHeight: "23px",
            }}
          >
            Invitation
          </strong>
          <i
            className={`fa fa-envelope-open text-danger`}
            style={{ fontSize: 30 }}
          />
        </div>
        <div className="">
          <p style={{ lineHeight: "23px" }}>
            <span style={{ textTransform: "capitalize" }}>
              {invitation.owner.firstname} {invitation.owner.lastname}
            </span>{" "}
            vous invite :
          </p>
          {Object.values(invitation.roles).map((r) => (
            <>
              <div>
                <strong>{r.role}</strong>
              </div>
              <ul style={{ listStyle: "none", padding: 0 }}>
                {r.clients.map((c) => (
                  <li key={`invi${invitation.id}${c}`}>- {c}</li>
                ))}
              </ul>
            </>
          ))}

          <div className="d-flex justify-content-around align-items-center">
            {isLoadingInvit == invitation.id ? (
              <Loader />
            ) : (
              <>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={acceptInvitation}
                >
                  Accepter
                </button>
                <button
                  className="btn btn-light btn-sm"
                  onClick={declineInvitation}
                >
                  Refuser
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
