import Control from "../../common/Control";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import GreyCard from "../Dashboard/GreyCard";

const MyEmail = ({
  email,
  setEmail,
  emailError,
  saveEmail,
  setModifyEmail,
  isLoadingEmail,
}) => {
  return (
    <>
      <button
        className="btn btn-link pl-0 text-success mb-2"
        onClick={() => setModifyEmail(false)}
      >
        <i className="fa fa-arrow-left mr-2" />
        Retour
      </button>
      <p className="custom-p ">
        <strong>Modifiez votre email</strong>
        <br />
      </p>
      <GreyCard>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveEmail();
          }}
          style={{ color: "#5F5F5F" }}
        >
          <Control
            label="Nouvelle adresse email"
            name="email"
            value={email}
            change={(e) => setEmail(e.target.value)}
            error={emailError}
          />
          <div className="d-center w-100">
            <SaveBtn
              type="primary"
              className="mt-4 btn-sm"
              save={saveEmail}
              isSaving={isLoadingEmail}
              typeBtn="submit"
            />
          </div>
        </form>
      </GreyCard>
    </>
  );
};

export default MyEmail;
