import { useState } from "react";
import { searchClientUsers } from "../../../../../../../../../services/clientService";
import useCompanyItem from "../../useCompanyItem";

const useBPUsers = () => {
  const { company, actions } = useCompanyItem();
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const onGetCompanyUsers = () => {
    setIsLoadingUsers(true);
    searchClientUsers(company.id)
      .then((data) => actions.populateCompanyUsers(data))
      .finally(() => setIsLoadingUsers(false));
  };

  return {
    isLoadingUsers,
    users: company.users,
    onGetCompanyUsers,
  };
};

export default useBPUsers;
