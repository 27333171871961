import React from "react";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";

const UserForm = ({ user, setUser, errors, validUserForm, isLoading }) => {
  return (
    <div className="mt-4">
      <Control
        type="btnList"
        name="gender"
        datas={[
          { id: 1, name: "Monsieur" },
          { id: 2, name: "Madame" },
        ]}
        btnInline
        value={user.gender}
        change={(e) => setUser({ ...user, gender: e.target.value })}
        error={errors}
      />
      <Control
        label="Nom"
        name="lastname"
        type="text"
        value={user.lastname}
        change={(e) => setUser({ ...user, lastname: e.target.value })}
        error={errors}
      />
      <Control
        label="Prénom"
        name="firstname"
        type="text"
        value={user.firstname}
        change={(e) => setUser({ ...user, firstname: e.target.value })}
        error={errors}
      />
      <Control
        label="Téléphone mobile"
        name="phone"
        type="tel"
        value={user.phone}
        change={(e) => setUser({ ...user, phone: e.target.value })}
        error={errors}
      />
      <p className="my-3" style={{ fontSize: 14, fontWeight: "bold" }}>
        Pour activer son compte et définir son mot de passe, cet utilisateur recevra un email.
      </p>
      {(user.gender + "").length && user.firstname.length && user.lastname.length && user.phone.length ? (
        <div className="d-center">
          <SaveBtn text="Étape suivante" type="primary btn-sm mt-4" save={validUserForm} isSaving={isLoading} />
        </div>
      ) : (
        <i>Veuillez compléter tous les champs.</i>
      )}
    </div>
  );
};

export default UserForm;
