import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { API_URL } from "../../../config";
import { toast } from "react-toastify";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import useStore from "../../../context/useStore";
import Axios from "axios";
import SaveBtn from "../SaveBtn";

const ImageFileCrop2 = ({ setModifyAvatar }) => {
  const [st, dispatch] = useStore();
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1,
    },
    croppedImageUrl: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [imageRef, setImageRef] = useState("");
  var fileUrl = "";

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      var format = e.target?.files[0]?.type || "invalid";
      if (!["image/png", "image/jpg", "image/jpeg"].includes(format)) {
        toast.error(`Ce format d'image n'est pas supporté (${format})`);
        return false;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => setState({ ...state, src: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setState({ ...state, crop });
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, crop, "newFile.jpeg");

      setState({ ...state, croppedImageUrl });
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const uploadFile = async () => {
    setIsLoading(true);
    const data = new FormData();
    let blob = await fetch(croppedImageUrl).then((r) => r.blob());
    data.append("file", blob, "avatar.jpg");

    Axios.post(API_URL + "files/myavatar", data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    })
      .then((res) => {
        toast.success("Votre photo de profil a bien été enregistrée.");
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...st.auth.user, newImage: true },
        });
        setModifyAvatar(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const { crop, croppedImageUrl, src } = state;

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center m-auto"
      style={{
        maxWidth: "300px",
      }}
    >
      <div className="my-3">
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      {src && (
        <>
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
          <p className="mt-2">Déplacez et redimensionnez la zone en pointillés pour cadrer votre visage.</p>
        </>
      )}
      {croppedImageUrl && (
        <>
          <div style={{ maxHeight: 50 }} className="d-center mt-3">
            <strong style={{ fontSize: 18, lineHeight: "22" }}>
              Whouaou !!! Trop classe <img src="/images/emoji.svg" />
            </strong>
          </div>
          <img alt="Crop" style={{ maxWidth: "100%", borderRadius: "100%" }} src={croppedImageUrl} />
        </>
      )}
      <SaveBtn save={uploadFile} isSaving={isLoading} type="primary" className="mt-3 btn-sm" />
    </div>
  );
};

export default ImageFileCrop2;
