import React, { useState } from "react";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";

const InviteUser = ({ user, setUser, inviteUser, isLoading, errors }) => {
  return (
    <div>
      <div className="text-success mt-5 mb-3 font-weight-bold" style={{ fontSize: 17 }}>
        Qui voulez-vous inviter ? 🚀
      </div>
      <Control
        label="Email"
        type="email"
        value={user.email}
        change={(e) => setUser({ ...user, email: e.target.value })}
        error={errors}
        name="email"
      />

      <div className="d-center">
        <SaveBtn text="Étape suivante" type="primary btn-sm mt-4" save={inviteUser} isSaving={isLoading} />
      </div>
    </div>
  );
};

export default InviteUser;
