import React from "react";
import useCompanyItem from "../useCompanyItem";
import BPCompanyInfo from "./BPCompanyInfo/BPCompanyInfo";
import BPDocuments from "./BPDocuments/BPDocuments";
import BPPolicies from "./BPPolicies/BPPolicies";
import BPUsers from "./BPUsers/BPUsers";
import CompanyBottomPanel from "./CompanyBottomPanel/CompanyBottomPanel";

const BottomPanel = () => {
  const { bottomPanelContent } = useCompanyItem();

  return !bottomPanelContent ? null : (
    <CompanyBottomPanel>
      {(() => {
        switch (bottomPanelContent) {
          case "COMPANY":
            return <BPCompanyInfo />;
          case "DOCUMENTS":
            return <BPDocuments />;
          case "POLICIES":
            return <BPPolicies />;
          case "USERS":
            return <BPUsers />;
          case "EVENTS":
            return <>évènements...</>;
        }
      })()}
    </CompanyBottomPanel>
  );
};

export default BottomPanel;
