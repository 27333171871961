import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
// Pages
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidenav from "./components/common/layout/Sidenav";
import TopHeader from "./components/common/layout/TopHeader";
import AccountList from "./components/pages/AccountList/AccountList";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import ClientList from "./components/pages/ClientList/ClientList";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import DocumentList from "./components/pages/DocumentList/DocumentList";
import MyAccount from "./components/pages/MyAccount/MyAccount";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import { authActions } from "./context/actions/authActions";
import { constantActions } from "./context/actions/constantActions";
import { AUTH_TYPES } from "./context/reducers/authReducers";
import useStore from "./context/useStore";
// import AccountDetail from "./components/pages/AccountDetail/AccountDetail";
import Axios from "axios";
import { loadReCaptcha } from "react-recaptcha-v3";
import RoleStyle from "./RoleStyle";
import ContractDetail from "./components/pages/ContractDetail/ContractDetail";
import ContractRenew from "./components/pages/ContractRenew/ContractRenew";
import MyTimeline from "./components/pages/MyTimeline/MyTimeline";
import PaymentMethods from "./components/pages/PaymentMethods/PaymentMethods";
import { API_URL, OPENREPLAY_KEY, REF_APP, recaptcha_token } from "./config";
//import PaymentMethods from "./components/common/Payment/PaymentMethods";
import Tracker from "@openreplay/tracker";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import ScrollToTop from "./ScrollToTop";
import CustomModal from "./components/common/CustomModal/CustomModal";
import CustomSidenav from "./components/common/CustomSidenav/CustomSidenav";
import Loader from "./components/common/Loader";
import NotifModal from "./components/common/NotifModal/NotifModal";
import UserCreate from "./components/pages/AccountDetail/UserCreate";
import ActivateAccountInvite from "./components/pages/ActivateAccountInvite/ActivateAccountInvite";
import CGV from "./components/pages/CGV/CGV";
import ContractRenewAll from "./components/pages/ContractRenewAll/ContractRenewAll";
import Forms from "./components/pages/Forms/Forms";
import LegalNotice from "./components/pages/LegalNotice/LegalNotice";
import ModifyAnswers from "./components/pages/ModifyAnswers/ModifyAnswers";
import NotFound from "./components/pages/NotFound/NotFound";
import ProtectionJuridique from "./components/pages/ProtectionJuridique/ProtectionJuridique";
import { inputsConfigActions } from "./context/actions/inputsConfigActions";
import { ExternalConstantsContext } from "./hooks/useExternalConstants/ExternalConstantsContext";
import useLegalForms from "./hooks/useExternalConstants/useLegalForms";
import { sidenavItems } from "./sidenavItems";
import MesFacturesPage from "./components/pages/MesFactures/MesFacturesPage";
const tawkto = require("tawkto-react");

window.borderRadius = 0;
var firstLoad = true;

export const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // -> 0.25 in production
  tracesSampleRate: 0.25,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  const i_actions = inputsConfigActions(state, dispatch);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const { legalForms, legalFormsActions } = useLegalForms();

  Axios.defaults.withCredentials = true;
  Axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response?.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== API_URL + "auth/logout") {
          actions.logoutUser(true);
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    Axios.get(API_URL + "auth/me/" + REF_APP)
      .then((res) => {
        const decoded = jwt_decode(res.data);
        try {
          window.tracker.setUserID(decoded.email);
        } catch (err) {}
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: decoded,
        });
      })
      .catch((err) => {
        //actions.logoutUser();
      });
    i_actions.getInputsConfig();
    c_actions.getConstants();
    loadReCaptcha(recaptcha_token, () => {});

    tawkto("5b6830dadf040c3e9e0c536a");
    try {
      if (OPENREPLAY_KEY) {
        window.tracker = new Tracker({
          projectKey: OPENREPLAY_KEY,
          onStart: ({ sessionID }) =>
            console.log("OpenReplay tracker started with session: ", sessionID),
        });
        window.tracker.start();
      }
    } catch (err) {
      console.log("err open replay", err);
    }
    legalFormsActions.getLegalForms();
  }, []);

  useEffect(() => {
    if (!state.auth.isAuthenticated) {
      document.querySelector("#main-content").style.width = "100%";
    }
  });

  return (
    <ExternalConstantsContext.Provider value={{ legalForms }}>
      <Router>
        <ScrollToTop />
        <div className="wrapper" id="outer-container">
          <TopHeader />
          {state.auth.isAuthenticated && state.auth.user.isActivated && (
            <Sidenav
              sidenavItems={sidenavItems}
              sidenavOpen={sidenavOpen}
              setSidenavOpen={setSidenavOpen}
            />
          )}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <div
            id="main-content"
            className="position-relative"
            style={{ paddingBottom: 50, paddingTop: 64, minHeight: "100vh" }}
          >
            <RoleStyle />

            {Object.keys(state.constants.items).length ? (
              <Switch>
                {/* <AppContainer> */}
                <SentryRoute
                  path="/renew-password/:token"
                  exact
                  component={RenewPassword}
                />
                <SentryRoute
                  path="/activate-account/:token"
                  exact
                  component={ActivateAccount}
                />
                <SentryRoute
                  path="/activate-account-invite/:token"
                  exact
                  component={ActivateAccountInvite}
                />
                <SentryRoute path="/cgv" exact component={CGV} />
                <SentryRoute
                  path="/mentions-legales"
                  exact
                  component={LegalNotice}
                />
                <PrivateRoute path="/" exact component={Dashboard} />
                <PrivateRoute path="/accueil" exact component={Dashboard} />

                <PrivateRoute path="/me" exact component={MyAccount} />

                <PrivateRoute path="/clients" exact component={ClientList} />

                <PrivateRoute path="/files" exact component={DocumentList} />
                <PrivateRoute path="/accounts" exact component={AccountList} />
                <PrivateRoute
                  path="/accounts/:id"
                  exact
                  component={UserCreate}
                />
                <PrivateRoute
                  path="/contracts/:id"
                  exact
                  component={ContractDetail}
                />
                <PrivateRoute
                  path="/contracts/:id/renew"
                  exact
                  component={ContractRenew}
                />
                <PrivateRoute
                  path="/payer-mes-contrats"
                  exact
                  component={ContractRenewAll}
                />
                <PrivateRoute
                  path="/paymentmethods"
                  exact
                  component={PaymentMethods}
                />
                <PrivateRoute
                  path="/protection-juridique"
                  exact
                  component={ProtectionJuridique}
                />
                <PrivateRoute
                  path="/modify-answers/:productId/:clientId/:establishmentId/:quotationId"
                  exact
                  component={ModifyAnswers}
                />
                <PrivateRoute path="/forms" exact component={Forms} />
                <PrivateRoute path="/forms/:id" exact component={Forms} />
                {/* <PrivateRoute
              path="/paymentmethods"
              exact
              component={PaymentMethods}
            /> */}
                <PrivateRoute path="/timeline" exact component={MyTimeline} />
                <PrivateRoute
                  path="/mes-factures"
                  exact
                  component={MesFacturesPage}
                />
                <SentryRoute component={NotFound} />
                {/* </AppContainer> */}
              </Switch>
            ) : (
              <Loader size={70} />
            )}
          </div>
        </div>
        <CustomModal />
        <CustomSidenav />
        {state.auth.isAuthenticated ? <NotifModal /> : null}
      </Router>
    </ExternalConstantsContext.Provider>
  );
};

export default App;
