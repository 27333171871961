import React from "react";

const GoDown = () => {
  return (
    <div
      onClick={() => document.querySelector("#anchor-pj").scrollIntoView()}
      className="godown-anim p-3 d-center bg-primary text-white"
      style={{
        position: "fixed",
        right: 4,
        top: "50%",
        zIndex: "9999",
      }}
    >
      <i className="fa fa-arrow-down animated fadeInDown infinite faster" />
    </div>
  );
};

export default GoDown;
