import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../helpers/tools";
import AddressModifier from "./AddressModifier";
import CheckList from "./CheckList";
import CompanyRecapWrapper from "./CompanyRecapWrapper";
import CompanyTopRecap from "./CompanyTopRecap";
import InputDisplay from "./InputDisplay";
import RecapCard from "./RecapCard";
import RecapCompanyInfos from "./RecapCompanyInfos";

const MRPBlocks = ({ company, client }) => {
  const guaranties = {
    isElectricalDamage: "Dommages électriques",
    isWaterDamage: "Dégâts des eaux",
    isStealDamage: "Vol contenu",
    isIceBreakDamage: "Bris de glace",
    isSignBreakDamage: "Bris d'enseigne",
    isTransportedGoodsDamage: "Marchandises transportées terrestres",
    isHardwareDamage: "Bris de matériels informatiques",
    isMachineDamage: "Bris de machines",
    isFreezeRoomGoodsDamage: "Pertes de marchandises en chambre froide",
    isOperatingLossDamage: "Pertes d'exploitation après incendie",
    isGoodwillMarketValueDamage: "Valeur vénale du fond de commerce",
  };

  return (
    <CompanyRecapWrapper>
      <CompanyTopRecap company={company} />

      <div className="mt-3">
        <CheckList
          items={[
            {
              text: (
                <>
                  <InputDisplay
                    inputName="qualityOccupant"
                    inputType="btnList"
                    inputValue={company.qualityOccupant}
                  />
                  {", "}
                  <InputDisplay
                    inputName="buildingArea"
                    inputType="text"
                    inputValue={<>{tools.truenumberWithSpaces(company.buildingArea)} m²</>}
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  Contenu global :{" "}
                  <InputDisplay
                    inputName="classicContentValue"
                    inputType="text"
                    inputValue={
                      <>
                        {tools.truenumberWithSpaces(
                          tools.calculateContentValue([
                            company.classicContentValue,
                            company.itContentValue,
                            company.electricalContentValue,
                            tools.isOwner(company.qualityOccupant)
                              ? company.amenitiesValue
                              : company.amenitiesAndEmbelishmentsValue,
                          ])
                        )}{" "}
                        €
                      </>
                    }
                  />
                </>
              ),
            },
            {
              text: (
                <>
                  Liste des garanties :{" "}
                  <ul>
                    <li>
                      <small>
                        <strong>Incendie</strong>
                      </small>
                    </li>
                    <li>
                      <small>
                        <strong>Catastrophes Naturelles</strong>
                      </small>
                    </li>
                    <li>
                      <small>
                        <strong>T.O.C</strong>
                      </small>
                    </li>
                    <li>
                      <small>
                        <strong>RC Exploitation</strong>
                      </small>
                    </li>
                    {Object.keys(guaranties).map((ent, entk) => {
                      if (company[ent]) {
                        return (
                          <li key={`gua${company.id}${ent}`}>
                            <small>
                              <strong>{guaranties[ent]}</strong>
                            </small>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </>
              ),
            },
          ]}
        />
        <div className="d-center">
          <Link
            to={`/modify-answers/MRP/${client.clientId}/${company.establishmentId}/${company.quotationId}`}
            className="btn btn-link pt-3 pb-4"
            style={{ fontWeight: "400", fontSize: 16 }}
          >
            Modifier mes réponses
          </Link>
        </div>
      </div>
    </CompanyRecapWrapper>
  );
};

export default MRPBlocks;
