import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Page = ({
  container = "container",
  containerClassname = "",
  title,
  subTitle = "",
  children,
  errors = null,
  back = null,
  action = null,
  style = {},
  margin = "my-5",
  titleMargin = "mb-lg-5 mb-md-4 mb-3",
  notAnimated = false,
  noMarginBottom = false,
  backText = "Retour",
}) => {
  return (
    <div
      className={
        container +
        "  px-lg-5 px-4 " +
        margin +
        " " +
        containerClassname +
        (notAnimated ? "" : " animated fadeIn faster ")
      }
      style={{ marginBottom: noMarginBottom ? 0 : 150, ...style }}
    >
      {back && (
        <Link
          to={back}
          className="btn text-success mr-3 shadow-none"
          style={{ padding: "15px 18px 18px 0px" }}
        >
          <i className="fa fa-arrow-left mr-2"></i>
          {backText || ""}
        </Link>
      )}
      {title && (
        <div className={" d-flex align-items-center " + titleMargin}>
          <h1
            className="align-items-center d-flex justify-content-between mb-0 mx-0 text-center w-100"
            style={{ fontSize: "1.5rem" }}
          >
            {title}
            {action && (
              <Link to={action.to} className="btn btn-primary float-right ml-3">
                <i className="fa fa-plus mr-2"></i>
                {action.text}
              </Link>
            )}
          </h1>
        </div>
      )}

      {errors && errors.other && (
        <div className="alert alert-danger">{errors.other}</div>
      )}
      {children}
    </div>
  );
};

export default Page;
