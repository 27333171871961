import React from "react";

const AccordionItemTitle = (props) => {
  return (
    <div className="d-flex flex-row flex-wrap align-items-center justify-content-between w-100 user-title-container">
      <div className="d-flex flex-row flex-wrap align-items-center user-title-header">
        <div
          className="d-flex flex-column mr-2 p-2 bg-success text-white user-title-icon  align-items-center"
          style={{
            fontSize: 12,
            lineHeight: "22px",
            textTransform: "capitalize",
            borderBottomRightRadius: 10,
          }}
        >
          <i className={"fa mt-1 fa-" + props.icon} />
          {props.title}
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default AccordionItemTitle;
