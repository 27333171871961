import React, { useEffect } from "react";
import styled from "styled-components";
import tools from "../../../../../../../helpers/tools";
import CompanyInfo from "../../../../../../common/CompanyInfo/CompanyInfo";
import LabelValuePair from "../../../../../../common/LabelValuePair/LabelValuePair";
import LegalFormValue from "../../../../../../common/LegalFormValue/LegalFormValue";
import OpenDetailButton from "../../../../../../common/OpenDetailButton/OpenDetailButton";

import useContextualTimeline from "../../useContextualTimeline/useContextualTimeline";
import BottomPanel from "./BottomPanel/BottomPanel";
import { CloseCompanyBottomPanelButton } from "./BottomPanel/CompanyBottomPanel/CompanyBottomPanel";
import CompanyPoliciesRecap from "./CompanyPoliciesRecap/CompanyPoliciesRecap";
import useCompanyItem from "./useCompanyItem";

const CompanyItem = ({ companyData }) => {
  const { company, isDeleted, isDeleting, actions } = useCompanyItem();
  const { openTimeline } = useContextualTimeline();

  useEffect(() => {
    actions.initCompany(companyData);
  }, []);

  return !company ? null : (
    <CompanyContainer
      isDeleted={isDeleted}
      className="border-left border-right rounded overflow-hidden  mb-3 position-relative"
      id={`leaditem${company.id}`}
    >
      <div className="row mx-0 bg-blue pt-3 rounded pb-0 border-top border-bottom  animated fadeIn">
        <div className="col-12 col-md-4">
          <CompanyInfo
            noBadge
            company={company.company}
            siret={company.siret}
            infos={[
              `${tools.getFullAddress(company.location1)}`,
              `${company.ape} - ${company.companyActivity}`,
              <LabelValuePair
                label="Forme juridique"
                value={<LegalFormValue legalForm={company.legalForm} />}
              />,
              <LabelValuePair
                label="CA"
                value={`${tools.truenumberWithSpaces(company.turnover)} €`}
              />,
              <LabelValuePair
                label="Immatriculée le"
                value={tools.formatDate(company.registrationAt)}
              />,
              <LabelValuePair
                label="Effectif"
                value={
                  company.workforce === 0 ? "0" : tools.truenumberWithSpaces(company.workforce)
                }
              />,
            ]}
          />
        </div>
        <div className="col-12 col-md-4">
          <CompanyPoliciesRecap />
        </div>
        <div className="col-12 col-md-4 text-right">
          <OpenDetailButton
            className="mt-2"
            onClick={() => actions.openBottomPanel("COMPANY")}
            title={<>Informations détaillées</>}
          />
          <OpenDetailButton
            dataPriv="bo_viewcompanydocs"
            className="mt-2"
            onClick={() => actions.openBottomPanel("DOCUMENTS")}
            title={<>Documents</>}
          />
          {company.policies?.length > 0 && (
            <OpenDetailButton
              className="mt-2"
              onClick={() => actions.openBottomPanel("POLICIES")}
              title={<>Contrats</>}
            />
          )}
          {/* <OpenDetailButton
            className="mt-2"
            onClick={() => actions.openBottomPanel("USERS")}
            title={<>Utilisateurs</>}
          /> */}
          <OpenDetailButton
            className="mt-2"
            onClick={() =>
              openTimeline({
                model: "clients",
                id: company.id,
                searchString: "",
                type: "",
              })
            }
            title={<>Evénements de l'entreprise</>}
          />
        </div>
        <div className="col-12 mt-3 position-relative">
          <CloseCompanyBottomPanelButton />
          <BottomPanel />
        </div>
      </div>
    </CompanyContainer>
  );
};

const CompanyContainer = styled.div`
  filter: ${({ isDeleted }) => (isDeleted ? "grayscale(1)" : "none")};

  &::after {
    display: ${({ isDeleted }) => (isDeleted ? "flex" : "none")};
    content: "supprimée";
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    z-index: 99;
    background: rgba(0, 0, 0, 0.32);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
  }
`;

export default CompanyItem;
