import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import DynamicPage from "../../common/DynamicPage/DynamicPage";

const CGV = () => {
  return (
    <Page container="container-fluid px-5">
      <DynamicPage url="cgv" />
    </Page>
  );
};

export default CGV;
