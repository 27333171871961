import React from "react";

const PlusIcon = () => {
  return (
    <div
      className="d-center animated pulse infinite fast"
      style={{
        fontSize: 14,
        position: "absolute",
        left: -18,
        background: "#040da5",
        width: 30,
        height: 30,
        color: "white",
      }}
    >
      <i className="fa fa-plus" style={{ lineHeight: "30px" }} />
    </div>
  );
};

export default PlusIcon;
