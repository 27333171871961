import React from "react";
import { notifActions } from "../../../context/actions/notifActions";
import useStore from "../../../context/useStore";

const NotifModal = () => {
  const [state, dispatch] = useStore();
  const actions = notifActions(state, dispatch);
  const notifProps = state.notif;

  return (
    <div
      className={`position-fixed bg-white notif-container px-3 pb-3 pt-4 shadow-lg ${
        notifProps.isOpen
          ? "animated fadeInDown faster"
          : "animated fadeOutUp faster"
      }`}
      style={{
        top: 64,
        width: "min(100%, 375px)",
        // height: "calc(100% - 64px)",
        maxHeight: "90%",
        right: 0,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
      }}
    >
      <div
        className="d-flex justify-content-end text-success cursor-pointer font-weight-bold pb-3"
        style={{
          fontSize: 16,
        }}
        onClick={() => actions.updateNotif({ isOpen: false })}
      >
        <i className="fa fa-times" />
      </div>
      {notifProps.content}
      <div
        className="d-flex justify-content-end text-success cursor-pointer font-weight-bold py-2"
        style={{
          fontSize: 16,
        }}
        onClick={() => actions.updateNotif({ isOpen: false })}
      >
        Fermer
      </div>
    </div>
  );
};

export default NotifModal;
