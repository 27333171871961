import React, { useEffect } from "react";
import styled from "styled-components";
import CompanyList from "./CompanyList/CompanyList";
import useClientItem from "./useClientItem";
import UserInfos from "./UserInfos/UserInfos";

const ClientItem = ({ clientData, isMine }) => {
  const { client, actions } = useClientItem();

  useEffect(() => {
    actions.initClient(clientData);
  }, [clientData]);

  return !client ? null : (
    <StyledClientItem className="mb-3 position-relative bg-white" id={`leaditem${clientData.id}`}>
      {isMine && (
        <div className="p-3">
          <PanelSectionTitleAbs>Compte</PanelSectionTitleAbs>
          <UserInfos />
        </div>
      )}
      <div className="px-3">
        <PanelSectionTitleAbs>
          {isMine ? "Mes" : "Autres"} entreprises ({client.clients.length})
        </PanelSectionTitleAbs>
      </div>
      <CompanyList />
    </StyledClientItem>
  );
};

const StyledClientItem = styled.div`
  margin-bottom: 40px;
`;

const PanelSectionTitleAbs = styled.h4`
  position: relative;
  margin: 0;
  padding: 8px 0px;
  color: var(--color-default-dark);
  color: #adb0d8;
`;

export default ClientItem;
