import React, { useState, useEffect } from "react";
import TabClient from "./TabClient";
import TabPolicies from "./TabPolicies";
import Tabs from "../../common/Tabs";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import TabAccount from "./TabAccount";
import Control from "../../common/Control";
import { toast } from "react-toastify";
import TabClientUsers from "./TabClientUsers";
import TimelineFiltered from "../../common/Timeline/TimelineFiltered";
import { DateTime } from "luxon";
import { ClientContext } from "./ClientContext/ClientContext";

var keepDocumentK = [];
var activeTab = 0;

const ViewClient = ({
  datas,
  updateFile,
  setUsers,
  updateUsersData,
  resendActivationMail,
  openClient,
  isSendingMail,
  isBOA,
  closeOverlay,
}) => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocsLoading, setIsDocsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [isClientUsersLoading, setIsClientUsersLoading] = useState(false);

  var user = datas.user;
  var client = datas.client;
  var clientList = datas.clientList;
  var currentTab = datas.currentTab;
  var currentIndex = 0;
  clientList.forEach((cl, clk) => {
    if (cl.id == client.id) currentIndex = clk;
  });

  useEffect(() => {
    if (datas.currentTab == 0) {
      //getDocuments();
    }
  }, []);

  useEffect(() => {
    activeTab = datas.currentTab;
  }, [datas.currentTab]);

  useEffect(() => {
    if (datas.currentTab == 1) {
      getContracts();
    }
    if (datas.currentTab == 0) {
      getDocuments();
    }
    if (datas.currentTab == 3) {
      getClientUsers();
    }
  }, [datas]);

  function getContracts() {
    if (!isLoading) {
      setIsLoading(true);
      Axios.get(API_URL + "policies/client/" + client.id)
        .then((res) => {
          setPolicies(res.data);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  function getDocuments() {
    if (!isDocsLoading) {
      setIsDocsLoading(true);
      Axios.get(API_URL + "clients/" + client.id + "/files")
        .then((res) => {
          setDocuments(res.data);
        })
        .then(() => {
          setIsDocsLoading(false);
        });
    }
  }

  function getClientUsers() {
    if (!isClientUsersLoading) {
      setIsClientUsersLoading(true);
      if (isBOA) {
        Axios.post(API_URL + "customers/" + user.id, {
          clientId: client.id,
        })
          .then((res) => {
            setClientUsers(res.data);
          })
          .then(() => {
            setIsClientUsersLoading(false);
          });
      } else {
        Axios.post(API_URL + "customers/mines", {
          clientId: client.id,
        })
          .then((res) => {
            setClientUsers(res.data);
          })
          .then(() => {
            setIsClientUsersLoading(false);
          });
      }
    }
  }

  function documentUploaded(k, ck, doc) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs = JSON.parse(JSON.stringify(keepDocumentK));
    dcs[k][0] = JSON.parse(JSON.stringify(doc));
    toast.success("Document envoyé avec succès");
    setDocuments(dcs);
  }
  function documentRemoved(k, ck, oldDoc) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[k] = dcs[k].filter((dc, dck) => {
      return dck != ck;
    });

    if (dcs[k].length == 0) {
      dcs[k].push({
        fileType: oldDoc.fileType,
        entity: oldDoc.entity,
      });
    }
    keepDocumentK = JSON.parse(JSON.stringify(dcs));
    toast.success("Document supprimé avec succès");
    setDocuments(dcs);
  }

  function uploadNewFile(k, ck) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[k] = [
      {
        fileType: dcs[k][ck].fileType,
        entity: dcs[k][ck].entity,
        isNewVersion: true,
      },
    ].concat(dcs[k]);
    keepDocumentK = JSON.parse(JSON.stringify(dcs));

    setDocuments(dcs);
  }

  useEffect(() => {
    keepDocumentK = JSON.parse(JSON.stringify(documents));
  }, [documents]);

  function cancelNewVersion() {
    getDocuments();
  }

  return (
    <ClientContext.Provider value={client}>
      <div className="pt-2 mb-5 d-flex  justify-content-center mx-auto align-items-center flex-wrap">
        {clientList[currentIndex - 1] && (
          <button
            className="btn btn-default  btn-hoverable btn-sm"
            style={{
              marginTop: 35,
            }}
            onClick={() => openClient(user, clientList[currentIndex - 1], clientList, activeTab)}
            data-name={clientList[currentIndex - 1].company}
          >
            <i className="fa fa-arrow-left mr-2" />
            <span className="hidden-hover">{clientList[currentIndex - 1].company}</span>
          </button>
        )}
        <div className="text-center">
          <Control
            label="Sociétés"
            margin="mx-2 my-2"
            type="select"
            inputClassName="shadow"
            labelClassname="font-weight-bold"
            datas={clientList}
            dataLabel="company"
            dataIndex="id"
            value={clientList[currentIndex].id}
            change={(e) => openClient(user, { id: e.target.value }, clientList, activeTab)}
          />
        </div>

        {clientList[currentIndex + 1] && (
          <button
            style={{
              marginTop: 35,
            }}
            className="btn btn-hoverable btn-sm btn-default"
            onClick={() => openClient(user, clientList[currentIndex + 1], clientList, activeTab)}
            data-name={clientList[currentIndex + 1].company}
          >
            <span className="hidden-hover">{clientList[currentIndex + 1].company}</span>
            <i className="fa fa-arrow-right ml-2" />
          </button>
        )}
      </div>
      <Tabs
        currentTab={datas.currentTab}
        k="10"
        items={[
          {
            id: "client",
            title: "Détails de la société",
            onClick: () => {
              getDocuments();
              activeTab = 0;
            },
            content: isLoading ? (
              <Loader withContainer />
            ) : (
              <>
                <TabClient
                  client={client}
                  openClient={() => openClient(user, clientList[currentIndex], clientList)}
                  isDocsLoading={isDocsLoading}
                  documents={documents}
                  documentUploaded={documentUploaded}
                  documentRemoved={documentRemoved}
                  uploadNewFile={uploadNewFile}
                  cancelNewVersion={cancelNewVersion}
                  isBOA={isBOA}
                  closeOverlay={closeOverlay}
                />
              </>
            ),
          },
          {
            id: "contracts",
            title: "Contrats",

            content: isLoading ? (
              <Loader withContainer />
            ) : policies.length == 0 ? (
              <span className="text-muted">Aucun contrat trouvé</span>
            ) : (
              <TabPolicies
                clientId={client.id}
                policies={policies}
                policySel={datas.policyId}
                isBOA={isBOA}
              />
            ),
            onClick: () => {
              getContracts();
              activeTab = 1;
            },
          },
          {
            id: "account",
            title: "Compte",
            dataPriv: "app_viewowner-" + client.id,
            content: isLoading ? (
              <Loader withContainer />
            ) : (
              <TabAccount
                user={user}
                setUsers={setUsers}
                updateUsersData={updateUsersData}
                resendActivationMail={resendActivationMail}
                isSendingMail={isSendingMail}
                isBOA={isBOA}
              />
            ),
            onClick: () => {
              activeTab = 2;
            },
          },
          ...(isBOA
            ? [
                {
                  id: "users",
                  title: "Utilisateurs",
                  content: isClientUsersLoading ? (
                    <Loader />
                  ) : (
                    <TabClientUsers clientUsers={clientUsers} />
                  ),
                  onClick: () => {
                    getClientUsers();
                    activeTab = 3;
                  },
                },
              ]
            : []),

          {
            id: "events",
            title: "Événements",
            content: (
              <TimelineFiltered
                className="mt-4"
                showFilters={true}
                initialSearch={{
                  model: "clients",
                  id: client.id,
                  searchString: "",
                  beginAt: DateTime.local().minus({ months: 1 }).startOf("day"),
                  endAt: DateTime.local().endOf("day"),
                  type: "",
                }}
              />
            ),
            onClick: () => {
              activeTab = 4;
            },
          },
        ]}
      />
    </ClientContext.Provider>
  );
};

export default ViewClient;
