import React from "react";

const ErrorBlock = ({ errorsArray }) => {
  return (
    <div className="row mt-4 animated flash">
      <div className="col-12 text-center">
        {errorsArray.map((er, erk) => (
          <>
            <p className="text-danger mb-0" key={"err" + erk}>
              {er}
            </p>
          </>
        ))}
      </div>
    </div>
  );
};

export default ErrorBlock;
