import React from "react";
import ACBlocks from "./ACBlocks";
import ClientAccordion from "./ClientAccordion";
import ProductTitle from "./ProductTitle";

const ACContainer = ({ ACClients }) => {
  return (
    <div className="mb-5">
      <ProductTitle
        icon="chess-rook"
        title="Demande d'Assurance Construction"
      />
      <div className="mt-3">
        <p>
          <strong>Nous étudions votre demande d’Assurance Construction</strong>{" "}
          à partir des informations que vous avez renseigné. Nous revenons vers
          vous au plus vite.
        </p>
      </div>
      {ACClients.map((client) => {
        return (
          <ClientAccordion client={client}>
            {client.establishments.map((company) => (
              <ACBlocks company={company} key={`compac${company.name}`} />
            ))}
          </ClientAccordion>
        );
      })}
    </div>
  );
};

export default ACContainer;
