import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { REF_APP } from "../../../config";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Page from "../../common/layout/Page";
import SaveBtn from "../../common/SaveBtn";
import InviteUser from "./InviteUser";
import UserExistsMessage from "./UserExistsMessage";
import UserForm from "./UserForm";
import UserRecap from "./UserRecap";
import UserRoleItem from "./UserRoleItem";

const UserCreate = (props) => {
  const [state, dispatch] = useStore();
  var fetchId = props.match.params.id;
  var defaultRoleId = Date.now();

  const [errors, setErrors] = useState({});
  const [userFormEdit, setUserFormEdit] = useState(true);
  const [checkResult, setCheckResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [userExists, setUserExists] = useState(false);
  const [userIsMine, setUserIsMine] = useState(false);
  const [roleConfig, setRoleConfig] = useState({});
  const [PRIVILEGE_CATEGORIES, SET_PRIVILEGES_CATEGORIES] = useState({});
  const [ROLES, SET_ROLES] = useState({});
  const [PRIVILEGES, SET_PRIVILEGES] = useState({});
  const [editRoleId, setEditRoleId] = useState(defaultRoleId);

  const userInitialState = {
    email: "",
    gender: "",
    firstname: "",
    lastname: "",
    phone: "",
  };
  const roleInitialState = {
    platform: REF_APP,
    user: "",
    privileges: [],
    roleKey: "",
    owner: state.auth.user.id,
    clients: [],
    clientsName: [],
    id: defaultRoleId,
  };

  const [user, setUser] = useState(userInitialState);
  const [roles, setRoles] = useState([roleInitialState]);

  useEffect(() => {
    getCompanies();
    getConfig();
  }, []);

  useEffect(() => {
    if (fetchId != "create" && companies.length && Object.values(roleConfig).length) {
      getUser();
    }
  }, [companies, roleConfig]);

  useEffect(() => {
    setCheckResult(false);
    setUserIsMine(false);
    setUserExists(false);
  }, [user.email]);

  useEffect(() => {
    var elem = document.querySelector(`#role${editRoleId}`);
    if (elem) {
      tools.scrollToTargetAdjusted(elem);
    }
  }, [editRoleId]);

  const getUser = () => {
    dataService.get(`customers/${fetchId}`, (datas) => {
      setUser(datas);
      setUserFormEdit(false);
      setCheckResult(true);
      setEditRoleId(false);
    });
    dataService.get(`customers/${fetchId}/roles`, (datas) => {
      setRoles(
        datas
          .filter((d) => !d.isPending)
          .map((d) => {
            return {
              ...d,
              clientsName: d.clients.map((c) => {
                return companies.find((comp) => comp.id == c).company;
              }),
            };
          })
      );
    });
  };

  const getCompanies = () => {
    setIsLoading(true);
    dataService.get(`clients/mines`, setCompanies, setErrors, () => setIsLoading(false));
  };

  const getConfig = () => {
    dataService.get(`constants/roles`, (datas) => {
      SET_PRIVILEGES(datas.PRIVILEGES);
      SET_PRIVILEGES_CATEGORIES(datas.PRIVILEGE_CATEGORIES);
      SET_ROLES(datas.ROLES);
      dataService.get(`roles/${[REF_APP]}`, setRoleConfig);
    });
  };

  const inviteUser = () => {
    setIsLoading(true);
    dataService.get(
      `customers/exists/${user.email}`,
      (datas) => {
        setCheckResult(datas);
        setUserExists(datas.exists);
        setUserIsMine(datas.isMine);
        if (datas.exists) {
          setUser(datas.userCustomer);
        }
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  const validUserForm = () => {
    setUserFormEdit(false);
  };

  const addNewRole = () => {
    var newId = Date.now();
    setRoles([...roles, { ...roleInitialState, id: newId }]);
    setEditRoleId(newId);
  };

  const removeRole = () => {
    if (!user.id || fetchId == "create") {
      setRoles(roles.filter((r) => r.id != editRoleId));
      setEditRoleId(false);
    } else {
      deleteRole();
    }
  };

  const save = () => {
    setIsLoading(true);
    dataService.post(
      `customers/mines/add`,
      {
        userDatas: user,
        roleDatas: roles,
      },
      (datas) => {
        toast.success("L'utilisateur a bien été invité");
        props.history.push("/accounts");
      },
      (err) => {},
      () => setIsLoading(false)
    );
  };

  const saveRole = () => {
    setIsLoading(true);
    var roleToSave = roles.find((r) => r.id == editRoleId);
    if ((editRoleId + "").length < 24) {
      dataService.post(
        `customers/${user.id}/roles`,
        roleToSave,
        (datas) => {
          toast.success("Le rôle a bien été ajouté");
          setRoles(
            roles.map((r) => {
              if (r.id == roleToSave.id) {
                return { ...r, id: datas.id };
              }
              return r;
            })
          );
          setEditRoleId(false);
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      dataService.patch(
        `customers/${user.id}/roles/${roleToSave.id}`,
        roleToSave,
        (datas) => {
          toast.success("Le rôle a bien été enregistré");

          setEditRoleId(false);
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  const deleteRole = () => {
    var roleToDelete = roles.find((r) => r.id == editRoleId);
    setIsLoading(true);
    dataService.remove(
      `roles/${editRoleId}`,
      {},
      (datas) => {
        setRoles(roles.filter((r) => r.id != editRoleId));
        setEditRoleId(false);
      },
      setErrors,
      () => setIsLoading(false),
      true
    );
  };

  return (
    <Page margin="mt-5 mb-5 pb-5" style={{ maxWidth: 960 }}>
      <div style={{ fontSize: 16 }}>
        <Link to="/accounts" className="text-success font-weight-bold">
          <i className="fa fa-arrow-left mr-2" />
          Retour
        </Link>
      </div>
      {fetchId != "create" ? (
        <div style={{ fontSize: 14, lineHeight: "22px" }} className="mt-3 px-2">
          <p>
            Faites évoluer l’affectation d’une société pour cet utilisateur.{" "}
            <strong>Modifiez son rôle et/ou ajustez si besoin ses privilèges.</strong>
          </p>
          <p style={{ fontSize: 12, lineHeight: "19px" }}>
            <strong>
              <span className="text-danger">Attention</span> supprimer tous les rôles d'un utilisateur supprimera son
              compte.
            </strong>{" "}
            Pour pouvoir réutiliser l'adresse email de cet utilisateur, vous devez contacter Perceval.
          </p>
        </div>
      ) : null}
      {userFormEdit && fetchId == "create" ? (
        <>
          <InviteUser
            inviteUser={inviteUser}
            checkResult={checkResult}
            user={user}
            setUser={setUser}
            errors={errors}
            isLoading={isLoading}
          />

          {checkResult && !userExists ? (
            <UserForm user={user} setUser={setUser} errors={errors} validUserForm={validUserForm} />
          ) : null}
          {checkResult && userExists ? (
            <UserExistsMessage
              userIsMine={userIsMine}
              user={user}
              setCheckResult={setCheckResult}
              approve={() => setUserFormEdit(false)}
            />
          ) : null}
        </>
      ) : checkResult ? (
        <>
          <UserRecap user={user} setUserFormEdit={setUserFormEdit} />
          {roles.map((role, rk) => (
            <div className="mb-3" id={`role${role.id}`} key={`rolemain${role.id}`}>
              <UserRoleItem
                key={`role${role.rk}`}
                role={role}
                roles={roles}
                setRoles={setRoles}
                user={user}
                setUserFormEdit={setUserFormEdit}
                companies={companies}
                ROLES={ROLES}
                PRIVILEGES={PRIVILEGES}
                PRIVILEGE_CATEGORIES={PRIVILEGE_CATEGORIES}
                roleConfig={roleConfig}
                editRoleId={editRoleId}
                setEditRoleId={setEditRoleId}
                removeRole={removeRole}
                saveRole={saveRole}
                isLoading={isLoading}
                userIsMine={userIsMine}
                fetchId={fetchId}
              />
            </div>
          ))}
          {!editRoleId || !roles.length ? (
            <div className="d-center flex-column mt-4">
              <p className="text-center" style={{ fontSize: 16, lineHeight: "22px" }}>
                Ajoutez un {roles.length ? "autre" : ""} rôle à cet utilisateur pour une autre de vos sociétés.
              </p>
              <button className="btn btn-primary btn-sm" onClick={addNewRole}>
                Ajouter un {roles.length ? "autre" : ""} rôle
              </button>
            </div>
          ) : null}

          {roles.length && !editRoleId && fetchId == "create" ? (
            <div className="d-center flex-column w-100 mt-5 pb-5">
              <p className="text-center font-weight-bold" style={{ fontSize: 16, lineHeight: "22px" }}>
                Votre nouvel utilisateur recevra un mail d'activation de son compte.
              </p>
              <SaveBtn text="Envoyer l'invitation" save={save} isSaving={isLoading} type="info" />
            </div>
          ) : null}
        </>
      ) : null}
    </Page>
  );
};

export default UserCreate;
