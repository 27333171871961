const buildingTips = {
  isInBuilding: {
    title: "Immeuble",
    content: 
      <>
          <p><strong>Un immeuble est un bâtiment de plusieurs étages</strong>, qui peut être conçu pour assurer aussi bien des fonctions résidentielles, administratives (privées ou publiques), d’enseignement (écoles, universités), de soins (hôpitaux, cliniques), commerciales, financières ou hôtelières. </p>
          <p><strong>Un immeuble comprend la plupart du temps plusieurs occupants, qu’ils soient locataires ou copropriétaires.</strong></p>
          <p><strong>Bon à savoir</strong> : un ensemble de bâtiments de plain-pied (sans étages) réunis sous la même toiture constitue un immeuble.</p>
      </>
    },
    buildingNumberOfFloors: {
      title: "Les niveaux d’un immeuble",
      content: 
        <>
            <p><strong>Le nombre de niveaux d’un immeuble est la somme de chaque étage d’un bâtiment, y compris le rez-de-chaussée et les sous-sols.</strong></p>
            <p><i>Par exemple un bâtiment avec RDC, 1er étage et 2ème étage aura donc 3 niveaux.</i></p>
            <p><strong>Bon à savoir</strong> : les combles aménagés comportant des fenêtres sont considérés comme un niveau à part entière.</p>
        </>
      },
    risksAroundInBuilding: {
      title: "Activités à risque",
      content: 
        <>
            <p><strong>Lorsque des activités à risque ou dites « sensibles » sont présentes dans le bâtiment ou à proximité immédiate des locaux à assurer, elles doivent être déclarés à l’Assureur.</strong></p>
            <p>Elles représentent en effet un caractère aggravant qui doit être pris en compte pour le calcul de la cotisation d’assurance mais aussi en terme de prévention des risques (mise en oeuvre de mesures adaptées pour éviter les sinistres).</p>
            <p><strong>Liste non exhaustive d’activités à risque</strong> : discothèque, boîte de nuit, bowling, bar de nuit, travail du bois, garage, station-service, usine de fabrication, industrielle, activité avec usage ou stockage de matières dangereuses, recyclage, traitement des déchets, etc.</p>
        </>
    },
    risksAroundNextToBuilding: {
      title: "Activités à risque",
      content: 
        <>
            <p><strong>Lorsque des activités à risque ou dites « sensibles » sont présentes dans le bâtiment ou à proximité immédiate des locaux à assurer, elles doivent être déclarés à l’Assureur.</strong></p>
            <p>Elles représentent en effet un caractère aggravant qui doit être pris en compte pour le calcul de la cotisation d’assurance mais aussi en terme de prévention des risques (mise en oeuvre de mesures adaptées pour éviter les sinistres).</p>
            <p><strong>Liste non exhaustive d’activités à risque</strong> : discothèque, boîte de nuit, bowling, bar de nuit, travail du bois, garage, station-service, usine de fabrication, industrielle, activité avec usage ou stockage de matières dangereuses, recyclage, traitement des déchets, etc.</p>
        </>
    },
    isRankedBuilding: {
      title: "Monument historique",
      content: 
        <>
            <p>Un monument historique est un <strong>immeuble</strong> recevant un statut juridique particulier destiné à le <strong>protéger pour son intérêt</strong> historique, artistique, architectural mais aussi technique ou scientifique afin qu’il soit conservé, restauré et mis en valeur.</p>
        </>
    }
};

export default buildingTips;
