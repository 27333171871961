const fundsTips = {
    isFundsMarket: {
        title: "Fonds de commerce",
        content: (
            <>
                <p>
                    <strong>Ce sont les éléments « incorporels »</strong> suivants : droit au bail,
                    pas-de porte, clientèle, enseigne, marque de fabrique, brevets, licences, nom
                    commercial et/ou raison sociale, achalandage (partie de la clientèle liée à
                    l’implantation géographique des locaux professionnels).
                </p>
                <p>
                    <strong>Bon à savoir</strong> : le fonds de commerce est généralement exploité
                    par le propriétaire du fonds mais ce dernier peut en confier l’exploitation soit
                    à un commerçant qui va le gérer pour son propre compte en « locataire-gérant »
                    et assurer les aléas financiers afférents à tout commerce, soit à un « gérant
                    salarié ».{" "}
                </p>
            </>
        ),
    },
    qualityOccupantFundsMarket: {
        title: "Fonds de commerce",
        content: (
            <>
                <p>
                    <strong>
                        Ce sont les éléments
                        <br />« incorporels »
                    </strong>{" "}
                    suivants : droit au bail, pas-de porte, clientèle, enseigne, marque de fabrique,
                    brevets, licences, nom commercial et/ou raison sociale, achalandage (partie de
                    la clientèle liée à l’implantation géographique des locaux professionnels).
                </p>
                <p>
                    <strong>Bon à savoir</strong> : le fonds de commerce est généralement exploité
                    par le propriétaire du fonds mais ce dernier peut en confier l’exploitation soit
                    à un commerçant qui va le gérer pour son propre compte en « locataire-gérant »
                    et assurer les aléas financiers afférents à tout commerce, soit à un « gérant
                    salarié ».{" "}
                </p>
            </>
        ),
    },
    isBuildingExclusion: {
        title: "Risques locatifs",
        content: (
            <>
                <p>
                    <strong>
                        C’est la responsabilité contractuelle qu’un Assuré peut encourir, en tant
                        que locataire (ou occupant à titre gratuit) des locaux professionnels
                        assurés, vis-à-vis du propriétaire du fait :
                    </strong>
                </p>
                <ul style={{ paddingLeft: "5%" }}>
                    <li>Des dommages matériels causés aux biens loués ou mis à sa disposition,</li>
                    <li>
                        Des dommages matériels au bien immobilier (ou une partie du bien immobilier)
                        dans lequel se situe les biens loués ou mis à disposition.
                    </li>
                </ul>
                <p>
                    <strong>
                        Exclure les risques locatifs lorsque l’Assuré est locataire n’est recommandé
                        que dans de rares cas.
                    </strong>
                </p>
                <p>
                    <i>
                        Par exemple lorsque le bail commercial prévoit une renonciation à recours
                        réciproque entre le propriétaire et le locataire. <br />
                        La renonciations à recours réciproque supprime de fait le besoin des
                        garanties de responsabilités, plus précisément le besoin de la garantie «
                        recours des locataires » pour le propriétaire, et le besoin de la garantie «
                        risques locatifs » pour le locataire.
                    </i>
                </p>
            </>
        ),
    },
    isInsuranceCommonAccount: {
        title: "Assurance pour compte commun",
        content: (
            <>
                <p>
                    <strong>
                        C’est le fait pour un locataire de souscrire une assurance pour le compte du
                        propriétaire en cas d’intérêt commun (pécuniaire ou financier).
                    </strong>
                </p>
                <p>
                    Si le bail commercial prévoit une clause d’assurance pour le compte du
                    propriétaire il appartient au locataire de souscrire un contrat tant pour son
                    compte que pour celui de son propriétaire.
                </p>
                <p>
                    <strong>Bon à savoir</strong> : ce cas est fréquent en cas d’achat des murs par
                    l’exploitant en nom propre ou par le biais d’une SCI et qu’il loue alors ses
                    locaux à son entreprise.
                </p>
            </>
        ),
    },
    isAlignmentHit: {
        title: "Bâtiment frappé d’alignement. Expropriation.",
        content: (
            <>
                <p>
                    <strong>
                        L’État, les départements, et communes peuvent délimiter leur domaine public
                        routier par rapport aux propriétés riveraines. Cette délimitation est
                        réalisée suivant une procédure administrative dite « d’alignement ».
                    </strong>
                    <br />
                    Lorsque les locaux professionnels sont situés dans un bâtiment frappé
                    d’alignement, en procédure d’expropriation, ou soumis à interdiction de
                    reconstruire, ils font souvent l’objet de certaines limitations de garantie par
                    l’Assureur.
                    <br />
                    Un bâtiment peut également faire l’objet d’une procédure d’expropriation pour
                    cause d’utilité publique.{" "}
                    <strong>
                        En effet l’État peut, pour son propre compte ou au profit de certains
                        bénéficiaires, déposséder un propriétaire de son bien, en général
                        immobilier.
                    </strong>
                </p>
                <p>
                    <strong>
                        <u>Important :</u>
                        <br />
                        Lorsque les locaux professionnels sont situés dans un bâtiment frappé
                        d’alignement, en procédure d’expropriation, ou soumis à interdiction de
                        reconstruire, ils font souvent l’objet de certaines limitations de garantie
                        par l’Assureur.{" "}
                    </strong>
                </p>
            </>
        ),
    },
};

export default fundsTips;
