import React from "react";
import useStore from "../../../context/useStore";

const InputDisplay = ({ inputName, inputValue, inputType }) => {
  const [state, dispatch] = useStore();
  const { inputsConfig } = state;
  const inputRef = inputsConfig[inputName];
  let returnValue = "";

  if (inputRef) {
    switch (inputType) {
      case "btnList":
        returnValue = inputRef.datas.find((d) => d.id == inputValue).name;
        break;
      case "text":
        returnValue = inputValue;
    }
  }

  return <strong>{returnValue}</strong>;
};

export default InputDisplay;
