import React from "react";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";

const InfoGozenPremium = () => {
  const [state, dispatch] = useStore();
  const modalAct = modalActions(state, dispatch);

  const premiumGaranties = [
    {
      id: 1,
      icon: "doublement-garanti.svg",
      title: "Doublement Garanti",
      text: "Vous doublez votre garantie annuelle à hauteur de 50 000€.",
      modalText: (
        <>
          <p>Vous doublez votre garantie annuelle à hauteur de 50 000€. </p>
          <p>
            C’est l’ensemble des frais et honoraires que nous prenons en charge
            :
          </p>
          <ul>
            <li>
              La prise en charge maximum pour un même litige est 50 000€ peu
              importe sa durée de résolution.{" "}
            </li>
            <li>Vous bénéficiez de services supplémentaires. </li>
            <li>
              Vous êtes bien sûr couvert pour plusieurs litiges dans la même
              année.
            </li>
          </ul>
          <p>
            <i>
              Exemple : votre entreprise subit 6 litiges d’un coût moyen de 10
              000€ chacun, la même année, nous prendrons en charge 50 000€.
            </i>
          </p>
        </>
      ),
    },
    {
      id: 2,
      icon: "signez-confiance.svg",
      title: "Signez en Toute Confiance",
      text:
        "Lorsque vous envisagez de signer un contrat, nous vous assistons dans sa lecture et sa compréhension afin de vérifier sa conformité avec les règles de droit.",
      modalText: (
        <>
          <p>
            Lorsque vous envisagez de signer un contrat dans le cadre de votre
            activité professionnelle, nous vous assistons dans sa lecture et sa
            compréhension afin de vérifier sa conformité avec les règles de
            droit, avant sa conclusion.{" "}
          </p>
          <p>
            Lorsque notre juriste identifie une difficulté, le projet de contrat
            est soumis à un avocat. Il vous confirmera par écrit sa validité
            juridique ou vous proposera son adaptation. Nous prenons en charge
            les frais liés à cette prestation deux fois par an.{" "}
          </p>
          <p>Sont pris en charge, les : </p>
          <ul>
            <li>contrats de travail, </li>
            <li>baux commerciaux, </li>
            <li>contrats de vente de biens mobiliers, </li>
            <li>contrats de prestation de services. </li>
          </ul>
          <p>Ne sont pas pris en charge :</p>
          <ul>
            <li>les contrats de vente immobilière. </li>
          </ul>
          <p>
            <strong>Bon à savoir : </strong>cette prestation s’applique aux
            contrats rédigés en langue française et relevant du droit Français.{" "}
          </p>
        </>
      ),
    },
    {
      id: 3,
      icon: "aides-subventions.svg",
      title: "Aides et Subventions",
      text:
        "Nous vous aidons à rechercher les aides financières pouvant vous être attribuées pour vos activités.",
      modalText: (
        <>
          <p>
            Nous vous aidons à rechercher les aides financières pouvant vous
            être attribuées pour vos activités.
          </p>
          <p>
            <strong>Bon à savoir : </strong>par aides et subventions, nous
            entendons les sommes d’argent allouées aux entreprises par les
            collectivités locales, les départements, les régions, l’État,
            l’Europe dans le cadre d’un projet, ainsi que les allègements de
            charges ou crédits d’impôts.{" "}
          </p>
        </>
      ),
    },
    {
      id: 4,
      icon: "propriete-intellectuelle.svg",
      title: "Propriété Intellectuelle",
      text:
        "Nous intervenons en recours lorsque vous êtes victime d'une atteinte à vos droits de propriété industrielle, littéraire ou artistique.",
      modalText: (
        <>
          <p>
            Nous intervenons en recours lorsque vous êtes victime d'une atteinte
            à vos droits de propriété industrielle, littéraire ou artistique.
          </p>
          <p>
            <i>
              Exemple : un nouveau concurrent dans votre domaine d'activité
              utilise une marque commerciale créant une confusion avec la vôtre.
            </i>
          </p>
          <p>
            <strong>Bon à savoir : </strong>notre prise en charge financière est
            garantie jusqu’à 2000€ par litige.{" "}
          </p>
        </>
      ),
    },
    {
      id: 5,
      icon: "mediation-consommation.svg",
      title: "Médiation de la Consommation",
      text:
        "Dès lors qu'un litige relevant de la médiation de la consommation vous oppose à un consommateur, nous prenons en charge les honoraires du médiateur de la consommation.",
      modalText: (
        <>
          <p>
            Dès lors qu'un litige relevant de la médiation de la consommation
            vous oppose à un consommateur, nous prenons en charge les honoraires
            du médiateur de la consommation.
          </p>
          <p>
            <strong>Bon à savoir : </strong>notre prise en charge financière est
            garantie jusqu’à 2000€ par an.{" "}
          </p>
        </>
      ),
    },
  ];

  const openInfos = (id) => {
    modalAct.updateModal({
      isOpen: true,
      content: (
        <>
          <h2>{premiumGaranties.find((p) => p.id == id).title}</h2>
          {premiumGaranties.find((p) => p.id == id).modalText}
        </>
      ),
    });
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="btn-link cursor-pointer" onClick={() => openInfos(1)}>
          <i className="fa fa-question-circle mr-2" />
          Doublement garanti
        </div>
        <div className="btn-link cursor-pointer" onClick={() => openInfos(2)}>
          <i className="fa fa-question-circle mr-2" />
          Signez en toute confiance
        </div>
        <div className="btn-link cursor-pointer" onClick={() => openInfos(3)}>
          <i className="fa fa-question-circle mr-2" />
          Aides et subventions
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="btn-link cursor-pointer" onClick={() => openInfos(4)}>
          <i className="fa fa-question-circle mr-2" />
          Propriété intellectuelle
        </div>
        <div className="btn-link cursor-pointer" onClick={() => openInfos(5)}>
          <i className="fa fa-question-circle mr-2" />
          Médiation de la consommation
        </div>
      </div>
    </div>
  );
};

export default InfoGozenPremium;
