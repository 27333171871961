import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import tools from "../../../helpers/tools";
import Loader from "../Loader";

export default function CheckoutForm({
  paymentIntent,
  setErrors,
  setPaymentIntent,
  isPaying,
  setIsPaying,
  validatePayment,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setErrors({});
    setIsPaying(true);

    const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
      setup_future_usage: "off_session",
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setErrors({ payment: result.error.message });
      setIsPaying(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        setIsPaying(false);
        validatePayment();
        setPaymentIntent(result.paymentIntent);

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <div className="d-center mt-2">
          {isPaying ? (
            <Loader
              className="flex-column mt-4 mx-auto"
              text="Paiement en cours"
              textClassName="mt-2"
            />
          ) : (
            <button
              className="btn-primary py-3 font-weight-bold w-100 border-0"
              disabled={!stripe}
            >
              <i className="fa fa-lock mr-2" />
              PAYER{" "}
              {paymentIntent.amount
                ? tools
                    .truenumberWithSpaces(
                      Math.round((paymentIntent.amount / 100) * 100) / 100
                    )
                    .toString()
                    .replace(".", ",") + "€"
                : ""}
            </button>
          )}
        </div>
      </form>
    </>
  );
}
