import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import PasswordControl from "../../common/PasswordControl";
import Control from "../../common/Control";
const ActivateAccountInvite = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPassword, setIsLoadingPassword] = useState(true);
  const [isActivated, setIsActivated] = useState(false);
  const [errors, setErrors] = useState(false);
  const [passwordToken, setPasswordToken] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [passwordsEqual, setPasswordsEqual] = useState(false);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (props.match.params.token) {
      Axios.post(API_URL + "customers/password-definition-token", {
        token: props.match.params.token,
      })
        .then((res) => {
          setIsActivated(true);
          setIsLoading(false);
          setPasswordToken(res.data.token);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.data) setErrors(err.response.data);
        });
    }
  }, []);

  useEffect(() => {
    setErrors({});
    var errs = {};
    if (password != passwordConfirm || password.length == 0) {
      errs.passwordConfirm = "Doit-être identique au premier";
    }

    if (Object.keys(errs).length === 0 && errs.constructor === Object) {
      setPasswordsEqual(true);
    } else {
      setPasswordsEqual(false);
    }
    setErrors(errs);
  }, [password, passwordConfirm]);

  const renewPassword = () => {
    if (canSave && passwordsEqual) {
      //save
      setIsLoadingPassword(true);
      Axios.post(API_URL + "customers/reset-password", {
        password: password,
        token: passwordToken,
      })
        .then((res) => {
          setIsSuccess(true);
          setErrors({});
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoadingPassword(false);
        });
    }
  };

  return (
    <Page>
      {!isActivated && (
        <div className="border rounded">
          <div
            style={{ borderRadius: 5 }}
            className="col-12 text-center  p-4  m-auto "
            style={{
              background: "#F1F2FE",
            }}
          >
            {isLoading ? (
              <>
                <p>Activation de votre compte en cours...</p>
                <Loader />
              </>
            ) : isActivated ? (
              <div className="text-success">
                <i className="fa fa-check fa-3x" />
                <h3 className="py-3">Votre compte a bien été activé.</h3>
                <p>Vous pouvez à présent vous connecter.</p>
                <Link to="/" className="btn btn-primary">
                  Se connecter
                </Link>
              </div>
            ) : (
              <>
                <i className="fa fa-times fa-3x text-danger" />
                <p className="text-danger mb-0 mt-3">Un problème est survenu lors de l'activation de votre compte.</p>
                <p className="mb-0 text-danger">{errors.other}</p>
              </>
            )}
          </div>
        </div>
      )}

      {isActivated && !isSuccess && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            renewPassword();
          }}
          className="mt-4"
        >
          <p className="text-center mb-4 font-weight-bold" style={{ fontSize: 18 }}>
            Veuillez définir un mot de passe pour votre compte
          </p>
          <div
            style={{
              background: "#F1F2FE",
            }}
            className="p-3 rounded border text-left w-100"
          >
            <Control
              className=""
              label="Nouveau mot de passe"
              type="password"
              name="password"
              value={password}
              change={(e) => setPassword(e.target.value)}
              error={errors}
              isValid={!errors.password && password != "" && canSave}
            />
            <PasswordControl
              style={{}}
              password={password}
              minLength={6}
              minUppercase={1}
              minLowercase={3}
              minNumber={1}
              setCanSave={setCanSave}
            />
            <Control
              label="Confirmez votre mot de passe"
              type="password"
              name="passwordConfirm"
              value={passwordConfirm}
              change={(e) => setPasswordConfirm(e.target.value)}
              error={errors}
              isValid={!errors.passwordConfirm && passwordConfirm != ""}
            />
            <span className="text-danger">{errors.other}</span>
            {canSave && passwordsEqual && (
              <div className="text-center">
                <button className={"btn mt-3  btn-primary btn-sm animated zoomIn faster"} type="submit">
                  Enregistrer mon mot de passe
                </button>
              </div>
            )}
          </div>
        </form>
      )}

      {isSuccess && (
        <div className="d-center flex-column">
          <p>✅ Votre mot de passe a bien été enregistré, vous pouvez à présent vous connecter.</p>
          <Link to="/" className="btn btn-primary">
            Se connecter
          </Link>
        </div>
      )}
    </Page>
  );
};

export default ActivateAccountInvite;
