import React from "react";

const ProductTitle = ({ icon, title }) => {
  return (
    <div className="d-flex align-items-center justify-content-start text-success">
      <div>
        <i className={`fa fa-${icon}`} style={{ fontSize: 41 }} />
      </div>
      <div className="ml-2" style={{ fontSize: 22, lineHeight: "23px" }}>
        <strong>{title}</strong>
      </div>
    </div>
  );
};

export default ProductTitle;
