import React from "react";

const ViewSwitch = ({ showDetailView, setShowDetailView }) => {
  return (
    <div className="d-center" style={{ fontSize: 14 }}>
      <span
        onClick={() => setShowDetailView(false)}
        className={` ${
          !showDetailView ? " font-weight-bold" : "text-black-50 cursor-pointer"
        }`}
        style={
          showDetailView
            ? {
                textDecoration: "underline",
                fontWeight: "500",
              }
            : { color: "black" }
        }
      >
        simple
      </span>
      <div
        className="mx-1"
        style={{
          border: "1px solid #cecece",
          width: 2,
          height: 17,
        }}
      ></div>
      <span
        onClick={() => setShowDetailView(true)}
        className={` ${
          showDetailView ? "font-weight-bold" : "text-black-50 cursor-pointer"
        }`}
        style={
          !showDetailView
            ? {
                textDecoration: "underline",
                fontWeight: "500",
              }
            : { color: "black" }
        }
      >
        détaillé
      </span>
    </div>
  );
};

export default ViewSwitch;
