import React from "react";

const AddressModifier = ({ company }) => {
  return (
    <div>
      <div
        className="d-flex flex-row justify-content-between align-items-center my-3 border-left border-primary"
        style={{
          paddingLeft: 23,
          marginLeft: 10,
          fontSize: 16,
        }}
      >
        <div>
          <div>{company.location1.address}</div>
          <div>{company.location1.address2}</div>
          <div>
            {company.location1.postalCode} {company.location1.city}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressModifier;
