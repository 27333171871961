import React from "react";
import useStore from "../../../../../../../../../context/useStore";
import tools from "../../../../../../../../../helpers/tools";
import InfoGrid from "../../../../../../../../common/InfoGrid/InfoGrid";
import LegalFormValue from "../../../../../../../../common/LegalFormValue/LegalFormValue";
import useCompanyItem from "../../useCompanyItem";

const BPCompanyInfo = () => {
  const { company } = useCompanyItem();
  const [state] = useStore();
  const { CLIENT_TYPES, CONSTRUCTION_ACTIVITIES, CLIENT_INTERESTS } = state.constants.items;

  return (
    <div className="row">
      <div className="col-12">
        <InfoGrid
          rows={[
            [
              {
                title: "Références",
                size: 6,
                fields: [
                  { label: "Date de création", value: tools.formatDate(company.createdAt) },
                  { label: "Date de modification", value: tools.formatDate(company.updatedAt) },
                  {
                    label: "Type de client",
                    value: tools.findIn(CLIENT_TYPES, company.type, "id").name,
                  },
                  { label: "Référence", value: company.reference },
                  { label: "Société", value: company.company },
                ],
              },
              {
                title: "Dirigeant",
                size: 6,
                fields: [
                  { label: "Civilité du dirigeant", value: tools.getGender(company.gender) },
                  { label: "Nom du dirigeant", value: tools.capitalize(company.lastname) },
                  { label: "Prénom du dirigeant", value: tools.capitalize(company.firstname) },
                  {
                    label: "Email du dirigeant",
                    value: <span style={{ wordBreak: "break-all" }}>{company.email}</span>,
                  },
                  { label: "Email secondaire", value: company.email2 },
                  { label: "Téléphone domicile", value: tools.formatPhone(company.phone1) },
                  { label: "Téléphone bureau", value: tools.formatPhone(company.phone2) },
                  { label: "Téléphone mobile", value: tools.formatPhone(company.mobilePhone) },
                ],
              },

              {
                title: "Société",
                size: 6,
                fields: [
                  { label: "SIRET", value: company.siret },
                  { label: "Code APE", value: company.ape },
                  { label: "Activité", value: company.companyActivity },
                  { label: "Date immat.", value: tools.formatDate(company.registrationAt) },
                  {
                    label: "Activité de construction",
                    value: tools.findIn(CONSTRUCTION_ACTIVITIES, company.constructionActivity, "id")
                      .name,
                  },
                  { label: "Site internet", value: company.website },
                ],
              },
              {
                title: "Chiffres clés",
                size: 6,
                fields: [
                  {
                    label: "Forme juridique",
                    value: <LegalFormValue legalForm={company.legalForm} />,
                  },
                  {
                    label: "Chiffre d'affaires",
                    value: tools.truenumberWithSpaces(company.turnover),
                  },
                  { label: "Capital social", value: tools.truenumberWithSpaces(company.capital) },
                  { label: "Effectif", value: company.workforce },
                  {
                    label: "CA sous-traité (%)",
                    value: company.turnoverOutsourced,
                  },
                ],
              },

              {
                title: "Adresse 1",
                size: 6,
                fields: [
                  {
                    label: "Adresse 1",
                    value: company.location1?.address,
                  },
                  {
                    label: "Adresse 1 (suite)",
                    value: company.location1?.address2,
                  },
                  {
                    label: "Adresse 1 (fin)",
                    value: company.location1?.address3,
                  },
                  {
                    label: "Code postal 1",
                    value: company.location1?.postalCode,
                  },
                  {
                    label: "Ville 1",
                    value: company.location1?.city,
                  },
                  {
                    label: "Pays 1",
                    value: company.location1?.country,
                  },
                ],
              },
              {
                title: "Adresse 2",
                size: 6,
                fields: [
                  {
                    label: "Adresse 2",
                    value: company.location2?.address,
                  },
                  {
                    label: "Adresse 2 (suite)",
                    value: company.location2?.address2,
                  },
                  {
                    label: "Adresse 2 (fin)",
                    value: company.location2?.address3,
                  },
                  {
                    label: "Code postal 2",
                    value: company.location2?.postalCode,
                  },
                  {
                    label: "Ville 2",
                    value: company.location2?.city,
                  },
                  {
                    label: "Pays 2",
                    value: company.location2?.country,
                  },
                ],
              },
              // {
              //   title: "Notes",
              //   size: 6,
              //   fields: [
              //     {
              //       label: "Commentaires",
              //       value: company.notes,
              //     },
              //     {
              //       label: "Note importante",
              //       value: company.importantNote,
              //     },
              //   ],
              // },
              // {
              //   title: "Centres d'intérêt",
              //   size: 6,
              //   fields: [
              //     {
              //       label: "Produits",
              //       value: [...new Set(company.interests)].map((ci) => {
              //         return (
              //           <span className="badge badge-primary mr-1">
              //             {tools.findIn(CLIENT_INTERESTS, ci, "id").name}
              //           </span>
              //         );
              //       }),
              //     },
              //   ],
              // },
            ],
          ]}
        />
      </div>
    </div>
  );
};

export default BPCompanyInfo;
