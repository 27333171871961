import React, { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import GreyCard from "../Dashboard/GreyCard";
import PaymentMethodItem from "./PaymentMethodItem";

const PaymentMethods = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageState, setPageState] = useState({
    isLoading: false,
    errors: {},
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [nbPm, setNbPm] = useState(0);

  useEffect(() => {
    getClients();
    // getPaymentMethods();
    //setDisplayPaymentForm(true);
  }, []);

  const getClients = () => {
    setIsLoading(true);
    dataService.get(
      "clients/mines",
      setClients,
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return (
    <Page errors={pageState.errors} margin="mt-5">
      <p className="custom-p mb-0">
        <strong>Retrouvez</strong> facilement <strong>vos moyens de paiement</strong> pour chaque
        société dont vous êtes propriétaire. Ajoutez ou supprimez vos CB et prélèvements SEPA sans
        aucun frais.
      </p>

      <p className="text-grey mb-5" style={{ fontSize: 14, lineHeight: "19px" }}>
        Service de paiement sécurisé fourni par{" "}
        <span style={{ color: "black" }}>Stripe France</span>, 10 boulevard Hausmann, Paris 9ème.
      </p>
      {!clients.length && !isLoading && (
        <p>
          <strong>Vous ne gérez aucune société en tant que propriétaire pour le moment.</strong>
        </p>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        clients.map((client, ck) => {
          return (
            <GreyCard marginBottom={15}>
              <PaymentMethodItem client={client} nbPm={nbPm} setNbPm={setNbPm} />
            </GreyCard>
          );
        })
      )}
      {/* <Accordion
        btnClassName="shadow-sm"
        cardClassName="shadow-none bg-transparent mb-1"
        bodyClassName=""
        color="default"
        btnStyle={{
          border: "1px solid #d8d8d8",
          borderRadius: 8,
          overflowX: "hidden",
        }}
        items={clients.map((client) => ({
          title: (
            <div className="text-success d-flex align-items-center justify-content-between w-100">
              <div>
                <i className="fa fa-building mr-2" />
                <strong>{client.company}</strong>
              </div>
              <div
                style={{
                  width: 30,
                  height: 30,
                }}
                className={`${
                  parseInt(nbPm[client.id]) > 0
                    ? "bg-primary text-white font-weight-bold"
                    : "bg-light text-primary"
                }  d-center shadow rounded-circle`}
              >
                {nbPm[client.id] || nbPm[client.id] === 0 ? (
                  nbPm[client.id]
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          ),
          content: <PaymentItem client={client} setNbPm={setNbPm} />,
        }))}
      /> */}
      {/* <>
        {paymentMethods.find((p) => p.type == "CB") && (
          <ul className="list-group">
            {paymentMethods
              .filter((p) => p.type == "CB")
              .map((p) => (
                <li className="list-group-item">
                  <i className={`pf pf-${cardBrandToPfClass[p.brand]}`} />{" "}
                  {p.info}
                </li>
              ))}
          </ul>
        )}
        {paymentMethods.find((p) => p.type == "SEPA") && (
          <div className="col-12 col-md-6">
            <Control
              label="IBAN enregistrés"
              type="btnList"
              datas={paymentMethods.filter((p) => p.type == "SEPA")}
              dataIndex="paymentMethodId"
              dataLabel="info"
              value={null}
              change={(e) => {}}
            />
          </div>
        )}
      </> */}
    </Page>
  );
};

export default PaymentMethods;
