import dataService from "../helpers/dataService";

export const searchClients = (searchData) => {
  return new Promise((resolve, reject) => {
    dataService.post(`customers/search`, searchData, resolve, reject);
  });
};
export const getClients = () => {
  return new Promise((resolve, reject) => {
    dataService.get(`clients/mines/customers`, resolve, reject);
  });
};

export const searchClientUsers = (clientId) => {
  return new Promise((resolve, reject) => {
    dataService.post(`customers/mines`, { clientId }, resolve, reject);
  });
};
