import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const ClientAccordion = ({ client, isOpenByDefault = false, ...props }) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleIsOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div>
      <div
        className="bg-default border p-3 d-flex justify-content-between align-items-center flex-row flex-wrap cursor-pointer rounded mb-2"
        onClick={toggleIsOpen}
      >
        <h4 className="m-0 p-0">{client.client}</h4>
        <i
          className={`fa text-primary ${
            isOpen ? "fa-chevron-up" : "fa-chevron-down"
          } fa-2x`}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: "0px" }}
            animate={{ height: "auto" }}
            exit={{ height: "0px" }}
            transition={{ ease: "linear" }}
            className={`d-flex flex-row align-items-start justify-content-around flex-wrap w-100 overflow-hidden  pt-4 rounded`}
          >
            {props.children}
          </motion.div>
        )}
      </AnimatePresence>
      {isOpen && (
        <div
          className="d-center flex-column w-100 mb-3 cursor-pointer position-relative"
          onClick={toggleIsOpen}
        >
          <div className="border-top w-100 my-3" style={{ height: 1 }}></div>
          <i
            className="fa fa-chevron-circle-up fa-2x text-primary bg-white"
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ClientAccordion;
