import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import tools from "../../../helpers/tools";
import useQuery from "../../../hooks/useQuery";
import PaymentItem from "./PaymentItem";

const PaymentMethodItem = ({ client, nbPm, setNbPm }) => {
  const query = useQuery();
  const [showDetail, setShowDetail] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const clientId = query.get("clientId");
    if (clientId) {
      const element = document.querySelector(`#pmof${clientId}`);
      if (element) tools.scrollToTargetAdjusted(element, 90);
      if (clientId == client.id) {
        setShowDetail(true);
      }
    }
  }, []);

  return (
    <>
      <div
        className="d-flex align-items-start flex-row justify-content-between w-100 cursor-pointer"
        id={`pmof${client.id}`}
        onClick={() => setShowDetail(!showDetail)}
      >
        <span className="d-center">
          <i className={`fa fa-chess-rook text-primary mr-3`} style={{ fontSize: 30 }} />
          <strong
            className="text-primary"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: 14,
              lineHeight: "17px",
              fontWeight: "500",
            }}
          >
            <span className="text-success">{client.company}</span>
          </strong>
        </span>
        <span className="d-center">
          <div
            style={{
              width: 35,
              height: 35,
              fontSize: 24,
            }}
            className={`
                       font-weight-bold d-center  rounded-circle ${
                         nbPm[client.id] > 0 ? "bg-success text-white" : "bg-default text-black-50"
                       }`}
          >
            {nbPm[client.id] ? nbPm[client.id] : 0}
          </div>
          <i
            className={`fa fa-chevron-${showDetail ? "up" : "down"} text-primary position-relative`}
            style={{ fontSize: 24, top: -14, right: -6 }}
          />
        </span>
      </div>
      {paymentMethods.length > 0 &&
        paymentMethods.map((pm) => {
          if (pm.type == "CB" && DateTime.local() > DateTime.fromISO(pm.expirationDate)) {
            return (
              <div
                className="py-2 badge badge-warning"
                style={{ fontSize: 13 }}
                key={`pmexpi${pm.paymentMethodId}`}
              >
                La carte {pm.info} a expiré le {tools.formatDate(pm.expirationDate)}
              </div>
            );
          }

          return null;
        })}
      <div
        style={{
          overflow: "hidden",
          transition: "400ms",
          maxHeight: showDetail ? "800px" : "0px",
        }}
      >
        <PaymentItem client={client} setNbPm={setNbPm} onSetPaymentMethods={setPaymentMethods} />
      </div>
    </>
  );
};

export default PaymentMethodItem;
