import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Loader from "../../common/Loader";
import Timer from "../../common/Timer/Timer";
import DownloadButton from "./DownloadButton";

const MRPDocs = ({ client }) => {
  const prudenceCreoleFileCode = "PROPO_ASSU_MRP_PC";
  const allCompaniesFileCode = "PROPO_ASSU_MRP_PERCEVAL";
  const hasManyEstab = client.establishments.length > 1;
  const [isLoading, setIsLoading] = useState(false);
  const [timer1, setTimer1] = useState(0);
  const [files, setFiles] = useState({
    [prudenceCreoleFileCode]: null,
    [allCompaniesFileCode]: null,
  });

  useEffect(() => {
    if (timer1 > 0) {
      setTimeout(
        () => document.querySelector(`#doc-container-${client.quotationId}`).scrollIntoView(),
        1000
      );
    }
  }, [timer1]);

  useEffect(() => {
    getAllDocs();
  }, []);

  const getAllDocs = async () => {
    const file1 = await getDocs(prudenceCreoleFileCode);
    const file2 = await getDocs(allCompaniesFileCode);
    setFiles({
      ...file1,
      ...file2,
    });
  };

  useEffect(() => {
    //On check si des quotations ont été update il y a moins de 40sec
    //car on doit bloquer le téléchargement du doc pendant qu'il est généré
    //sur monkeyPDF
    var lastUpdateSeconds = DateTime.fromISO(client.quotationUpdatedAt)
      .diffNow("seconds")
      .toObject();
    lastUpdateSeconds = parseInt(Math.abs(lastUpdateSeconds.seconds));
    if (40 - lastUpdateSeconds > 0) {
      setTimer1(40 - lastUpdateSeconds);
    }
  }, []);

  const getDocs = (docCode) => {
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      dataService.get(
        `quotations/${client.quotationId}/files-by-file-type/${docCode}`,
        (data) => {
          if (data?.id) {
            resolve({ [docCode]: data });
          } else {
            resolve({});
          }
        },
        (err) => {
          setTimer1(40);
        },
        () => setIsLoading(false)
      );
    });
  };

  const downloadDoc = (docCode) => {
    var filename = tools.getDocumentName({
      fileExt: "pdf",
      documentName: files[docCode].fileType.name,
      productName: "MRP",
      companyName: client.client,
      uploadedAt: files[docCode].uploadedAt,
    });
    setIsLoading(docCode);
    dataService.downloadFile(
      `files/${files[docCode].id}`,
      { filename },
      () => {
        const dataLayer = window.dataLayer;
        if (dataLayer) {
          dataLayer.push({
            event: "telechargment_pdf",
            nom_pdf: docCode,
          });
        }
      },
      () => {},
      () => setIsLoading(false)
    );
  };

  const onTimer1Finish = async (docCode) => {
    setTimer1(0);
    const file1 = await getDocs(prudenceCreoleFileCode);
    const file2 = await getDocs(allCompaniesFileCode);
    setFiles({
      ...file1,
      ...file2,
    });
  };

  return (
    <>
      <div
        className="bg-blue px-4 pb-4 pt-4 d-center flex-column mt-4 shadow-small rounded text-center w-100 border"
        id={`doc-container-${client.quotationId}`}
        style={{ fontSize: 16 }}
      >
        <strong style={{ fontSize: 18 }}>
          Téléchargez votre questionnaire Multirisque Professionnelle pour
        </strong>
        <div className="text-success d-center flex-column mt-3">
          <strong style={{ fontSize: 18 }}>{client.client}</strong>
          <small>
            ({client.establishments.length} établissement
            {hasManyEstab && "s"})
          </small>
        </div>
        {timer1 > 0 && (
          <div className="mt-3">
            <Timer timeLimit={timer1} timerFinish={onTimer1Finish} />
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="d-center w-100 flex-row flex-wrap my-3">
            {files[prudenceCreoleFileCode] ? (
              <DownloadButton
                downloadFile={() => downloadDoc(prudenceCreoleFileCode)}
                text="Prudence Créole"
                image="prudence-creole-icon.png"
                disabled={isLoading}
                timer={timer1}
                isLoading={isLoading}
              />
            ) : isLoading ? (
              <Loader />
            ) : null}
            {files[allCompaniesFileCode] ? (
              <DownloadButton
                downloadFile={() => downloadDoc(allCompaniesFileCode)}
                text="Toutes compagnies"
                image="toutes-compagnies-icon.png"
                disabled={isLoading}
                timer={timer1}
                isLoading={isLoading}
              />
            ) : isLoading ? (
              <Loader />
            ) : null}
          </div>
        )}
        <p className="mb-0 text-success text-center" style={{ fontSize: 14 }}>
          Le questionnaire <strong>Toutes Compagnies</strong> répond aux attentes de tous les
          assureurs.
          <br />
          Transmettez-le au Courtier ou à la Compagnie de votre choix pour
          <strong> souscrire ou mettre à jour votre contrat Multirisque Professionnelle.</strong>
        </p>
      </div>
    </>
  );
};

export default MRPDocs;
