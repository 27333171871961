import React from "react";
import styled from "styled-components";
import tools from "../../../helpers/tools";
import Badge from "../Badge/Badge";

const CompanyInfo = ({ isHeadOffice, noBadge, company, siret, infos = [] }) => {
  return (
    <StyledCompanyInfo>
      {!noBadge && <Badge color="default">{isHeadOffice ? "Siège" : "Établissement"}</Badge>}
      <div className="info__company">{company}</div>
      <div className="info__siret">{tools.formatFollowingPattern(siret, [3, 3, 3, 5], " ")}</div>
      {infos.map((info, ik) => (
        <div className="info__item" key={`inf${ik}`} title={info}>
          {info}
        </div>
      ))}
    </StyledCompanyInfo>
  );
};

const StyledCompanyInfo = styled.div`
  & .info__company {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 600;
  }
  & .info__siret {
    font-size: 12px;
    color: var(--color-default-dark);
  }

  & .info__item {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export default CompanyInfo;
