import React from "react";
import Loader from "../../common/Loader";
import ErrorBlock from "../../common/PaymentComponents/ErrorBlock";

const PaymentSuccess = ({ isVerifyingPayment, paymentVerified, verifErrors }) => {
  var errArr = Object.values(verifErrors);

  return (
    <div className="row">
      <div className="col-12">
        {isVerifyingPayment ? (
          <Loader />
        ) : paymentVerified ? (
          <div
            className="bg-white p-4 shadow-sm text-center d-center flex-column border"
            style={{ fontSize: 18 }}
          >
            <i className="fa fa-check-double text-info mb-3 fa-3x animated rotateIn" />
            Vos modifications ont bien été prises en compte.
            <br />
            Retrouvez votre avenant et votre quittance dans la section détails de votre contrat.
            <br />
            <strong>
              La génération de ces documents est en cours et peut prendre quelques minutes.
            </strong>
          </div>
        ) : errArr.length > 0 ? (
          <ErrorBlock errorsArray={errArr} />
        ) : null}
      </div>
    </div>
  );
};

export default PaymentSuccess;
