import { useStripe } from "@stripe/react-stripe-js";
import React from "react";
import tools from "../../../helpers/tools";
import Loader from "../Loader";
import ErrorBlock from "../PaymentComponents/ErrorBlock";

const PayWithSavedCard = ({
  paymentIntent,
  errors,
  setErrors,
  setPaymentIntent,
  isPaying,
  setIsPaying,
  validatePayment,
  selectedPaymentMethod,
}) => {
  const stripe = useStripe();

  const pay = async () => {
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setErrors({});
    setIsPaying(true);

    const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
      payment_method: selectedPaymentMethod,
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setErrors({ payment: result.error.message });
      setIsPaying(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        setIsPaying(false);
        validatePayment();
        setPaymentIntent(result.paymentIntent);

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  var errorsArr = Object.values(errors);

  return errorsArr.length > 0 ? (
    <ErrorBlock errorsArray={errorsArr} />
  ) : (
    <div className="d-flex justify-content-between mt-2">
      {isPaying ? (
        <div className="d-center w-100">
          <Loader
            className="flex-column mt-4"
            text="Paiement en cours"
            textClassName="mt-2"
          />
        </div>
      ) : (
        <button
          className="btn btn-primary w-100"
          onClick={pay}
          disabled={!stripe}
        >
          Payer{" "}
          <strong>
            {paymentIntent.amount
              ? tools
                  .truenumberWithSpaces(
                    Math.round((paymentIntent.amount / 100) * 100) / 100
                  )
                  .toString()
                  .replace(".", ",") + " €"
              : ""}
          </strong>{" "}
          avec la carte sélectionnée
        </button>
      )}
    </div>
  );
};

export default PayWithSavedCard;
