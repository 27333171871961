import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import ErrorBlock from "../../common/PaymentComponents/ErrorBlock";

const PaymentSuccess = ({
  isVerifyingPayment,
  paymentVerified,
  verifErrors,
}) => {
  var errArr = Object.values(verifErrors);

  return (
    <div className="row">
      <div className="col-12">
        {isVerifyingPayment ? (
          <Loader />
        ) : paymentVerified ? (
          <div
            className="bg-white p-4 shadow-sm text-center d-center flex-column border"
            style={{ fontSize: 18 }}
          >
            <i className="fa fa-check-double text-info mb-3 fa-3x animated rotateIn" />
            Vos modifications ont bien été prises en compte.
            <br />
            <p>
              Retrouvez votre contrat mis à jour sur la page{" "}
              <Link to="/clients" className="btn btn-link btn-sm">
                <i className="fa fa-link mr-2" />
                Contrats
              </Link>
            </p>
            <p>
              Retrouvez votre facture sur la page{" "}
              <Link to="/mes-factures" className="btn btn-link btn-sm">
                <i className="fa fa-link mr-2" />
                Mes factures
              </Link>
            </p>
            <br />
            <p>
              <strong>
                La génération du nouveau contrat est en cours et peut prendre
                quelques minutes.
              </strong>
            </p>
          </div>
        ) : errArr.length > 0 ? (
          <ErrorBlock errorsArray={errArr} />
        ) : null}
      </div>
    </div>
  );
};

export default PaymentSuccess;
