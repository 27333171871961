import React from "react";
import ReactDOM from "react-dom";
import "./variables.scss";
import "./index.scss";
import "./animate.css";
import "./color-theme.scss";
import "./buttons.scss";
import "./inputs.scss";
import "./titles.scss";
import "./medias.scss";
import App from "./App";
import Store from "./context/Store";
import rootReducer from "./context/rootReducer";
import "react-dates/initialize";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
ReactDOM.render(
  <Store rootReducer={rootReducer}>
    <App />
  </Store>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.unregister();
