import React from "react";
import tools from "../../../helpers/tools";

const PaymentCheck = ({
  params,
  validatePayment,
  paymentAction,
  priceToPay,
}) => {
  const save = () => {
    validatePayment({
      policyId: params.policyId,
      paymentDatas: {
        paymentType: "CHECK",
        paymentAction,
      },
      product: {
        beginAt: params.beginAt,
        isPremium: params.isPremium,
        optRc: params.optRc,
        optFs: params.optFs,
        turnover: params.turnover,
      },
    });
  };

  return (
    <>
      <div
        className="font-weight-bold mb-2 mt-3"
        style={{
          fontSize: 24,
        }}
      >
        Chèque
      </div>
      <p>
        Vous optez pour le paiement par chèque, les garanties vous sont acquises
        au jour de la date d'effet choisie. Vous avez 30 jours à compter
        d'aujourd'hui pour envoyer votre chèque libellé à l’ordre de{" "}
        <strong>Perceval</strong> au{" "}
        <strong>14 Allée des Primevères, 97490 Sainte Clotilde.</strong>
      </p>
      <button
        onClick={save}
        className="btn-primary py-3 font-weight-bold w-100 border-0"
      >
        PAYER{" "}
        {tools
          .truenumberWithSpaces(Math.round(priceToPay * 100) / 100)
          .toString()
          .replace(".", ",")}
        €
      </button>
    </>
  );
};

export default PaymentCheck;
