import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../../common/Avatar";
import Loader from "../../common/Loader";

const AccountItem = ({ user, cancelInvitation, k, isCancelling }) => {
  return (
    <div
      className="p-3 mb-3 rounded animated fadeInRight fast "
      style={{
        background: user.isPending ? "#EBF7EF" : "#F1F2FE",
        animationDelay: parseInt(k) * 80 + "ms",
      }}
    >
      <div className="d-flex flex-row justify-content-start align-items-start">
        <div>
          <Avatar
            id={user.id}
            user={`${user.firstname} ${user.lastname}`}
            className="d-center"
            containerWidth={54}
            width={"auto"}
            height={54}
            acronymSize={54}
            acronymFontsize={23}
          />
        </div>
        <div className="pl-3" style={{ maxWidth: "80%", color: "black" }}>
          <div
            className="font-weight-bold"
            style={{ lineHeight: "19px", textTransform: "capitalize" }}
          >
            {user.firstname}
          </div>
          <div
            style={{
              textTransform: "uppercase",
              lineHeight: "19px",
              fontWeight: "500",
            }}
          >
            {user.lastname}
          </div>
          <div
            style={{
              lineHeight: "19px",
              fontWeight: "400",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              width: "100%",
            }}
          >
            {user.email}
          </div>
        </div>
      </div>
      <div className="mt-3">
        {user.role.map((role, rk) => (
          <div key={`role${rk}${user.isPending ? "pending" : "normal"}`}>
            <div className="font-weight-bold">{role.role}</div>
            <ul
              style={{
                listStyle: "none",
                padding: 0,
                fontSize: 12,
                lineHeight: "19px",
              }}
            >
              {role.clients.map((c, ck) => (
                <li key={`comp${rk}${c}`}>- {c}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="mt-3 d-center">
        {user.isPending ? (
          isCancelling == user.role[0].id ? (
            <Loader />
          ) : (
            <button
              className="btn btn-danger btn-sm"
              onClick={() => cancelInvitation(user.role[0].id)}
            >
              Annuler
            </button>
          )
        ) : (
          <Link to={`/accounts/${user.id}`} className="btn btn-primary btn-sm">
            Modifier
          </Link>
        )}
      </div>
    </div>
  );
};

export default AccountItem;
