const risksTips = {
  isMortgage: {
    title: "Fonds de commerce",
    content: 
      <>
          <p><strong>Un crédit hypothécaire est un crédit par lequel une personne (morale ou physique) met en garantie un immeuble.</strong></p>
          <p>On dit que cet immeuble est hypothéqué.</p>
          <p><strong>Bon à savoir</strong> : on appelle « créancier hypothécaire » l’institution bancaire à qui doit être remboursé le crédit hypothécaire.</p>
      </>
    },
};

export default risksTips;
