import React, { useEffect, useState } from "react";
import ActionCard from "../../common/ActionCard/ActionCard";
import Control from "../../common/Control";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import InfoGozenPremium from "./InfoGozenPremium";
import InfoOptRc from "./InfoOptRc";
import InfoOptFs from "./InfoOptFs";
import useContract from "./useContract";
import dataService from "../../../helpers/dataService";
import PriceResults from "./PriceResults";
import { DateTime } from "luxon";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import tools from "../../../helpers/tools";
import Payment from "../../common/Payment/Payment";
import PaymentSuccess from "./PaymentSuccess";
import ErrorBlock from "../../common/PaymentComponents/ErrorBlock";
import GoDown from "../../common/PaymentComponents/GoDown";
import PaymentMethods from "../../common/Payment/PaymentMethods";
import { colors } from "../../../helpers/colors";

var searchTimeout = null;
var godownTimeout = null;

const ContractDetail = (props) => {
  const [params, setParams] = useState({
    turnover: 0,
    isPremium: false,
    optFs: false,
    optRc: false,
    policyId: false,
    beginAt: DateTime.local().toISO(),
  });
  const {
    state,
    constants,
    isLoading,
    errors,
    contract,
    itemId,
    isInit,
    setIsLoading,
    setErrors,
    setContract,
    getContract,
  } = useContract(props);

  const [hasChanged, setHasChanged] = useState(false);
  const [results, setResults] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [displayPayment, setDisplayPayment] = useState(false);
  const [displayPaymentForm, setDisplayPaymentForm] = useState(false);
  const [priceErrors, setPriceErrors] = useState({});
  const [goDown, setGoDown] = useState(false);
  const [isVerifyingPayment, setIsVerifyingPayment] = useState(false);
  const [paymentOk, setPaymentOk] = useState(false);
  const [paymentVerified, setPaymentVerified] = useState(false);
  const [verifErrors, setVerifErrors] = useState({});
  const [whatHasChanged, setWhatHasChanged] = useState({});
  const [hasSavedPaymentMethods, setHasSavedPaymentMethods] = useState(false);

  useEffect(() => {
    if (paymentOk) {
      registerPaymentOnDataLayer();
      setIsVerifyingPayment(true);
      const data = {
        produitData: {
          contratId: paymentOk.policyId,
          debutLe: paymentOk.product.beginAt,
          chiffreAffaires: paymentOk.product.turnover,
          typeProduit: "PJ",
          avenantType: "UPDATE",
          simulation: false,
          data: {
            isPremium: paymentOk.product.isPremium,
            optFs: paymentOk.product.optFs,
            optRc: paymentOk.product.optRc,
          },
        },
        paiementData: {
          avenantType: "UPDATE",
          typeMethodePaiement: paymentOk.paymentDatas?.paymentType || null,
          intentionPaiementId: paymentOk.paymentDatas?.paymentIntentId || null,
        },
      };
      dataService.patch(
        `policies/${paymentOk.policyId}/update`,
        data,
        // {
        //   productData: paymentOk.product,
        //   paymentData: paymentOk.paymentDatas,
        // },
        () => {
          setPaymentVerified(true);
          getContract();
        },
        setVerifErrors,
        () => setIsVerifyingPayment(false)
      );
    }
  }, [paymentOk]);

  const registerPaymentOnDataLayer = () => {
    var dlData = {
      event: "ContractModify",
      ecommerce: {
        purchase: {
          actionField: {
            id: Date.now(), // Transaction ID. Required for purchases and refunds.
            revenue: "" + results.proration.GLOBAL.ttc, // Total transaction value (incl. tax and shipping)
            tax: "0",
          },
          products: [],
        },
      },
    };
    window.dataLayer.push({
      event: "payment-method",
      "payment-method": paymentOk?.paymentDatas?.paymentType || "SEPA",
    });
    dlData.ecommerce.purchase.products.push({
      name: "Protection juridique",
      id: "2",
      price: "" + results.proration.GLOBAL.ttc,
      quantity: 1,
      category: "Modification de contrat",
    });
    window.dataLayer.push(dlData);
  };

  useEffect(() => {
    if (isInit) {
      let par = { ...params };
      par = {
        turnover: contract.policy.client.turnover,
        isPremium: contract.policy.isGozenPremium,
        optFs: contract.policy.options?.includes("OPTION_FS") || false,
        optRc: contract.policy.options?.includes("OPTION_RC") || false,
        policyId: itemId,
        beginAt: DateTime.local().toISO(),
      };
      var amendmentDate = DateTime.fromISO(contract.amendment.mainEcheance)
        .minus({ month: 1 })
        .toJSDate();
      var nowPlus4Month = DateTime.local()
        .plus({ months: 4, day: 1 })
        .toJSDate();
      var mDate = null;

      mDate = amendmentDate < nowPlus4Month ? amendmentDate : nowPlus4Month;

      setMaxDate(mDate);

      var effectDate = DateTime.fromISO(contract.amendment.effectDate);
      if (effectDate > DateTime.fromISO(params.beginAt)) {
        par.beginAt = effectDate.toISO();
        setMinDate(effectDate.toJSDate());
      } else {
        setMinDate(DateTime.fromISO(params.beginAt).toJSDate());
      }
      setParams(par);
    }
  }, [isInit]);

  useEffect(() => {
    if (hasChanged && checkHasReallyChanged()) {
      clearTimeout(searchTimeout);
      clearTimeout(godownTimeout);
      setIsCalculating(true);
      setGoDown(true);
      searchTimeout = setTimeout(() => {
        calculate();
      }, 1000);
      godownTimeout = setTimeout(() => {
        setGoDown(false);
      }, 3000);
    }
    setDisplayPayment(false);
    setDisplayPaymentForm(false);
  }, [params]);

  const checkHasReallyChanged = () => {
    var whc = {
      turnover: params.turnover == contract.policy.client.turnover,
      isPremium: params.isPremium == contract.policy.isGozenPremium,
      optFs: params.optFs == contract.policy.options?.includes("OPTION_FS"),
      optRc: params.optRc == contract.policy.options?.includes("OPTION_RC"),
    };

    var hasReallyChanged = !(
      contract.policy &&
      whc.isPremium &&
      whc.optFs &&
      whc.optRc
    );
    setHasChanged(hasReallyChanged);
    setWhatHasChanged(whc);
    return hasReallyChanged;
  };

  const calculate = () => {
    setPriceErrors({});

    const data = {
      contratId: params.policyId,
      ape: contract.policy?.client?.ape || "",
      debutLe: params.beginAt,
      // "finLe": "2024-05-18",
      codePostal: contract.policy?.client?.location1?.postalCode || "",
      chiffreAffaires: parseInt(params.turnover),
      typeProduit: "PJ",
      avenantType: "UPDATE",
      simulation: false,
      data: {
        isPremium: params.isPremium,
        optFs: params.optFs,
        optRc: params.optRc,
      },
    };
    dataService.post(
      `tarificators/policies/${params.policyId}`,
      data,
      setResults,
      setPriceErrors,
      () => setIsCalculating(false)
    );
  };

  const beginAtChange = (date) => {
    setParams((inp) => {
      return {
        ...inp,
        beginAt: DateTime.fromJSDate(date)
          .startOf("day")
          .toFormat("yyyy-MM-dd"),
      };
    });
  };
  var priceErrorsArr = Object.values(priceErrors);

  return isInit ? (
    <>
      {goDown && <GoDown />}
      <Page
        container="container-fluid"
        containerClassname={"mx-auto"}
        style={{ maxWidth: 900 }}
        title={
          <div className="text-left">
            Modifier le contrat{" "}
            <span style={{ color: colors.info }}>{contract.policy.title}</span>
            <br />
            de{" "}
            <span style={{ color: colors.primary }}>
              {contract.policy.client.company}
            </span>
            <br />
            <div
              className="mt-2"
              style={{ color: colors.dark, fontSize: "1rem" }}
            >
              En cours du{" "}
              {DateTime.fromISO(contract.amendment.effectDate).toFormat(
                "dd/MM/yyyy"
              )}{" "}
              au{" "}
              {DateTime.fromISO(contract.amendment.mainEcheance)
                .minus({ days: 1 })
                .toFormat("dd/MM/yyyy")}
            </div>
            <div style={{ color: colors.dark, fontSize: "1rem" }}>
              Prime actuelle{" "}
              {tools.formatNumber2Decimals(contract.amendment.annualPremium)}{" "}
              €/an
            </div>
          </div>
        }
        errors={errors}
        back="/clients"
      >
        {isLoading && <Loader />}
        {!paymentOk ? (
          <div
            className="mx-auto"
            style={{ maxWidth: 900, overflow: "hidden" }}
          >
            <div className="row">
              <div className="col-12">
                <div
                  className="alert alert-primary font-weight-bold d-flex align-items-center justify-content-start"
                  style={{ fontSize: 13 }}
                >
                  <i className="fa fa-question-circle fa-2x mr-3" />
                  <p className="mb-0">
                    Faites évoluer les garanties de votre Protection Juridique
                    selon vos besoins.
                    <br />
                    Besoin d'aide ? Notre équipe vous conseille sur le{" "}
                    <span
                      style={{
                        borderBottom: "1px solid",
                        cursor: "pointer",
                      }}
                      onClick={() => window.Tawk_API.maximize()}
                    >
                      tchat <i className="fa fa-comment" />
                    </span>
                    .
                  </p>
                </div>
              </div>
              <div className="col-12">
                <div className="border-bottom">
                  <div
                    className="d-flex flex-wrap  align-items-center justify-content-between bg-white p-3"
                    style={{ gap: "1rem" }}
                  >
                    <div>
                      <h1 className="">Votre chiffre d'affaires</h1>
                      <p
                        className="mb-0 mt-1 text-black-50"
                        style={{ fontSize: 14 }}
                      >
                        Mettez le à jour si celui-ci a changé.
                      </p>
                    </div>
                    <Control
                      name="turnover"
                      type="text"
                      value={tools.truenumberWithSpaces(params.turnover)}
                      suffix="€"
                      inputStyle={{
                        background: "rgb(241 241 241)",
                        fontSize: 24,
                        border: "rgba(255,255,255,0.01)",
                        textAlign: "right",
                      }}
                      appendStyle={{
                        background: "white",
                        fontSize: 24,
                        color: "#3e75ff",
                        paddingLeft: 8,
                        border: "rgba(255,255,255,0.01)",
                      }}
                      containerStyle={{
                        width: 190,
                        marginLeft: 150,
                        textAlign: "center",
                        background: "white",
                        border: "rgba(255,255,255,0.01)",
                      }}
                      change={(e) => {
                        setHasChanged(true);
                        setParams({
                          ...params,
                          turnover: e.target.value.split(" ").join(""),
                        });
                      }}
                      error={errors}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <ActionCard
                  title="Les garanties renforcées"
                  params={params}
                  isActive={params.isPremium}
                  onClick={() => {
                    setHasChanged(true);
                    setParams({
                      ...params,
                      isPremium: !params.isPremium,
                    });
                  }}
                  isPlurial={true}
                  infos={<InfoGozenPremium />}
                  subText="Doublez la mise et profitez de services supplémentaires."
                />

                <ActionCard
                  title="L'option Fiscale et Sociale"
                  params={params}
                  isActive={params.optFs}
                  onClick={() => {
                    setParams({ ...params, optFs: !params.optFs });
                    setHasChanged(true);
                  }}
                  infos={<InfoOptFs />}
                  subText="Nous intervenons lors d'un contrôle de l'administration fiscale."
                />

                <ActionCard
                  title="L'option Recouvrement de Créances"
                  params={params}
                  isActive={params.optRc}
                  onClick={() => {
                    setParams({ ...params, optRc: !params.optRc });
                    setHasChanged(true);
                  }}
                  infos={<InfoOptRc />}
                  subText="Une facture impayée ? On s'occupe du recouvrement."
                />
              </div>
              <div className="col-12 mb-5">
                <div className="border-bottom">
                  <div
                    className="d-flex flex-wrap align-items-start justify-content-between bg-white p-3"
                    style={{ gap: "1rem" }}
                  >
                    <div>
                      <h1 className="">
                        Date d'effet
                        <br /> de la modification :{" "}
                        {DateTime.fromISO(params.beginAt).toFormat(
                          "dd/MM/yyyy"
                        )}
                      </h1>
                      <p
                        className="mb-0 mt-1 text-black-50"
                        style={{ fontSize: 14 }}
                      >
                        À quelle date ces modifications prendront effet ?
                      </p>
                    </div>
                    <div className="d-flex justify-content-end mx-auto">
                      <Calendar
                        next2Label={null}
                        prev2Label={null}
                        minDate={minDate}
                        maxDate={maxDate}
                        locale="fr-FR"
                        onChange={(date) => {
                          beginAtChange(date);
                          setHasChanged(true);
                        }}
                        value={DateTime.fromISO(params.beginAt).toJSDate()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 text-center my-2">
          <button
            className="btn btn-primary py-4 w-100 font-weight-bold"
            onClick={calculate}
          >
            {isCalculating ? (
              <Loader />
            ) : (
              "Calculer ma nouvelle prime d'assurance"
            )}
          </button>
        </div> */}

            <div className="col-12 mb-5" id="anchor-pj">
              {isCalculating && (
                <div className="text-center mt-4">
                  <Loader />
                </div>
              )}
              {priceErrorsArr.length > 0 ? (
                <ErrorBlock errorsArray={priceErrorsArr} />
              ) : results && hasChanged ? (
                <PriceResults
                  params={params}
                  results={results}
                  isCalculating={isCalculating}
                  mainEcheance={contract.amendment.mainEcheance}
                  contractName={contract.policy.title}
                  oldPrime={contract.amendment.annualPremium}
                  displayPayment={displayPayment}
                  setDisplayPayment={setDisplayPayment}
                  setPaymentOk={setPaymentOk}
                  whatHasChanged={whatHasChanged}
                  paymentAction={"UPDATE"}
                />
              ) : (
                <div className="d-center text-center p-2">
                  <p className="mb-0" style={{ fontSize: 14 }}>
                    Apportez un changement à vos options pour recalculer votre
                    prime
                  </p>
                </div>
              )}
            </div>
            {displayPayment && !displayPaymentForm && (
              <div className="col-12 mb-5">
                <PaymentMethods
                  policyDatas={contract}
                  setDisplayPaymentForm={setDisplayPaymentForm}
                  params={params}
                  paymentAction={"UPDATE"}
                  priceToPay={results.proration?.GLOBAL?.ttc}
                  setPaymentOk={setPaymentOk}
                  setHasSavedPaymentMethods={setHasSavedPaymentMethods}
                />
              </div>
            )}
            {displayPaymentForm && (
              <div className="col-12 mb-5">
                <Payment
                  paymentOk={paymentOk}
                  setPaymentOk={setPaymentOk}
                  params={params}
                  paymentAction={"UPDATE"}
                  policyDatas={contract}
                  priceToPay={results.proration?.GLOBAL?.ttc}
                  hasSavedPaymentMethods={hasSavedPaymentMethods}
                  setDisplayPaymentForm={setDisplayPaymentForm}
                />
              </div>
            )}
          </div>
        ) : (
          <PaymentSuccess
            isVerifyingPayment={isVerifyingPayment}
            paymentVerified={paymentVerified}
            verifErrors={verifErrors}
          />
        )}
        <div style={{ height: 200 }}></div>
      </Page>
    </>
  ) : null;
};

export default ContractDetail;
