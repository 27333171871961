import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";
import React from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../../../../context/actions/modalActions";
import useStore from "../../../../../../context/useStore";
import dataService from "../../../../../../helpers/dataService";
import { clientAtom } from "../useClientItem";
import UserForm from "./UserForm";

const isSendingActivationAtom = atom(false);
const isSavingUserAtom = atom(false);
const isUnlockingAtom = atom(false);
const isActivatingAtom = atom(false);
const errorsAtom = atom({});
const isSavingEmailAtom = atom(false);
const isSavingPhoneAtom = atom(false);
const isSavingPasswordAtom = atom(false);
const isAllClientsAtom = atom(false);

const useUserInfos = () => {
  const [client, setClient] = useAtom(clientAtom);
  const [isSendingActivation, setIsSendingActivation] = useAtom(isSendingActivationAtom);
  const [isActivating, setIsActivating] = useAtom(isActivatingAtom);
  const [isUnlocking, setIsUnlocking] = useAtom(isUnlockingAtom);
  const [isSavingUser, setIsSavingUser] = useAtom(isSavingUserAtom);
  const [isSavingPassword, setIsSavingPassword] = useAtom(isSavingPasswordAtom);
  const [errors, setErrors] = useAtom(errorsAtom);

  const [isSavingEmail, setIsSavingEmail] = useAtom(isSavingEmailAtom);
  const [isSavingPhone, setIsSavingPhone] = useAtom(isSavingPhoneAtom);
  const [isAllClients, setIsAllClients] = useAtom(isAllClientsAtom);

  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);

  const sendActivation = () => {
    setIsSendingActivation(true);
    dataService.get(
      `customers/${client.id}/email_activation`,
      (data) => {
        setClient({ ...client, activationTokenSendedAt: DateTime.local().toISO() });
        toast.success("Email d'activation envoyé");
      },
      (err) => {},
      () => setIsSendingActivation(false)
    );
  };

  const unlockAccount = () => {
    setIsUnlocking(true);
    dataService.get(
      `customers/${client.id}/authorization/reset`,
      (data) => {
        toast.success("Le compte de l'utilsateur a été débloqué");
      },
      setErrors,
      () => setIsUnlocking(false)
    );
  };

  const activateMail = () => {
    setIsActivating(true);
    dataService.patch(
      `customers/${client.id}`,
      { ...client, isActivated: true },
      (data) => {
        toast.success("Le compte de l'utilisateur a bien été activé");
      },
      setErrors,
      () => setIsActivating(false)
    );
  };

  const updateClient = (newClient) => {
    setClient(newClient);
  };

  const openUserForm = () => {
    mActions.updateModal({
      isOpen: true,
      content: <UserForm client={client} updateClient={updateClient} />,
    });
  };

  return {
    client,
    isSendingActivation,
    errors,
    isActivating,
    isUnlocking,
    actions: {
      sendActivation,
      unlockAccount,
      activateMail,
      updateClient,
      openUserForm,
    },
  };
};

export default useUserInfos;
