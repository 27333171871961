import React from "react";
import Control from "../../common/Control";
import PasswordControl from "../../common/PasswordControl";
import SaveBtn from "../../common/SaveBtn";
import GreyCard from "../Dashboard/GreyCard";

const MyPassword = ({
  password,
  setPassword,
  setPasswordValid,
  setPasswordConfirm,
  passwordConfirm,
  errors,
  pwdIdentical,
  passwordValid,
  savePassword,
  isSavingPassword,
  setShowPassword,
}) => {
  return (
    <>
      <button
        className="btn btn-link pl-0 text-success mb-2"
        onClick={() => setShowPassword(false)}
      >
        <i className="fa fa-arrow-left mr-2" />
        Retour
      </button>
      <p className="custom-p ">
        <strong>Modifiez votre mot de passe</strong>
      </p>
      <GreyCard>
        <div>
          <Control
            label="Nouveau mot de passe"
            name="password"
            type="password"
            autocomplete={"off"}
            value={password}
            change={(e) => setPassword(e.target.value)}
            error={errors}
          />
          <PasswordControl
            style={{ marginLeft: 7 }}
            password={password}
            minLength={6}
            minUppercase={1}
            minLowercase={3}
            minNumber={1}
            setCanSave={setPasswordValid}
          />
          <Control
            label="Retapez le mot de passe"
            name="passwordConfirm"
            type="password"
            value={passwordConfirm}
            change={(e) => setPasswordConfirm(e.target.value)}
            error={errors}
          />
          <span
            className={`
                    d-flex align-items-center ml-2 
                    ${pwdIdentical ? "text-success" : "text-danger"}`}
          >
            {
              <i
                className={"mr-2 fa fa-" + (pwdIdentical ? "check" : "times")}
              />
            }
            Doit-être identique à celui du dessus{" "}
          </span>
          {passwordValid && pwdIdentical && (
            <div className="d-center w-100">
              <SaveBtn
                type="primary"
                className="mt-4 btn-sm"
                save={savePassword}
                isSaving={isSavingPassword}
              />
            </div>
          )}
        </div>
      </GreyCard>
    </>
  );
};

export default MyPassword;
