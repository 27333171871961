import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import GreyCard from "../Dashboard/GreyCard";

const MyInformations = ({
  user,
  updateData,
  errors,
  save,
  isSaving,
  isSaved,
  state,
  setModifyInfo,
}) => {
  return (
    <>
      <button
        className="btn btn-link pl-0 text-success mb-2"
        onClick={() => setModifyInfo(false)}
      >
        <i className="fa fa-arrow-left mr-2" />
        Retour
      </button>
      <p className="custom-p ">
        <strong>Modifiez vos informations</strong>
        <br />
      </p>
      <GreyCard>
        <Control
          label="Civilité"
          name="gender"
          type="btnList"
          datas={state.constants.items.GENDERS}
          btnInline={true}
          value={user.gender}
          change={updateData}
          error={errors}
        />
        <Control
          label="Nom"
          name="lastname"
          value={user.lastname}
          change={updateData}
          error={errors}
        />
        <Control
          label="Prénom"
          name="firstname"
          value={user.firstname}
          change={updateData}
          error={errors}
        />
        <Control
          label="Téléphone"
          name="phone"
          type="tel"
          value={user.phone}
          change={updateData}
          error={errors}
        />
        <div className="d-center w-100">
          <SaveBtn
            className="mt-4 btn-sm"
            type="primary"
            save={save}
            text="Enregistrer"
            isSaving={isSaving}
            isSaved={isSaved}
          />
        </div>
      </GreyCard>
    </>
  );
};

export default MyInformations;
