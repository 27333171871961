import React, { useState } from "react";
import { colors } from "../../../helpers/colors";

const ActionCard = ({ title, params, isActive, onClick, infos, subText, isPlurial = false }) => {
  const [infoVisible, setInfoVisible] = useState(false);
  return (
    <div className="border-bottom">
      <div className="d-flex align-items-start justify-content-between bg-white p-3" style={{ gap: "1rem" }}>
        <div>
          <h1 className="">{title}</h1>
          <p className="mb-0 mt-1 text-black-50" style={{ fontSize: 14 }}>
            {subText}
          </p>
          <div>
            <button className="btn btn-link px-0 btn-sm mt-2" onClick={() => setInfoVisible(!infoVisible)}>
              En savoir +
            </button>
          </div>
        </div>
        <button
          className="btn shadow-sm big-shadow-hover"
          style={{
            minWidth: 125,
            border: "1px solid " + (isActive ? colors.info : "#ded7d7"),
          }}
          onClick={onClick}
        >
          {isActive ? (
            <span className="text-info d-center flex-column">
              <div
                className="bg-info text-white d-center mb-2"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  padding: 5,
                  fontSize: 20,
                }}
              >
                <i className="fa fa-check  animated fadeInLeft faster" />
              </div>
              Activée{isPlurial && "s"}
            </span>
          ) : (
            <span className="text-black-50  d-center flex-column">
              <div
                className=" text-white d-center mb-2"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  padding: 5,
                  fontSize: 20,
                  background: "#a7a7a7",
                }}
              >
                <i className="fa fa-times  animated fadeInRight faster" />
              </div>
              Désactivée{isPlurial && "s"}
            </span>
          )}
        </button>
      </div>
      <div
        className={
          "alert alert-light mb-0 animated fadeInUp faster border-left border-right rounded-0 " +
          (infoVisible ? "d-block" : "d-none")
        }
      >
        {infos}
      </div>
    </div>
  );
};

export default ActionCard;
