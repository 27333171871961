import React, { useEffect, useState } from "react";
import Control from "../Control";
import Loader from "../Loader";
import dataService from "../../../helpers/dataService";
import PayWithSavedCard from "./PayWithSavedCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PayWithSavedSepa from "./PayWithSavedSepa";
import tools from "../../../helpers/tools";
import { DateTime } from "luxon";
import SavedCBs from "./SavedCBs";
import SavedSEPAs from "./SavedSEPAs";
import PaymentForm from "./PaymentFormWithSavedMethod";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentMethods = ({
  policyDatas,
  setDisplayPaymentForm,
  params,
  paymentAction,
  setPaymentOk,
  setHasSavedPaymentMethods,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPaymentIntent, setIsLoadingPaymentIntent] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [modePayment, setModePayment] = useState(false);

  useEffect(() => {
    getPaymentMethods();
    //setDisplayPaymentForm(true);
  }, []);

  const getPaymentMethods = () => {
    setIsLoading(true);

    const clientId = policyDatas.policy.client.id;
    dataService.get(
      `clients/${clientId}/stripe/paymentmethods`,
      setPaymentMethods,
      setErrors,
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    if (paymentMethods) {
      if (paymentMethods.length) {
        setHasSavedPaymentMethods(true);
        document.querySelector(".payment-method-module").scrollIntoView();
        const defaultPM = paymentMethods.find(
          (d) =>
            d.isDefault && DateTime.fromISO(d.expirationDate) > DateTime.local()
        );
        if (defaultPM) setSelectedPaymentMethod(defaultPM.paymentMethodId);
      } else {
        setHasSavedPaymentMethods(false);
        setDisplayPaymentForm(true);
      }
    }
  }, [paymentMethods]);
  useEffect(() => {
    if (selectedPaymentMethod) {
      setErrors({});
      var modeP = paymentMethods.find(
        (p) => p.paymentMethodId == selectedPaymentMethod
      ).type;
      setModePayment(modeP);
      getPaymentIntent(modeP);
    }
  }, [selectedPaymentMethod]);

  const getPaymentIntent = (modeP) => {
    setIsLoadingPaymentIntent(true);
    dataService.post(
      "payments/payment-intent/update-policy",
      tools.transformIntentParams({
        policyId: params.policyId,
        paymentData: {
          paymentType: modeP,
          paymentAction,
          paymentMethodId: selectedPaymentMethod,
        },
        productData: {
          beginAt: params.beginAt,
          isPremium: params.isPremium,
          optRc: params.optRc,
          optFs: params.optFs,
          turnover: params.turnover,
        },
      }),
      setPaymentIntent,
      setErrors,
      () => setIsLoadingPaymentIntent(false)
    );
  };

  const validatePayment = () => {
    setPaymentOk({
      policyId: params.policyId,
      paymentDatas: {
        paymentType: modePayment,
        paymentIntentId: paymentIntent.paymentIntentId,
        paymentAction,
      },
      product: {
        beginAt: params.beginAt,
        isPremium: params.isPremium,
        optRc: params.optRc,
        optFs: params.optFs,
        turnover: params.turnover,
      },
    });
  };

  return (
    <div className="payment-method-module">
      {isLoading ? (
        <Loader />
      ) : (
        paymentMethods && (
          <PaymentForm
            paymentMethods={paymentMethods}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            isLoadingPaymentIntent={isLoadingPaymentIntent}
            paymentIntent={paymentIntent}
            setPaymentIntent={setPaymentIntent}
            errors={errors}
            setErrors={setErrors}
            isPaying={isPaying}
            modePayment={modePayment}
            stripePromise={stripePromise}
            setIsPaying={setIsPaying}
            validatePayment={validatePayment}
            setDisplayPaymentForm={setDisplayPaymentForm}
          />
        )
      )}
    </div>
  );
};

export default PaymentMethods;
