import { atom, useAtom } from "jotai";
import useStore from "../../../../context/useStore";
import { getClients } from "../../../../services/clientService";

const clientsAtom = atom([]);
const isLoadingClientsAtom = atom(false);
const clientFiltersAtom = atom({
  user: {
    searchString: "",
    insCompanies: [],
    products: [],
    productTypes: [],
    accountStatus: "",
    contractStatus: "",
    hasMinOneContract: false,
  },
  page: 1,
});
const resultCountAtom = atom(0);
const myClientsAtom = atom([]);
const otherClientsAtom = atom([]);

const useClientList = () => {
  const [state] = useStore();
  const { ACCOUNT_STATUS, POLICIES_STATUS } = state.constants.items;
  const [clientFilters, setClientFilters] = useAtom(clientFiltersAtom);
  const [clients, setClients] = useAtom(clientsAtom);
  const [resultCount, setResultCount] = useAtom(resultCountAtom);
  const [isLoadingClients, setIsLoadingClients] = useAtom(isLoadingClientsAtom);
  const [myClients, setMyClients] = useAtom(myClientsAtom);
  const [otherClients, setOtherClients] = useAtom(otherClientsAtom);
  const myUserId = state.auth.user.id;

  const onGetClients = ({ overrideFilters = null } = {}) => {
    setIsLoadingClients(true);
    getClients()
      .then((data) => {
        setClients(data);
        setMyClients(data.filter((d) => d.id == myUserId));
        setOtherClients(data.filter((d) => d.id != myUserId));
        setResultCount(data.length);
      })
      .finally(() => setIsLoadingClients(false));
  };

  const onClientFiltersChange = (newFilters) => {
    setClientFilters(newFilters);
  };

  const updatePage = (page) => {
    const newFilters = {
      ...clientFilters,
      page,
    };
    onGetClients(newFilters);
    setClientFilters(newFilters);
  };

  return {
    clients,
    resultCount,
    clientFilters,
    isLoadingClients,
    ACCOUNT_STATUS,
    POLICIES_STATUS,
    myClients,
    otherClients,
    actions: { onGetClients, onClientFiltersChange, updatePage },
  };
};

export default useClientList;
