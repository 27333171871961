export const sidenavItems = [
  // { name: "Mon compte", path: "/me", icon: "user" },
  {
    name: "Accueil",
    path: "/accueil",
    icon: "layer-group",
  },
  {
    name: "Contrats",
    path: "/clients",
    icon: "file-signature",
  },
  {
    name: "Demandes en cours",
    path: "/forms",
    icon: "paper-plane",
  },

  {
    name: "Protection Juridique",
    path: "/protection-juridique",
    icon: "user-shield",
  },
  {
    name: "Utilisateurs",
    path: "/accounts",
    icon: "users",
    dataPriv: "app_viewusers",
  },
  {
    name: "Timeline",
    path: "/timeline",
    icon: "heartbeat",
  },
  {
    name: "Moyens de paiement",
    path: "/paymentmethods",
    icon: "credit-card",
    dataPriv: "app_modifycompanycontract",
  },
  {
    name: "Mes factures",
    path: "/mes-factures",
    icon: "file-invoice",
    dataPriv: "app_viewbillings",
  },
];
