import React from "react";

const LoaderSvg = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        backgroundColor: "rgba(255, 255, 255, 0)",
        display: "block",
        backgroundPosition: "initial initial",
        backgroundRpeat: " initial initial",
      }}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="rotate(0 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.9419152276295133s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(27.692307692307693 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.8634222919937206s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(55.38461538461539 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.7849293563579278s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(83.07692307692308 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.706436420722135s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(110.76923076923077 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.6279434850863422s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(138.46153846153845 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.5494505494505494s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(166.15384615384616 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.47095761381475665s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(193.84615384615384 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.3924646781789639s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(221.53846153846155 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.3139717425431711s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(249.23076923076923 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.23547880690737832s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(276.9230769230769 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.15698587127158556s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(304.61538461538464 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="-0.07849293563579278s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(332.3076923076923 50 50)">
        <rect
          x="46.5"
          y="23"
          rx="3.5"
          ry="6"
          width="7"
          height="12"
          fill={color}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.0204081632653061s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    </svg>
  );
};

export default LoaderSvg;
