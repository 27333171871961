const stockageTips = {
  classicContentValue: {
    title: "Contenu classique",
    content: 
      <>
          <p><strong>Ce sont les biens suivants</strong>, se trouvant dans vos locaux et vous appartenant ou pris en location (y compris par crédit-bail) ou dont vous avez la garde et <strong>nécessaires à l’exercice de votre activité professionnelle</strong> : </p>
          <ul style={{paddingLeft: "5%"}}>
            <li><strong>Mobilier</strong> (meubles non destinés à la vente),<br /></li>
            <li><strong>Matériel et outils professionnels non électriques</strong>,<br /></li>
            <li><strong>Marchandises</strong> (tout bien destiné à être vendu avec ou sans transformation),<br /></li>
            <li><strong>Fournitures, approvisionnements et matières premières,</strong><br /></li>
            <li><strong>Emballages,</strong><br /></li>
            <li><strong>Produits finis, produits semi-ouvrés ou en cours de fabrication,</strong><br /></li>
            <li><strong>Marchandises vendues ferme mais non encore livrées,</strong><br /></li>
            <li><strong>Consommables.</strong><br /></li>
          </ul>
          <p><strong>Bon à savoir</strong> : l’Assuré bénéficie d’une tolérance de 10% (maximum) entre la superficie réelle et la superficie déclarée à l’Assureur, sans que cela ne lui porte préjudice en cas de sinistre.</p>
      </>
    },
    itContentValue: {
      title: "Matériel informatique et de bureautique",
      content: 
        <>
          <p><strong>Ce sont les matériels suivants, nécessaires à l’exercice de votre activité professionnelle et non destinés à la vente.</strong></p>
          <ul style={{paddingLeft: "1em"}}>
            <li><strong>Matériel informatique</strong> :<div style={{marginLeft: "-1.1em"}}>Ce sont les ordinateurs de bureaux, les ordinateurs portables, les tablettes, les serveurs, les périphériques et les connexions entre ces éléments, et le matériel destiné à stocker les informations sous forme numérique à l’exception des informations numériques elles même (système d’exploitation, logiciels de traitement de l’information et autres fichiers informatiques).</div></li><br/>
            <li><strong>Matériel de bureautique et de télématique</strong> :<div style={{marginLeft: "-1.1em"}}>Ce sont les caisses enregistreuses, photocopieurs, télécopieurs, standards téléphoniques, vidéoprojecteurs, terminaux de paiement électroniques.</div></li>
          </ul>
      </>
    },
    electricalContentValue: {
      title: "Matériel électrique",
      content:
        <>
          <p><strong>Ce sont les matériels suivants, nécessaires à l’exercice de votre activité professionnelle et non destinés à la vente :  </strong></p>
          <ul style={{paddingLeft: "5%"}}>
            <li>Appareils et installations d’exploitation électriques, électroniques ou mécaniques,</li>
            <li>Appareils <u>mobiles de climatisation</u>, d’alimentation électrique et de protection du matériel assuré,</li>
            <li>Réfrigérateurs et congélateurs, meubles réfrigérants,</li>
            <li>Équipements de cuisine et de salle de repos destinés à être utilisés par les membres de l’entreprise.</li>
          </ul>
          <p><strong>Bon à savoir</strong> : le matériel électrique est à différencier du matériel informatique et de bureautique, et ne comprends pas les installations fixes de climatisation.</p>
        </>
    },
    amenitiesValue: {
      title: "Agencements, aménagements et embellissements.",
      content:
        <>
          <p><strong>Aménagements et installations fixés aux locaux professionnels intérieurement ou extérieurement, qui ne peuvent être détachés sans être détériorés ou sans détériorer la construction elle-même, et notamment : </strong></p>
          <ul style={{paddingLeft: "1em"}}>
            <li>Tout revêtement de sol, de murs et de plafond, </li>
            <li>Les aménagements immobiliers fixés aux locaux professionnels tels que les installations privatives de chauffage ou de climatisation, les matériels de production d’énergies renouvelables, les pompes à chaleur et les installations de climatisation raccordées aux bâtiments.</li>
          </ul>
        </>
    },
    amenitiesAndEmbelishmentsValue: {
      title: "Agencements, aménagements et embellissements.",
      content:
        <>
          <p><strong>Aménagements et installations fixés aux locaux professionnels intérieurement ou extérieurement, qui ne peuvent être détachés sans être détériorés ou sans détériorer la construction elle-même, et notamment : </strong></p>
          <ul style={{paddingLeft: "1em"}}>
            <li>Tout revêtement de sol, de murs et de plafond, </li>
            <li>Les aménagements immobiliers fixés aux locaux professionnels tels que les installations privatives de chauffage ou de climatisation, les matériels de production d’énergies renouvelables, les pompes à chaleur et les installations de climatisation raccordées aux bâtiments.</li>
          </ul>
        </>
    },
    isMerchandiseStorage:  {
      title: "Marchandises",
      content:
        <>
          <p>Tout bien destiné à être vendu avec ou sans transformation</p>
        </>
    },
    isMerchandiseStored10cmFromFloor:  {
      title: "Marchandises",
      content:
        <>
          <p>Tout bien destiné à être vendu avec ou sans transformation</p>
        </>
    },
    isDangerousProductsStored: {
      title: "Produit dangereux",
      content:
        <>
          <p><strong>Les produits dangereux, sous forme de substances pures ou de mélanges, font l’objet d’une réglementation pointue pour leur utilisation, leur stockage et leur transport : </strong></p>
          <ul style={{paddingLeft: "5%"}}>
            <li>Carburant,</li>
            <li>Explosifs,</li>
            <li>Gaz inflammables,</li>
            <li>Aérosols,</li>
            <li>Gaz comburants,</li>
            <li>Gaz sous pression,</li>
            <li>Liquides inflammables,</li>
            <li>Matières solides inflammables,</li>
            <li>Peroxydes organiques,</li>
            <li>Substances ou mélanges corrosifs pour les métaux.</li>
          </ul>
          <p><strong>Bon à savoir</strong> : avec les bonnes pratiques et pour de faibles quantités, le stockage de produits dangereux ne pose pas de problème pour l’assurance multirisque professionnelle.</p>
        </>
    },
    dangerousProductsNature: {
      title: "Produit dangereux",
      content:
        <>
          <p><strong>Les produits dangereux, sous forme de substances pures ou de mélanges, font l’objet d’une réglementation pointue pour leur utilisation, leur stockage et leur transport : </strong></p>
          <ul style={{paddingLeft: "5%"}}>
            <li>Carburant,</li>
            <li>Explosifs,</li>
            <li>Gaz inflammables,</li>
            <li>Aérosols,</li>
            <li>Gaz comburants,</li>
            <li>Gaz sous pression,</li>
            <li>Liquides inflammables,</li>
            <li>Matières solides inflammables,</li>
            <li>Peroxydes organiques,</li>
            <li>Substances ou mélanges corrosifs pour les métaux.</li>
          </ul>
          <p><strong>Bon à savoir</strong> : avec les bonnes pratiques et pour de faibles quantités, le stockage de produits dangereux ne pose pas de problème pour l’assurance multirisque professionnelle.</p>
        </>
    },
};

export default stockageTips;
