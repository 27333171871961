import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../Loader";
import React, { useEffect, useState } from "react";

const DynamicPage = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState("");

  useEffect(() => {
    Axios.get(API_URL + "pages/code/" + url).then((res) => {
      setContent(res.data);
      setIsLoading(false)
    });
  }, []);

  return (
    isLoading ? <Loader /> : 
      <div
        className="mx-auto mb-5"
        style={{ maxWidth: 900 }}
        dangerouslySetInnerHTML={{ __html: content.content }}
      ></div>
  );
};

export default DynamicPage;
