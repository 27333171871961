var API_URL = (exports.API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:4000/");
var WEB_URL = (exports.WEB_URL = process.env.REACT_APP_WEB_URL
  ? process.env.REACT_APP_WEB_URL
  : "http://localhost:4000/");
var REF_BO = (exports.REF_BO = process.env.REACT_APP_REF_BO);
var REF_APP = (exports.REF_APP = process.env.REACT_APP_REF_APP);
var recaptcha_token = (exports.recaptcha_token = process.env.REACT_APP_RECAPTCHA_TOKEN);
var REACT_APP_STRIPE_PUBLIC_KEY = (exports.REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY);
var OPENREPLAY_KEY = (exports.OPENREPLAY_KEY = process.env.REACT_APP_OPENREPLAY_KEY);
