import React from "react";
import { Link } from "react-router-dom";
import { notifActions } from "../../../../context/actions/notifActions";
import useStore from "../../../../context/useStore";

const NotifAction = ({ action }) => {
  const [state, dispatch] = useStore();
  const nActions = notifActions(state, dispatch);

  const closeNotifs = () => {
    nActions.updateNotif({ isOpen: false });
  };

  const getActionComponent = () => {
    switch (action.type) {
      case "PROFILE_PICTURE":
        return (
          <Link onClick={closeNotifs} className="btn btn-primary btn-sm" to="/me?avatar">
            Ajouter ma photo
          </Link>
        );

      case "EXPIRED_PAYMENT_METHOD":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/paymentmethods?clientId=${action.data}`}
          >
            Accéder aux moyens de paiement
          </Link>
        );
      case "CP_GENERATED":
        return (
          <Link
            onClick={closeNotifs}
            className="btn btn-primary btn-sm"
            to={`/protection-juridique`}
          >
            Télécharger le document
          </Link>
        );
      default:
        return <></>;
    }
  };

  return <div className="d-center pt-3 pb-2">{getActionComponent()}</div>;
};

export default NotifAction;
