import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./comp.scss";
import useRenewablePolicies from "./useRenewablePolicies";
import { useHistory } from "react-router-dom";

const RenewablePolicies = () => {
  const { policies, getAllRenewablePolicies } = useRenewablePolicies();
  const history = useHistory();

  useEffect(() => {
    getAllRenewablePolicies();
  }, []);

  const onClickPolicyToPay = (polId) => {
    history.push(`/contracts/${polId}/renew`);
  };

  return policies.length ? (
    <div style={{ marginBottom: 25 }} className="row">
      <div className="col-12 mb-2">
        <div className="d-flex flex-row align-items-center flex-wrap justify-content-between">
          <p
            className=" bounceInRight text-success font-weight-bold m-0"
            style={{ fontSize: 14 }}
          >
            <i className="fa fa-arrow-down text-success mr-2" />
            {policies.length > 1
              ? `${policies.length} de vos contrats arrivent bientôt à échéance :`
              : "Un de vos contrats arrive bientôt à échéance :"}
          </p>
        </div>
      </div>
      <div className="col-12">
        <ul className="list-group shadow-small">
          {policies.map((pol, polk) => (
            <li
              className={`list-group-item border bg-blue cursor-pointer animated fadeInUp faster d-flex align-items-center justify-content-between flex-wrap polist`}
              style={{
                animationDelay: `${polk * 50}ms`,
              }}
              key={`polrenew${polk}`}
              onClick={() => {
                onClickPolicyToPay(pol.id);
              }}
            >
              <div
                className="d-flex flex-row flex-wrap align-items-start justify-content-between w-100"
                style={{ gap: "0.5rem" }}
              >
                <div className="">
                  <div
                    className="text-success font-weight-bold mr-2 w-100"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    <i className="fa fa-building mr-2" />
                    {pol.nomSociete}
                  </div>
                  <div className="mr-2 mt-1 font-weight-bold">
                    <span
                      className={`badge bg-white shadow-small px-2 text-success rounded`}
                      style={{ fontSize: 12 }}
                    >
                      {pol.titreContrat}
                    </span>
                    <span
                      className="text-muted font-weight-normal ml-2"
                      style={{ whiteSpace: "nowrap", fontSize: 12 }}
                    >
                      échéance le{" "}
                      {DateTime.fromISO(pol.expireLe).toFormat("dd/MM/yyyy")}
                    </span>
                  </div>
                </div>
                <Link
                  to={`/contracts/${pol.id}/renew`}
                  className="btn btn-primary btn-sm"
                >
                  <i className="fa fa-sync mr-2" />
                  Renouveler
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

export default RenewablePolicies;
