import React, { useState } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { DateTime } from "luxon";
import Stepper from "../Stepper/Stepper";
import Avatar from "../Avatar";
import Colors from "../../../Colors";

const TimelineEvent = ({ event }) => {
  var eventColor = Colors[event.color];
  const [historicVisible, setHistoricVisible] = useState(false);

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: "#fff",
        color: "#404040",
        padding: "8px 13px",
        border: `1px solid ${eventColor}`,
        boxShadow: "none",
        borderRadius: 8,
        // boxShadow: "0 -3px 0 " + eventColor + " ,0 0 15px -3px #afafaf",
      }}
      contentArrowStyle={{ borderRight: "7px solid " + eventColor }}
      date={DateTime.fromISO(event.date).toFormat("dd/MM/yyyy")}
      iconStyle={{
        background: eventColor,
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
      }}
      icon={<i className={"fa fa-1x fa-" + event.icon} />}
    >
      <span style={{ color: eventColor, fontSize: 14, fontWeight: "800" }}>
        {event.type}
      </span>
      <h3
        style={{
          fontSize: 12,
          color: "black",
          fontWeight: "400",
        }}
        className="vertical-timeline-element-title"
      >
        {event.title}
      </h3>
      {event.company && (
        <div style={{ color: eventColor }}>
          <strong>
            <i className="fa fa-building mr-1" />
            <span style={{ wordBreak: "break-word" }}>{event.company}</span>
          </strong>
        </div>
      )}
      {event.description && (
        <p
          className="mt-2"
          style={{ fontSize: "0.8rem", lineHeight: "0.9rem" }}
          dangerouslySetInnerHTML={{ __html: event.description }}
        />
      )}
      {event.historics?.length > 0 && (
        <div className="py-2" style={{ overflow: "hidden" }}>
          <button
            className={"btn btn-default bg-transparent pl-0 position-relative"}
            onClick={() => setHistoricVisible((v) => !v)}
            style={{ zIndex: "99999" }}
          >
            {historicVisible ? "Masquer" : "Afficher"} l'historique
          </button>
          {historicVisible && <Stepper steps={event.historics} />}
        </div>
      )}
      {/* {event.important && (
        <i
          title="Evénement important"
          className="fa fa-bell text-danger position-absolute"
          style={{ top: 5, right: 5 }}
        />
      )} */}
      {event.updatedBy && (
        <div
          className="d-flex justify-content-end mt-2 w-100"
          style={{
            color: "##939393",
            right: 5,
            bottom: 5,
            fontSize: 12,
            lineHeight: "22px",
            left: -2,
          }}
        >
          <div className="d-flex align-items-end justify-content-between w-100 ">
            <div>
              <span>
                Par {event.updatedBy} le{" "}
                {DateTime.fromISO(event.date).toFormat("dd/MM/yyyy à HH:mm")}
              </span>
            </div>
            <Avatar
              imageUrl={
                event.updatedBy == "Perceval"
                  ? "/images/logo_timeline.png"
                  : event.avatar
              }
              className="d-center"
              containerWidth={30}
              width={"auto"}
              height={30}
              user={event.avatarName}
            />
          </div>
        </div>
      )}
    </VerticalTimelineElement>
  );
};

export default TimelineEvent;
