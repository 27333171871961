import BPPolicyAmendments from "./BPPolicyAmendments/BPPolicyAmendments";
import BPPolicyDocuments from "./BPPolicyDocuments/BPPolicyDocuments";
import BPPolicyReceipts from "./BPPolicyReceipts/BPPolicyReceipts";
import usePolicyItem from "./usePolicyItem";

const PolicyBottomPanel = () => {
  const { bottomPanelContent } = usePolicyItem();

  const getPanelContent = () => {
    switch (bottomPanelContent) {
      case "DOCUMENTS":
        return <BPPolicyDocuments />;
      case "AMENDMENTS":
        return <BPPolicyAmendments />;
      case "RECEIPTS":
        return <BPPolicyReceipts />;
      case "EVENTS":
        return <>EVENTS...</>;
    }
  };

  return <div>{getPanelContent()}</div>;
};

export default PolicyBottomPanel;
