import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutFormSepa from "./CheckoutFormSepa.js";
import dataService from "../../../helpers/dataService.js";
import Loader from "../Loader.js";
import ErrorBlock from "../PaymentComponents/ErrorBlock.js";
import tools from "../../../helpers/tools.js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentSepa = (props) => {
  const { validatePayment, params, paymentAction, policyDatas } = props;
  const [paymentIntent, setPaymentIntent] = useState(false);
  const [errors, setErrors] = useState({});
  const [piErrors, setPiErrors] = useState({});
  const [isPaying, setIsPaying] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);

  useEffect(() => {
    getPaymentIntent();
  }, []);

  function getPaymentIntent() {
    setStripeLoading(true);
    dataService.post(
      "payments/payment-intent/update-policy",
      tools.transformIntentParams({
        policyId: params.policyId,
        paymentData: {
          paymentType: "SEPA",
          paymentAction,
        },
        productData: {
          beginAt: params.beginAt,
          isPremium: params.isPremium,
          optRc: params.optRc,
          optFs: params.optFs,
          turnover: params.turnover,
        },
      }),
      setPaymentIntent,
      setPiErrors,
      () => setStripeLoading(false)
    );
  }

  const validPay = () => {
    validatePayment({
      policyId: params.policyId,
      paymentDatas: {
        paymentType: "SEPA",
        paymentIntentId: paymentIntent.paymentIntentId,
        paymentAction,
      },
      product: {
        beginAt: params.beginAt,
        isPremium: params.isPremium,
        optRc: params.optRc,
        optFs: params.optFs,
        turnover: params.turnover,
      },
    });
  };

  var errorsArr = Object.values(errors);
  var piErrorsArr = Object.values(piErrors);

  return (
    <>
      <div
        className="font-weight-bold mb-2 mt-3"
        style={{
          fontSize: 24,
        }}
      >
        Virement SEPA
      </div>
      <p>
        Vous optez pour le paiement par prélevement SEPA, les garanties vous
        sont acquises à partir de la date d'effet choisie.
      </p>
      {stripeLoading ? (
        <Loader />
      ) : piErrorsArr.length > 0 ? (
        <ErrorBlock errorsArray={piErrorsArr} />
      ) : (
        <Elements stripe={stripePromise}>
          <CheckoutFormSepa
            paymentIntent={paymentIntent}
            setPaymentIntent={setPaymentIntent}
            setErrors={setErrors}
            isPaying={isPaying}
            setIsPaying={setIsPaying}
            validatePayment={validPay}
            userDatas={policyDatas}
          />
        </Elements>
      )}
      {errorsArr.length > 0 && <ErrorBlock errorsArray={errorsArr} />}
    </>
  );
};

export default PaymentSepa;
