import tools from "../../../../../../../../../../../../helpers/tools";
import LabelValuePair from "../../../../../../../../../../../common/LabelValuePair/LabelValuePair";
import useReceiptItem from "../useReceiptItem";

const ReceiptPaymentHistorics = () => {
  const { receipt, PAYMENT_STATUS } = useReceiptItem();

  return (
    <div>
      {receipt?.payments?.historics?.map((hist) => {
        const statusRef = tools.findIn(PAYMENT_STATUS, hist.status, "id");
        return (
          <div className="border px-2 rounded mb-1 bg-blue ">
            {" "}
            <div className={`badge badge-${statusRef.color}`}>{statusRef.name}</div>
            <div>
              <LabelValuePair label="Modifié le" value={tools.formatDate(hist.updatedAt)} />
            </div>
            <div>
              <LabelValuePair label="Montant" value={`${tools.round(hist.amount / 100)} €`} />
            </div>
            {hist.comment && (
              <div>
                <i className="fa fa-comment" /> <strong>{tools.getFullName(hist.updatedBy)}</strong>{" "}
                :<p>{hist.comment}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReceiptPaymentHistorics;
