import { useContext } from "react";
import { ExternalConstantsContext } from "../../../hooks/useExternalConstants/ExternalConstantsContext";

const LegalFormValue = ({ legalForm }) => {
  const { legalForms } = useContext(ExternalConstantsContext);
  const legalFormRef = legalForms.find((l) => l.id == legalForm);

  return legalFormRef?.name || "";
};

export default LegalFormValue;
