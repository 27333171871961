import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Page from "../../common/layout/Page";
import SimpleView from "../ClientList/SimpleView/SimpleView";
import GreyCard from "../Dashboard/GreyCard";
import WhiteCard from "../Dashboard/WhiteCard";
import FeatureSlider from "./FeatureSlider";
import PjPoliciesList from "./PjPoliciesList";
import PjPoliciesPremiumList from "./PjPoliciesPremiumList";
import PjPoliciesRcList from "./PjPoliciesRcList";
import PjPoliciesFsList from "./PjPoliciesFsList";
import tools from "../../../helpers/tools";
import { WEB_URL } from "../../../config";

const ProtectionJuridique = (props) => {
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const { PHONE_PERCEVAL, PHONE_PJ } = state.constants.items.PERCEVAL_PHONES;
  const [hints, setHints] = useState([]);
  const [nbClients, setNbClients] = useState(0);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [docsLoaded, setDocsLoaded] = useState(false);

  useEffect(() => {
    searchClients();
    getHints();
  }, []);

  function searchClients() {
    setErrors(false);
    if (isLoading) return false;
    setIsLoading(true);
    dataService.get(
      "clients/mines/customers",
      (datas) => {
        setUsers(datas);
      },
      setErrors,
      () => {
        setIsLoading(false);
        setUsersLoaded(true);
      }
    );
  }

  const getHints = () => {
    dataService.get(`hints`, setHints);
  };

  const setAllLoaded = () => {
    setDocsLoaded(true);
  };

  useEffect(() => {
    if (docsLoaded && usersLoaded) {
      if (props.location.search == "?garantiesrenforcees") {
        setTimeout(() => {
          tools.scrollToTargetAdjusted(document.querySelector("#garantiesrenforcees"));
        }, 500);
      }
    }
  }, [docsLoaded, usersLoaded, props.location.search]);

  const sectionStyle = { maxWidth: "850px", padding: "0 30px" };

  return (
    <>
      <div className="mt-5 animated fadeIn faster mx-auto" style={{ ...sectionStyle }}>
        {!isLoading ? (
          <div className="custom-p">
            <strong>
              Vous avez {nbClients} entreprise{nbClients > 1 ? "s" : ""} assurée
              {nbClients > 1 ? "s" : ""} par la Protection Juridique de Perceval.
            </strong>
            <br />
            {nbClients > 0 ? (
              <div className="mb-3">
                Retrouvez ici toutes les informations sur vos garanties et comment en bénéficier.
              </div>
            ) : (
              <>
                <div className="m-0" style={{ fontSize: 14, lineHeight: "22px" }}>
                  Rien ne vaut de preux juristes à votre disposition et une myriade de précieuses
                  ressources vous prodiguant moult services qui tomberont à pic le moment venu,{" "}
                  <span className="text-primary">
                    mais aussi la prise en charge des frais de procédure et d’avocat en attaque
                    comme en défense.
                  </span>
                </div>
                <a
                  href={`${WEB_URL}assurer-mon-entreprise`}
                  target="_BLANK"
                  className="btn btn-primary d-flex align-items-center justify-content-center mx-auto text-left font-weight-bold mt-3 mb-5"
                  style={{
                    fontSize: 14,
                    lineHeight: "18px",
                    maxWidth: 330,
                  }}
                >
                  <i className="fa fa-user-shield mr-3" style={{ fontSize: 26 }} />
                  Assurer une nouvelle entreprise
                </a>
              </>
            )}
          </div>
        ) : null}
        <SimpleView
          embeded
          users={users}
          onlyGozen={true}
          setNbClients={setNbClients}
          setAllLoaded={setAllLoaded}
        />
        {nbClients > 0 ? (
          <GreyCard marginBottom={10} paddingClassname="p-4">
            <div className="d-flex align-items-center flex-row justify-content-start w-100">
              <i className="fa fa-user-shield fa-2x text-success mr-3" />
              <strong
                className="text-success"
                style={{
                  fontSize: 17,
                  lineHeight: "22px",
                }}
              >
                Vous avez une question sur un domaine du droit ?
              </strong>
            </div>
            <div className="" style={{ marginTop: 12 }}>
              <p className="m-0">
                <strong>Appelez un juriste dédié</strong> qui répond à toutes vos questions, négocie
                à votre place et vous tiens informé à chaque étapes clés.{" "}
                <strong>Demandes illimitées.</strong>
                <br />
                <i>Appel non surtaxé.</i>
              </p>
              <a
                href={`tel:${PHONE_PJ.split(" ").join("")}`}
                className="btn btn-primary d-flex align-items-center justify-content-center mx-auto text-left font-weight-bold my-3"
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  maxWidth: 430,
                }}
              >
                <i className="fa fa-phone-volume mr-3" style={{ fontSize: 26 }} />
                <div>
                  Faire appel à un juriste GoZen au{" "}
                  <span
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {PHONE_PJ}
                  </span>
                </div>
              </a>
            </div>
          </GreyCard>
        ) : null}
        <GreyCard marginBottom={10} paddingClassname="p-4">
          <div className="d-flex align-items-center flex-row justify-content-start w-100">
            <i className="fa fa-lightbulb fa-2x text-success mr-3" />
            <strong
              className="text-success"
              style={{
                fontSize: 17,
                lineHeight: "22px",
              }}
            >
              LE SAVIEZ-VOUS ?
            </strong>
          </div>
          <div className="my-4">
            <FeatureSlider hints={hints} />
          </div>
        </GreyCard>
        {nbClients > 0 ? (
          <GreyCard marginBottom={10} paddingClassname="p-4">
            <div className="d-flex align-items-center flex-row justify-content-start w-100">
              <i className="fa fa-university fa-2x text-success mr-3" />
              <strong
                className="text-success"
                style={{
                  fontSize: 17,
                  lineHeight: "22px",
                }}
              >
                De l'aide pour régler un litige à l'amiable ou au juridicaire ?
              </strong>
            </div>
            <div className="" style={{ marginTop: 12 }}>
              <p className="custom-p">
                <strong>Détaillez-nous votre situation par email</strong> et joignez les documents
                (courriers, échanges, contrats, réclamations, etc.) que vous jugez essentiels à la
                compréhension de votre affaire.
              </p>
              <p className="m-0">
                <strong>Un juriste dédié prendra contact avec vous rapidement,</strong> pour
                négocier à votre place et vous accompagner pas à pas. Vous restez informé à chaque
                étape clé par téléphone et par écrit, jusqu'à la résolution du litige.
              </p>
              <a
                href={`mailto:gestionpj@goperceval.fr`}
                className="btn btn-primary d-flex align-items-center justify-content-center mx-auto text-left font-weight-bold my-3"
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  maxWidth: 288,
                }}
              >
                <i className="fa fa-paper-plane mr-3" style={{ fontSize: 26 }} />
                Déclarer un litige par email
              </a>
            </div>
          </GreyCard>
        ) : null}
        <div className="p-4 text-center">
          <img
            src="/images/logo-groupama-color.png"
            style={{ maxWidth: 266, filter: "grayscale(1)", opacity: "0.6" }}
          />
          <p className="my-3 custom-p pt-2 mb-4">
            <strong>Depuis 1993, Groupama PJ est notre partenaire de confiance</strong> pour
            garantir et simplifier la protection juridique des entrepreneur·e·s.
          </p>
        </div>

        {nbClients > 0 ? (
          <GreyCard marginBottom={10} paddingClassname="p-4">
            <div className="d-flex align-items-center flex-row justify-content-start w-100">
              <i className="fa fa-book fa-2x text-success mr-3" />
              <strong
                className="text-success"
                style={{
                  fontSize: 17,
                  lineHeight: "22px",
                }}
              >
                Votre accès « Digidroit »
              </strong>
            </div>
            <div style={{ marginTop: 12 }}>
              <p>
                Vous disposez d’un accès illimité à une base documentaire riche en contenus
                juridiques, articles, lettres types, textes de lois et autres jurisprudences,{" "}
                <strong>mise à jour en continu.</strong>
              </p>
              <div className="d-center w-100">
                <a
                  target="_BLANK"
                  href="https://espaceclient.protectionjuridique.fr"
                  className="btn btn-primary d-center px-4"
                >
                  <i className="fa fa-external-link-alt mr-3" style={{ fontSize: 26 }} />
                  Consulter Digidroit
                </a>
              </div>
            </div>
          </GreyCard>
        ) : null}
      </div>
      <section
        style={{
          background: "#F1F2FE",
          marginTop: 85,
          marginBottom: 40,
        }}
      >
        <div style={{ ...sectionStyle }} className="mx-auto ">
          <div className="d-center">
            {/* <i
            className="fa fa-shield fa-7x text-success"
            style={{
              marginTop: -31,
            }}
          /> */}
            <img id="garantiesrenforcees" src="/images/blades.png" style={{ marginTop: -48 }} />
          </div>

          <div className="py-4">
            <div className="text-center d-flex flex-column align-items-center justify-content-center">
              <strong
                className="text-success mb-3"
                style={{
                  fontSize: 23,
                  lineHeight: "27px",
                }}
              >
                Garanties essentielles
              </strong>
              {isLoading ? null : (
                <div
                  className="text-center mb-4"
                  style={{
                    fontSize: 14,
                    lineHeight: "23px",
                  }}
                >
                  <PjPoliciesList users={users} />
                </div>
              )}
            </div>

            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Frais d'Avocat et de Procédure</>}
              image="/images/frais-avocat.svg"
              text={
                <>
                  En attaque comme en défense, lorsqu’un litige ne peut se résoudre à l’amiable et
                  doit être porté devant une juridiction, une commission ou un tribunal,{" "}
                  <strong>
                    votre affaire est confiée à l’un de nos juristes spécialisé en gestion
                    judiciaire.
                  </strong>
                </>
              }
              content={
                <>
                  <p className="custom-p">
                    Il connaît tous les rouages des procédures et sera{" "}
                    <strong>chargé de vous accompagner et de vous guider</strong> pendant toute la
                    durée de votre affaire.
                  </p>
                  <p className="custom-p">
                    Lorsqu’il le faut, <strong>vous choisissez librement votre avocat</strong> pour
                    vous représenter.
                  </p>
                  <p className="custom-p">
                    <strong>Nous prenons bien sûr en charge les frais et honoraires :</strong>
                  </p>
                  <ul className="custom-ul">
                    <li>de l'avocat de votre choix</li>
                    <li>de procédure</li>
                    <li>d'expertise judiciaire</li>
                    <li>d'huissier de justice</li>
                  </ul>

                  <p className="custom-p">
                    <strong>Nous ne prenons pas en charge les :</strong>
                  </p>
                  <ul className="custom-ul">
                    <li>
                      condamnations, amendes, dépens et frais que le Tribunal estime équitable de
                      vous faire supporter si vous êtes condamné
                    </li>
                    <li>sommes réclamées par l’administration, taxes, droits et pénalités</li>
                    <li>
                      frais de déplacement de l’avocat en dehors du ressort de la Cour d’Appel dont
                      dépend son ordre
                    </li>
                    <li>honoraires de résultat</li>
                  </ul>
                  <p className="custom-p">
                    <strong>Bon à savoir</strong> : nous avançons directement les frais dans plus de
                    95% des cas, le détail de tous les frais garantis est disponible à l'article 5
                    des Disposition Générales.
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Assistance Amiable</>}
              image="/images/assistance-amiable.svg"
              text={
                <>
                  Nous offrons un <strong>accompagnement personnalisé</strong> dans la{" "}
                  <strong>résolution de votre litige.</strong> Cela commence par{" "}
                  <strong>vous dédier</strong> l’un de nos
                  <strong> juristes spécialisé</strong> en négociation amiable,{" "}
                  <strong>il vous accompagnera de bout en bout.</strong>
                </>
              }
              content={
                <>
                  <p className="custom-p">
                    Cette approche amiable est privilégiée en première intention.{" "}
                    <strong>Votre juriste négocie avec la partie adverse</strong> (il a été formé à
                    cela) et fait tout ce qu’il peut pour obtenir le meilleur résultat, dans votre
                    intérêt.
                  </p>
                  <p className="custom-p">
                    Et lorsque l’appui d’un intervenant extérieur (expert, avocat ou huissier) est
                    nécessaire (notamment lorsque votre adversaire est représenté par un avocat),
                    nous prenons en charge les frais et honoraires de cet intervenant.
                  </p>
                  <p className="custom-p">
                    <strong>Bon à savoir</strong> : la démarche amiable auprès de votre adversaire
                    est toujours tentée lorsqu’elle est possible. Elle aboutit dans 70% des cas, ce
                    qui vous épargne une procédure judiciaire longue et à l’issue incertaine.
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Consultation Juridique</>}
              image="/images/consultation-juridique.svg"
              text={
                <>
                  <strong>Nos juristes vous répondent 6j/7</strong>, par téléphone et par écrit, sur
                  les règles de droit applicables à votre cas et vous donnent un avis sur la
                  conduite à tenir.
                  <br />
                  <strong>Demandes illimitées.</strong>
                </>
              }
              content={
                <>
                  <ul className="custom-ul">
                    <li>
                      + de 90 juristes spécialisés, titulaires d’une formation supérieure en droit
                      (bac+5)
                    </li>
                    <li>Tous les domaines du droit Français sont couverts</li>
                    <li>
                      <strong>Accompagnement de bout en bout</strong> par le juriste qui vous est
                      dédié
                    </li>
                    <li>Envoi d’un compte-rendu écrit à chaque étape</li>
                  </ul>
                  <p className="custom-p">
                    <strong>Bon à savoir</strong> : vous avez la possibilité de noter votre juriste
                    à chaque moment clé du dossier.
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Digidroit 24h/24</>}
              image="/images/digidroit.svg"
              text={
                <>
                  <p className="custom-p">
                    <strong>Bibliothèque</strong> en ligne de <strong>+ 4000 documents</strong> pour
                    toutes vos recherches <strong>juridiques</strong> : social, locaux pro,
                    relations client fournisseur, administration, etc.
                  </p>
                </>
              }
              content={
                <>
                  <ul className="custom-ul">
                    <li>Une réponse juridique fiable et écrite constamment mise à jour</li>
                    <li>
                      Des documents types « prêt-à-l’emploi » : lettres, contrats, réclamations, etc
                    </li>
                    <li>
                      Des articles d’actualité régulièrement publiés par nos juristes passionnés
                    </li>
                    <li>
                      Newsletter mensuelle et personnalisée sur les sujets qui vous intéressent
                    </li>
                  </ul>
                </>
              }
            />

            <div className="text-center d-flex flex-column align-items-center justify-content-center mt-5 pt-3">
              <strong
                className="text-success mb-3"
                style={{
                  fontSize: 23,
                  lineHeight: "27px",
                }}
              >
                <span style={{ color: "black" }}>Domaines</span> d'interventions
              </strong>
              <p className="custom-p text-center">
                À l’amiable ou au tribunal, vous êtes assuré•e dans tous les domaines.
              </p>
            </div>
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Activité Professionnelle</>}
              image="/images/activite-professionnelle.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Litiges avec un fournisseur, un prestataire de service, un client, un
                      concurrent.
                    </strong>
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemples : livraison non conforme à votre commande, mauvaise réparation de vos
                      matériels, annulation abusive d’une commande par un client, détournement de
                      clientèle, etc.
                    </i>
                  </p>
                  <p className="custom-p">
                    <strong>Ce qui n'est pas couvert :</strong>
                  </p>
                  <ul className="custom-ul">
                    <li>Le recouvrement de créances (disponible en option)</li>
                  </ul>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Locaux Professionnels</>}
              image="/images/locaux-professionnels.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Litiges avec un fournisseur, un prestataire de service, un client, un
                      concurrent.
                    </strong>
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemples : litiges avec le propriétaire (déspécialisation du bail,
                      augmentation de loyer), litiges avec la copropriété (perte de clientèle suite
                      à pose d’un échafaudage, contestation des charges), etc.
                    </i>
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Administratif</>}
              image="/images/administratif.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Litiges avec l’administration, un service public, une collectivité
                      territoriale.
                    </strong>
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemples : difficulté pour obtenir une autorisation nécessaire à votre
                      activité, litige avec une Mairie, dommages causés par des travaux publics,
                      contestation de la légalité d’une décision administrative, etc.
                    </i>
                  </p>
                  <p className="custom-p font-weight-bold">Ce qui n'est pas couvert :</p>
                  <ul className="custom-ul">
                    <li>Les litiges avec l’administration fiscale (disponible en option)</li>
                  </ul>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Recours Pénal</>}
              image="/images/recours-penal.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    Lorsque, vous ou vos préposés, êtes{" "}
                    <strong>victime(s) d’une infraction pénale</strong> (contravention ou délit)
                    dans l’exercice de votre activité,{" "}
                    <strong>nous prenons en charge les recours.</strong>
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Prud'hommes</>}
              image="/images/prudhomme.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Litiges vous opposant à un Salarié (conflit individuel du travail) portant sur
                      la conclusion, l’exécution ou la rupture du contrat de travail.
                    </strong>
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemples : contestation de licenciement, demande de paiement d’heures
                      supplémentaires, demande de requalification du contrat de travail, etc.
                    </i>
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>E-Réputation</>}
              image="/images/ereputation.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    Lorsque vous avez déposé plainte contre l’auteur d’une atteinte à votre
                    e-réputation, <strong>nous vous assistons</strong> et{" "}
                    <strong>
                      intervenons pour obtenir la suppression du contenu et la réparation de votre
                      préjudice.
                    </strong>
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemples : utilisation de votre logo sans votre autorisation et occasionnant
                      un préjudice d’image, dénigrement ou injures dans le cadre de votre activité
                      professionnelle sur les réseaux sociaux, etc.
                    </i>
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Protection Sociale</>}
              image="/images/protection-sociale.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Litiges avec la Sécurité Sociale, les Caisses de Retraite Complémentaire et
                      les organismes de prévoyance auxquels vous cotisez, Pôle Emploi.
                    </strong>
                  </p>
                  <p className="custom-p">
                    <strong>Ce qui n'est pas couvert :</strong>
                  </p>
                  <ul className="custom-ul">
                    <li>Les litiges avec l’URSSAF (disponible en option)</li>
                  </ul>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Défense Pénale et Disciplinaire</>}
              image="/images/defense-penale.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Nous intervenons pour votre défense lorsque vous êtes poursuivi pénalement
                    </strong>{" "}
                    (dépôt de plainte, citation directe, mise en examen) ou convoqué devant une
                    commission administrative ou une juridiction disciplinaire, pour des faits
                    commis dans le cadre de votre activité professionnelle garantie.
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemples : poursuite pour non respect de règles d’hygiène et de sécurité,
                      poursuite pour diffamation, etc.
                    </i>
                  </p>
                  <p className="custom-p">
                    <strong>Ce qui n'est pas couvert :</strong>
                  </p>
                  <ul className="custom-ul">
                    <li>Les infractions au Code de la Route</li>
                  </ul>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Usurpation d'Identité</>}
              image="/images/usurpation-identite.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    Lorsque vous avez déposé plainte pour usurpation d’identité,{" "}
                    <strong>
                      nous intervenons pour obtenir la réparation de votre préjudice, et également
                      pour défendre vos intérêts
                    </strong>{" "}
                    dans le cadre des actions (recours, injonctions, assignations…) éventuellement
                    engagées à votre encontre à l’occasion de l’usurpation avérée de votre identité.
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemples : utilisation frauduleuse de votre papier à entête, fraude à la carte
                      bancaire, etc.
                    </i>
                  </p>
                </>
              }
            />
            <WhiteCard
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Garantie Maximum</>}
              image="/images/garantie-maximum.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>Vous êtes assuré à hauteur de 25 000€ par an.</strong>
                  </p>
                  <p className="custom-p">
                    <strong>
                      C’est l’ensemble des frais et honoraires que nous prenons en charge.
                    </strong>
                  </p>
                  <ul className="custom-ul">
                    <li>
                      La prise en charge maximum pour un même litige est de 25 000€, peu importe sa
                      durée de résolution
                    </li>
                    <li>Vous êtes bien sûr couvert pour plusieurs litiges dans la même année</li>
                  </ul>
                  <p className="custom-p">
                    <i>
                      Exemple : votre entreprise subit 5 litiges d’un coût moyen de 6 000€ chacun,
                      la même année, nous prendrons en charge 25 000€.
                    </i>
                  </p>
                </>
              }
            />
          </div>
        </div>
        <div className="py-4 bg-white pt-5">
          <div style={{ ...sectionStyle }} className="mx-auto">
            <div className="text-center d-flex flex-column align-items-center justify-content-center">
              <strong
                className="text-success mb-3"
                style={{
                  fontSize: 23,
                  lineHeight: "27px",
                }}
              >
                <span style={{ color: "black" }}>Garanties</span> renforcées
              </strong>
              {isLoading ? null : (
                <div
                  className="text-center mb-4"
                  style={{
                    fontSize: 14,
                    lineHeight: "23px",
                  }}
                >
                  <PjPoliciesPremiumList users={users} />
                </div>
              )}
            </div>
            <WhiteCard
              bgColor="#F1F2FE"
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Doublement Garanti</>}
              image="/images/doublement-garanti.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>Vous doublez votre garantie annuelle à hauteur de 50 000€.</strong>
                  </p>
                  <p className="custom-p">
                    <strong>
                      C’est l’ensemble des frais et honoraires que nous prenons en charge :
                    </strong>
                  </p>
                  <ul className="custom-ul">
                    <li>
                      La prise en charge maximum pour un même litige est 50 000€ peu importe sa
                      durée de résolution
                    </li>
                    <li>Vous bénéficiez de services supplémentaires</li>
                    <li>Vous êtes bien sûr couvert pour plusieurs litiges dans la même année</li>
                  </ul>
                  <p className="custom-p">
                    <i>
                      Exemple : votre entreprise subit 6 litiges d’un coût moyen de 10 000€ chacun,
                      la même année, nous prendrons en charge 50 000€.
                    </i>
                  </p>
                </>
              }
            />
            <WhiteCard
              bgColor="#F1F2FE"
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Signez en toute Confiance</>}
              image="/images/signez-en-confiance.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Lorsque vous envisagez de signer un contrat dans le cadre de votre activité
                      professionnelle, nous vous assistons dans sa lecture et sa compréhension afin
                      de vérifier sa conformité avec les règles de droit, avant sa conclusion.
                    </strong>
                  </p>
                  <p className="custom-p">
                    Lorsque notre juriste identifie une difficulté, le projet de contrat est soumis
                    à un avocat. Il vous confirmera par écrit sa validité juridique ou vous
                    proposera son adaptation. Nous prenons en charge les frais liés à cette
                    prestation deux fois par an.
                  </p>
                  <p className="custom-p">
                    <strong>Sont pris en charge, les :</strong>
                  </p>
                  <ul className="custom-ul">
                    <li>contrats de travail</li>
                    <li>baux commerciaux</li>
                    <li>contrats de vente de biens mobiliers</li>
                    <li>contrats de prestation de services</li>
                  </ul>
                  <p className="custom-p">
                    <strong>Ne sont as pris en charge :</strong>
                  </p>
                  <ul className="custom-ul">
                    <li>les contrats de vente immobilière</li>
                  </ul>
                  <p className="custom-p">
                    <strong>Bon à savoir :</strong> cette prestation s’applique aux contrats rédigés
                    en langue française et relevant du droit Français.
                  </p>
                </>
              }
            />
            <WhiteCard
              bgColor="#F1F2FE"
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Propriété Intellectuelle</>}
              image="/images/propriete-intellectuelle.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>
                      Nous intervenons en recours lorsque vous êtes victime d’une atteinte à vos
                      droits de propriété industrielle, littéraire ou artistique.
                    </strong>
                  </p>
                  <p className="custom-p">
                    <i>
                      Exemple : un nouveau concurrent dans votre domaine d’activité utilise une
                      marque commerciale créant une confusion avec la vôtre.
                    </i>
                  </p>

                  <p className="custom-p">
                    <strong>Bon à savoir</strong> : notre prise en charge financière est garantie
                    jusqu’à 2 000€ par litige.
                  </p>
                </>
              }
            />
            <WhiteCard
              bgColor="#F1F2FE"
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Médiation de la Consommation</>}
              image="/images/mediation-consommation.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    Dès lors qu’un litige relevant de la médiation de la consommation vous oppose à
                    un consommateur,{" "}
                    <strong>
                      nous prenons en charge les honoraires du médiateur de la consommation.
                    </strong>
                  </p>

                  <p className="custom-p">
                    <strong>Bon à savoir</strong> : notre prise en charge financière est garantie
                    jusqu’à 2 000€ par an.
                  </p>
                </>
              }
            />
            <WhiteCard
              bgColor="#F1F2FE"
              marginBottom={10}
              paddingClassname="p-4"
              title={<>Aides et Subventions</>}
              image="/images/aides-subventions.svg"
              text={null}
              content={
                <>
                  <p className="custom-p">
                    <strong>Nous vous aidons à rechercher les aides financières</strong> pouvant
                    vous être attribuées pour vos activités.
                  </p>

                  <p className="custom-p">
                    <strong>Bon à savoir</strong> : par aides et subventions, nous entendons les
                    sommes d’argent allouées aux entreprises par les collectivités locales, les
                    départements, les régions, l’État, l’Europe dans le cadre d’un projet, ainsi que
                    les allègements de charges ou crédits d’impôts.
                  </p>
                </>
              }
            />

            <div className=" bg-white pt-5" style={{ marginBottom: 150 }}>
              <div className="text-center d-flex flex-column align-items-center justify-content-center">
                <strong
                  className="text-success mb-3"
                  style={{
                    fontSize: 23,
                    lineHeight: "27px",
                  }}
                >
                  <span style={{ color: "black" }}>Garanties</span> optionnelles
                </strong>

                <div
                  className="text-center mb-4"
                  style={{
                    fontSize: 14,
                    lineHeight: "23px",
                  }}
                >
                  Ou comment avoir
                  <br />
                  <strong>plus d'une corde à son arc !</strong>
                </div>
              </div>
              <WhiteCard
                bgColor="#F1F2FE"
                marginBottom={10}
                paddingClassname="p-4"
                title={<>Recouvrement de Créances</>}
                image="/images/recouvrement-creances.svg"
                text={<PjPoliciesRcList users={users} />}
                content={
                  <>
                    <p className="custom-p">
                      <strong>
                        Votre juriste dédié s’occupe de tout pour le recouvrement de vos créances
                        professionnelles
                      </strong>
                      , certaines, liquides et exigibles ayant une ancienneté maximale de 180 jours
                      et dont le montant est au moins égal à 500€.
                    </p>

                    <p className="custom-p">
                      Il sera retenu, à titre de franchise, 15% du montant des sommes recouvrées par
                      dossier.
                    </p>
                  </>
                }
              />
              <WhiteCard
                bgColor="#F1F2FE"
                marginBottom={10}
                paddingClassname="p-4"
                title={<>Garantie Fiscale et Sociale</>}
                image="/images/garantie-fiscale-sociale.svg"
                text={<PjPoliciesFsList users={users} />}
                content={
                  <>
                    <p className="custom-p">
                      <strong>
                        Nous intervenons lors d’un contrôle de l’administration fiscale.
                      </strong>
                    </p>

                    <p className="custom-p">
                      <strong>En cas de contrôle par : </strong>
                    </p>
                    <ul className="custom-ul">
                      <li>l’administration fiscale Française, </li>
                      <li>
                        ou portant sur les cotisations sociales versées à l’URSSAF et organismes
                        assimilés
                      </li>
                      <li>
                        et dans le cadre d’éventuels recours, conséquences de ce(s) contrôle(s)
                      </li>
                    </ul>
                    <p className="custom-p">
                      <strong>Vous êtes couvert pour : </strong>
                    </p>
                    <ul className="custom-ul">
                      <li>
                        la prise en charge des honoraires d’expert-comptable, les frais et
                        honoraires d’avocat et d’huissier de justice, ainsi que les frais de
                        procédure
                      </li>
                      <li>avec un engagement maximum fixé à 10 000€ par contrôle</li>
                      <li>
                        et pour tout contrôle notifié 2 mois après la souscription de l’option
                        (délai de carence)
                      </li>
                    </ul>
                    <p className="custom-p">
                      <strong>Vous n’êtes pas couverts en cas :</strong>
                    </p>
                    <ul className="custom-ul">
                      <li>
                        de défaut de réponse aux demandes de renseignements, non transmission de
                        pièces, retard de déclaration, ou de non-paiement des créances à
                        l’administration fiscale, l’URSSAF ou organismes assimilés
                      </li>
                      <li>de non tenue de comptabilité ou de comptabilité irrégulière</li>
                      <li>
                        d’opposition à un contrôle fiscal ou URSSAF ou à celui d’un organisme
                        assimilé
                      </li>
                      <li>d’inexactitude, d’insuffisance ou d’omissions de façon délibérée</li>
                      <li>de poursuites pénales</li>
                    </ul>
                  </>
                }
              />
              <a
                href={`${WEB_URL}assurer-mon-entreprise`}
                target="_BLANK"
                className="btn btn-primary d-flex align-items-center justify-content-center mx-auto text-left font-weight-bold my-3"
                style={{
                  fontSize: 14,
                  lineHeight: "18px",
                  maxWidth: 330,
                }}
              >
                <i className="fa fa-user-shield mr-3" style={{ fontSize: 26 }} />
                Assurer une nouvelle entreprise
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProtectionJuridique;
