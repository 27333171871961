import React from "react";
import tools from "../../../helpers/tools";

const MyRoles = () => {
  var roles = tools.getState("dashboard", { myRoles: [] }).myRoles;
  var rolesArr = [];
  roles.forEach((r) => {
    if (!rolesArr.includes(r.role)) {
      rolesArr.push(r.role);
    }
  });

  return (
    <div className="mt-4">
      {rolesArr.map((role, rk) => (
        <div key={`role${role}`}>
          <strong>{role}</strong>
          <ul style={{ paddingLeft: 18 }}>
            {roles
              .filter((r) => r.role == role)
              .map((ro, rok) =>
                ro.clients.map((client, ck) => (
                  <li style={{ fontSize: 12 }}>{client.name}</li>
                ))
              )}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MyRoles;
