export const colors = {
  primary: "#3e75ff",
  success: "#040ca5",
  info: "#009404",
  danger: "#dc5f45",
  warning: "#ffcc99",
  dark: "#6e6e71",
  secondary: "#3e75ff",
  light: "#f2f3fa",
  tertiary: "#afc8cb",
};
