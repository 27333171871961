import { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";

const useContract = (props) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [contract, setContract] = useState(false);
  const itemId = props.match.params.id;
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getContract();
  }, []);

  const getContract = () => {
    dataService.get(
      "policies/" + itemId,
      (datas) => {
        setContract(datas);
      },
      setErrors,
      () => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (contract) setIsInit(true);
  }, [contract]);

  return {
    state,
    constants,
    isLoading,
    errors,
    contract,
    itemId,
    isInit,
    setIsLoading,
    setErrors,
    setContract,
    getContract,
  };
};

export default useContract;
