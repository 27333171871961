import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import PasswordControl from "../../common/PasswordControl";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import SaveBtn from "../../common/SaveBtn";
import { DateTime } from "luxon";
import Progress from "../../common/Progress";

var timerInterval = null;

const RenewPassword = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errors, setErrors] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [passwordsEqual, setPasswordsEqual] = useState(false);
  const [resetValid, setResetValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMissingParam, setIsMissionParam] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [phoneCodeValid, setPhoneCodeValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [authId, setAuthId] = useState("");
  const [canResendAt, setCanResendAt] = useState(false);
  const [canReceiveNewCode, setCanReceiveNewCode] = useState(false);
  const [progress, setProgress] = useState(0);
  const [resendDuration, setResendDuration] = useState(60);
  const [timeBeforeNewCode, setTimeBeforeNewCode] = useState(resendDuration);

  useEffect(() => {
    if (phoneCode.length == 6) {
      checkPhoneCode();
    }
  }, [phoneCode]);

  useEffect(() => {
    if (!props.match.params.token) {
      setIsMissionParam(true);
    } else {
      checkActivationToken();
    }
  }, []);

  useEffect(() => {
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      if (!canResendAt) return;
      var timeLeft = DateTime.fromISO(canResendAt)
        .diff(DateTime.fromISO(new Date().toISOString()))
        .toObject();

      var progress = Math.round(
        ((resendDuration * 1000 - timeLeft.milliseconds) /
          1000 /
          resendDuration) *
          100
      );

      if (progress >= 100) {
        setCanReceiveNewCode(true);
      } else {
        setCanReceiveNewCode(false);
        setTimeBeforeNewCode(Math.round(timeLeft.milliseconds / 1000));

        setProgress(progress);
      }
    }, 1000);
  }, [canResendAt]);

  function checkActivationToken() {
    setIsLoading(true);
    Axios.post(API_URL + "tokens/renew-password", {
      token: props.match.params.token,
    })
      .then((res) => {
        setTokenValid(true);
        setPhoneNumber(res.data.phone);
        setAuthId(res.data.authorizationId);
        setCanResendAt(res.data.canResendAt);
        setResendDuration(res.data.resendDuration);
        setErrors({});
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setErrors({});
    var errs = {};
    if (password != passwordConfirm || password.length == 0) {
      errs.passwordConfirm = "Doit-être identique au premier";
    }

    if (Object.keys(errs).length === 0 && errs.constructor === Object) {
      setPasswordsEqual(true);
    } else {
      setPasswordsEqual(false);
    }
    setErrors(errs);
  }, [password, passwordConfirm]);

  function checkPhoneCode() {
    setIsLoading(true);
    Axios.post(API_URL + "auth/password-code/valid", {
      code: phoneCode,
      authorizationId: authId,
      token: props.match.params.token,
    })
      .then((res) => {
        setPhoneCodeValid(true);
        setErrors({});
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        } else {
          setErrors({ other: "Une erreur est survenue, veuillez réessayer" });
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  function save() {
    if (canSave && passwordsEqual) {
      //save
      setIsLoading(true);
      Axios.post(API_URL + "customers/reset-password", {
        password: password,
        authorizationId: authId,
        token: props.match.params.token,
      })
        .then((res) => {
          setIsSuccess(true);
          setErrors({});
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <Page errors={errors} back="/">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 m-auto">
          <div className="d-center flex-column">
            <img
              src="/images/logo-small.svg"
              className="mb-3 mx-auto"
              style={{ height: 48 }}
            />
            <strong
              className="mb-5 p-0 d-block text-success"
              style={{
                fontSize: 17,
              }}
            >
              Modifier mon mot de passe
            </strong>

            {isLoading ? (
              <Loader text="Vérification en cours" />
            ) : isMissingParam ? (
              <p className="mb-0 text-danger">Ce lien est incorrect.</p>
            ) : !tokenValid ? (
              <p className="mb-0 text-danger text-center">
                Ce jeton n'est plus valide.
              </p>
            ) : resetValid ? (
              <>
                <p className="mb-4 text-success">
                  Votre mot de passe a bien été modifié, vous pouvez à présent
                  vous connecter.
                </p>
                <Link className="btn btn-link" to="/">
                  Se connecter
                </Link>
              </>
            ) : !phoneCodeValid ? (
              <>
                <div className="mb-4 text-center">
                  <p className="mb-0">
                    Un code à 6 chiffres vous a été envoyé au
                  </p>
                  <p className="mb-0">
                    <strong className="my-3 d-block" style={{ fontSize: 18 }}>
                      {phoneNumber}
                    </strong>
                  </p>
                  <p className="mb-0">veuillez le renseigner ci-dessous :</p>
                </div>
                <div className="mx-auto" style={{ maxWidth: 280 }}>
                  <Control
                    type="digits"
                    className="animated fadeInUp faster"
                    containerStyle={{ animationDelay: "50ms" }}
                    label=""
                    digitParams={[
                      { size: 1, value: "" },
                      { size: 1, value: "" },
                      { size: 1, value: "" },
                      { size: 1, value: "" },
                      { size: 1, value: "" },
                      { size: 1, value: "" },
                    ]}
                    pattern="[0-9]*"
                    name={"phoneCode"}
                    change={(e) => setPhoneCode(e.target.value)}
                    value={phoneCode}
                    error={errors}
                  />
                </div>
                <button
                  className={
                    "btn w-100 mt-5 btn-sm " +
                    (canReceiveNewCode
                      ? "btn-primary"
                      : "btn-default shadow-none")
                  }
                  onClick={() => {
                    if (canReceiveNewCode) checkActivationToken();
                  }}
                >
                  {canReceiveNewCode ? (
                    "Recevoir un nouveau code"
                  ) : (
                    <>
                      <span className="text-black-50 font-weight-bold">
                        Patientez encore{" "}
                        <span className="text-primary">
                          {timeBeforeNewCode} secondes
                        </span>{" "}
                        pour recevoir un nouveau code
                      </span>
                    </>
                  )}
                </button>
              </>
            ) : isSuccess ? (
              <>
                <i className="fa fa-check fa-4x text-success animated zoomIn" />
                <p className="text-success  font-weight-bold mt-3 mb-4 font-weight-bold animated fadeIn">
                  Votre mot de passe a bien été modifié.
                </p>
                <Link to="/" className="btn btn-success animated fadeIn">
                  Se connecter
                </Link>
              </>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  save();
                }}
              >
                <p className="text-center mb-4">
                  Veuillez saisir votre nouveau mot de passe.
                </p>
                <div
                  style={{
                    background: "#F1F2FE",
                  }}
                  className="p-3 rounded text-left w-100"
                >
                  <Control
                    className=""
                    label="Nouveau mot de passe"
                    type="password"
                    name="password"
                    value={password}
                    change={(e) => setPassword(e.target.value)}
                    error={errors}
                    isValid={!errors.password && password != "" && canSave}
                  />
                  <PasswordControl
                    style={{}}
                    password={password}
                    minLength={6}
                    minUppercase={1}
                    minLowercase={3}
                    minNumber={1}
                    setCanSave={setCanSave}
                  />
                  <Control
                    label="Confirmez votre mot de passe"
                    type="password"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    change={(e) => setPasswordConfirm(e.target.value)}
                    error={errors}
                    isValid={!errors.passwordConfirm && passwordConfirm != ""}
                  />
                  <span className="text-danger">{errors.other}</span>
                  {canSave && passwordsEqual && (
                    <div className="text-center">
                      <button
                        className={
                          "btn mt-3  btn-primary btn-sm animated zoomIn faster"
                        }
                        type="submit"
                      >
                        Enregistrer
                      </button>
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      {/* <div className="text-center mt-5">
        <CanvasParticle id="canvas2" image64={logoPercevalSmall} radius={60} />
      </div> */}
    </Page>
  );
};

export default RenewPassword;
