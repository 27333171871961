import React, { useEffect } from "react";
import Loader from "../../common/Loader";
import Page from "../../common/layout/Page";
import MesFacturesList from "./MesFacturesList";
import useMesFactures from "./useMesFactures";

const MesFacturesPage = () => {
  const { isLoadingFactures, factures, getFactures } = useMesFactures();

  useEffect(() => {
    getFactures();
  }, []);

  return (
    <Page
      container="container-fluid"
      title={
        <div className="text-left">
          <p
            className="custom-p pt-2 font-weight-normal"
            style={{ color: "black", lineHeight: "20px" }}
          >
            🔖 <strong>Retrouvez ici toutes vos factures</strong>.<br />
            Les paiements par prélèvement SEPA sont validés par votre banque
            sous quelques jours,{" "}
            <strong>
              la facture est disponible une fois le paiement effectué
            </strong>
            .
          </p>
        </div>
      }
      back="/"
      style={{ maxWidth: 800, margin: "0 auto" }}
    >
      {isLoadingFactures ? <Loader /> : <MesFacturesList factures={factures} />}
    </Page>
  );
};

export default MesFacturesPage;
