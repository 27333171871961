import React, { useEffect, useState } from "react";

const PjPoliciesPremiumList = ({ users }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (users.length) {
      var allClients = [];
      users.forEach((user) => {
        user.clients.map((client) => {
          if (
            client.policies.filter((c) => c.isGozen && c.isGozenPremium).length
          ) {
            allClients.push(client.company);
          }
        });
      });
      setClients(allClients);
    }
  }, [users]);

  var hasMany = clients.length > 1;

  return (
    <>
      Doublez la mise et profitez de services supplémentaires
      {clients.length ? (
        " pour "
      ) : (
        <>
          .<br /> Vous pouvez rajouter ces garanties à tout moment.
        </>
      )}
      <strong>
        {clients.map((c, ck) => {
          var str = `${c}`;
          if (clients.length > 1) {
            if (ck == clients.length - 1) {
              str = " et " + str;
            } else {
              if (ck > 0) str = ", " + str;
            }
          }
          return str;
        })}
        {clients.length ? "." : ""}
      </strong>
    </>
  );
};

export default PjPoliciesPremiumList;
