const constructionTips = {
  buildingArea: {
    title: "Superficie à assurer",
    content: 
      <>
          <p>C’est la surface totale à assurer. À savoir l’<strong>addition des surfaces occupées en rez-de-chaussée, et sur chacun des niveaux (dont les mezzanines, caves, sous-sols), des bâtiments et dépendances</strong>. La surface étant calculée à partir de l’extérieur des murs de façades (Surface Hors d’Oeuvre Brute : SHOB).</p>
          <p><strong>Comptent pour moitié de leur surface</strong> : les loggias, terrasses non maçonnées fixes ou démontables attenantes ou non aux bâtiments, terrasses maçonnées ou fixées au sol, toitures-terrasses utilisées pour l’activité professionnelle, combles, greniers, buanderies, box, garages, emplacements de parkings clos.</p>
          <p><strong>Les balcons et toitures-terrasses non utilisés pour l’activité professionnelle ne comptent pas.</strong></p>
          <p><strong>Bon à savoir</strong> : l’Assuré bénéficie d’une tolérance de 10% (maximum) entre la superficie réelle et la superficie déclarée à l’Assureur, sans que cela ne lui porte préjudice en cas de sinistre.</p>
      </>
    },
    isWaiverOfRecourse: {
      title: "Renonciation à recours",
      content: 
        <>
          <p><strong>En matière de contrat de location commerciaux et professionnels, le propriétaire et le locataire peuvent choisir d’aménager certaines règles en mettant en place une clause de renonciation à recours.</strong><br />Par définition, cette renonciation consistera en l’engagement de ne pas exercer de recours contre une personne responsable d’un dommage. </p>
          <ul style={{paddingLeft: "5%"}}>
            <li>La renonciation peut être <strong>unilatérale</strong>, c’est-à-dire qu’une seule partie au bail s’engage à renoncer à exercer un recours contre son cocontractant. Ainsi, le locataire bénéficiant de la renonciation à recours consentie par son bailleur ne verra pas sa responsabilité locative engagée vis-à-vis du propriétaire du bien loué. Inversement, une renonciation à recours en faveur du bailleur assurera à ce dernier l’absence de tout recours du locataire.</li>
            <li>La renonciation peut être <strong>réciproque</strong>. Dans ce dernier cas, chacune des parties renonce mutuellement à exercer son recours à l’encontre de l’autre. Ce type de renonciation est fréquemment rencontré lorsqu’une communauté d’intérêt entre le propriétaire et le locataire existe. C’est l’hypothèse d’un bail signé entre une SCI propriétaire des lieux et une société locataire ayant le même gérant.</li>
          </ul>
          <p>En général les clauses de renonciation à recours entre propriétaire et locataires sont étendues à leurs assureurs respectifs, afin d’écarter tout recours à l’encontre de l’assureur du responsable. </p>
          <p><strong>Bon à savoir</strong> : l’insertion d’une clause de renonciation à recours constitue une aggravation de risque, qui a pour effet d’augmenter la cotisation d’assurance.</p>
      </>
    },
    waiverOfRecourseType: {
      title: "Type de renonciation à recours",
      content:
        <>
          <p><strong>En matière de contrat de location commerciaux et professionnels, le propriétaire et le locataire peuvent choisir d’aménager certaines règles en mettant en place une clause de renonciation à recours.</strong><br />Par définition, cette renonciation consistera en l’engagement de ne pas exercer de recours contre une personne responsable d’un dommage. </p>
          <ul style={{paddingLeft: "5%"}}>
            <li>La renonciation peut être <strong>unilatérale</strong>, c’est-à-dire qu’une seule partie au bail s’engage à renoncer à exercer un recours contre son cocontractant. Ainsi, le locataire bénéficiant de la renonciation à recours consentie par son bailleur ne verra pas sa responsabilité locative engagée vis-à-vis du propriétaire du bien loué. Inversement, une renonciation à recours en faveur du bailleur assurera à ce dernier l’absence de tout recours du locataire.</li>
            <li>La renonciation peut être <strong>réciproque</strong>. Dans ce dernier cas, chacune des parties renonce mutuellement à exercer son recours à l’encontre de l’autre. Ce type de renonciation est fréquemment rencontré lorsqu’une communauté d’intérêt entre le propriétaire et le locataire existe. C’est l’hypothèse d’un bail signé entre une SCI propriétaire des lieux et une société locataire ayant le même gérant.</li>
          </ul>
          <p>En général les clauses de renonciation à recours entre propriétaire et locataires sont étendues à leurs assureurs respectifs, afin d’écarter tout recours à l’encontre de l’assureur du responsable. </p>
          <p><strong>Bon à savoir</strong> : l’insertion d’une clause de renonciation à recours constitue une aggravation de risque, qui a pour effet d’augmenter la cotisation d’assurance.</p>
        </>
    }
};

export default constructionTips;
