import React from "react";
import { colors } from "../../helpers/colors";
import LoaderSvg from "./LoaderSvg";

const Loader = ({ size = 24, color = colors.success, withContainer = false, containerStyle = {}, text = false }) =>
  withContainer ? (
    <div
      className="w-100 h-100 d-flex align-items-center justify-content-center flex-column animated fadeIn slower"
      style={containerStyle}
    >
      <LoaderSvg color={color} size={size} />
      {text && <b className="mb-2">{text}</b>}
    </div>
  ) : (
    <div className="d-flex flex-column align-items-center justify-content-center   animated fadeIn slower">
      {/* <ClipLoader color={color} size={size} /> */}
      {/* <img src="/images/loader.svg" style={{ width: size, height: size }} /> */}
      <LoaderSvg color={color} size={size} />
      {text && <b className="mb-2">{text}</b>}
    </div>
  );

export default Loader;
