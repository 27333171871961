import React, { useEffect, useState } from "react";
import InputsBlock from "./InputsBlock";
import ConstructionInputs from "./Construction/ConstructionInputs";
import FundsInputs from "./Funds/FundsInputs";
import StockageInputs from "./Stockage/StockageInputs";
import BuildingInputs from "./Building/BuildingInputs";
import useFormState from "./useFormState";
import Loader from "../../../common/Loader";
import RisksInputs from "./Risks/RisksInputs";
import AccidentInputs from "./Accident/AccidentInputs";
import AccidentInputs2 from "./Accident2/AccidentInputs2";
import FormContainer from "../FormContainer/FormContainer";
import GlobalInfosInputs from "./GlobalInfos/GlobalInfosInputs";
import HeadOfficeInfosInputs from "./headOfficeInfos/HeadOfficeInfosInputs";
import { useHistory } from "react-router-dom";

let featureItemTimeout = null;

const MRPForm = ({ clientId }) => {
    const { inputsConfig, apiData, formData, actions, errors, mrpForm } = useFormState();
    const history = useHistory();

    const [blocksWithError, setBlocksWithError] = useState([]);

    useEffect(() => {
        if (mrpForm.isSaved) {
            history.push(`/forms/${clientId}`);
        }
    }, [mrpForm.isSaved]);
    useEffect(() => {
        if (errors && Object.values(errors).length > 0) {
            var errs = Object.keys(errors);
            var withError = [];
            Object.keys(formData).forEach((block) => {
                var blockObj = formData[block];
                Object.keys(blockObj).forEach((attribute, atk) => {
                    if (errs.includes(attribute)) {
                        withError.push(block);
                    }
                });
            });
            setBlocksWithError(withError);
        } else {
            setBlocksWithError([]);
        }
    }, [errors]);

    useEffect(() => {
        setTimeout(() => {
            var elem = document.querySelector(".hasError");
            if (elem) {
                elem.scrollIntoView();
                clearTimeout(featureItemTimeout);
                featureItemTimeout = setTimeout(() => {
                    window.scrollTo(0, window.scrollY - 90);
                }, 800);
            }
        }, 700);
    }, [blocksWithError]);

    return Object.values(inputsConfig).length > 0 && apiData && mrpForm.isLoaded ? (
        <FormContainer onSubmit={actions.saveForm} isSaving={mrpForm.isSaving}>
            <InputsBlock
                title="Informations de l'entreprise"
                forceOpen={blocksWithError.includes("headOfficeInfosInputs")}
            >
                <HeadOfficeInfosInputs />
            </InputsBlock>
            <InputsBlock
                title="Informations de l'établissement"
                forceOpen={blocksWithError.includes("globalInfosInputs")}
            >
                <GlobalInfosInputs />
            </InputsBlock>
            <InputsBlock title="Locaux" forceOpen={blocksWithError.includes("constructionInputs")}>
                <ConstructionInputs />
            </InputsBlock>
            <InputsBlock
                title="Valeurs des biens"
                forceOpen={blocksWithError.includes("stockageInputs")}
            >
                <StockageInputs />
            </InputsBlock>
            <InputsBlock title="Situation" forceOpen={blocksWithError.includes("fundsInputs")}>
                <FundsInputs />
            </InputsBlock>
            <InputsBlock
                title="Environnement du site"
                forceOpen={blocksWithError.includes("buildingInputs")}
            >
                <BuildingInputs />
            </InputsBlock>
            <InputsBlock title="Antécédents" forceOpen={blocksWithError.includes("risksInputs")}>
                <RisksInputs />
            </InputsBlock>
            <InputsBlock
                title="Garanties 1/2"
                forceOpen={blocksWithError.includes("accident1Inputs")}
            >
                <AccidentInputs />
            </InputsBlock>
            <InputsBlock
                title="Garanties 2/2"
                forceOpen={blocksWithError.includes("accident2Inputs")}
            >
                <AccidentInputs2 />
            </InputsBlock>
        </FormContainer>
    ) : (
        <Loader />
    );
};

export default MRPForm;
