import { atom, useAtom } from "jotai";
import dataService from "../../helpers/dataService";

const insuranceCompaniesAtom = atom([]);

const useInsuranceCompanies = () => {
  const [insuranceCompanies, setInsuranceCompanies] = useAtom(insuranceCompaniesAtom);

  const onGetInsuranceCompanies = () => {
    dataService.get(`companies`, setInsuranceCompanies);
  };

  return {
    insuranceCompanies,
    onGetInsuranceCompanies,
  };
};

export default useInsuranceCompanies;
