import React, { useEffect, useState } from "react";

const PjPoliciesList = ({ users }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (users.length) {
      var allClients = [];
      users.forEach((user) => {
        user.clients.map((client) => {
          if (client.policies.filter((c) => c.isGozen).length) {
            allClients.push(client.company);
          }
        });
      });
      setClients(allClients);
    }
  }, [users]);

  var hasMany = clients.length > 1;
  var hasNone = clients.length == 0;

  return hasNone ? (
    <>
      Soyez informé·e et accompagné·e dans la défense de vos intérêts par + de
      90 juristes spécialisés.
    </>
  ) : (
    <>
      <strong>
        {clients.map((c, ck) => {
          var str = `${c}`;
          if (clients.length > 1) {
            if (ck == clients.length - 1) {
              str = " et " + str;
            } else {
              if (ck > 0) str = ", " + str;
            }
          }
          return str;
        })}
      </strong>{" "}
      {hasMany ? "sont" : "est"} informée{hasMany ? "s" : ""} et accompagnée
      {hasMany ? "s" : ""} dans la défense de {hasMany ? "leurs" : "ses"}{" "}
      intérêts par + de 90 juristes spécialisés.
    </>
  );
};

export default PjPoliciesList;
